import {
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from "react-query";

import { useAuth } from "../../sdk/hooks";
import { ICustomerReviewDetails } from "../../sdk/types";
import { api } from "../../sdk/utils";

type TUseQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryKey extends QueryKey,
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "queryFn">;

export interface IUseGetCustomerReviews {
    options?: TUseQueryOptions<any, unknown, ICustomerReviewDetails, string>;
    limit?: number;
}

export const useGetCustomerReviews = (
    parameters: IUseGetCustomerReviews,
): UseQueryResult<ICustomerReviewDetails, unknown> => {
    const { limit, options } = parameters;
    const auth = useAuth();

    return useQuery({
        queryKey: options?.queryKey ?? [],
        queryFn: async (props) => {
            const { pageParam = "" } = props;

            return await api.v1_1.CustomerReviews.list({
                token: auth.token,
                limit: limit ?? 200,
                cursor: pageParam,
            });
        },
        getNextPageParam: (lastPage) => {
            if (!lastPage || !lastPage.reviews) {
                return undefined;
            }
            return lastPage.reviews;
        },
    });
};
