import { FunctionComponent, ReactElement } from "react";

import { Chip, styled } from "@mui/material";

import { statusChipBgColors, statusChipColors } from "../../utils/constants";

interface IStatusChipProps {
    value: number;
    label: string;
}

const StyledChip = styled(Chip, {
    shouldForwardProp: (propName) => propName !== "value",
})<IStatusChipProps>`
    width: 100%;

    background-color: ${({ value }) => statusChipBgColors[value]};
    color: ${({ value }) => statusChipColors[value]};

    text-transform: uppercase;
    font-weight: 500;
`;

export const StatusChip: FunctionComponent<IStatusChipProps> = (
    props: IStatusChipProps,
): ReactElement => {
    const { value, label } = props;
    return <StyledChip label={label} value={value} size="small" />;
};
