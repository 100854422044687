import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAuth } from "../../sdk/hooks/useAuth";
import { IAdminGroup } from "../../sdk/types";
import { adminGroups } from "../../sdk/utils/api";

export interface IDeleteAdminGroupParameters {
    id: string;
}

export const useDeleteAdminGroup = (
    invalidQueryKeys: string | string[],
): UseMutationResult<IAdminGroup, unknown, IDeleteAdminGroupParameters> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation<IAdminGroup, unknown, IDeleteAdminGroupParameters>({
        mutationFn: async (parameters: IDeleteAdminGroupParameters) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot delete admin group without authorization token.",
                );
            }
            return await adminGroups.AdminGroup.delete({
                token: auth.token,
                ...parameters,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(invalidQueryKeys);
        },
    });
};
