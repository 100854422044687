import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { api } from "../../utils";

export interface IDeleteEntitySchemaParameters {
    schemaId: string;
}

export const useDeleteEntitySchema = (
    queryKey: string | string[],
): UseMutationResult<void, unknown, IDeleteEntitySchemaParameters> => {
    const auth = useAuth();
    return useMutation({
        mutationFn: async (parameters: IDeleteEntitySchemaParameters) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot delete entity schema without authorization token.",
                );
            }

            return await api.EntitySchema.delete({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
