import { useMutation, useQueryClient } from "react-query";

import { api } from "../utils";

import { useAuth } from "./useAuth";

export interface IDeleteCustomerAddressParameters {
    id: string;
    customerId: string;
}

export const useDeleteCustomerAddress = (invalidQueryKeys: string[] = []) => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    if (!auth.token) {
        throw new Error(
            "Cannot delete customer address without a valid token.",
        );
    }

    const {
        mutate,
        mutateAsync,
        isLoading,
        data,
        error,
        isError,
        isIdle,
        isSuccess,
    } = useMutation({
        mutationFn: async (variables: IDeleteCustomerAddressParameters) =>
            await api.addresses.deleteCustomerAddress(
                variables.id,
                variables.customerId,
                auth.token!,
            ),
        onSuccess: () => {
            queryClient.invalidateQueries(invalidQueryKeys);
        },
    });

    return {
        mutate,
        mutateAsync,
        isLoading,
        data,
        error,
        isError,
        isIdle,
        isSuccess,
    };
};
