import { FunctionComponent, ReactElement, useCallback } from "react";

import { ButtonBase, Paper, Typography, styled } from "@mui/material";

import { Delete, ImageNotSupported } from "@mui/icons-material";

import { Counter } from "../../../components/common";
import { useUpdateCartLineItem } from "../../../sdk/hooks";
import { ICartLineItem } from "../../../sdk/types";
import { getCompareAtPrice } from "../../../sdk/utils/misc";
import { formatCurrency } from "../../../sdk/utils/numbers";

const ProductImage = styled("img")`
    width: 60px;
    height: auto;
`;

const Title = styled(Typography)`
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;

    color: #1a1a1a;
`;

const Price = styled(Typography)`
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #1a1a1a;
    text-align: left;

    grid-template-rows: 1fr 1fr 1fr;
`;

const ComparePrice = styled(Typography)`
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #697386;
    text-decoration: line-through;
`;

const ProductDetails = styled("div")`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${({ theme }) => theme.spacing(1)};
    margin: ${({ theme }) => theme.spacing(0, "auto", 0, 2)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        margin: ${({ theme }) => theme.spacing(0, "auto", 0, 2)};
    }
`;

const Wrapper = styled(Paper)`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    place-items: center;

    padding: ${({ theme }) => theme.spacing(2, 3)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        grid-template-columns: 1fr 5fr 1fr;
    }
`;

const Savings = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const MobileContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const Units = styled(Typography)`
    font-size: 12px;
`;

const CounterWrapper = styled(Paper)`
    width: 85px;
    height: 32px;

    place-items: center;
`;

const CancelButton = styled(ButtonBase)`
    align-self: start;
    justify-self: end;

    color: #7b7b7b;

    margin-top: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        margin: 0;
    }
`;

const BrokenImage = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 5/6;

    background-color: #f4f4f4;
`;

export interface ICartItemProps {
    item: ICartLineItem;
    cartId: string;
    onChange: any;
}

export const CartItem: FunctionComponent<ICartItemProps> = (
    props: ICartItemProps,
): ReactElement => {
    const { item, cartId, onChange } = props;
    const updateCartLineItemMutation = useUpdateCartLineItem();

    const disabled =
        !cartId || updateCartLineItemMutation.isLoading || !item.variantId;

    const handleUpdateCart = useCallback(
        (quantity: number) => {
            if (!cartId) {
                throw new Error("Buy Now should not be enabled without cart.");
            }

            updateCartLineItemMutation.mutate(
                {
                    cartId,
                    lineItemId: item.variantId,
                    quantity,
                },
                {
                    onSuccess: (cart) => {
                        onChange(cart);
                    },
                },
            );
        },
        [cartId, item.variantId, onChange, updateCartLineItemMutation],
    );

    const handleRemoveItem = useCallback(() => {
        handleUpdateCart(0);
    }, [handleUpdateCart]);

    const savedAmount = item.mrp - item.price;

    return (
        <Wrapper elevation={0} variant="outlined" id="cart--line-item--count">
            {item.imageUrl && (
                <ProductImage src={item.imageUrl} alt={item.title} />
            )}
            {!item.imageUrl && (
                <BrokenImage>
                    <ImageNotSupported />
                </BrokenImage>
            )}
            <ProductDetails>
                <Title>{item.title}</Title>
                <Units>
                    <CounterWrapper elevation={0} variant="outlined">
                        <Counter
                            value={item.quantity}
                            onChange={handleUpdateCart}
                            loading={disabled}
                        />
                    </CounterWrapper>
                </Units>
                <MobileContainer>
                    <Price id="cart--line-item--amount-data">
                        {formatCurrency(
                            parseFloat(item.price.toString()) * item.quantity,
                        )}
                        <Savings>
                            <ComparePrice id="cart--line-item--mrp">
                                {getCompareAtPrice(
                                    false,
                                    savedAmount,
                                    item,
                                    item,
                                )}
                            </ComparePrice>
                        </Savings>
                    </Price>
                </MobileContainer>
            </ProductDetails>
            <CancelButton
                onClick={handleRemoveItem}
                disabled={disabled}
                id="cart-item--button--remove"
            >
                <Delete />
            </CancelButton>
        </Wrapper>
    );
};
