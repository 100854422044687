import {
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from "react-query";

import { useAuth } from "../../sdk/hooks";
import { IInflatedEntitySchema, api } from "../../utils";

export type TUseQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryKey extends QueryKey,
> = Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn"
>;

export const useGetEntitySchemaById = (
    queryKey: string[],
    schemaId: string,
    options?: TUseQueryOptions<any, unknown, IInflatedEntitySchema, string[]>,
): UseQueryResult<IInflatedEntitySchema> => {
    const auth = useAuth();

    return useQuery([schemaId], async () => {
        if (!auth.token) {
            throw new Error(
                "Cannot fetch entity schema without authorization token.",
            );
        }

        return await api.EntitySchema.getById(schemaId, auth.token);
    });
};
