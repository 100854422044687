import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { ICart } from "../types";
import { carts } from "../utils/api";

import { useAuth } from "./useAuth";

export interface IUseApplyDiscountMutationParameters {
    cartId: string;
    discountCode: string;
}

export const useApplyDiscount = (
    invalidQueryKeys: string[] = [],
): UseMutationResult<ICart, unknown, IUseApplyDiscountMutationParameters> => {
    const queryClient = useQueryClient();
    const { token } = useAuth();

    return useMutation<ICart, unknown, IUseApplyDiscountMutationParameters>({
        mutationFn: async (
            parameters: IUseApplyDiscountMutationParameters,
        ): Promise<ICart> => {
            const { cartId, discountCode } = parameters;

            if (!token) {
                throw new Error("Cannot apply discount without a valid token.");
            }

            return await carts.applyDiscountCode({
                cartId,
                discountCode,
                token,
            });
        },
        onSuccess: async () => {
            queryClient.invalidateQueries({
                queryKey: invalidQueryKeys,
            });
        },
    });
};
