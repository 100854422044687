import { useCallback } from "react";

import { TSetterFunction } from "../types/common";

export const useToggleCallbacks = (setter: TSetterFunction<boolean>) => {
    const handleTrue = useCallback(() => {
        setter(true);
    }, [setter]);

    const handleFalse = useCallback(() => {
        setter(false);
    }, [setter]);

    return [handleTrue, handleFalse];
};
