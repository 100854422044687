import { FunctionComponent, ReactElement, ReactNode, useCallback } from "react";

import axios, { AxiosError } from "axios";
import lodash from "lodash";
import { useSnackbar } from "notistack";

import * as contexts from "../types/contexts";
import { MessageContext } from "../contexts";

const durations = { long: 6000, short: 3000 };

export interface IMessageProviderProps {
    children: ReactNode;
}

export const MessageProvider: FunctionComponent<IMessageProviderProps> = (
    props: IMessageProviderProps,
): ReactElement => {
    const { children } = props;

    const { enqueueSnackbar } = useSnackbar();

    const handleShowError = useCallback(
        (
            error: string | Error | AxiosError | unknown,
            duration0: contexts.TMessageDuration = "long",
        ) => {
            let message = "";
            if (axios.isAxiosError(error)) {
                message = error.response?.data.message;
            } else if (error instanceof Error) {
                message = error.message;
            } else {
                message = `${error}`;
            }
            message = lodash.unescape(message);

            enqueueSnackbar(message, {
                variant: "error",
                autoHideDuration: durations[duration0],
            });
        },
        [enqueueSnackbar],
    );

    const handleShowSuccess = useCallback(
        (message0: string, duration0: contexts.TMessageDuration = "long") => {
            enqueueSnackbar(message0, {
                variant: "success",
                autoHideDuration: durations[duration0],
            });
        },
        [enqueueSnackbar],
    );

    return (
        <MessageContext.Provider
            value={{
                showError: handleShowError,
                showSuccess: handleShowSuccess,
            }}
        >
            {children}
        </MessageContext.Provider>
    );
};
