import { CSSProperties, ReactElement } from "react";
import React from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    IconButton,
    Typography,
    styled,
} from "@mui/material";
import Grid from "@mui/material/Grid";

import { Delete } from "@mui/icons-material";
import { ExpandMore } from "@mui/icons-material";

import {
    ArrayFieldTemplateItemType,
    FormContextType,
    RJSFSchema,
    StrictRJSFSchema,
} from "@rjsf/utils";

const Root = styled(Container)`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: ${({ theme }) => theme.spacing(1)};
`;

const AccordianContainer = styled(Accordion)`
    border: 1px solid ${({ theme }) => theme.palette.divider};
    box-shadow: none;

    margin-top: ${({ theme }) => theme.spacing(2)};
`;

const GridContainer = styled(Grid)`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const GridItem = styled(Grid)`
    overflow: auto;
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(2)};
    align-items: center;

    padding-top: ${({ theme }) => theme.spacing(1)};
`;

const ActionsGrid = styled(Grid)`
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(1)};
`;

const Content = styled("div")`
    flex-grow: 1;
`;

const DeleteButton = styled(IconButton)`
    margin-top: ${({ theme }) => theme.spacing(2)};
`;

const btnStyle: CSSProperties = {
    flex: 1,
    fontWeight: "bold",
    minWidth: 0,
};

/**
 * The `ArrayFieldItemTemplate` component is the template used to render an
 * item of an array.
 *
 * @param props - The `ArrayFieldTemplateItemType` props for the component.
 */
export const ArrayFieldItemTemplate = <
    T = any,
    S extends StrictRJSFSchema = RJSFSchema,
    F extends FormContextType = any,
>(
    props: ArrayFieldTemplateItemType<T, S, F>,
): ReactElement => {
    const {
        children,
        disabled,
        hasToolbar,
        hasMoveDown,
        hasMoveUp,
        hasRemove,
        index,
        onDropIndexClick,
        onReorderClick,
        readonly,
        uiSchema,
        registry,
    } = props;
    const { MoveDownButton, MoveUpButton, RemoveButton } =
        registry.templates.ButtonTemplates;

    const childFormData = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return (child as React.ReactElement<any>).props.formData;
        }
    });

    return (
        <Root>
            <AccordianContainer>
                <AccordionSummary
                    expandIcon={
                        <IconButton>
                            <ExpandMore />
                        </IconButton>
                    }
                >
                    <Typography>
                        {childFormData[0]?.name ?? `Item ${index + 1}`}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <GridItem item={true} xs={true}>
                        <Content>{children}</Content>
                    </GridItem>
                </AccordionDetails>
            </AccordianContainer>
            {hasRemove && !readonly && (
                <DeleteButton
                    style={btnStyle}
                    disabled={disabled || readonly}
                    onClick={onDropIndexClick(index)}
                >
                    <Delete />
                </DeleteButton>
            )}
        </Root>
    );
};
