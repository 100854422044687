import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import {
    FormControl,
    FormHelperText,
    Icon,
    InputAdornment,
    InputLabel,
    MenuItem,
    ListItemText as MuiListItemText,
    Select,
    TextField,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { ArrowDropDown, InfoRounded } from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik } from "formik";
import lodash from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import {
    CircularLoader,
    Paper,
    WorkspaceToolbar,
} from "../../components/common";
import {
    useCreateProductVariant,
    useGetProduct,
    useUpdateProductVariant,
} from "../../hooks";
import { useMessage } from "../../sdk/hooks";
import { IProductVariant } from "../../types";
import { ProductStatus, productStatusByEnum } from "../../utils/enums";

import { VariantContainer } from "./VariantContainer";

const Root = styled("div")`
    padding: ${({ theme }) => theme.spacing(1, 3)};
    min-height: 86vh;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1.5)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
        padding: ${theme.spacing(0, 2)};
    }
    
  `};
`;

const Section = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      flex-direction:column;
      gap: ${theme.spacing(3)};
    }
    
  `}
`;

const SectionTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    color: #626262;

    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const SelectField = styled(Select)`
    height: 40px;
`;

const RightContainer = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};
    flex: 1;
    max-width: 800px;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      max-width: unset;
    }
    
  `}
`;

const LeftContainer = styled("div")``;

const TextFieldWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(4)};
`;

const ListItemText = styled(MuiListItemText)`
    text-transform: capitalize;
`;

const Row = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    width: 100%;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          flex-direction: column;
    }
  `}
`;

const TextFieldContainer = styled("div")`
    height: 40px;
    width: 100%;
`;

const StyledArrowDown = styled(ArrowDropDown)`
    margin-right: ${({ theme }) => theme.spacing(3)};
`;

const validationSchema = Yup.object({
    title: Yup.string().min(1).required("Please enter a valid variant name"),
    shopifyVariantId: Yup.string()
        .min(1)
        .required("Please enter a valid shopify variant identifier"),
    price: Yup.number()
        .integer()
        .required("Please enter a selling price for the variant"),
    shippingPrice: Yup.number()
        .integer()
        .required("Please enter a shipping price for the variant"),
    mrp: Yup.number().integer().required("Please enter a MRP for the variant"),
    sku: Yup.string().required(),
    position: Yup.number().integer().required(),
    status: Yup.number()
        .integer()
        .oneOf([
            ProductStatus.ARCHIVED,
            ProductStatus.DRAFT,
            ProductStatus.PUBLISHED,
        ])
        .required(),
});

const statuses = Object.keys(productStatusByEnum);

export const EditProductVariantScreen: FunctionComponent = (): ReactElement => {
    const params = useParams();
    const newProduct = params.variantId === "new";
    const navigate = useNavigate();
    const message = useMessage();

    const [selectedVariant, setSelectedVariant] = useState<IProductVariant>({
        id: "",
        title: "",
        shopifyVariantId: "",
        price: 0,
        shippingPrice: 0,
        mrp: 0,
        sku: "",
        productId: "",
        position: 0,
        status: ProductStatus.DRAFT,
    });
    const [initialValues, setInitialValues] = useState<IProductVariant>({
        id: "",
        title: "",
        shopifyVariantId: "",
        price: 0,
        shippingPrice: 0,
        mrp: 0,
        sku: "",
        productId: "",
        position: 0,
        status: ProductStatus.DRAFT,
    });

    const createMutation = useCreateProductVariant("products");
    const updateMutation = useUpdateProductVariant("products");

    const {
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        handleBlur,
        dirty,
        isValid,
    } = useFormik<IProductVariant>({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: (values0) => {
            if (newProduct) {
                createMutation.mutate(
                    {
                        title: values0.title,
                        productId: params?.productId ?? "",
                        shopifyVariantId: values0.shopifyVariantId,
                        price: values0.price * 100,
                        mrp: values0.mrp * 100,
                        shippingPrice: values0.shippingPrice * 100,
                        sku: values0.sku,
                        position: values0.position,
                        status: values0.status,
                    },
                    {
                        onSuccess: (product) => {
                            const createdVariant = lodash.differenceWith(
                                product.variants,
                                getQuery.data?.variants ?? [],
                                (variant1, variant2) =>
                                    variant1.id === variant2.id,
                            );

                            if (createdVariant.length === 1) {
                                navigate(
                                    `/products/${params?.productId}/variants/${createdVariant[0].id}/edit`,
                                    { replace: true },
                                );
                                message.showSuccess(
                                    "Created product variant successfully",
                                );
                            } else {
                                message.showError(
                                    "Something went wrong. More than one variant was created.",
                                );
                            }
                        },
                        onError: (error) => {
                            message.showError(error);
                        },
                    },
                );
            } else {
                updateMutation.mutate(
                    {
                        id: params.variantId ?? "",
                        title: values0.title,
                        productId: params?.productId ?? "",
                        shopifyVariantId: values0.shopifyVariantId,
                        price: values0.price * 100,
                        mrp: values0.mrp * 100,
                        shippingPrice: values0.shippingPrice * 100,
                        sku: values0.sku,
                        position: values0.position,
                        status: values0.status,
                    },
                    {
                        onSuccess: () => {
                            message.showSuccess(
                                "Updated product variant successfully",
                            );
                        },
                        onError: (error) => {
                            message.showError(error);
                        },
                    },
                );
            }
        },
    });

    const getQuery = useGetProduct(
        ["products", params.productId ?? "", params.variantId ?? ""],
        params.productId ?? "",
        {
            enabled:
                params.productId !== undefined && params.productId !== "new",
            onSuccess: (product) => {
                const variant = product.variants.find(
                    (variant) => variant.id === params.variantId,
                );
                if (!variant) {
                    if (!newProduct) {
                        message.showError("Selected variant not found");
                    }
                } else {
                    setSelectedVariant(variant);
                    setInitialValues({
                        id: variant.id,
                        title: variant.title,
                        shopifyVariantId: variant.shopifyVariantId,
                        price: variant.price,
                        shippingPrice: variant.shippingPrice,
                        mrp: variant.mrp,
                        sku: variant.sku,
                        productId: variant.productId,
                        position: variant.position,
                        status: variant.status,
                    });
                }

                if (newProduct) {
                    setInitialValues((values) => ({
                        ...values,
                        position: product.variants.length + 1,
                    }));
                }
            },
        },
    );

    const handleSave = useCallback(() => handleSubmit(), [handleSubmit]);

    if (getQuery.isLoading) {
        return <CircularLoader />;
    }

    return (
        <>
            <WorkspaceToolbar
                title={
                    newProduct
                        ? "Create Product Variant"
                        : "Edit Product Variant"
                }
                enableSearch={false}
                breadcrumbs={[
                    { title: "Products", link: "/products" },
                    {
                        title: getQuery.data?.title ?? "",
                        link: `/products/${params.productId}`,
                    },
                    {
                        title: newProduct
                            ? "Create Product Variant"
                            : selectedVariant?.title ?? "",
                        link: `/products/${params.productId}`,
                    },
                ]}
                actions={
                    <>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="small"
                            endIcon={<Icon>check_circle</Icon>}
                            onClick={handleSave}
                            disabled={!dirty || !isValid}
                            loading={
                                createMutation.isLoading ||
                                updateMutation.isLoading
                            }
                        >
                            Save
                        </LoadingButton>
                    </>
                }
            />
            <Root>
                <Section>
                    <LeftContainer>
                        <VariantContainer
                            productId={params.productId ?? ""}
                            currentVariantId={params.variantId ?? ""}
                            variants={getQuery.data?.variants ?? []}
                        />
                    </LeftContainer>
                    <RightContainer>
                        {/* General */}
                        <Paper>
                            <SectionTitle>General</SectionTitle>
                            <TextFieldWrapper>
                                <Row>
                                    <TextFieldContainer>
                                        <TextField
                                            required={true}
                                            label="Title"
                                            fullWidth={true}
                                            name="title"
                                            value={values.title}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            error={
                                                Boolean(errors.title) &&
                                                touched.title
                                            }
                                            helperText={
                                                touched.title && errors.title
                                            }
                                            placeholder="Indian Oud"
                                        />
                                    </TextFieldContainer>
                                </Row>
                                <Row>
                                    <TextFieldContainer>
                                        <TextField
                                            required={true}
                                            label="SKU"
                                            fullWidth={true}
                                            name="sku"
                                            value={values.sku}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            error={
                                                Boolean(errors.sku) &&
                                                touched.sku
                                            }
                                            helperText={
                                                touched.sku && errors.sku
                                            }
                                        />
                                    </TextFieldContainer>
                                </Row>
                                <Row>
                                    <TextFieldContainer>
                                        <TextField
                                            required={true}
                                            label="Shopify Variant Identifier"
                                            fullWidth={true}
                                            name="shopifyVariantId"
                                            value={values.shopifyVariantId}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            error={
                                                Boolean(
                                                    errors.shopifyVariantId,
                                                ) && touched.shopifyVariantId
                                            }
                                            helperText={
                                                touched.shopifyVariantId &&
                                                errors.shopifyVariantId
                                            }
                                        />
                                    </TextFieldContainer>
                                    <TextFieldContainer />
                                </Row>
                                <Row>
                                    <TextFieldContainer>
                                        <FormControl
                                            size="small"
                                            fullWidth={true}
                                            required={true}
                                        >
                                            <InputLabel>Status</InputLabel>
                                            <SelectField
                                                label="Status"
                                                name="status"
                                                size="small"
                                                fullWidth={true}
                                                value={values.status}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                IconComponent={(props) => (
                                                    <StyledArrowDown
                                                        {...props}
                                                    />
                                                )}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            enterTouchDelay={0}
                                                            placement="top"
                                                            title={
                                                                <>
                                                                    Status of
                                                                    the product
                                                                    variant.
                                                                    Only
                                                                    published
                                                                    product
                                                                    variants are
                                                                    visible on
                                                                    the website.
                                                                </>
                                                            }
                                                        >
                                                            <InfoRounded
                                                                htmlColor="#626262"
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    </InputAdornment>
                                                }
                                                error={
                                                    Boolean(errors.status) &&
                                                    touched.status
                                                }
                                            >
                                                {statuses.map((status) => (
                                                    <MenuItem
                                                        key={status}
                                                        value={status}
                                                    >
                                                        <ListItemText
                                                            primary={
                                                                productStatusByEnum[
                                                                    Number(
                                                                        status,
                                                                    )
                                                                ]
                                                            }
                                                        />
                                                    </MenuItem>
                                                ))}
                                            </SelectField>
                                            {Boolean(errors.status) &&
                                                touched.status && (
                                                    <FormHelperText>
                                                        {errors.status}
                                                    </FormHelperText>
                                                )}
                                        </FormControl>
                                    </TextFieldContainer>
                                    <TextFieldContainer />
                                </Row>
                            </TextFieldWrapper>
                        </Paper>

                        {/* Pricing  */}
                        <Paper>
                            <SectionTitle>Pricing</SectionTitle>
                            <TextFieldWrapper>
                                <Row>
                                    <TextFieldContainer>
                                        <TextField
                                            required={true}
                                            label="Price"
                                            fullWidth={true}
                                            name="price"
                                            value={values.price}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        ₹
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={
                                                Boolean(errors.price) &&
                                                touched.price
                                            }
                                            helperText={
                                                touched.price && errors.price
                                            }
                                        />
                                    </TextFieldContainer>
                                    <TextFieldContainer>
                                        <TextField
                                            required={true}
                                            label="MRP"
                                            fullWidth={true}
                                            name="mrp"
                                            value={values.mrp}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        ₹
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={
                                                Boolean(errors.mrp) &&
                                                touched.mrp
                                            }
                                            helperText={
                                                touched.mrp && errors.mrp
                                            }
                                        />
                                    </TextFieldContainer>
                                </Row>
                                <Row>
                                    <TextFieldContainer>
                                        <TextField
                                            required={true}
                                            label="Shipping Price"
                                            fullWidth={true}
                                            name="shippingPrice"
                                            value={values.shippingPrice}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        ₹
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={
                                                Boolean(errors.shippingPrice) &&
                                                touched.shippingPrice
                                            }
                                            helperText={
                                                touched.shippingPrice &&
                                                errors.shippingPrice
                                            }
                                        />
                                    </TextFieldContainer>
                                    <TextFieldContainer />
                                </Row>
                            </TextFieldWrapper>
                        </Paper>
                    </RightContainer>
                </Section>
            </Root>
        </>
    );
};
