import React, { FunctionComponent, ReactElement, useState } from "react";

import { ThemeProvider, styled } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Outlet } from "react-router-dom";

import { AuthProvider } from "../../sdk/providers";
import { theme } from "../../utils/theme";

import { Navbar } from "./Navbar";
import { PrimaryDrawer } from "./PrimaryDrawer";

const Main = styled("main")`
    min-height: 100vh;
`;

const Container = styled("section")`
    margin-left: 280px;
    margin-top: ${({ theme }) => theme.spacing(7)};

    min-height: 100vh;

    ${({ theme }) => theme.breakpoints.down("sm")} {
        margin-left: 0px;
    }
`;

export const PrimaryLayout: FunctionComponent = (): ReactElement => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <AuthProvider>
                    <Main>
                        <Navbar onToggle={handleDrawerToggle} />
                        <PrimaryDrawer
                            open={mobileOpen}
                            onToggle={handleDrawerToggle}
                        />
                        <Container>
                            <Outlet />
                        </Container>
                    </Main>
                </AuthProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
};
