import {
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from "react-query";

import { IBulkOrder, IExternalListPage } from "../../types";
import { BulkOrder } from "../../utils/api";
import { useAuth } from "../useAuth";

type TUseQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryKey extends QueryKey,
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "queryFn">;

export interface IUseGetBulkOrdersParameters {
    cursor?: string;
    direction?: string;
    limit: number;
    options?: TUseQueryOptions<
        any,
        unknown,
        IExternalListPage<IBulkOrder>,
        string[]
    >;
}

export const useGetBulkOrders = (
    parameters: IUseGetBulkOrdersParameters,
): UseQueryResult<IExternalListPage<IBulkOrder>, unknown> => {
    const { options, cursor, direction, limit } = parameters;

    const { token } = useAuth();

    if (!token) {
        throw new Error(
            "Cannot fetch bulk orders without authentication token",
        );
    }
    return useQuery({
        queryKey: options?.queryKey ?? [],
        queryFn: async () => {
            const response = await BulkOrder.list({
                direction,
                limit,
                cursor,
                token,
            });

            return response;
        },
        ...options,
    });
};
