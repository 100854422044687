import {
    ChangeEvent,
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

import {
    Button,
    Drawer,
    Modal,
    RadioGroup,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import { AxiosError } from "axios";

import { CircularLoader } from "../../../../components/common";
import {
    useCreateCustomerAddress,
    useDeleteCustomerAddress,
    useGetCustomerAddresses,
    useMessage,
    useUpdateCustomerAddress,
} from "../../../../sdk/hooks";
import {
    IAddressFormValues,
    ICustomer,
    ICustomerAddress,
} from "../../../../sdk/types";

import { AddressCard } from "./AddressCard";
import { AddressForm } from "./AddressForm";

const Root = styled("section")`
    flex: 3;
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(0, 1)};
`;

const AddAddressButton = styled(Button)`
    font-weight: 500;
    color: #7b7b7b;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: none;
    border: 2px dotted;
    height: 64px;

    cursor: pointer;

    &:hover {
        background-color: transparent;
        box-shadow: none;
        color: #7b7b7b;
        border: 2px dotted;
    }

    padding: ${({ theme }) => theme.spacing(2)};
    margin: ${({ theme }) => theme.spacing(2, 0)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")}{
        min-width: 600px;
    }
    ${theme.breakpoints.down("sm")}{
        min-width: 100%;
    }
    `}
`;

const SavedAddresses = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};

    margin-bottom: ${({ theme }) => theme.spacing(1)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")}{
        min-width: 600px;
    }
    ${theme.breakpoints.down("sm")}{
        min-width: 100%;
    }
    `}
`;

const AddressesWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const Subtitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const MainContainer = styled("div")`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: start;

    gap: ${({ theme }) => theme.spacing(4)};

    ${({ theme }) => theme.breakpoints.down("md")} {
        flex-direction: column;
        gap: ${({ theme }) => theme.spacing(2)};
    }
`;

const ModalContainer = styled("div")`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: fit-content;
    height: fit-content;

    background-color: #fff;
    outline: 0;

    border-radius: ${({ theme }) => theme.spacing(1)};

    max-height: 100vh;
    overflow: auto;
`;

const FormContainer = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: ${({ theme }) => theme.spacing(3)};
`;

const FormDrawer = styled(Drawer)`
    .MuiDrawer-paper {
        height: 95vh;
        border-radius: ${({ theme }) => theme.spacing(2, 2, 0, 0)};
    }
`;

const CustomerContainer = styled("div")`
    display: flex;
    padding: ${({ theme }) => theme.spacing(1, 2)};
    background-color: #f2f2f2;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const CustomerLabel = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
`;

export interface ICustomerAddressesProps {
    customer: ICustomer | undefined;
    selectedAddress: ICustomerAddress | undefined;
    setSelectedAddress: React.Dispatch<
        React.SetStateAction<ICustomerAddress | undefined>
    >;
}

export const CustomerAddresses: FunctionComponent<ICustomerAddressesProps> = (
    props: ICustomerAddressesProps,
): ReactElement => {
    const { customer, selectedAddress, setSelectedAddress } = props;
    const [formOpen, setFormOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const { showError } = useMessage();
    const getAddressesQuery = useGetCustomerAddresses(
        ["addresses", customer?.id ?? ""],
        customer?.id,
    );
    const createAddressMutation = useCreateCustomerAddress([
        "addresses",
        customer?.id ?? "",
    ]);
    const updateAddressMutation = useUpdateCustomerAddress([
        "addresses",
        customer?.id ?? "",
    ]);
    const deleteAddressMutation = useDeleteCustomerAddress([
        "addresses",
        customer?.id ?? "",
    ]);

    const addresses = useMemo(
        () => getAddressesQuery.data?.records ?? [],
        [getAddressesQuery.data?.records],
    );
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    useEffect(() => {
        if (addresses.length === 1) {
            setSelectedAddress(addresses[0]);
        }
    }, [addresses, setSelectedAddress]);

    const handleEditAddress = useCallback((index: number) => {
        setEditIndex(index);
    }, []);

    const handleAddAddress = useCallback(() => {
        setFormOpen(true);
    }, []);

    const handleFormClose = useCallback(() => {
        setFormOpen(false);
        setEditIndex(-1);
    }, []);

    const handleDeleteAddress = useCallback(
        (index: number, id: string) => {
            if (customer) {
                deleteAddressMutation.mutate(
                    { id, customerId: customer.id },
                    {
                        onError: (error: any) => {
                            showError(error);
                        },
                    },
                );
            }
        },
        [customer, deleteAddressMutation, showError],
    );

    const handleCreateAddress = useCallback(
        (values: IAddressFormValues) => {
            if (customer) {
                createAddressMutation.mutate(
                    {
                        ...values,
                        customerId: customer.id,
                    },
                    {
                        onSuccess: () => {
                            setFormOpen(false);
                        },
                        onError: (error: unknown) => {
                            const message =
                                ((error as AxiosError).response?.data as any)
                                    ?.message ?? (error as Error).message;
                            if (
                                message.indexOf(
                                    "fails to match the required pattern",
                                ) >= 0
                            ) {
                                showError("The specified pincode is invalid.");
                                return;
                            }
                            showError(message);
                        },
                    },
                );
            }
        },
        [createAddressMutation, customer, showError],
    );

    const handleUpdateAddress = useCallback(
        async (address: IAddressFormValues, id?: string) => {
            if (customer) {
                await updateAddressMutation.mutateAsync(
                    { ...address, id: id!, customerId: customer.id },
                    {
                        onSuccess: () => {
                            setEditIndex(-1);
                        },
                        onError: (error: any) => {
                            showError(error);
                        },
                    },
                );
            }
        },
        [customer, showError, updateAddressMutation],
    );

    const handleSelectAddress = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setSelectedAddress(addresses[Number(event.target.value)]);
        },
        [addresses, setSelectedAddress],
    );

    const loading =
        getAddressesQuery.isLoading ||
        updateAddressMutation.isLoading ||
        createAddressMutation.isLoading ||
        deleteAddressMutation.isLoading;

    const renderForLargeScreen = () => {
        if (customer) {
            return (
                <Modal
                    open={
                        formOpen ||
                        editIndex !== -1 ||
                        (addresses.length === 0 && !getAddressesQuery.isLoading)
                    }
                    onClose={handleFormClose}
                >
                    <ModalContainer>
                        <FormContainer>
                            {(formOpen || addresses.length === 0) && (
                                <AddressForm
                                    customer={customer}
                                    onSave={handleCreateAddress}
                                    saving={createAddressMutation.isLoading}
                                    onClose={handleFormClose}
                                    closeable={addresses.length !== 0}
                                />
                            )}

                            {editIndex !== -1 && (
                                <AddressForm
                                    customer={customer}
                                    oldAddress={addresses[editIndex]}
                                    onSave={handleUpdateAddress}
                                    saving={updateAddressMutation.isLoading}
                                    onClose={handleFormClose}
                                    closeable={addresses.length !== 0}
                                />
                            )}
                        </FormContainer>
                    </ModalContainer>
                </Modal>
            );
        }
    };

    const renderForSmallScreen = () => {
        if (customer) {
            return (
                <FormDrawer
                    anchor={"bottom"}
                    open={
                        formOpen ||
                        editIndex !== -1 ||
                        (addresses.length === 0 && !getAddressesQuery.isLoading)
                    }
                    onClose={handleFormClose}
                >
                    <FormContainer>
                        {(formOpen || addresses.length === 0) && (
                            <AddressForm
                                customer={customer}
                                onSave={handleCreateAddress}
                                saving={createAddressMutation.isLoading}
                                onClose={handleFormClose}
                                closeable={addresses.length !== 0}
                            />
                        )}

                        {editIndex !== -1 && (
                            <AddressForm
                                customer={customer}
                                oldAddress={addresses[editIndex]}
                                onSave={handleUpdateAddress}
                                saving={updateAddressMutation.isLoading}
                                onClose={handleFormClose}
                                closeable={addresses.length !== 0}
                            />
                        )}
                    </FormContainer>
                </FormDrawer>
            );
        }
    };

    return (
        <>
            {loading && <CircularLoader />}
            {!loading && (
                <Root>
                    <Subtitle>Step 1: Customer Details</Subtitle>
                    <MainContainer>
                        {customer && (
                            <CustomerContainer>
                                <CustomerLabel>
                                    Selected Customer:
                                </CustomerLabel>
                                {`${customer.firstName} ${customer.lastName}, ${customer.phoneNumber}, ${customer.emailAddress}`}
                            </CustomerContainer>
                        )}
                        {customer && addresses.length > 0 && (
                            <>
                                <Subtitle>Select Address</Subtitle>
                                <SavedAddresses>
                                    <RadioGroup
                                        name="existingAddresses"
                                        onChange={handleSelectAddress}
                                        value={selectedAddress}
                                        style={{ width: "100%" }}
                                    >
                                        <AddressesWrapper>
                                            {addresses.map((address, index) => (
                                                <AddressCard
                                                    key={index}
                                                    index={index}
                                                    isSelected={
                                                        address.id ===
                                                        selectedAddress?.id
                                                    }
                                                    onEditAddress={
                                                        handleEditAddress
                                                    }
                                                    onDeleteAddress={
                                                        handleDeleteAddress
                                                    }
                                                    onSelectAddress={
                                                        handleSelectAddress
                                                    }
                                                    {...address}
                                                    address={address}
                                                />
                                            ))}
                                        </AddressesWrapper>
                                    </RadioGroup>
                                </SavedAddresses>
                            </>
                        )}
                        {customer && (
                            <AddAddressButton
                                id="address-list--button--add-new-address"
                                variant="outlined"
                                onClick={handleAddAddress}
                            >
                                ADD NEW ADDRESS
                            </AddAddressButton>
                        )}
                        {/* for screens bigger than "sm" we use modal */}
                        {!smallScreen && renderForLargeScreen()}

                        {/* for screens smaller than "sm" we use drawer */}
                        {smallScreen && renderForSmallScreen()}
                    </MainContainer>
                </Root>
            )}
        </>
    );
};
