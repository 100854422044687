import { FunctionComponent, ReactElement } from "react";

import { Route, Routes } from "react-router-dom";

import { RequireAuth } from "./components/common";
import { PrimaryLayout } from "./components/layout";
import {
    AcceptInvitationScreen,
    AdminGroupScreen,
    BulkOrderScreen,
    BulkOrdersScreen,
    CollectionsScreen,
    CustomerGroupScreen,
    CustomerGroupsScreen,
    CustomerReviewsScreen,
    DiscountScreen,
    DiscountsScreen,
    EditEntitySchemaScreen,
    EditLinkScreen,
    EditProductVariantScreen,
    Login,
    OrderScreen,
    OrdersScreen,
    ProductCollectionScreen,
    ViewAdminGroupsScreen,
    ViewCustomerScreen,
    ViewCustomersScreen,
    ViewEntitySchemasScreen,
    ViewLinkScreen,
    ViewLinksScreen,
    ViewProductScreen,
    ViewProductsScreen,
} from "./screens";
import { CreateOrderScreen } from "./screens/order/create/CreateOrder";
import { SelectCart } from "./screens/order/create/SelectCart";
import { ViewUsersScreen } from "./screens/users";
import { useAuth } from "./sdk/hooks";
import { AdminRole } from "./utils/enums";

const App: FunctionComponent = (): ReactElement => {
    const { user } = useAuth();

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route
                path="/invitations/accept"
                element={<AcceptInvitationScreen />}
            />
            <Route
                path="/"
                element={
                    <RequireAuth>
                        <PrimaryLayout />
                    </RequireAuth>
                }
            >
                <Route path="/links" element={<ViewLinksScreen />} />

                <Route path="/links/:linkId" element={<ViewLinkScreen />} />
                <Route
                    path="/links/:linkId/edit"
                    element={<EditLinkScreen />}
                />

                <Route path="/orders" element={<OrdersScreen />} />
                <Route path="/orders/:id" element={<OrderScreen />} />
                <Route path="/carts/new" element={<SelectCart />} />
                <Route path="/carts/:id" element={<CreateOrderScreen />} />
                <Route path="/discounts" element={<DiscountsScreen />} />
                <Route path="/discounts/:id" element={<DiscountScreen />} />
                <Route
                    path="/product-collections"
                    element={<CollectionsScreen />}
                />
                <Route
                    path="/product-collections/:id"
                    element={<ProductCollectionScreen />}
                />
                <Route path="/products/:id" element={<ViewProductScreen />} />
                <Route
                    path="/products/:productId/variants/:variantId/edit"
                    element={<EditProductVariantScreen />}
                />
                <Route
                    path="/customer-groups"
                    element={<CustomerGroupsScreen />}
                />
                <Route
                    path="/customer-groups/:id"
                    element={<CustomerGroupScreen />}
                />

                <Route
                    path="/admin-groups"
                    element={<ViewAdminGroupsScreen />}
                />

                <Route
                    path="/admin-groups/:id"
                    element={<AdminGroupScreen />}
                />
                <Route
                    path="/customer-reviews"
                    element={<CustomerReviewsScreen />}
                />

                <Route path="/customers" element={<ViewCustomersScreen />} />

                <Route
                    path="/customers/:customerId"
                    element={<ViewCustomerScreen />}
                />
                <Route
                    path="/entity-schemas"
                    element={<ViewEntitySchemasScreen />}
                />
                <Route
                    path="/entity-schemas/:id"
                    element={<EditEntitySchemaScreen />}
                />
                <Route path="/bulk-orders" element={<BulkOrdersScreen />} />
                <Route
                    path="/bulk-orders/:bulkOrderId"
                    element={<BulkOrderScreen />}
                />
                {user &&
                    [
                        AdminRole.ROOT,
                        AdminRole.ADMIN,
                        AdminRole.MANAGER,
                    ].includes(user.role) && (
                        <Route path="/users" element={<ViewUsersScreen />} />
                    )}
                <Route path="/products" element={<ViewProductsScreen />} />
            </Route>
        </Routes>
    );
};

export default App;
