import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useState,
} from "react";

import { Typography, styled } from "@mui/material";

import * as Yup from "yup";
import {
    DataGrid,
    GridColDef,
    GridRowParams,
    GridToolbar,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format, parse } from "date-fns";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { DataGridFooter } from "../../components/common";
import { useGetBulkOrders } from "../../sdk/hooks";
import { formatCurrency } from "../../sdk/utils/numbers";
import { IPage } from "../../types";
import { reverseCountryMapping } from "../../utils/constants";

const Root = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(3)};
`;

const Heading = styled(Typography)`
    font-size: ${({ theme }) => theme.spacing(3)};
    font-weight: 500;
`;

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }
`;

const TitleContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const columns: GridColDef[] = [
    {
        field: "name",
        headerName: "Name",
        flex: 0.2,
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.firstName} ${params.row.lastName}`,
    },
    {
        field: "phoneNumber",
        headerName: "Phone Number",
        flex: 0.2,
        valueGetter: (params: GridValueGetterParams) =>
            params.row.phoneNumber ?? "",
    },
    {
        field: "country",
        headerName: "Country",
        flex: 0.2,
        valueGetter: (params: GridValueGetterParams) =>
            reverseCountryMapping[params.row.country] ?? "",
    },
    {
        field: "state",
        headerName: "State",
        flex: 0.2,
        valueGetter: (params: GridValueGetterParams) => params.row.state ?? "",
    },

    {
        field: "totalSellingPrice",
        headerName: "Total Selling Price",
        flex: 0.2,
        valueGetter: (params: GridValueGetterParams) =>
            formatCurrency(params.row.totalSellingPrice / 100),
    },
    {
        field: "totalAskingPrice",
        headerName: "Total Asking Price",
        flex: 0.2,
        valueGetter: (params: GridValueGetterParams) =>
            formatCurrency(params.row.totalAskingPrice / 100),
    },
    {
        field: "scheduledCallDate",
        headerName: "Scheduled call slot",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) => {
            const date = new Date(params.row.scheduledCallDate);
            const startTime = params.row.scheduledCallStartTime.slice(0, 5);
            const endTime = params.row.scheduledCallEndTime.slice(0, 5);
            const formattedDate = format(date, "dd-MM-yyyy");
            return `${formattedDate},  ${startTime} - ${endTime}`;
        },
    },
];

const filterSchema = Yup.object({
    limit: Yup.number(),
    cursor: Yup.string().nullable(),
    direction: Yup.mixed().oneOf(["after", "before"]),
});

export const BulkOrdersScreen: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();

    const [page, setPage] = useState<IPage>({
        limit: 20,
        cursor: null,
        direction: "after",
    });

    const { values, setFieldValue } = useFormik({
        initialValues: {
            limit: page.limit,
            cursor: page.cursor,
            direction: page.direction,
        },
        validationSchema: filterSchema,
        onSubmit: () => {
            return;
        },
    });

    const getBulkOrdersQuery = useGetBulkOrders({
        limit: values.limit,
        cursor: values.cursor ?? undefined,
        direction: values.direction,
        options: {
            queryKey: ["bulkOrders"],
        },
    });

    const handleNavigateBack = useCallback(() => {
        setPage((prevPage) => ({
            ...prevPage,
            cursor: getBulkOrdersQuery.data?.cursors?.previous ?? "",
            direction: "before",
        }));
        setFieldValue(
            "cursor",
            getBulkOrdersQuery.data?.cursors?.previous ?? "",
        );
        setFieldValue("direction", "before");
    }, [getBulkOrdersQuery.data?.cursors?.previous, setFieldValue]);

    const handleNavigateForward = useCallback(() => {
        setPage((prevPage) => ({
            ...prevPage,
            cursor: getBulkOrdersQuery.data?.cursors?.next ?? "",
            direction: "after",
        }));
        setFieldValue("cursor", getBulkOrdersQuery.data?.cursors?.next ?? "");
        setFieldValue("direction", "after");
    }, [getBulkOrdersQuery.data?.cursors?.next, setFieldValue]);

    const handleRowClick = useCallback(
        (params: GridRowParams) => {
            navigate(`/bulk-orders/${params.row.id}`);
        },
        [navigate],
    );

    return (
        <Root>
            <TitleContainer>
                <Heading>Bulk Order Requests</Heading>
            </TitleContainer>

            {getBulkOrdersQuery.isSuccess &&
                getBulkOrdersQuery.data?.records?.length > 0 && (
                    <StyledDataGrid
                        autoHeight={true}
                        rows={getBulkOrdersQuery.data?.records ?? []}
                        columns={columns}
                        onRowClick={handleRowClick}
                        loading={getBulkOrdersQuery.isLoading}
                        slots={{
                            toolbar: GridToolbar,
                            footer: () => (
                                <DataGridFooter
                                    hasNextRecords={Boolean(
                                        getBulkOrdersQuery.data.cursors.next,
                                    )}
                                    hasPreviousRecords={Boolean(
                                        getBulkOrdersQuery.data.cursors
                                            .previous,
                                    )}
                                    onNavigateBack={handleNavigateBack}
                                    onNavigateForward={handleNavigateForward}
                                />
                            ),
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: {
                                    disableToolbarButton: false,
                                },
                                printOptions: {
                                    disableToolbarButton: true,
                                },
                            },
                        }}
                    />
                )}
        </Root>
    );
};
