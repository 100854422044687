import { UseQueryResult, useQuery } from "react-query";

import { IGetOrderResponse } from "../types";
import { getActualAmountOfOrder } from "../utils";
import { orders } from "../utils/api/v1.1";

import { useAuth } from "./useAuth";

export const useGetOrder = (
    queryKey: string[],
    orderId: string,
): UseQueryResult<IGetOrderResponse, unknown> => {
    const auth = useAuth();

    return useQuery({
        queryKey,
        queryFn: async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch order without authorization token.",
                );
            }

            return await orders.getOrder({
                orderId,
                token: auth.token,
            });
        },
        select: (data) => {
            const response = data;
            response.actualAmount = getActualAmountOfOrder(data);
            return response;
        },
    });
};
