import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";

import {
    IconButton,
    Paper,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { AddCircle, Edit } from "@mui/icons-material";

import * as Yup from "yup";
import {
    DataGrid,
    GridColDef,
    GridRowSelectionModel,
    GridToolbar,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import EmptyImage from "../../assessts/empty_image.svg";
import { StickyBottomBar } from "../../components/common";
import {
    useCreateAdminGroup,
    useDeleteAdminGroup,
    useGetAdminGroupById,
    useUpdateAdminGroup,
} from "../../hooks";
import { useGetAdmins, useMessage, useToggleCallbacks } from "../../sdk/hooks";
import { globalDateTimeFormat } from "../../utils/constants";
import { AdminRole, AdminStatus, Department } from "../../utils/enums";

import { AdminsTableModal } from "./AdminsTableModal";

const Root = styled("main")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(3)};
    margin: ${({ theme }) => theme.spacing(0, "auto")};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            gap: ${theme.spacing(3)};
    }
  `}
`;

const LeftContainer = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(4)};
    width: 100%;

    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Title = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const FieldContainer = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const Container = styled("div")``;

const TextFieldContainer = styled("div")`
    height: 40px;
    width: 250px;
`;

const TopContainer = styled("div")`
    display: flex;
    justify-content: space-between;

    margin-bottom: ${({ theme }) => theme.spacing(8)};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            flex-direction: column
    }
  `}
`;

const TableHeader = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const NoAdminsContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 300px;
`;

const NoAdminsText = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    color: grey;
`;

const EmptyImageContainer = styled("img")`
    height: 220px;
    width: 220px;
`;

const MultilineTextFieldContainer = styled("div")`
    height: 80px;
    width: 600px;
`;

const EditIcon = styled(Edit)`
    font-size: 18px;
`;

export interface IAdminGroupForm {
    name: string;
    description: string;
    adminIds: string[];
    id: string;
}

const validationSchema = Yup.object({
    name: Yup.string().min(1).required("Name is required"),
    adminIds: Yup.array()
        .of(Yup.string().uuid())
        .required("Atleast one admin is required"),
});

const columns: GridColDef[] = [
    {
        field: "emailAddress",
        headerName: "Email address",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "role",
        headerName: "Role",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            AdminRole[params.row.role],
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            AdminStatus[params.row.status],
    },
    {
        field: "department",
        headerName: "Department",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            Department[params.row.department],
    },
    {
        field: "createdAt",
        headerName: "Created on",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
];

export const AdminGroupScreen: FunctionComponent = (): ReactElement => {
    const params = useParams();
    const createNewAdminGroup = params.id === "new";
    const navigate = useNavigate();

    const adminGroupQuery = useGetAdminGroupById(params.id ?? "");
    const updateAdminGroupMutation = useUpdateAdminGroup("adminGroups");
    const deleteAdminGroupMutation = useDeleteAdminGroup("adminGroups");
    const createAdminGroupMutation = useCreateAdminGroup();

    const [disabled, setDisabled] = useState(false);

    const adminsQuery = useGetAdmins(["admins", "20", "", "after"], {
        limit: 20,
        cursor: "",
        direction: "after",
    });

    const [pageTitle, setPageTitle] = useState("");
    const [action, setAction] = useState("");
    const [snackbarAction, setSnackbarAction] = useState("");

    const { showError, showSuccess } = useMessage();

    const [selectedAdminIds, setSelectedAdminIds] = useState<string[]>(
        adminGroupQuery.data?.admins.map((admin) => {
            return admin.id;
        }) ?? [],
    );

    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (createNewAdminGroup) {
            setPageTitle("Create");
            setSelectedAdminIds([]);
            setAction("Select");
            setSnackbarAction("Added");
        } else {
            setPageTitle("Edit");
            setAction("Update");
            setSelectedAdminIds(
                adminGroupQuery.data?.admins.map((admin) => admin.id) ?? [],
            );
            setSnackbarAction("Updated");
        }
    }, [adminGroupQuery.data?.admins, createNewAdminGroup]);

    const {
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
        handleBlur,
    } = useFormik<IAdminGroupForm>({
        initialValues: {
            name: "",
            description: "",
            adminIds: selectedAdminIds,
            id: params.id ?? "",
        },

        validationSchema,

        onSubmit: async (values) => {
            if (createNewAdminGroup) {
                await createAdminGroupMutation.mutate(values, {
                    onSuccess: () => {
                        showSuccess("Admin group created successfully");
                    },
                });
            } else {
                await updateAdminGroupMutation.mutate(values, {
                    onSuccess: () => {
                        showSuccess("Admin group updated successfully");
                    },
                });
            }
            setTimeout(() => {
                navigate("/admin-groups");
            }, 1000);
            setDisabled(true);
        },
    });

    const existingAdminIds = adminGroupQuery.data?.admins.map(
        (admin) => admin.id,
    );

    useEffect(() => {
        if (createNewAdminGroup) {
            setFieldValue("adminIds", selectedAdminIds);
        } else {
            setFieldValue("name", adminGroupQuery.data?.name);
            setFieldValue("id", adminGroupQuery.data?.id);
            setFieldValue("adminIds", selectedAdminIds);
            setFieldValue("description", adminGroupQuery.data?.description);
        }
    }, [
        adminGroupQuery.data,
        createNewAdminGroup,
        selectedAdminIds,
        setFieldValue,
    ]);

    const handleDelete = useCallback(() => {
        deleteAdminGroupMutation.mutate(
            {
                id: params.id ?? "",
            },
            {
                onSuccess: () => {
                    showError("Admin group deleted successfully");
                    setTimeout(() => {
                        navigate("/admin-groups");
                    }, 1000);
                },
            },
        );
    }, [deleteAdminGroupMutation, navigate, params.id, showError]);

    const handleSelectedAdminIds = useCallback(
        (newSelection: GridRowSelectionModel) => {
            setSelectedAdminIds(newSelection as string[]);
        },
        [],
    );

    const selectedAdmins =
        adminsQuery?.data?.records.filter((admin) =>
            selectedAdminIds.includes(admin.id),
        ) ?? [];

    useEffect(() => {
        if (!createNewAdminGroup && selectedAdmins.length === 0) {
            setSelectedAdminIds(
                adminGroupQuery?.data?.admins.map((admin) => admin.id) ?? [],
            );
        }
    }, [
        adminGroupQuery?.data?.admins,
        createNewAdminGroup,
        selectedAdmins.length,
    ]);

    const handleSave = useCallback(() => {
        handleSubmit();
    }, [handleSubmit]);

    const handleDiscard = useCallback(() => {
        navigate("admin-groups");
    }, [navigate]);

    const [handleOpen, handleClose] = useToggleCallbacks(setOpenDialog);

    return (
        <>
            <Container>
                <Root>
                    <TopContainer>
                        <LeftContainer>
                            <Title>{pageTitle} admin group</Title>
                            <FieldContainer>
                                <TextFieldContainer>
                                    <TextField
                                        fullWidth={true}
                                        name="name"
                                        label="Name"
                                        placeholder="Name"
                                        value={values.name}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.name) && touched.name
                                        }
                                        helperText={touched.name && errors.name}
                                    />
                                </TextFieldContainer>
                            </FieldContainer>
                            <FieldContainer>
                                <MultilineTextFieldContainer>
                                    <TextField
                                        required={true}
                                        multiline={true}
                                        rows={5}
                                        fullWidth={true}
                                        name="description"
                                        label="Description"
                                        placeholder="Description"
                                        value={values.description}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.description) &&
                                            touched.description
                                        }
                                        helperText={
                                            touched.description &&
                                            errors.description
                                        }
                                    />
                                </MultilineTextFieldContainer>
                            </FieldContainer>
                        </LeftContainer>
                    </TopContainer>
                    <FieldContainer>
                        <TableHeader>
                            <Title>Admins</Title>
                            {!createNewAdminGroup && (
                                <IconButton onClick={handleOpen}>
                                    <EditIcon color="primary" />
                                </IconButton>
                            )}
                            {createNewAdminGroup && (
                                <IconButton onClick={handleOpen}>
                                    <AddCircle color="primary" />
                                </IconButton>
                            )}
                        </TableHeader>
                        {(selectedAdmins ?? []).length > 0 && (
                            <DataGrid
                                rows={selectedAdmins ?? []}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 20,
                                        },
                                    },
                                }}
                                rowSelectionModel={
                                    createNewAdminGroup ? selectedAdminIds : []
                                }
                                checkboxSelection={createNewAdminGroup}
                                onRowSelectionModelChange={
                                    handleSelectedAdminIds
                                }
                                pageSizeOptions={[10, 20, 30]}
                                disableRowSelectionOnClick={true}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        csvOptions: {
                                            disableToolbarButton: true,
                                        },
                                        printOptions: {
                                            disableToolbarButton: true,
                                        },
                                    },
                                }}
                                disableColumnSelector={true}
                                disableDensitySelector={true}
                            />
                        )}
                    </FieldContainer>
                    {openDialog && (
                        <AdminsTableModal
                            onClose={handleClose}
                            openModal={openDialog}
                            onSelectAdminIds={handleSelectedAdminIds}
                            selectedAdminIds={selectedAdminIds}
                            action={action}
                            snackbarAction={snackbarAction}
                            existingAdminIds={existingAdminIds}
                        />
                    )}
                    {createNewAdminGroup && selectedAdmins?.length === 0 && (
                        <NoAdminsContainer variant="outlined" elevation={0}>
                            <EmptyImageContainer src={EmptyImage} />
                            <NoAdminsText>No admins selected</NoAdminsText>
                        </NoAdminsContainer>
                    )}
                </Root>
            </Container>
            {createNewAdminGroup && (
                <StickyBottomBar
                    cancelText="Discard"
                    submitText="Save"
                    onCancel={handleDiscard}
                    onSubmit={handleSubmit}
                    disabled={disabled}
                />
            )}
            {!createNewAdminGroup && (
                <StickyBottomBar
                    cancelText="Delete"
                    submitText="Save"
                    onCancel={handleDelete}
                    onSubmit={handleSave}
                    disabled={disabled}
                />
            )}
        </>
    );
};
