import {
    ChangeEventHandler,
    FocusEventHandler,
    FunctionComponent,
    ReactElement,
    ReactNode,
} from "react";

import { Button, Icon, SelectChangeEvent, styled } from "@mui/material";

import { Record } from "./Record";

const Root = styled("div")`
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(2)};
    align-items: center;

    max-width: 800px;
`;

export interface IKeyValuePair {
    key: string;
    keyName: string;
    keyId: string;
    keyLabel: string;
    keyError: boolean;
    keyHelperText?: string;

    value: string;
    valueName: string;
    valueId: string;
    valueLabel: string;
    valueError: boolean;
    valueHelperText?: string;
    values: string[];
}

export interface IRecordsProps {
    pairs: IKeyValuePair[];
    selectField: boolean;
    disableOnAdd: boolean;
    disableOnRemove: boolean;
    onAdd: () => void;
    onRemove: (index: number) => void;

    onKeyChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onKeyBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;

    onValueChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onValueSelect?: (
        event: SelectChangeEvent<string>,
        child: ReactNode,
    ) => void;
    onValueBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const Records: FunctionComponent<IRecordsProps> = (
    props: IRecordsProps,
): ReactElement => {
    const {
        pairs,
        selectField,
        onAdd,
        onRemove,
        onKeyChange,
        onKeyBlur,
        onValueChange,
        onValueSelect,
        onValueBlur,
        disableOnAdd,
        disableOnRemove,
    } = props;

    return (
        <Root>
            {pairs.map((pair, index) => (
                <Record
                    key={index}
                    index={index}
                    onRemove={onRemove}
                    keyValue={pair.key}
                    keyLabel={pair.keyLabel}
                    keyName={pair.keyName}
                    keyId={pair.keyId}
                    keyError={pair.keyError}
                    keyHelperText={pair.keyHelperText}
                    onKeyChange={onKeyChange}
                    onKeyBlur={onKeyBlur}
                    valueValue={pair.value}
                    valueLabel={pair.valueLabel}
                    valueName={pair.valueName}
                    valueId={pair.valueId}
                    valueError={pair.valueError}
                    valueHelperText={pair.valueHelperText}
                    onValueChange={onValueChange}
                    onValueSelect={onValueSelect}
                    onValueBlur={onValueBlur}
                    selectField={selectField}
                    disableOnRemove={disableOnRemove}
                    valueValues={pair.values}
                />
            ))}

            {!disableOnAdd && (
                <Button
                    size="small"
                    endIcon={<Icon>add_circle</Icon>}
                    onClick={onAdd}
                >
                    ADD ENTRY
                </Button>
            )}
        </Root>
    );
};
