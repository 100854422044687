import { FunctionComponent, ReactElement } from "react";

import {
    FormControl,
    FormHelperText,
    Icon,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    styled,
} from "@mui/material";

import { ArrowDropDown, InfoRounded } from "@mui/icons-material";

import * as Yup from "yup";
import { FormikProps } from "formik";

import { DiscountCodeStatus, discountCodeStatusNames } from "../../utils/enums";

const Root = styled("form")`
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(3)};

    width: 100%;
`;

const StyledTooltip = styled(Tooltip)`
    cursor: default;
`;

const StyledArrowDown = styled(ArrowDropDown)`
    margin-right: ${({ theme }) => theme.spacing(3)};
`;

export const generatorFormSchema = Yup.object({
    count: Yup.number()
        .integer()
        .min(1)
        .max(1000)
        .required("Count is required"),
    charset: Yup.string()
        .oneOf(["numbers", "letters", "alphanumeric"])
        .required("Charset is required"),
    pattern: Yup.string().min(1).max(255).required("Pattern is required"),
    status: Yup.number()
        .oneOf([DiscountCodeStatus.DRAFT, DiscountCodeStatus.ACTIVE])
        .required("Status is required"),
});

const discountCodeStatusList = Object.values(DiscountCodeStatus).filter(
    (value) =>
        typeof value !== "string" && value !== DiscountCodeStatus.ARCHIVED,
);

const charsets = [
    {
        value: "numbers",
        title: "Numbers [0-9]",
    },
    {
        value: "letters",
        title: "Letters [A-Z]",
    },
    {
        value: "alphanumeric",
        title: "Alphanumeric [A-Z0-9]",
    },
];

export interface IGeneratorFormValues {
    count: number;
    charset: "numbers" | "letters" | "alphanumeric";
    pattern: string;
    status: DiscountCodeStatus;
}

export interface IGeneratorFormProps {
    formik: FormikProps<IGeneratorFormValues>;
}

export const GeneratorForm: FunctionComponent<IGeneratorFormProps> = (
    props: IGeneratorFormProps,
): ReactElement => {
    const { formik } = props;
    return (
        <Root>
            <TextField
                name="count"
                label="Count"
                required={true}
                fullWidth={true}
                value={formik.values.count}
                onChange={formik.handleChange}
                size="small"
                type="number"
                variant="outlined"
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.count) && formik.touched.count}
                helperText={formik.touched.count && formik.errors.count}
            />
            <TextField
                name="pattern"
                label="Pattern"
                required={true}
                fullWidth={true}
                value={formik.values.pattern}
                onChange={formik.handleChange}
                size="small"
                variant="outlined"
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.pattern) && formik.touched.pattern}
                helperText={formik.touched.pattern && formik.errors.pattern}
                InputProps={{
                    endAdornment: (
                        <StyledTooltip
                            enterTouchDelay={0}
                            placement="top"
                            title={
                                "Hashes (#) are replaced with random characters"
                            }
                        >
                            <Icon style={{ color: "#626262" }} fontSize="small">
                                info
                            </Icon>
                        </StyledTooltip>
                    ),
                }}
            />
            <FormControl size="small" fullWidth={true} required={true}>
                <InputLabel>Charset</InputLabel>
                <Select
                    name="charset"
                    label="Charset"
                    fullWidth={true}
                    size="small"
                    value={formik.values.charset}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        Boolean(formik.errors.charset) && formik.touched.charset
                    }
                    IconComponent={(props) => <StyledArrowDown {...props} />}
                    endAdornment={
                        <InputAdornment position="end">
                            <Tooltip
                                enterTouchDelay={0}
                                placement="top"
                                title={
                                    <>
                                        The charset from which random characters
                                        are selected to replace hashes (#).
                                    </>
                                }
                            >
                                <InfoRounded
                                    htmlColor="#626262"
                                    fontSize="small"
                                />
                            </Tooltip>
                        </InputAdornment>
                    }
                >
                    {charsets.map((charset) => (
                        <MenuItem key={charset.value} value={charset.value}>
                            <ListItemText primary={charset.title} />
                        </MenuItem>
                    ))}
                </Select>
                {Boolean(formik.errors.status) && formik.touched.status && (
                    <FormHelperText>{formik.errors.status}</FormHelperText>
                )}
            </FormControl>
            <FormControl size="small" fullWidth={true} required={true}>
                <InputLabel>Status</InputLabel>
                <Select
                    name="status"
                    label="Status"
                    fullWidth={true}
                    size="small"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        Boolean(formik.errors.status) && formik.touched.status
                    }
                    IconComponent={(props) => <StyledArrowDown {...props} />}
                    endAdornment={
                        <InputAdornment position="end">
                            <Tooltip
                                enterTouchDelay={0}
                                placement="top"
                                title={
                                    <>
                                        Status of the new discount codes. Only
                                        active discounts are available for use
                                        by customers.
                                    </>
                                }
                            >
                                <InfoRounded
                                    htmlColor="#626262"
                                    fontSize="small"
                                />
                            </Tooltip>
                        </InputAdornment>
                    }
                >
                    {discountCodeStatusList.map((status) => (
                        <MenuItem key={status} value={status}>
                            <ListItemText
                                primary={
                                    discountCodeStatusNames[Number(status)]
                                }
                            />
                        </MenuItem>
                    ))}
                </Select>
                {Boolean(formik.errors.status) && formik.touched.status && (
                    <FormHelperText>{formik.errors.status}</FormHelperText>
                )}
            </FormControl>
        </Root>
    );
};
