import { useQuery } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { api } from "../../sdk/utils";

export const useGetPriceRuleById = (
    queryKey: string | string[],
    priceRuleId: string | undefined,
) => {
    const auth = useAuth();

    return useQuery(
        [queryKey, priceRuleId],
        async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch price rule without authorization token.",
                );
            }

            return await api.priceRule.fetchPriceRuleById(
                auth.token,
                priceRuleId,
            );
        },
        { enabled: priceRuleId !== "new" && priceRuleId !== "" },
    );
};
