import { FunctionComponent, ReactElement, ReactNode } from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Icon,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
} from "@mui/material";

import { NavLink, useLocation } from "react-router-dom";

const StyledAccordion = styled(Accordion)`
    &.MuiAccordion-root {
        background: rgba(124, 172, 248, 0.07);
        padding: 0px;
        width: 100%;
    }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
    background-color: rgba(124, 172, 248, 0.07);
`;

const SummaryContainer = styled("div")`
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(1)};
`;

const StyledLink = styled(NavLink)`
    text-decoration: none;
    color: inherit;
`;

const StyledListItemButton = styled(ListItemButton)`
    padding: ${({ theme }) => theme.spacing(1, 1, 1, 3)};

    &.Mui-selected {
        color: white;
        font-weight: 500;
        background-color: #0842a0;
    }
`;

export interface INavigationGroupItem {
    id: string;
    icon: ReactNode;
    title: string;
    url: string;
}

export interface INavigationGroup {
    id: string;
    icon: ReactNode;
    title: string;
    items: INavigationGroupItem[];
}

export interface INavigationGroupsProps {
    groups: INavigationGroup[];
}

export const NavigationGroups: FunctionComponent<INavigationGroupsProps> = (
    props: INavigationGroupsProps,
): ReactElement => {
    const { groups } = props;

    const location = useLocation();
    return (
        <>
            {groups.map((group) => (
                <StyledAccordion
                    key={group.id}
                    elevation={0}
                    disableGutters={true}
                >
                    <StyledAccordionSummary
                        expandIcon={
                            <Icon sx={{ color: "white" }}>expand_more</Icon>
                        }
                    >
                        <SummaryContainer>
                            {group.icon}
                            {group.title}
                        </SummaryContainer>
                    </StyledAccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                        <List disablePadding={true} sx={{ py: 2 }}>
                            {group.items.map((item) => (
                                <StyledLink key={item.id} to={item.url}>
                                    <StyledListItemButton
                                        selected={
                                            location.pathname === item.url
                                        }
                                    >
                                        <ListItemIcon sx={{ minWidth: 30 }}>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText>
                                            {item.title}
                                        </ListItemText>
                                    </StyledListItemButton>
                                </StyledLink>
                            ))}
                        </List>
                    </AccordionDetails>
                </StyledAccordion>
            ))}
        </>
    );
};
