import { useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { api } from "../../sdk/utils";

export interface IDeletePriceRuleParameters {
    id: string;
}

export const useDeletePriceRule = (queryKey: string | string[]) => {
    const auth = useAuth();
    return useMutation({
        mutationFn: async (parameters: IDeletePriceRuleParameters) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot delete price rule without authorization token.",
                );
            }

            return await api.priceRule.deletePriceRule({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
