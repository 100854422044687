import { FunctionComponent, ReactElement } from "react";

import { Button, Icon, styled } from "@mui/material";

import { InstantSearch } from "react-instantsearch";
import { SearchBox, SortBy } from "react-instantsearch";
import { Link } from "react-router-dom";

import { CreateFromFileMenu } from "./CreateFromFileMenu";
import { LinksGrid } from "./LinksDataGrid";
import { typesenseAdapter } from "./config/typesense.config.adapter";

const Root = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(3)};
`;

const TitleContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Heading = styled("div")`
    font-size: ${({ theme }) => theme.spacing(3)};
    font-weight: 500;
`;

const SearchContainer = styled("div")`
    display: flex;
    align-items: center;
    height: 40px;

    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: ${({ theme }) => theme.shape.borderRadius};

    margin-right: ${({ theme }) => theme.spacing(1)};

    overflow: hidden;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
`;

const CreateContainer = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const StyledSearchBox = styled(SearchBox)`
    width: 100%;
    height: 40px;
    display: flex;

    & input {
        flex-grow: 1;
        padding: ${({ theme }) => theme.spacing(1)};

        border: none;
        outline: none;
        font-size: ${({ theme }) => theme.typography.body1.fontSize};

        appearance: none;
        &::-webkit-search-cancel-button {
            display: none;
        }
    }

    & button {
        padding: ${({ theme }) => theme.spacing(1)};

        background-color: #fff;
        border: none;
        cursor: pointer;

        transition: background-color 0.3s ease;
    }
`;

const StyledSortBy = styled(SortBy)`
    margin-right: ${({ theme }) => theme.spacing(2)};

    min-width: 200px;

    border: 1px solid ${({ theme }) => theme.palette.divider};

    border-radius: ${({ theme }) => theme.shape.borderRadius};
    overflow: hidden;

    & select {
        padding: ${({ theme }) => theme.spacing(1.5)};

        border: none;
        outline: none;

        font-size: ${({ theme }) => theme.typography.body1.fontSize};

        background-color: transparent;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
`;

export const ViewLinksScreen: FunctionComponent = (): ReactElement => {
    return (
        <InstantSearch
            searchClient={typesenseAdapter.searchClient}
            indexName={`${process.env.REACT_APP_TYPESENSE_PREFIX}links`}
        >
            <Root>
                <TitleContainer>
                    <Heading>Links</Heading>
                    <CreateContainer>
                        <SearchContainer>
                            <StyledSearchBox />
                        </SearchContainer>
                        <Link to="/links/new/edit">
                            <Button
                                size="small"
                                variant="contained"
                                disableElevation={true}
                                endIcon={<Icon>add_circle</Icon>}
                            >
                                Create
                            </Button>
                        </Link>
                        <CreateFromFileMenu />
                    </CreateContainer>
                </TitleContainer>
                <TitleContainer>
                    <StyledSortBy
                        items={[
                            {
                                value: `${process.env.REACT_APP_TYPESENSE_PREFIX}links/sort/createdAt:desc`,
                                label: "Created At (Newest First)",
                            },
                            {
                                value: `${process.env.REACT_APP_TYPESENSE_PREFIX}links/sort/createdAt:asc`,
                                label: "Created At (Oldest First)",
                            },
                        ]}
                    />
                </TitleContainer>

                <LinksGrid />
            </Root>
        </InstantSearch>
    );
};
