import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";

import { TypesenseConfig } from "../../../utils/third-party/typesense";

export const typesenseAdapter = new TypesenseInstantsearchAdapter({
    server: TypesenseConfig,
    additionalSearchParameters: {
        query_by: "name,description,alias",
        num_typos: 3,
        typo_tokens_threshold: 3,
        per_page: 20,
        sort_by: "createdAt:desc",
    },
});
