import axios from "axios";

import * as api from "../../types/api";
import { DiscountCodeStatus } from "../../../utils/enums";

export interface IGenerateParams {
    count: number;
    charset: "numbers" | "letters" | "alphanumeric";
    pattern: string;
    status: DiscountCodeStatus;
    priceRuleId: string;
    token: string;
}

export const generate = async (
    parameters: IGenerateParams,
): Promise<api.IDiscountCode[]> => {
    const { token, ...body } = parameters;
    const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/discounts/price-rules/discount-codes/generate`,
        body,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        },
    );
    return data;
};

export interface ICreateDiscountParameter {
    priceRuleId: string;
    codes: {
        code: string;
        status: DiscountCodeStatus;
    }[];
    token: string;
}

export const createDiscount = async (
    parameters: ICreateDiscountParameter,
): Promise<api.IDiscount> => {
    const { token, ...body } = parameters;
    const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/discounts/price-rules/discount-codes`,
        body,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return data;
};

export interface IUpdateDiscountParameters {
    code: string;
    token: string;
    id: string;
    status: DiscountCodeStatus;
}

export const updateDiscount = async (
    parameters: IUpdateDiscountParameters,
): Promise<api.IDiscount> => {
    const { token, id, ...body } = parameters;
    const { data } = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/discounts/price-rules/discount-codes/${id}`,
        body,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return data;
};

export interface IDeleteDiscountParameters {
    token: string;
    id: string;
}

export const deleteDiscount = async (
    parameters: IDeleteDiscountParameters,
): Promise<api.IDiscount> => {
    const { token, id } = parameters;
    const { data } = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/discounts/price-rules/discount-codes/${id}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return data;
};
