import React, { FunctionComponent, useCallback } from "react";

import { Checkbox, FormControlLabel } from "@mui/material";

export interface IToggleAllProps {
    options: string[];
    field: string;
    values: string[];
    onToggle: (field: string, value: any[]) => void;
}

export const ToggleAll: FunctionComponent<IToggleAllProps> = (
    props: IToggleAllProps,
) => {
    const { options, field, values, onToggle } = props;

    const handleToggleAll = useCallback(() => {
        if (values.length === options.length) {
            onToggle(field, []);
        } else {
            onToggle(field, options);
        }
    }, [field, onToggle, options, values]);

    return (
        <FormControlLabel
            key={0}
            control={<Checkbox checked={values.length === options.length} />}
            name={"all"}
            onChange={handleToggleAll}
            label={"Select/deselect all"}
        />
    );
};
