import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";

import { Button, Chip, Typography, styled } from "@mui/material";

import { Add } from "@mui/icons-material";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridToolbar,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { Funnel } from "@phosphor-icons/react";
import { addDays, format, subDays } from "date-fns";
import { useNavigate, useSearchParams } from "react-router-dom";

import { CircularLoader, EmptyRecords } from "../../components/common";
import { DataGridFooter } from "../../components/common";
import { useGetAllOrders } from "../../hooks";
import { useToggleCallbacks } from "../../sdk/hooks";
import { formatCurrency } from "../../sdk/utils/numbers";
import { globalDateFormat } from "../../utils/constants";
import {
    NativePaymentStatus,
    PaymentMethodType,
    orderStatusByEnum,
} from "../../utils/enums";

import { FiltersDialog } from "./FiltersDialog";

const Root = styled("section")`
    padding: ${({ theme }) => theme.spacing(3)};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const TitleContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Heading = styled(Typography)`
    font-size: ${({ theme }) => theme.spacing(3)};
    font-weight: 500;
`;

const EmptyRecordsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
`;

const ActionsRow = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const Title = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`;

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }

    .MuiDataGrid-row:hover {
        cursor: pointer;
    }
`;

const columns: GridColDef[] = [
    {
        field: "name",
        headerName: "Order",
        flex: 0.5,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.name === "" ? "Not Synced" : params.row.name}`,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Chip label={params.value} variant="outlined" />
        ),
    },
    {
        field: "createdAt",
        headerName: "Created at",
        flex: 0.5,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(params.row.createdAt, "dd-MM-yyyy, p")}`,
    },
    {
        field: "customer",
        headerName: "Customer",
        flex: 0.4,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) => {
            if (!params.row.customer.phoneNumber) {
                return "Not Synced";
            }
            if (
                !params.row.customer.firstName ||
                !params.row.customer.lastName
            ) {
                return "NA";
            }
            return `${params.row.customer.firstName} ${params.row.customer.lastName}`;
        },
    },
    {
        field: "phoneNumber",
        headerName: "Phone number",
        flex: 0.4,
        minWidth: 175,
        valueGetter: (params: GridValueGetterParams) => {
            if (!params.row.customer.phoneNumber) {
                return "Not Synced";
            }
            return params.row.customer.phoneNumber;
        },
    },
    {
        field: "subtotalAmount",
        headerName: "Total",
        flex: 0.4,
        minWidth: 100,
        valueGetter: (params: GridValueGetterParams) => {
            return formatCurrency(params.row.payment?.amount);
        },
    },
    {
        field: "source",
        headerName: "Source",
        flex: 0.5,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.sellerId ? "Insider Sales" : "Direct"}`,
    },
    {
        field: "status",
        headerName: "Status",
        flex: 0.4,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) =>
            `${orderStatusByEnum[params.row.status]}`,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Chip label={params.value} variant="outlined" />
        ),
    },
    {
        field: "paymentMethod",
        headerName: "Payment Method",
        flex: 0.4,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) =>
            params.row.payment?.paymentMethodType.toUpperCase(),
    },
    {
        field: "gateway",
        headerName: "Gateway",
        flex: 0.4,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) =>
            params.row.payment?.gateway?.toUpperCase() ?? "NA",
    },
    {
        field: "paymentStatus",
        headerName: "Payment status",
        flex: 0.4,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) =>
            params.row.payment?.status,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Chip label={params.value} variant="outlined" />
        ),
    },
];

const orderStatusList = Object.keys(orderStatusByEnum);
const paymentMethods = Object.values(PaymentMethodType);
const paymentStatus = Object.keys(NativePaymentStatus);

const getArrayFromString = (
    searchParams: URLSearchParams,
    name: string,
    fallback: string[],
    withFallback: boolean,
) => {
    const string = searchParams.get(name);
    if (string) {
        const array = string.split(",");
        return array;
    } else if (withFallback) {
        return fallback;
    }
    return [];
};

const getInitialFilters = (
    searchParams: URLSearchParams,
    withFallback: boolean,
) => {
    return {
        orderStatuses: getArrayFromString(
            searchParams,
            "orderStatuses",
            orderStatusList,
            withFallback,
        ),
        startDate:
            searchParams.get("startDate") ??
            format(subDays(new Date(), 30), globalDateFormat),
        endDate:
            searchParams.get("endDate") ?? format(new Date(), globalDateFormat),
        next: searchParams.get("next") ?? "",
        prev: searchParams.get("prev") ?? "",
        names: getArrayFromString(searchParams, "names", [], false),
        phoneNumbers: getArrayFromString(
            searchParams,
            "phoneNumbers",
            [],
            false,
        ),
        sellers: getArrayFromString(searchParams, "sellers", [], false),
        direction: searchParams.get("direction") ?? "after",
        paymentMethods: getArrayFromString(
            searchParams,
            "paymentMethods",
            paymentMethods,
            withFallback,
        ),
        paymentStatuses: getArrayFromString(
            searchParams,
            "paymentStatuses",
            paymentStatus,
            withFallback,
        ),
        orderSources: getArrayFromString(
            searchParams,
            "orderSources",
            [],
            withFallback,
        ),
    };
};

export const OrdersScreen: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [handleOpenDialog, handlCloseDialog] =
        useToggleCallbacks(setDialogOpen);

    const [filters, setFilters] = useState<Record<string, any>>(() =>
        getInitialFilters(searchParams, true),
    );

    useEffect(() => {
        const urlParams = new URLSearchParams(filters);
        setSearchParams(urlParams, { replace: true });
    }, [filters, setSearchParams]);
    const fetchAllOrdersQuery = useGetAllOrders(
        [
            "orders",
            filters.startDate.toString(),
            filters.endDate.toString(),
            "100",
            filters.orderStatuses.toString(),
            filters.direction === "before" ? filters.prev : filters.next,
            filters.direction,
            filters.paymentMethods,
            filters.paymentStatuses,
            filters.orderSources,
            filters.orderStatuses,
            filters.names,
            filters.phoneNumbers,
            filters.sellers,
        ],
        {
            startDate: new Date(filters.startDate),
            endDate: addDays(new Date(filters.endDate), 1),
            limit: 100,
            cursor:
                filters.direction === "before" ? filters.prev : filters.next,
            direction: filters.direction,
            paymentMethods: filters.paymentMethods,
            paymentStatuses: filters.paymentStatuses,
            orderSources: filters.orderSources,
            orderStatuses: filters.orderStatuses,
            names: filters.names,
            phoneNumbers: filters.phoneNumbers,
            sellers: filters.sellers,
        },
    );

    const handleNavigateBack = useCallback(() => {
        setFilters((filters) => ({
            ...filters,
            prev: fetchAllOrdersQuery?.data?.cursors?.previous ?? "",
            direction: "before",
        }));
    }, [fetchAllOrdersQuery?.data?.cursors?.previous]);

    const handleNavigateForward = useCallback(() => {
        setFilters((filters) => ({
            ...filters,
            next: fetchAllOrdersQuery?.data?.cursors?.next ?? "",
            direction: "after",
        }));
    }, [fetchAllOrdersQuery?.data?.cursors?.next]);

    const handleRowClick = useCallback(
        (params: GridRowParams) => {
            const orderId = params.row.id;
            navigate(`/orders/${orderId}`);
        },
        [navigate],
    );

    const handleCreateOrder = useCallback(() => {
        navigate("/carts/new");
    }, [navigate]);

    if (fetchAllOrdersQuery.isLoading) {
        return <CircularLoader />;
    }

    return (
        <Root>
            <TitleContainer>
                <Heading>Orders</Heading>
                <ActionsRow>
                    <Button
                        variant="outlined"
                        onClick={handleOpenDialog}
                        size="small"
                        startIcon={
                            <Funnel
                                fontSize="small"
                                weight="fill"
                                color="black"
                            />
                        }
                    >
                        Filters
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleCreateOrder}
                        size="small"
                        startIcon={<Add />}
                    >
                        Create order
                    </Button>
                </ActionsRow>
            </TitleContainer>
            <FiltersDialog
                open={dialogOpen}
                onClose={handlCloseDialog}
                initialValues={filters}
                onApply={setFilters}
            />
            {fetchAllOrdersQuery?.data?.records.length === 0 && (
                <EmptyRecords>
                    <EmptyRecordsContainer>
                        <Title>No orders available</Title>
                        <Typography>
                            No records were found for the selected filters and
                            date range.
                        </Typography>
                        <Typography>
                            Try changing the filters for this view.
                        </Typography>
                    </EmptyRecordsContainer>
                </EmptyRecords>
            )}
            {fetchAllOrdersQuery.isSuccess &&
                fetchAllOrdersQuery?.data?.records?.length > 0 && (
                    <StyledDataGrid
                        autoHeight={true}
                        rows={fetchAllOrdersQuery?.data?.records ?? []}
                        columns={columns}
                        onRowClick={handleRowClick}
                        loading={fetchAllOrdersQuery.isLoading}
                        slots={{
                            toolbar: GridToolbar,
                            footer: () => (
                                <DataGridFooter
                                    hasNextRecords={Boolean(
                                        fetchAllOrdersQuery.data.cursors.next,
                                    )}
                                    hasPreviousRecords={Boolean(
                                        fetchAllOrdersQuery.data.cursors
                                            .previous,
                                    )}
                                    onNavigateBack={handleNavigateBack}
                                    onNavigateForward={handleNavigateForward}
                                />
                            ),
                        }}
                        disableColumnFilter={true}
                        slotProps={{
                            toolbar: {
                                csvOptions: {
                                    disableToolbarButton: false,
                                },
                                printOptions: {
                                    disableToolbarButton: true,
                                },
                            },
                        }}
                    />
                )}
        </Root>
    );
};
