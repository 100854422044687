import axios from "axios";
import { format } from "date-fns";

import { globalDateTimeFormat } from "../../../utils/constants";
import { ICollection, IExternalListPage } from "../../types/api";

export const transform = (data: any) => {
    return {
        ...data,
        createdAt: format(new Date(data.createdAt), globalDateTimeFormat),
        updatedAt: format(new Date(data.updatedAt), globalDateTimeFormat),
    };
};

export interface IWeightedVariant {
    id: string;
    manualScore: number;
}

export interface ICreateCollectionParameter {
    token: string;
    title: string;
    description: string;
    slug: string;
    sortOrder: number;
    variants: IWeightedVariant[];
}
export interface IGetCollectionParameters {
    token: string;
    id: string;
}

export interface IUpdateCollectionParameters {
    token: string;
    title: string;
    description: string;
    slug: string;
    sortOrder: number;
    id: string;
    variants: IWeightedVariant[];
}

export interface IDeleteCollectionParameters {
    token: string;
    id: string;
}
export class ProductCollection {
    static async createCollection(
        parameters: ICreateCollectionParameter,
    ): Promise<ICollection> {
        const { token, ...body } = parameters;
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/product-collections`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return data;
    }

    static async getCollections(
        token: string,
    ): Promise<IExternalListPage<ICollection>> {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/product-collections`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return {
            ...data,
            records: data.records.map(transform),
        };
    }

    static async getCollectionById(
        parameters: IGetCollectionParameters,
    ): Promise<ICollection> {
        const { token, id } = parameters;
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/product-collections/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return transform(data);
    }

    static async updateCollection(
        parameters: IUpdateCollectionParameters,
    ): Promise<ICollection> {
        const { token, id, ...body } = parameters;
        const { data } = await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/product-collections/${id}`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return data;
    }

    static async deleteCollection(
        parameters: IDeleteCollectionParameters,
    ): Promise<ICollection> {
        const { token, id } = parameters;
        const { data } = await axios.delete(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/product-collections/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return data;
    }
}
