import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { Close } from "@mui/icons-material";

import { CustomerReviewStatus, IVerifyCustomerReview } from "../../sdk/types";

const Root = styled("div")`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const TitleContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled(Typography)`
    font-weight: 500;
    font-size: 20px;
`;

const FlagReviewControl = styled(FormControl)`
    width: 100%;
    max-height: 40px;
    display: flex;
    margin: ${({ theme }) => theme.spacing(2, 0)};
`;

const ModalContainer = styled("div")`
    width: fit-content;
    height: fit-content;

    outline: 0;
    background: white;

    border-radius: ${({ theme }) => theme.spacing(1)};

    display: flex;
    flex-direction: column;

    padding: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        display: flex;
        justify-content: center;
        align-items: center;

        position: fixed;
        width: 100%;
        height: 100%;

        padding: ${({ theme }) => theme.spacing(2)};
    }
`;

const SaveButton = styled(Button)`
    margin-top: ${({ theme }) => theme.spacing(3)};
    width: 100%;

    ${({ theme }) => theme.breakpoints.down("sm")} {
        position: fixed;

        border-radius: 0;

        bottom: 0px;
        left: 0px;
    }
`;

const DescriptionSection = styled(Box)`
    display: flex;
    flex-direction: column;

    width: 500px;
    max-width: 100%;

    margin-top: ${({ theme }) => theme.spacing(3)};
`;

const StyledSelect = styled(Select)`
    height: 40px;
`;

const StyledInputLabel = styled(InputLabel)`
    font-size: 14px;
    justify-self: center;
    margin-top: ${({ theme }) => theme.spacing(-0.5)};
`;

const ButtonsContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};
    width: 100%;
`;

export interface IModerateCustomerReviewCardProps {
    onClose: () => void;
    reviewId: string;
    status: number;
    onUpdateReviewStatus: (reviewId: string, status: number) => void;
    values: IVerifyCustomerReview;
    onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    onChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(
            field: T_1,
        ): T_1 extends React.ChangeEvent<any> ? void : (e: string) => void;
    };
    onBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    loading: boolean;
}

export const rejectionReasons = [
    { value: 1, label: "Inappropriate Content" },
    { value: 2, label: "Offensive Language" },
    { value: 3, label: "False Information" },
    { value: 4, label: "Other" },
];

export const ModerateCustomerReviewCard: FunctionComponent<
    IModerateCustomerReviewCardProps
> = (props: IModerateCustomerReviewCardProps): ReactElement => {
    const { onClose, status, values, onSubmit, onChange, onBlur, loading } =
        props;

    const [formOpen, setFormOpen] = useState(true);

    const handleFormClose = useCallback(() => {
        setFormOpen(false);
        onClose();
    }, [setFormOpen, onClose]);

    const handleFormSubmit = useCallback(() => {
        onSubmit();
        handleFormClose();
    }, [handleFormClose, onSubmit]);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            {formOpen && (
                <Root>
                    <ModalContainer>
                        <TitleContainer>
                            <Title>Comment</Title>
                            <IconButton onClick={onClose}>
                                <Close />
                            </IconButton>
                        </TitleContainer>
                        {!loading && (
                            <>
                                <DescriptionSection>
                                    <TextField
                                        multiline={true}
                                        rows={3}
                                        name="comment"
                                        label="Comment"
                                        value={values.comment}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        required={false}
                                    />
                                </DescriptionSection>
                                {status === CustomerReviewStatus.REJECTED && (
                                    <FlagReviewControl>
                                        <StyledInputLabel>
                                            Flagged reason
                                        </StyledInputLabel>
                                        <StyledSelect
                                            name="flaggedReason"
                                            label="Flagged Reason"
                                            size="small"
                                            value={values.flaggedReason}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                        >
                                            {rejectionReasons.map((reason) => (
                                                <MenuItem
                                                    key={reason.value}
                                                    value={reason.value}
                                                >
                                                    {reason.label}
                                                </MenuItem>
                                            ))}
                                        </StyledSelect>
                                    </FlagReviewControl>
                                )}
                                <ButtonsContainer>
                                    <SaveButton
                                        variant="outlined"
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </SaveButton>
                                    <SaveButton
                                        variant="contained"
                                        onClick={handleFormSubmit}
                                    >
                                        Submit
                                    </SaveButton>
                                </ButtonsContainer>
                            </>
                        )}
                    </ModalContainer>
                </Root>
            )}
        </>
    );
};
