import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import { Icon, IconButton, Menu, MenuItem, Typography } from "@mui/material";

import { styled } from "@mui/system";

import { useToggleCallbacks } from "../../sdk/hooks";

import { FileUploadModal } from "./FileUploadModal";

const StyledMenu = styled(Menu)`
    & .MuiPaper-root {
        overflow: visible;

        filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));

        margin-top: ${({ theme }) => theme.spacing(3)};

        & .MuiAvatar-root {
            width: 32px;
            height: 32px;

            margin: ${({ theme }) => theme.spacing(0, 2, 0, -1)};
        }

        &::before {
            content: "";

            display: block;

            position: absolute;
            top: 0;
            right: 14px;

            transform: translateY(-50%) rotate(45deg);

            width: 10px;
            height: 10px;

            background-color: ${({ theme }) => theme.palette.background.paper};

            z-index: 0;
        }
    }
`;

const StyledMenuContainer = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledIconButton = styled(IconButton)`
    margin-left: ${({ theme }) => theme.spacing(1)};
`;

const SAMPLE_CSV = `name,allowIab,tags,alias,destination,startsAt,endsAt\nTest 1,True,"tag1,tag2",test,https://stage.ifeelblanko.com,01-20-2021 5:30:00,01-20-2021 17:30:00`;

export const CreateFromFileMenu: FunctionComponent = (): ReactElement => {
    /* The menu requires an anchor to determine the render position. */
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [fileUploadOpen, setFileUploadOpen] = useState<boolean>(false);

    const [openFileUpload, closeFileUpload] =
        useToggleCallbacks(setFileUploadOpen);

    const openFileMenu = Boolean(anchorEl);

    const handleMenuClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        },
        [],
    );

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleDownloadSampleClick = useCallback(() => {
        const csvData = new Blob([SAMPLE_CSV], { type: "text/csv" });
        const csvURL = URL.createObjectURL(csvData);

        const link = document.createElement("a");
        link.href = csvURL;
        link.download = "sample.csv";
        link.click();
        link.remove();
    }, []);

    return (
        <>
            <FileUploadModal open={fileUploadOpen} onClose={closeFileUpload} />
            <StyledMenuContainer>
                <StyledIconButton onClick={handleMenuClick} size="small">
                    <Icon>more_vert</Icon>
                </StyledIconButton>
            </StyledMenuContainer>
            <StyledMenu
                anchorEl={anchorEl}
                open={openFileMenu}
                onClick={handleMenuClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem onClick={openFileUpload}>
                    <Icon>import_export</Icon>
                    <Typography>Import from File</Typography>
                </MenuItem>
                <MenuItem onClick={handleDownloadSampleClick}>
                    <Icon>download</Icon>
                    <Typography>Download Sample</Typography>
                </MenuItem>
            </StyledMenu>
        </>
    );
};
