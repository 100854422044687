import { useMutation } from "react-query";

import { api } from "../utils";

import { useAuth } from "./useAuth";

export interface IUseGetShopifyOrderIdParameters {
    orderId: string;
}

export const useGetShopifyOrderId = () => {
    const auth = useAuth();
    return useMutation({
        mutationFn: (parameters: IUseGetShopifyOrderIdParameters) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot get customer addresses without valid token.",
                );
            }

            return api.orders.Order.getShopifyOrderId({
                orderId: parameters.orderId,
                token: auth.token,
            });
        },
    });
};
