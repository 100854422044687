import { FunctionComponent, ReactElement, useCallback } from "react";

import { InputAdornment, TextField } from "@mui/material";

import { Info } from "@mui/icons-material";

import { GridRowId } from "@mui/x-data-grid";
import { FormikErrors, FormikTouched } from "formik";

import { Paper } from "../../../components/common";

import { ProductVariants } from "./ProductVariants";
import {
    DiscountSection,
    IPriceRuleForm,
    Row,
    SectionTitle,
    StyledTooltip,
    TextFieldContainer,
    disableScrollWheelInput,
} from "./common";

export interface ICartPrereqsSectionProps {
    values: IPriceRuleForm;
    touched: FormikTouched<IPriceRuleForm>;
    errors: FormikErrors<IPriceRuleForm>;
    onChange: (e: React.ChangeEvent<any>) => void;
    onBlur: {
        (e: React.FocusEvent<any, Element>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
    ) => Promise<void> | Promise<FormikErrors<IPriceRuleForm>>;
}

export const CartPrereqsSection: FunctionComponent<ICartPrereqsSectionProps> = (
    props: ICartPrereqsSectionProps,
): ReactElement => {
    const { values, errors, touched, onChange, onBlur, setFieldValue } = props;

    const handlePrereqVariantIdsChange = useCallback(
        (newValue: GridRowId[] | undefined) => {
            setFieldValue("variantIds", newValue);
        },
        [setFieldValue],
    );

    return (
        <Paper>
            <DiscountSection>
                <SectionTitle>Cart prerequisites</SectionTitle>
                <Row>
                    <TextFieldContainer>
                        <TextField
                            type="number"
                            fullWidth={true}
                            name="prereqSubtotalMin"
                            value={values.prereqSubtotalMin}
                            onChange={onChange}
                            label="Min TSP"
                            size="small"
                            variant="outlined"
                            onBlur={onBlur}
                            onWheel={disableScrollWheelInput}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        ₹
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <StyledTooltip
                                            enterTouchDelay={0}
                                            placement="top"
                                            title={
                                                "The minimum Total Selling Price (TSP) which is needed, if any, to apply this discount."
                                            }
                                        >
                                            <Info
                                                htmlColor="#626262"
                                                fontSize="small"
                                            />
                                        </StyledTooltip>
                                    </InputAdornment>
                                ),
                            }}
                            error={
                                Boolean(errors.prereqSubtotalMin) &&
                                touched.prereqSubtotalMin
                            }
                            helperText={
                                touched.prereqSubtotalMin &&
                                errors.prereqSubtotalMin
                            }
                        />
                    </TextFieldContainer>
                    <TextFieldContainer />
                </Row>
                <Row>
                    <TextFieldContainer>
                        <TextField
                            name="prereqQuantityMin"
                            fullWidth={true}
                            value={values.prereqQuantityMin}
                            onChange={onChange}
                            onBlur={onBlur}
                            label="Min quantity of items"
                            size="small"
                            variant="outlined"
                            type="number"
                            onWheel={disableScrollWheelInput}
                            error={
                                Boolean(errors.prereqQuantityMin) &&
                                touched.prereqQuantityMin
                            }
                            helperText={
                                touched.prereqQuantityMin &&
                                errors.prereqQuantityMin
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <StyledTooltip
                                            enterTouchDelay={0}
                                            placement="top"
                                            title={
                                                "The minimum number of items required in the cart, irrespective of the product type, to be able to apply the discount."
                                            }
                                        >
                                            <Info
                                                htmlColor="#626262"
                                                fontSize="small"
                                            />
                                        </StyledTooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </TextFieldContainer>
                    <TextFieldContainer />
                </Row>
                <Row>
                    <TextFieldContainer>
                        <TextField
                            fullWidth={true}
                            name="prereqVariantsRequired"
                            value={values.prereqVariantsRequired}
                            placeholder="Quantity"
                            type="number"
                            label="Prerequisite variants required"
                            onChange={onChange}
                            size="small"
                            variant="outlined"
                            onBlur={onBlur}
                            onWheel={disableScrollWheelInput}
                            error={
                                Boolean(errors.prereqVariantsRequired) &&
                                touched.prereqVariantsRequired
                            }
                            helperText={
                                touched.prereqVariantsRequired &&
                                errors.prereqVariantsRequired
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <StyledTooltip
                                            enterTouchDelay={0}
                                            placement="top"
                                            title={
                                                "The minimum number of prerequisite product variants required in the cart to be able to apply the discount."
                                            }
                                        >
                                            <Info
                                                htmlColor="#626262"
                                                fontSize="small"
                                            />
                                        </StyledTooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </TextFieldContainer>
                    <TextFieldContainer />
                </Row>
                <ProductVariants
                    priceRuleProductVariantIds={values.variantIds}
                    onChange={handlePrereqVariantIdsChange}
                />
            </DiscountSection>
        </Paper>
    );
};
