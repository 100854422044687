import {
    ChangeEvent,
    FormEvent,
    FunctionComponent,
    ReactElement,
    useCallback,
    useRef,
    useState,
} from "react";

import {
    Alert,
    Button,
    Icon,
    IconButton,
    Modal,
    Typography,
    styled,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { Delete, FileUploadOutlined } from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useCreateFromFile, useMessage } from "../../sdk/hooks";

const FlexCenterContainer = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;

    gap: ${({ theme }) => theme.spacing(1)};
`;

const ModalForm = styled("form")`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};

    width: 700px;
    height: 500px;

    background-color: white;

    box-shadow: 24;

    border-radius: ${({ theme }) => theme.spacing(1)};

    padding: ${({ theme }) => theme.spacing(1)};
`;

const ModalFileContainer = styled(FlexCenterContainer)`
    width: 100%;
    height: 100px;

    position: relative;
    bottom: ${({ theme }) => theme.spacing(2)};
`;

const ModalFileInput = styled(FlexCenterContainer)`
    width: 200px;

    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const ModalFooter = styled("div")`
    width: 100%;

    display: flex;
    justify-content: end;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const ModalImage = styled(FileUploadOutlined)`
    font-size: 250px;
`;

const FileWrapper = styled("div")`
    width: 70%;
`;

const FileContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: ${({ theme }) => theme.spacing(0, 1)};

    border: 0.2px solid gray;

    border-radius: ${({ theme }) => theme.spacing(0.5)};

    flex: 1;
`;

const FileName = styled(Typography)`
    font-size: small;
    font-weight: 400;
`;

const FileDeleteIcon = styled(Delete)`
    color: #e86c6c;
`;

interface IFormMessage {
    error?: boolean;
}

const FormMessage = styled(Typography, {
    shouldForwardProp: (propName) => propName !== "error",
})<IFormMessage>`
    color: ${({ error }) => (error ? "red" : "gray")};
    text-align: center;

    font-size: 15px;
`;

const AlertContainer = styled("div")`
    height: 100px;
`;

const LoadingAlert = styled(Alert)`
    background-color: white;

    .MuiAlert-icon {
        display: none;
    }

    .MuiAlert-message {
        font-size: 15px;
        color: gray;
    }
`;

const HiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);

    overflow: hidden;

    position: absolute;
    bottom: 0;
    left: 0;

    white-space: nowrap;

    height: 1px;
    width: 5px;
`;

const validationSchema = Yup.object({
    fileType: Yup.string().oneOf(["text/csv"], "File format not supported"),
});

export interface IFileUploadForm {
    fileType: string;
}

export interface IFileUploadModalProps {
    open: boolean;
    onClose: () => void;
}

export const FileUploadModal: FunctionComponent<IFileUploadModalProps> = (
    props: IFileUploadModalProps,
): ReactElement => {
    const { open, onClose } = props;

    const fileRef = useRef<HTMLInputElement>(null);

    const [currentFile, setCurrentFile] = useState<File | null>(null);

    const message = useMessage();

    const createFromFileMutation = useCreateFromFile(["listAllLinks"], {
        onSuccess: (data) => {
            onClose();
            clearInputFiles();

            message.showSuccess(`Successfully imported ${data.length}  links`);
        },
        onError: (error) => {
            onClose();

            clearInputFiles();
            formik.resetForm();
            createFromFileMutation.reset();

            message.showError(error);
        },
    });

    const formik = useFormik<IFileUploadForm>({
        initialValues: {
            fileType: "",
        },
        onSubmit: () => {
            if (currentFile) {
                createFromFileMutation.mutate({
                    file: currentFile,
                });
            }
        },
        validationSchema,
    });

    const filetypeFormikError =
        formik.touched.fileType && formik.errors.fileType;

    const clearInputFiles = useCallback(() => {
        setCurrentFile(null);

        if (fileRef.current) {
            /* creating a new empty file list using ref because file input is not a controlled input */
            fileRef.current.files = new DataTransfer().files;
        }
    }, []);

    const handleFileChange = useCallback(
        async (event: ChangeEvent) => {
            const file = (event.target as HTMLInputElement).files?.item(0);

            if (!file) {
                return;
            }

            try {
                const fileFormat =
                    file.type.length === 0 ? "unknown" : file.type;

                await formik.setFieldTouched("fileType", true);
                await formik.setFieldValue("fileType", fileFormat, true);

                setCurrentFile(file);
            } catch (error: unknown) {}
        },
        [formik],
    );

    const handleClear = useCallback(() => {
        formik.resetForm();
        createFromFileMutation.reset();

        clearInputFiles();
    }, [formik, clearInputFiles, createFromFileMutation]);

    const handleClose = useCallback(() => {
        if (createFromFileMutation.isLoading) return;

        clearInputFiles();
        formik.resetForm();
        createFromFileMutation.reset();

        onClose();
    }, [clearInputFiles, onClose, formik, createFromFileMutation]);

    const handleSubmit = useCallback(
        (event: FormEvent) => {
            event.preventDefault();

            formik.handleSubmit();
        },
        [formik],
    );

    return (
        <Modal open={open} onClose={handleClose}>
            <ModalForm onSubmit={handleSubmit}>
                <ModalImage />
                {!createFromFileMutation.isError &&
                    !createFromFileMutation.isLoading && (
                        <ModalFileContainer>
                            {(!currentFile || filetypeFormikError) && (
                                <ModalFileInput>
                                    <Button
                                        component={"label"}
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        fullWidth={true}
                                        startIcon={<Icon>cloud_upload</Icon>}
                                    >
                                        Upload file
                                        <HiddenInput
                                            name="file"
                                            type="file"
                                            ref={fileRef}
                                            onBlur={formik.handleBlur}
                                            onChange={handleFileChange}
                                        />
                                    </Button>
                                    {filetypeFormikError && (
                                        <FormMessage error={true}>
                                            {formik.errors.fileType}
                                        </FormMessage>
                                    )}
                                    {!filetypeFormikError && (
                                        <FormMessage>
                                            Select CSV file to import
                                        </FormMessage>
                                    )}
                                </ModalFileInput>
                            )}
                            {currentFile && !filetypeFormikError && (
                                <FileWrapper>
                                    <FileContainer>
                                        <FlexCenterContainer>
                                            <Icon>upload_file</Icon>
                                            <FileName>
                                                {currentFile.name}
                                            </FileName>
                                        </FlexCenterContainer>
                                        <IconButton onClick={handleClear}>
                                            <FileDeleteIcon />
                                        </IconButton>
                                    </FileContainer>
                                </FileWrapper>
                            )}
                        </ModalFileContainer>
                    )}
                {createFromFileMutation.isLoading && (
                    <AlertContainer>
                        <LoadingAlert severity="info">
                            Importing links...
                        </LoadingAlert>
                    </AlertContainer>
                )}
                <ModalFooter>
                    <Button
                        variant={"text"}
                        size="small"
                        onClick={handleClose}
                        color="error"
                        startIcon={<Icon>clear</Icon>}
                        disabled={createFromFileMutation.isLoading}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant={"contained"}
                        size="small"
                        type="submit"
                        color="success"
                        loading={createFromFileMutation.isLoading}
                        startIcon={<Icon>done</Icon>}
                        disabled={
                            !formik.dirty ||
                            !formik.isValid ||
                            createFromFileMutation.isError
                        }
                    >
                        Import
                    </LoadingButton>
                </ModalFooter>
            </ModalForm>
        </Modal>
    );
};
