import { FunctionComponent, ReactElement } from "react";

import { Button, Divider, styled } from "@mui/material";

const BottomBar = styled("div")`
    background-color: white;

    position: sticky;
    bottom: 0;
    z-index: 2;
`;

const ActionContainer = styled("div")`
    display: flex;
    justify-content: flex-end;
    gap: ${({ theme }) => theme.spacing(2)};

    margin: 0 auto;
    padding: ${({ theme }) => theme.spacing(1, 3)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      padding: ${theme.spacing(1, 2)}
    }
  `}
`;

export interface IStickyBottomBarProps {
    onCancel: () => void;
    onSubmit: (e?: any) => void;
    cancelText: string;
    submitText: string;
    disabled?: boolean;
}

export const StickyBottomBar: FunctionComponent<IStickyBottomBarProps> = (
    props: IStickyBottomBarProps,
): ReactElement => {
    const { cancelText, submitText, onCancel, onSubmit, disabled } = props;
    return (
        <BottomBar>
            <Divider />
            <ActionContainer>
                <Button variant="outlined" color="error" onClick={onCancel}>
                    {cancelText}
                </Button>
                <Button
                    variant="contained"
                    onClick={onSubmit}
                    disabled={disabled}
                >
                    {submitText}
                </Button>
            </ActionContainer>
        </BottomBar>
    );
};
