import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { ICollection } from "../types";
import { collections } from "../utils/api";

import { useAuth } from "./useAuth";

export interface IDeleteCollectionParameters {
    id: string;
}

export const useDeleteCollection = (
    invalidQueryKeys: string | string[],
): UseMutationResult<ICollection, unknown, IDeleteCollectionParameters> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (parameters: IDeleteCollectionParameters) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot delete collection without authorization token.",
                );
            }
            return await collections.ProductCollection.deleteCollection({
                token: auth.token,
                ...parameters,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(invalidQueryKeys);
        },
    });
};
