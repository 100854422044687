import React from "react";

import { CssBaseline } from "@mui/material";

import axios from "axios";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider, MessageProvider } from "./sdk/providers";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.data.expired) {
            localStorage.setItem("adminToken", "");
            localStorage.setItem("admin", "");

            alert("Your session has expired. Please login again.");
            window.location.href = "/login";
        }
        return Promise.reject(error);
    },
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

root.render(
    <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
            <MessageProvider>
                <AuthProvider>
                    <BrowserRouter>
                        <CssBaseline />
                        <App />
                    </BrowserRouter>
                </AuthProvider>
            </MessageProvider>
        </SnackbarProvider>
    </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
