import { formatCurrency } from "./numbers";

/**
 * Utility for create empty functions that throw exceptions.
 * Useful for create default values for contexts.
 */
export const createEmptyFunction = <T>(): T =>
    (() => {
        throw new Error("This function has not been implemented!");
    }) as T;

export const serializeString = (value: string) => value;

export const deserializeString = (value: string) => value;

export const serializeInteger = (value: number) => value.toString();

export const getCompareAtPrice = (
    freeProduct: boolean,
    savedAmount: number,
    variant: any,
    item: any,
) => {
    if (savedAmount <= 0) {
        return "";
    }
    if (freeProduct) {
        return formatCurrency(variant.mrp * 1);
    }
    if (!freeProduct) {
        return formatCurrency(variant.mrp * item.quantity);
    }
    return "";
};

export const getSavedPrice = (
    freeProduct: boolean,
    savedAmount: number,
    variant: any,
    item: any,
    displayText?: "Save" | "Saved",
) => {
    if (savedAmount <= 0) {
        return "";
    }
    if (freeProduct) {
        return `${displayText ?? "Save"} ${formatCurrency(
            (variant.mrp - (item.price.amount ?? item.price)) * 1,
        )}`;
    }
    if (!freeProduct) {
        return `${displayText ?? "Save"} ${formatCurrency(
            (variant.mrp - (item.price.amount ?? item.price)) * item.quantity,
        )}`;
    }
    return "";
};
