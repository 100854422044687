import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useState,
} from "react";

import {
    Avatar,
    Button,
    Divider,
    IconButton,
    Menu,
    MenuList,
    AppBar as MuiAppBar,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import { useAuth } from "../../sdk/hooks";

const AppBar = styled(MuiAppBar)`
    padding: ${({ theme }) => theme.spacing(1, 3)};
    width: calc(100% - 240px);

    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};

    box-shadow: none;

    border-bottom: 1px #f0f0f0 solid;

    color: ${({ theme }) => theme.palette.text.primary};
    background-color: #ffffff;

    ${({ theme }) => theme.breakpoints.down("md")} {
        width: 100%;
    }
`;

const UserDetails = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const UserMenuPaperProp = {
    style: {
        background: "white",
        boxShadow: `
            12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
            100px 100px 80px rgba(0, 0, 0, 0.07)
        `,
    },
};

const MenuItemTitle = styled(Typography)`
    font-size: 12px;

    ${({ theme }) => theme.breakpoints.down("md")} {
        font-size: 10px;
    }
`;

const WelcomeText = styled(Typography)`
    font-size: 14px;

    ${({ theme }) => theme.breakpoints.down("md")} {
        font-size: 12px;
    }
`;

const ProfileMenu = styled(Menu)`
    margin-top: ${({ theme }) => theme.spacing(4)};
`;

const UserAvatar = styled(Avatar)`
    cursor: pointer;
`;

const StyledMenuList = styled(MenuList)`
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export interface INavbarProps {
    onToggle: () => void;
}

export const Navbar: FunctionComponent<INavbarProps> = (
    props: INavbarProps,
): ReactElement => {
    const { onToggle } = props;
    const { user, logout } = useAuth();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [userMenuAnchor, setUserMenuAnchor] = useState<null | HTMLElement>(
        null,
    );

    const handleOpenUserMenu = useCallback((event: any) => {
        setUserMenuAnchor(event.currentTarget);
    }, []);

    const handleUserMenuClose = useCallback(() => {
        setUserMenuAnchor(null);
    }, []);

    const handleLogout = useCallback(() => {
        logout();
        setUserMenuAnchor(null);
        window.location.replace("/");
    }, [logout]);

    return (
        <AppBar>
            {smallScreen && (
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={onToggle}
                >
                    <MenuIcon />
                </IconButton>
            )}
            <div />
            {user && (
                <UserDetails>
                    <WelcomeText>{`Welcome, ${user.firstName}`}</WelcomeText>
                    <UserAvatar
                        src={user.pictureUrl}
                        onClick={handleOpenUserMenu}
                    />
                    <ProfileMenu
                        anchorEl={userMenuAnchor}
                        autoFocus={false}
                        disableScrollLock={true}
                        keepMounted={true}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(userMenuAnchor)}
                        onClose={handleUserMenuClose}
                        PaperProps={UserMenuPaperProp}
                        elevation={0}
                    >
                        {user && (
                            <StyledMenuList>
                                <MenuItemTitle>
                                    {`Logged in as ${user.firstName} ${user.lastName}`}
                                    <br />
                                    {user.emailAddress}
                                </MenuItemTitle>
                                <Divider />
                                <Button
                                    variant="outlined"
                                    onClick={handleLogout}
                                >
                                    Logout
                                </Button>
                            </StyledMenuList>
                        )}
                    </ProfileMenu>
                </UserDetails>
            )}
        </AppBar>
    );
};
