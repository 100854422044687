import axios from "axios";

import * as api from "../../types/api";
import { AdminRole, Department } from "../../../utils/enums";

const transform = (user: any) => ({
    ...user,
    createdAt: new Date(user.createdAt),
    updatedAt: new Date(user.updatedAt),
});

export interface IUpdateUserParameters {
    id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    token: string;
}

export interface IInviteParameters {
    emailAddress: string;
    role: AdminRole;
    department: Department;
    token: string;
}

export interface IAcceptInvitationParameters {
    token: string;
}

export interface ISignInParameters {
    code: string;
    redirectUrl: string;
}

export class Authentication {
    public static async invite(
        parameters: IInviteParameters,
    ): Promise<api.IInvitation> {
        const { emailAddress, role, department, token } = parameters;
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/invitations`,
            {
                emailAddress,
                role,
                department,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return transform(data);
    }

    public static async acceptInvitation(
        parameters: IAcceptInvitationParameters,
    ): Promise<void> {
        const { token } = parameters;
        await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/invitations/accept`,
            {
                token,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
    }

    public static async signIn(
        parameters: ISignInParameters,
    ): Promise<api.IJwtSession> {
        const { code, redirectUrl } = parameters;
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/authenticate`,
            {
                code,
                redirectUrl,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
        return transform(data);
    }

    public static async updateUser(
        parameters: IUpdateUserParameters,
    ): Promise<void> {
        const { id, token, ...others } = parameters;
        const { data } = await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/admin/${id}`,
            others,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return transform(data);
    }
}
