import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";

import {
    Button,
    Icon,
    List as MuiList,
    ListItem as MuiListItem,
    Typography,
    styled,
} from "@mui/material";

import {
    LoadingButton,
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from "@mui/lab";

import { AddOutlined, Edit } from "@mui/icons-material";

import * as Yup from "yup";
import { addDays, format, isAfter, isBefore } from "date-fns";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { Paper, SaveAlert, WorkspaceToolbar } from "../../components/common";
import {
    useCreateDiscount,
    useCreatePriceRule,
    useGetPriceRuleById,
    useUpdatePriceRule,
} from "../../hooks";
import { useAuth, useMessage, useToggleCallbacks } from "../../sdk/hooks";
import { IAdmin } from "../../sdk/types";
import {
    DiscountCodeStatus,
    PaymentMethodType,
    PriceRuleCategoryType,
    PriceRuleStatus,
} from "../../utils/enums";
import {
    AllocationMethod,
    CustomerSelection,
    DiscountType,
} from "../../utils/enums";

import { DiscountCodeDataGrid } from "./DiscountCodeDataGrid";
import { GeneratorDialog } from "./GeneratorDialog";
import {
    ActivationSection,
    CartPrereqsSection,
    CustomerEligibilitySection,
    GeneralSection,
    IPriceRuleForm,
} from "./sections";

const Root = styled("main")`
    padding: ${({ theme }) => theme.spacing(4, 0)};

    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      flex-direction: column;
      gap: ${theme.spacing(3)};
    }
  `}
`;

const LeftContainer = styled("section")`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    max-width: 800px;
    width: 100%;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      max-width: 100%;
    }
  `}
`;

const RightContainer = styled("section")`
    min-width: 350px;
    max-width: 500px;
    padding-top: ${({ theme }) => theme.spacing(6)};

    flex: 0.5;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          padding-top: 0px;
          min-width: 100%;
          max-width: 100%;
    }
  `}
`;

const SummaryContainer = styled("section")`
    position: sticky;
    top: 95px;

    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const Title = styled(Typography)`
    font-size: 22px;
    font-weight: 600;
`;

const SectionTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    color: #626262;
`;

const List = styled(MuiList)`
    list-style-type: disc;
    padding: 0 0px 0px 18px;
`;

const ListItem = styled(MuiListItem)`
    font-family: "Poppins";
    display: list-item;
    padding: 8px 0px;
`;

const Container = styled("div")`
    width: 100%;
    padding: ${({ theme }) => theme.spacing(0, 6)};

    ${({ theme }) => theme.breakpoints.down("md")} {
        padding: ${({ theme }) => theme.spacing(0, 3)};
    }
`;

const StyledTimelineContent = styled(TimelineContent)`
    padding: ${({ theme }) => theme.spacing(1.5, 2)};
`;

const StyledTimeline = styled(Timeline)`
    padding: 0;
    margin: ${({ theme }) => theme.spacing(1, 0, 0)};
    .MuiTimelineItem-root:before {
        content: none;
    }
`;

const TimelineText = styled(Typography)`
    font-size: 14px;
`;

const TimelineTitle = styled(TimelineText)`
    font-weight: 500;
    color: #626262;
`;

const StyledTimelineDot = styled(TimelineDot)`
    box-shadow: none;
`;

const validationSchema = Yup.object({
    name: Yup.string()
        .min(1)
        .required("Please enter a valid name for this discount"),
    description: Yup.string()
        .min(1)
        .required("Please enter a valid description for this discount"),
    status: Yup.number()
        .oneOf([
            PriceRuleStatus.DRAFT,
            PriceRuleStatus.ACTIVE,
            PriceRuleStatus.ARCHIVED,
        ])
        .required("Please select a status for this discount"),
    discountCodes: Yup.array().of(
        Yup.object().shape({
            code: Yup.string(),
            id: Yup.string(),
        }),
    ),
    category: Yup.number()
        .oneOf([
            PriceRuleCategoryType.PAYMENT_METHODS,
            PriceRuleCategoryType.UI_DISCOUNT_CODES,
            PriceRuleCategoryType.PRIVATE_DISCOUNTS,
        ])
        .required("Please select a discount category"),
    discountType: Yup.number()
        .oneOf([DiscountType.FIXED, DiscountType.PERCENTAGE])
        .required("Please select a discount type"),
    discountValue: Yup.number()
        .required("Please specify discount value")
        .when("discountType", (value, field) => {
            const discountType = value[0];
            return discountType === DiscountType.PERCENTAGE
                ? field.max(100, "Maximum discount percentage allowed is 100%")
                : field;
        }),
    prereqSubtotalMin: Yup.number()
        .min(1, "Minimum value must be at least 1")
        .nullable(),
    allocationMethod: Yup.number()
        .oneOf([
            AllocationMethod.LOWEST_LINE_ITEMS,
            AllocationMethod.HIGHEST_LINE_ITEMS,
            AllocationMethod.COMBINED_LINE_ITEMS,
            AllocationMethod.EACH_LINE_ITEM,
        ])
        .required("Please select an allocation method"),
    prereqPaymentMethod: Yup.string()
        .oneOf([PaymentMethodType.COD, PaymentMethodType.PREPAID])
        .nullable()
        .when("category", (value, field) => {
            const category = value[0];
            return category === PriceRuleCategoryType.PAYMENT_METHODS
                ? field.required("Please select a payment method")
                : field;
        }),
    prereqQuantityMin: Yup.number()
        .min(1, "Minimum value must be at least 1")
        .nullable(),
    usageLimitByTotal: Yup.number()
        .min(1, "Minimum value must be at least 1")
        .nullable(),
    usageLimitByCustomer: Yup.number()
        .min(1, "Minimum value must be at least 1")
        .nullable(),
    prereqVariantsRequired: Yup.number()
        .min(0, "Minimum value is 0")
        .nullable(),
    variantIds: Yup.array().of(Yup.string()).min(0),
    customerSelection: Yup.number()
        .oneOf([CustomerSelection.ALL, CustomerSelection.PREREQ])
        .required("Please select customer eligibility"),
    prereqCustomerGroupIds: Yup.array().when(
        "customerSelection",
        (value, field) => {
            const customerSelection = value[0];
            return customerSelection === CustomerSelection.PREREQ
                ? field.min(1, "Please select atleast one customer group")
                : field;
        },
    ),
    startDate: Yup.date(),
    startTime: Yup.date(),
    enableEndTime: Yup.boolean().default(false),
    endDate: Yup.date().when("enableEndTime", (value, field) =>
        value ? field.required("Please enter end date") : field,
    ),
    endTime: Yup.date().when("enableEndTime", (value, field) =>
        value ? field.required("Please enter end time") : field,
    ),
    maxDiscountAmount: Yup.number().nullable(),
});

export const DiscountScreen: FunctionComponent = (): ReactElement => {
    const params = useParams();
    const newPriceRule = params.id === "new";
    const navigate = useNavigate();

    const priceRule = useGetPriceRuleById("priceRule", params.id);
    const createMutation = useCreatePriceRule("priceRule");
    const createDiscountCode = useCreateDiscount("discount");
    const updateMutation = useUpdatePriceRule("priceRule");
    const message = useMessage();
    const auth = useAuth();

    const [alertOpen, setAlertOpen] = useState(false);

    const [handleAlertOpen, handleAlertClose] =
        useToggleCallbacks(setAlertOpen);

    const {
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
        handleBlur,
        dirty,
        isValid,
    } = useFormik<IPriceRuleForm>({
        initialValues: {
            name: "",
            description: "",
            status: PriceRuleStatus.DRAFT,
            discountCodes: [] as {
                id: string;
                code: string;
                status: DiscountCodeStatus;
                createdBy: IAdmin;
            }[],
            category: PriceRuleCategoryType.UI_DISCOUNT_CODES,
            discountType: DiscountType.FIXED,
            discountValue: "",
            prereqSubtotalMin: "",
            prereqQuantityMin: "",
            prereqPaymentMethod: "cod",
            prereqVariantsRequired: 0,
            variantIds: [],
            allocationMethod: AllocationMethod.COMBINED_LINE_ITEMS,
            customerSelection: CustomerSelection.ALL,
            prereqCustomerGroupIds: [],
            usageLimitByTotal: "",
            usageLimitByCustomer: "",
            startDate: new Date(),
            startTime: new Date(),
            enableEndTime: false,
            endDate: addDays(new Date(), 5),
            endTime: addDays(new Date(), 5),
            maxDiscountAmount: "",
        },
        onSubmit: async (values) => {
            const {
                discountCodes,
                startDate,
                startTime,
                endDate,
                endTime,
                usageLimitByTotal,
                usageLimitByCustomer,
                discountValue,
                discountType,
                category,
                prereqQuantityMin,
                prereqSubtotalMin,
                prereqPaymentMethod,
                allocationMethod,
                prereqVariantsRequired,
                customerSelection,
                prereqCustomerGroupIds,
                enableEndTime,
                status,
                maxDiscountAmount,
                ...priceRuleParameter
            } = values;
            if (newPriceRule) {
                try {
                    const priceRuleResponse = await createMutation.mutateAsync({
                        ...priceRuleParameter,
                        category,
                        discountValue: Number(discountValue),
                        usageLimitByTotal:
                            values.usageLimitByTotal !== ""
                                ? Number(usageLimitByTotal)
                                : -1,
                        usageLimitByCustomer:
                            values.usageLimitByCustomer !== ""
                                ? Number(usageLimitByCustomer)
                                : -1,
                        prereqQuantityMin:
                            prereqQuantityMin === ""
                                ? -1
                                : Number(prereqQuantityMin),
                        prereqSubtotalMin:
                            prereqSubtotalMin === ""
                                ? -1
                                : Number(prereqSubtotalMin),
                        startsAt: new Date(
                            `${format(startDate, "MM/dd/yyyy")} ${format(
                                startTime,
                                "HH:mm:ss",
                            )}`,
                        ),
                        endsAt: enableEndTime
                            ? new Date(
                                  `${format(endDate, "MM/dd/yyyy")} ${format(
                                      endTime,
                                      "HH:mm:ss",
                                  )}`,
                              )
                            : null,
                        allocationMethod: Number(allocationMethod),
                        customerSelection: Number(customerSelection),
                        prereqCustomerGroupIds:
                            Number(customerSelection) ===
                            CustomerSelection.PREREQ
                                ? prereqCustomerGroupIds
                                : [],
                        discountType: Number(discountType),
                        prereqPaymentMethod:
                            category !== PriceRuleCategoryType.PAYMENT_METHODS
                                ? null
                                : prereqPaymentMethod,
                        prereqVariantsRequired: Number(prereqVariantsRequired),
                        variantIds: values.variantIds,
                        status: Number(status),
                        maxDiscountAmount:
                            maxDiscountAmount === ""
                                ? null
                                : Number(maxDiscountAmount),
                    });

                    if (values.discountCodes.length > 0) {
                        await createDiscountCode.mutateAsync(
                            {
                                priceRuleId: priceRuleResponse.id,
                                codes: values.discountCodes.map((codes) => ({
                                    code: codes.code,
                                    status: codes.status,
                                })),
                            },
                            {
                                onSuccess: () => {
                                    message.showSuccess(
                                        "Discount created successfully",
                                    );
                                },
                            },
                        );
                    }
                } catch (error: any) {
                    console.error(error);
                    message.showError(error);
                }
                navigate("/discounts");
            } else {
                await updateMutation.mutateAsync(
                    {
                        ...priceRuleParameter,
                        id: params.id ?? "",
                        usageLimitByTotal:
                            values.usageLimitByTotal !== ""
                                ? Number(usageLimitByTotal)
                                : -1,
                        usageLimitByCustomer:
                            values.usageLimitByCustomer !== ""
                                ? Number(usageLimitByCustomer)
                                : -1,
                        discountValue: Number(discountValue),
                        maxDiscountAmount:
                            maxDiscountAmount === ""
                                ? null
                                : Number(maxDiscountAmount),
                        prereqQuantityMin:
                            prereqQuantityMin === ""
                                ? -1
                                : Number(prereqQuantityMin),
                        prereqSubtotalMin:
                            prereqSubtotalMin === ""
                                ? -1
                                : Number(prereqSubtotalMin),
                        startsAt: new Date(
                            `${format(startDate, "MM/dd/yyyy")} ${format(
                                startTime,
                                "HH:mm:ss",
                            )}`,
                        ),
                        endsAt: enableEndTime
                            ? new Date(
                                  `${format(endDate, "MM/dd/yyyy")} ${format(
                                      endTime,
                                      "HH:mm:ss",
                                  )}`,
                              )
                            : null,
                        allocationMethod: Number(allocationMethod),
                        prereqPaymentMethod:
                            category !== PriceRuleCategoryType.PAYMENT_METHODS
                                ? null
                                : prereqPaymentMethod,
                        prereqVariantsRequired: Number(prereqVariantsRequired),
                        category,
                        discountType: Number(discountType),
                        customerSelection: Number(customerSelection),
                        prereqCustomerGroupIds:
                            Number(customerSelection) ===
                            CustomerSelection.PREREQ
                                ? prereqCustomerGroupIds
                                : [],
                        variantIds: values.variantIds,
                        status: Number(status),
                    },
                    {
                        onSuccess: () => {
                            navigate("/discounts");
                            return;
                        },
                    },
                );
            }
        },
        validationSchema: validationSchema,
    });

    useEffect(() => {
        if (Boolean(priceRule.data) && priceRule.isSuccess) {
            setFieldValue("discountCodes", priceRule.data.discountCodes);
            setFieldValue("discountType", priceRule.data.discountType);
            setFieldValue("discountValue", priceRule.data.discountValue);
            setFieldValue(
                "prereqSubtotalMin",
                priceRule.data.prereqSubtotalMin > 0
                    ? priceRule.data.prereqSubtotalMin
                    : "",
            );
            setFieldValue(
                "prereqQuantityMin",
                priceRule.data.prereqQuantityMin > 0
                    ? priceRule.data.prereqQuantityMin
                    : "",
            );
            setFieldValue(
                "customerSelection",
                priceRule.data.customerSelection,
            );
            setFieldValue(
                "prereqCustomerGroupIds",
                priceRule.data.prereqCustomerGroupIds,
            );
            setFieldValue(
                "usageLimitByTotal",
                priceRule.data.usageLimitByTotal > 0
                    ? priceRule.data.usageLimitByTotal
                    : "",
            );
            setFieldValue(
                "prereqVariantsRequired",
                Number(priceRule.data.prereqVariantsRequired),
            );
            setFieldValue(
                "prereqPaymentMethod",
                priceRule.data.prereqPaymentMethod,
            );
            setFieldValue("variantIds", priceRule.data.variantIds);

            setFieldValue(
                "usageLimitByCustomer",
                priceRule.data.usageLimitByCustomer > 0
                    ? priceRule.data.usageLimitByCustomer
                    : "",
            );
            setFieldValue("startDate", new Date(priceRule.data.startsAt));
            setFieldValue("startTime", new Date(priceRule.data.startsAt));
            setFieldValue("enableEndTime", Boolean(priceRule.data.endsAt));
            setFieldValue(
                "endDate",
                priceRule.data.endsAt !== null
                    ? new Date(priceRule.data.endsAt)
                    : addDays(new Date(), 5),
            );
            setFieldValue(
                "endTime",
                priceRule.data.endsAt !== null
                    ? new Date(priceRule.data.endsAt)
                    : addDays(new Date(), 5),
            );
            setFieldValue("name", priceRule.data.name);
            setFieldValue("description", priceRule.data.description);
            setFieldValue("status", priceRule.data.status);
            setFieldValue("category", priceRule.data.category);
            setFieldValue("allocationMethod", priceRule.data.allocationMethod);
            setFieldValue(
                "maxDiscountAmount",
                priceRule.data.maxDiscountAmount,
            );
        }
    }, [priceRule.data, priceRule.isSuccess, setFieldValue]);

    const onAddDiscountCode = useCallback(
        (index: number, value: string, status: DiscountCodeStatus) => {
            const updatedCodes = [...values.discountCodes];
            updatedCodes[index] = {
                code: value,
                id: "",
                status,
                createdBy: auth.user!,
            };
            setFieldValue("discountCodes", updatedCodes);
        },
        [auth.user, setFieldValue, values.discountCodes],
    );

    const publicAndActive =
        values.category === PriceRuleCategoryType.UI_DISCOUNT_CODES &&
        values.status === PriceRuleStatus.ACTIVE &&
        isAfter(
            new Date(),
            new Date(
                `${format(values.startDate, "MM/dd/yyyy")} ${format(
                    values.startTime,
                    "HH:mm:ss",
                )}`,
            ),
        ) &&
        (!values.endDate ||
            isBefore(
                new Date(),
                new Date(
                    `${format(values.endDate, "MM/dd/yyyy")} ${format(
                        values.endDate,
                        "HH:mm:ss",
                    )}`,
                ),
            ));

    const [openGenerator, setOpenGenerator] = useState(false);
    const [handleOpenGenerator, handleCloseGenerator] =
        useToggleCallbacks(setOpenGenerator);

    return (
        <>
            <WorkspaceToolbar
                title={newPriceRule ? "Create discount" : "Edit discount"}
                enableSearch={false}
                actions={
                    <>
                        {!newPriceRule && (
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                endIcon={<Icon>playlist_add</Icon>}
                                onClick={handleOpenGenerator}
                                disabled={priceRule.isLoading}
                            >
                                Generate Discount Codes
                            </Button>
                        )}
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="small"
                            endIcon={<Icon>check_circle</Icon>}
                            onClick={handleAlertOpen}
                            disabled={!dirty || !isValid}
                            loading={
                                createMutation.isLoading ||
                                updateMutation.isLoading
                            }
                        >
                            Save
                        </LoadingButton>
                    </>
                }
                breadcrumbs={[
                    {
                        title: "Discounts",
                        link: "/discounts",
                        icon: <Icon fontSize="small">percent</Icon>,
                    },
                    {
                        title: newPriceRule
                            ? "Create discount"
                            : priceRule.data?.name ?? "",
                        link: `/discounts/${params.id}`,
                    },
                ]}
            />
            <SaveAlert
                open={alertOpen}
                onCancel={handleAlertClose}
                onSave={handleSubmit}
                showWarning={publicAndActive}
                warningText="You are about to publish a public and active discount.
                        This change will be visible to all storefront users as
                        soon as you confirm."
            />
            <Container>
                <Root>
                    <LeftContainer>
                        <Title>Create product discount</Title>
                        {/* General */}
                        <GeneralSection
                            values={values}
                            errors={errors}
                            touched={touched}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {/* Discount codes */}
                        <DiscountCodeDataGrid
                            discountCodes={values.discountCodes}
                            createNewPriceRule={newPriceRule}
                            onAdd={onAddDiscountCode}
                            priceRuleId={params.id}
                        />
                        {/* Cart prereqs */}
                        <CartPrereqsSection
                            values={values}
                            errors={errors}
                            touched={touched}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            setFieldValue={setFieldValue}
                        />
                        {/* Customer eligibility */}
                        <CustomerEligibilitySection
                            values={values}
                            errors={errors}
                            onChange={handleChange}
                            setFieldValue={setFieldValue}
                        />
                        {/* Activation */}
                        <ActivationSection
                            values={values}
                            onChange={handleChange}
                            setFieldValue={setFieldValue}
                        />
                    </LeftContainer>
                    <RightContainer>
                        <SummaryContainer>
                            <Paper>
                                <SectionTitle>Summary</SectionTitle>
                                <List>
                                    <ListItem>For Online Store</ListItem>
                                    {Number(values.discountType) ===
                                        DiscountType.FIXED &&
                                        values.discountValue !== "" && (
                                            <ListItem>
                                                ₹{values.discountValue} off
                                            </ListItem>
                                        )}

                                    {Number(values.discountType) ===
                                        DiscountType.PERCENTAGE &&
                                        values.discountValue !== "" && (
                                            <ListItem>
                                                {values.discountValue}% off
                                                {values.maxDiscountAmount
                                                    ? ` upto ₹${values.maxDiscountAmount}`
                                                    : ""}
                                            </ListItem>
                                        )}

                                    <ListItem>
                                        {typeof values.prereqVariantsRequired !==
                                        "number"
                                            ? 0
                                            : values.prereqVariantsRequired}{" "}
                                        prereq variants required
                                    </ListItem>
                                    {values.prereqSubtotalMin === "" &&
                                        values.prereqQuantityMin === "" && (
                                            <ListItem>
                                                No minimum purchase requirement
                                            </ListItem>
                                        )}
                                    {values.prereqSubtotalMin !== "" && (
                                        <ListItem>
                                            Minimum purchase of ₹
                                            {values.prereqSubtotalMin}
                                        </ListItem>
                                    )}
                                    {values.prereqQuantityMin !== "" && (
                                        <ListItem>
                                            Minimum purchase of ₹
                                            {values.prereqQuantityMin}
                                        </ListItem>
                                    )}
                                    {values.customerSelection ===
                                        CustomerSelection.ALL && (
                                        <ListItem>All customers</ListItem>
                                    )}
                                    {values.customerSelection ===
                                        CustomerSelection.PREREQ && (
                                        <ListItem>
                                            Selected customer groups
                                        </ListItem>
                                    )}
                                    {!values.usageLimitByCustomer &&
                                        !values.usageLimitByTotal && (
                                            <ListItem>No usage limits</ListItem>
                                        )}
                                    {values.usageLimitByTotal && (
                                        <ListItem>
                                            Can be used{" "}
                                            {values.usageLimitByTotal} times in
                                            total
                                        </ListItem>
                                    )}
                                    {values.usageLimitByCustomer && (
                                        <ListItem>
                                            {`Can be used ${values.usageLimitByCustomer} times per customer`}
                                        </ListItem>
                                    )}
                                </List>
                            </Paper>
                            {priceRule.data && (
                                <Paper>
                                    <SectionTitle>Activity</SectionTitle>
                                    <StyledTimeline>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <StyledTimelineDot color="secondary">
                                                    <AddOutlined fontSize="small" />
                                                </StyledTimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <StyledTimelineContent>
                                                <TimelineTitle>
                                                    {`Created by ${priceRule.data.createdBy.firstName} ${priceRule.data.createdBy.lastName}`}
                                                </TimelineTitle>
                                                <TimelineText>
                                                    {`at ${format(
                                                        priceRule.data
                                                            .createdAt,
                                                        "dd-MM-yyyy, p",
                                                    )}`}
                                                </TimelineText>
                                            </StyledTimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineConnector />
                                                <StyledTimelineDot
                                                    variant="outlined"
                                                    color="secondary"
                                                >
                                                    <Edit
                                                        fontSize="small"
                                                        color="secondary"
                                                    />
                                                </StyledTimelineDot>
                                            </TimelineSeparator>
                                            <StyledTimelineContent>
                                                <TimelineTitle>
                                                    Last updated
                                                </TimelineTitle>
                                                <TimelineText>
                                                    {`at ${format(
                                                        priceRule.data
                                                            .updatedAt,
                                                        "dd-MM-yyyy, p",
                                                    )}`}
                                                </TimelineText>
                                            </StyledTimelineContent>
                                        </TimelineItem>
                                    </StyledTimeline>
                                </Paper>
                            )}
                        </SummaryContainer>
                    </RightContainer>
                </Root>
            </Container>
            {params.id && (
                <GeneratorDialog
                    open={openGenerator}
                    onClose={handleCloseGenerator}
                    priceRuleId={params.id}
                    invalidQueryKeys={["priceRule", params.id]}
                />
            )}
        </>
    );
};
