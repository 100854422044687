import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { ICustomerGroup } from "../../sdk/types";
import { api } from "../../sdk/utils";

export interface ICreateCustomerGroupParameter {
    name: string;
    description: string;
    customerIds: string[];
}

export const useCreateCustomerGroup = (): UseMutationResult<
    ICustomerGroup,
    unknown,
    ICreateCustomerGroupParameter
> => {
    const auth = useAuth();
    return useMutation({
        mutationFn: async (parameters: ICreateCustomerGroupParameter) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot create customer group without authorization token.",
                );
            }

            return await api.CustomerGroup.create({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
