import { FunctionComponent, ReactElement } from "react";

import { Typography, styled } from "@mui/material";

import { ICartLineItem, ICustomer, ICustomerAddress } from "../../../sdk/types";
import { stateCodes } from "../../../utils/constants";

import { CartItem } from "./CartItem";

const Root = styled("div")`
    flex: 2;
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing(2)};
`;

const Title = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const Subtitle = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
`;

const SummaryText = styled(Typography)`
    font-size: 14px;
`;

const Container = styled("div")`
    height: 100%;
    width: 100%;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing(2)};
    padding: ${({ theme }) => theme.spacing(2)};
`;

const SummaryCard = styled("div")`
    width: 100%;
    background-color: #ffffff;

    padding: ${({ theme }) => theme.spacing(2)};
    border-radius: ${({ theme }) => theme.spacing(0.5)};

    border: 1px solid rgba(0, 0, 0, 0.12);
`;

const Section = styled("div")`
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing(1)};
`;

export interface IOrderSummaryProps {
    cartItems: ICartLineItem[];
    address: ICustomerAddress | undefined;
    cartId: string | undefined;
    onChange: any;
    customer: ICustomer | undefined;
}

export const OrderSummary: FunctionComponent<IOrderSummaryProps> = (
    props: IOrderSummaryProps,
): ReactElement => {
    const { cartItems, address, cartId, onChange, customer } = props;

    return (
        <Root>
            <Title>Order Summary</Title>
            <Container>
                <Section>
                    <Subtitle>Cart Preview</Subtitle>
                    {cartItems.length > 0 &&
                        cartId &&
                        cartItems.map((item) => (
                            <CartItem
                                key={item.id}
                                item={item}
                                cartId={cartId}
                                onChange={onChange}
                            />
                        ))}
                    {cartItems.length === 0 && <>Cart is Empty</>}
                </Section>
                {customer && (
                    <Section>
                        <Subtitle>Ordering for</Subtitle>
                        <SummaryCard>
                            <SummaryText>{`${customer.firstName} ${customer.lastName}`}</SummaryText>
                            <SummaryText>{customer.phoneNumber}</SummaryText>
                            <SummaryText>{customer.emailAddress}</SummaryText>
                        </SummaryCard>
                    </Section>
                )}
                {address && (
                    <Section>
                        <Subtitle>Delivering to</Subtitle>
                        <SummaryCard>
                            <SummaryText>{`${address.firstName} ${address.lastName}`}</SummaryText>
                            <SummaryText>{address.addressLine1}</SummaryText>
                            {address.addressLine2 && (
                                <SummaryText>
                                    {address.addressLine2}
                                </SummaryText>
                            )}
                            <SummaryText>{address.city}</SummaryText>
                            <SummaryText>
                                {stateCodes[address.stateCode]}
                            </SummaryText>
                            <SummaryText>{address.zip}</SummaryText>
                        </SummaryCard>
                    </Section>
                )}
            </Container>
        </Root>
    );
};
