import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { api } from "../../sdk/utils";

import { IVerifyCustomerReview } from "./../../sdk/types/components";

export interface IVerifyCustomerReviewParameters {
    values: IVerifyCustomerReview;
}

export interface IVerifyCustomerReviewResponse {
    message: string;
}

export const useVerifyCustomerReview = (
    queryKey: string | string[],
): UseMutationResult<
    IVerifyCustomerReviewResponse,
    unknown,
    IVerifyCustomerReviewParameters
> => {
    const auth = useAuth();
    return useMutation({
        mutationFn: async (parameters: IVerifyCustomerReviewParameters) => {
            const { values } = parameters;
            if (!auth.token) {
                throw new Error(
                    "Cannot verify customer review without authorization token.",
                );
            }

            return await api.v1_1.CustomerReviews.verify({
                ...values,
                token: auth.token,
            });
        },
    });
};
