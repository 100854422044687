import { FunctionComponent, ReactElement, useCallback } from "react";

import { Button, IconButton, Modal, Typography, styled } from "@mui/material";

import { Close } from "@mui/icons-material";

import {
    DataGrid,
    GridColDef,
    GridRowSelectionModel,
    GridToolbar,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";

import { useGetAdmins, useMessage } from "../../sdk/hooks";
import { globalDateTimeFormat } from "../../utils/constants";
import { AdminRole, AdminStatus, Department } from "../../utils/enums";

const Container = styled("div")`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 80%;
    height: fit-content;
    background-color: #fff;

    outline: 0;
    border-radius: ${({ theme }) => theme.spacing(1)};

    padding: ${({ theme }) => theme.spacing(0, 0, 2, 0)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        padding: ${({ theme }) => theme.spacing(4, 2)};
    }
`;

const ButtonContainer = styled("div")`
    display: flex;
    justify-content: end;
    align-items: center;

    width: 100%;

    margin: ${({ theme }) => theme.spacing(1, 0)};
    padding-right: ${({ theme }) => theme.spacing(1)};
`;

const HeadingContainer = styled("div")`
    width: 100%;
    height: 60px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #f0f0f0;
    border-radius: ${({ theme }) => theme.spacing(1)};

    margin-bottom: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(2, 3)};
`;

const StyledButton = styled(Button)`
    margin-top: ${({ theme }) => theme.spacing(2)};
`;

const ModalTitle = styled(Typography)`
    font-size: 20px;
    font-weight: 500;
`;

const StyledDataGrid = styled(DataGrid)`
    margin: ${({ theme }) => theme.spacing(0, 1)};
`;

const columns: GridColDef[] = [
    {
        field: "emailAddress",
        headerName: "Email address",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "role",
        headerName: "Role",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            AdminRole[params.row.role],
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            AdminStatus[params.row.status],
    },

    {
        field: "department",
        headerName: "Department",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            Department[params.row.department],
    },
    {
        field: "createdAt",
        headerName: "Created on",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
];

export interface IAdminsTableModalProps {
    onClose: () => void;
    openModal: boolean;
    onSelectAdminIds: (newSelection: GridRowSelectionModel) => void;
    selectedAdminIds: string[];
    existingAdminIds?: string[];
    action: string;
    snackbarAction: string;
}

export const AdminsTableModal: FunctionComponent<IAdminsTableModalProps> = (
    props: IAdminsTableModalProps,
): ReactElement => {
    const {
        onClose,
        onSelectAdminIds,
        selectedAdminIds,
        openModal,
        existingAdminIds,
        action,
    } = props;
    const adminsQuery = useGetAdmins("admins");

    const { showError } = useMessage();

    const handleCompareAdminIds = useCallback(
        (selectedAdminIds: string[], existingAdminIds?: string[]) => {
            if (selectedAdminIds.length !== existingAdminIds?.length) {
                return false;
            }

            return selectedAdminIds.every(
                (element, index) => element === existingAdminIds[index],
            );
        },
        [],
    );

    const handleSave = useCallback(() => {
        onSelectAdminIds(selectedAdminIds);

        onClose();

        if (selectedAdminIds.length === 0) {
            showError("Please select atleast one admin!");
        }
        if (handleCompareAdminIds(selectedAdminIds, existingAdminIds)) {
            showError("The admins are unchanged");
        }
    }, [
        existingAdminIds,
        handleCompareAdminIds,
        onClose,
        selectedAdminIds,
        onSelectAdminIds,
        showError,
    ]);

    return (
        <Modal onClose={onClose} open={openModal}>
            <Container>
                <HeadingContainer>
                    <ModalTitle>{action} admins</ModalTitle>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </HeadingContainer>
                {(adminsQuery?.data?.records ?? []).length > 0 && (
                    <StyledDataGrid
                        rows={adminsQuery?.data?.records ?? []}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        rowSelectionModel={selectedAdminIds}
                        onRowSelectionModelChange={onSelectAdminIds}
                        pageSizeOptions={[5, 10]}
                        disableRowSelectionOnClick={true}
                        checkboxSelection={true}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                        disableColumnSelector={true}
                        disableDensitySelector={true}
                    />
                )}
                <ButtonContainer>
                    <StyledButton variant="contained" onClick={handleSave}>
                        Save
                    </StyledButton>
                </ButtonContainer>
            </Container>
        </Modal>
    );
};
