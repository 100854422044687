import { FunctionComponent, ReactElement, ReactNode } from "react";

import {
    Box,
    Icon,
    InputAdornment,
    Breadcrumbs as MuiBreadcrumbs,
    TextField,
    Toolbar,
    Typography,
    styled,
} from "@mui/material";

import { NavigateNext } from "@mui/icons-material";

import { Link } from "react-router-dom";

const StyledTitle = styled(Typography)`
    ${({ theme }) => theme.breakpoints.down("xs")} {
        display: none;
    }
`;

const StyledBreadcrumbs = styled(MuiBreadcrumbs)`
    padding: ${({ theme }) => theme.spacing(1, 3)};
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.palette.grey[700]};

    display: flex;
    align-items: center;
`;

const BreadcrumbTypography = styled(Typography)`
    color: black;
`;

export interface IStyledToolbarProps {
    sticky: boolean;
}

const StyledToolbar = styled(Toolbar, {
    shouldForwardProp: (propName) => propName !== "sticky",
})<IStyledToolbarProps>`
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(2)};

    color: ${({ theme }) => theme.palette.text.primary};
    background-color: #ffffff;

    box-shadow: none;

    border-bottom: 1px #f0f0f0 solid;

    position: ${({ sticky }) => (sticky ? "sticky" : "unset")};
    top: 10px;
    z-index: 2;
`;

const Search = styled(TextField)``;

const Actions = styled("div")`
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(1)};
`;

export interface IBreadcrumb {
    title: string;
    icon?: ReactNode;
    link: string;
}

export interface IWorkspaceToolbarProps {
    title: string;
    enableSearch: boolean;
    actions?: ReactNode;
    sticky?: boolean;
    breadcrumbs?: IBreadcrumb[];
}

export const WorkspaceToolbar: FunctionComponent<IWorkspaceToolbarProps> = (
    props: IWorkspaceToolbarProps,
): ReactElement => {
    const { title, enableSearch, actions, breadcrumbs, sticky = false } = props;
    return (
        <>
            <StyledToolbar sticky={sticky}>
                <StyledTitle variant="h6" noWrap={true}>
                    {title}
                </StyledTitle>
                <Box sx={{ flexGrow: 1 }} />

                {enableSearch && (
                    <Search
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>search</Icon>
                                </InputAdornment>
                            ),
                        }}
                        placeholder="Search…"
                    />
                )}

                <Actions>{actions}</Actions>
            </StyledToolbar>
            {breadcrumbs && breadcrumbs.length > 0 && (
                <StyledBreadcrumbs
                    separator={<NavigateNext fontSize="small" />}
                >
                    {breadcrumbs.map((breadcrumb, index) =>
                        index === breadcrumbs.length - 1 ? (
                            <BreadcrumbTypography>
                                {breadcrumb.icon}
                                {breadcrumb.title}
                            </BreadcrumbTypography>
                        ) : (
                            <StyledLink to={breadcrumb.link}>
                                {breadcrumb.icon}
                                {breadcrumb.title}
                            </StyledLink>
                        ),
                    )}
                </StyledBreadcrumbs>
            )}
        </>
    );
};
