import { UseQueryResult, useQuery } from "react-query";

import { TUseQueryOptions, useAuth } from "../../sdk/hooks";
import { IProduct } from "../../sdk/types";
import { api } from "../../sdk/utils";

export const useGetProduct = (
    queryKey: string[],
    slug: string,
    options?: TUseQueryOptions<any, unknown, IProduct, string[]>,
): UseQueryResult<IProduct, unknown> => {
    const auth = useAuth();

    return useQuery(
        queryKey,
        async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch product without authorization token.",
                );
            }

            return await api.Products.fetchProduct({ slug, token: auth.token });
        },
        options,
    );
};
