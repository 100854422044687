import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useState,
} from "react";

import {
    Button,
    Chip,
    IconButton,
    Snackbar as MuiSnackbar,
    SnackbarCloseReason,
    Typography,
    styled,
} from "@mui/material";

import { Add, Close } from "@mui/icons-material";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridRowSelectionModel,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { formatDistance } from "date-fns";
import { useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";

import { EmptyRecords, StatusChip } from "../../components/common";
import { DataGridFooter } from "../../components/common";
import { useDeletePriceRule, useGetAllPriceRule } from "../../hooks";
import { IDiscountCode } from "../../sdk/types";
import { IPage } from "../../types";
import {
    PriceRuleCategoryNames,
    PriceRuleCategoryType,
    PriceRuleStatus,
    priceRuleStatusNames,
} from "../../utils/enums";

const Root = styled("main")`
    padding: ${({ theme }) => theme.spacing(3)};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};
`;

const TitleContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`;

const EmptyRecordsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
`;

const Snackbar = styled(MuiSnackbar)`
    & .MuiPaper-root {
        background-color: ${({ theme }) => theme.palette.common.white};
        color: ${({ theme }) => theme.palette.common.black};
    }
`;

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }
    .MuiDataGrid-row:hover {
        cursor: pointer;
    }
`;

const CellTitle = styled(Typography)`
    font-size: 16px;
    color: ${({ theme }) => theme.palette.text.primary};
`;

const Description = styled(Typography)`
    font-size: 13px;
    color: grey;
`;

const StyledCell = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: start;
`;

const CodesCell = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(0.5)};
    align-items: center;

    font-size: 13px;
`;

const columns: GridColDef[] = [
    {
        field: "name",
        headerName: "Name",
        flex: 0.4,
        minWidth: 300,
        renderCell: (params: GridRenderCellParams) => (
            <StyledCell>
                <CellTitle>{params.row.name}</CellTitle>
                <Description>{params.row.description}</Description>
            </StyledCell>
        ),
    },
    {
        field: "codes",
        headerName: "Codes",
        flex: 1,
        minWidth: 300,
        valueGetter: (params: GridValueGetterParams) => {
            const codes = params.row.discountCodes.slice(0, 2);
            const moreItems =
                params.row.discountCodes.length > 2
                    ? params.row.discountCodes.length - 2
                    : 0;

            return { codes, moreItems };
        },
        renderCell: (
            params: GridRenderCellParams<{
                codes: IDiscountCode[];
                moreItems: number;
            }>,
        ) => (
            <CodesCell>
                {params.value.codes.map(
                    (code: IDiscountCode, index: number) => {
                        return (
                            <Chip label={code.code} size="small" key={index} />
                        );
                    },
                )}
                {params.value.moreItems > 0 && (
                    <Chip
                        label={`+${params.value.moreItems} more`}
                        size="small"
                    />
                )}
            </CodesCell>
        ),
    },
    {
        field: "category",
        headerName: "Category",
        flex: 0.15,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) =>
            PriceRuleCategoryNames[
                params.row.category as PriceRuleCategoryType
            ],
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Chip label={params.value} variant="outlined" />
        ),
    },
    {
        field: "status",
        headerName: "Status",
        flex: 0.5,
        minWidth: 140,
        maxWidth: 150,
        renderCell: (params: GridRenderCellParams) => (
            <StatusChip
                label={
                    priceRuleStatusNames[params.row.status as PriceRuleStatus]
                }
                value={params.row.status}
            />
        ),
    },
    {
        field: "createdBy",
        headerName: "Created by",
        flex: 0.2,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) => {
            const createdBy = params.row.createdBy;
            return createdBy
                ? `${createdBy.firstName} ${createdBy.lastName}`
                : "NA";
        },
    },
    {
        field: "createdAt",
        headerName: "Created",
        flex: 0.2,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) =>
            `${formatDistance(params.row.createdAt, new Date())} ago`,
    },
    {
        field: "updatedAt",
        headerName: "Last updated",
        flex: 0.2,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) =>
            `${formatDistance(params.row.updatedAt, new Date())} ago`,
    },
];

export const DiscountsScreen: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [selectedPriceRuleIds, setSelectedPriceRuleIds] = useState<string[]>(
        [],
    );
    const [page, setPage] = useState<IPage>({
        limit: 20,
        cursor: null,
        direction: "after",
    });

    const fetchAllPriceRule = useGetAllPriceRule(
        [
            "priceRules",
            page.limit.toString(),
            page.cursor ?? "",
            page.direction,
        ],
        {
            limit: page.limit,
            cursor: page.cursor,
            direction: page.direction,
        },
    );
    const deletePriceRule = useDeletePriceRule("priceRule");

    const handleRowClick = useCallback(
        (params: GridRowParams) => {
            const priceRuleId = params.row.id;
            navigate(`/discounts/${priceRuleId}`);
        },
        [navigate],
    );

    const handleCloseSnackbar = useCallback(
        (
            event: Event | React.SyntheticEvent<any, Event>,
            reason: SnackbarCloseReason,
        ) => {
            if (reason !== "clickaway") {
                setSelectedPriceRuleIds([]);
            }
        },
        [],
    );

    const handleClose = useCallback(() => {
        setSelectedPriceRuleIds([]);
    }, []);

    const handleNavigateBack = useCallback(() => {
        setPage({
            ...page,
            cursor: fetchAllPriceRule.data?.cursors?.previous ?? "",
            direction: "before",
        });
    }, [fetchAllPriceRule.data?.cursors?.previous, page]);

    const handleNavigateForward = useCallback(() => {
        setPage({
            ...page,
            cursor: fetchAllPriceRule.data?.cursors?.next ?? "",
            direction: "after",
        });
    }, [fetchAllPriceRule.data?.cursors?.next, page]);

    const handleDeletePriceRule = useCallback(() => {
        for (const priceRuleId of selectedPriceRuleIds) {
            deletePriceRule.mutate(
                { id: priceRuleId },
                {
                    onSuccess: () => {
                        queryClient.invalidateQueries([
                            "priceRules",
                            page.limit.toString(),
                            page.cursor ?? "",
                            page.direction,
                        ]);
                    },
                },
            );
        }
    }, [
        deletePriceRule,
        page.cursor,
        page.direction,
        page.limit,
        queryClient,
        selectedPriceRuleIds,
    ]);

    return (
        <Root>
            <TitleContainer>
                <Title>Discounts</Title>
                <Link to="/discounts/new">
                    <Button
                        variant="contained"
                        startIcon={<Add fontSize="small" />}
                    >
                        create discount
                    </Button>
                </Link>
            </TitleContainer>
            {fetchAllPriceRule?.data?.records.length === 0 && (
                <EmptyRecords>
                    <EmptyRecordsContainer>
                        <Title>Manage discounts and promotions</Title>
                        <div>
                            Create discount codes and automatic discounts that
                            apply at checkout.
                        </div>
                        <Link to="/discounts/new">
                            <Button variant="contained">create discount</Button>
                        </Link>
                    </EmptyRecordsContainer>
                </EmptyRecords>
            )}

            {fetchAllPriceRule.data &&
                (fetchAllPriceRule.data?.records ?? []).length > 0 && (
                    <>
                        <StyledDataGrid
                            rows={fetchAllPriceRule?.data?.records ?? []}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 20,
                                    },
                                },
                            }}
                            pageSizeOptions={[10, 20, 30]}
                            disableRowSelectionOnClick={true}
                            onRowClick={handleRowClick}
                            checkboxSelection={true}
                            rowSelectionModel={selectedPriceRuleIds}
                            onRowSelectionModelChange={(
                                newSelection: GridRowSelectionModel,
                            ) => {
                                setSelectedPriceRuleIds(
                                    newSelection as string[],
                                );
                            }}
                            components={{
                                Footer: () => (
                                    <DataGridFooter
                                        hasNextRecords={Boolean(
                                            fetchAllPriceRule.data.cursors.next,
                                        )}
                                        hasPreviousRecords={Boolean(
                                            fetchAllPriceRule.data.cursors
                                                .previous,
                                        )}
                                        onNavigateBack={handleNavigateBack}
                                        onNavigateForward={
                                            handleNavigateForward
                                        }
                                    />
                                ),
                            }}
                        />
                        <Snackbar
                            open={selectedPriceRuleIds.length > 0}
                            autoHideDuration={null}
                            onClose={handleCloseSnackbar}
                            message={`${selectedPriceRuleIds.length} discount${
                                selectedPriceRuleIds.length > 1 ? "s" : ""
                            } selected`}
                            ClickAwayListenerProps={{
                                mouseEvent: false,
                                touchEvent: false,
                            }}
                            action={
                                <>
                                    <Button onClick={handleDeletePriceRule}>
                                        Delete
                                    </Button>
                                    <IconButton onClick={handleClose}>
                                        <Close />
                                    </IconButton>
                                </>
                            }
                        />
                    </>
                )}
        </Root>
    );
};
