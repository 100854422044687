import { FunctionComponent, ReactElement, useEffect, useState } from "react";

import { Typography } from "@mui/material";

import styled from "@emotion/styled";

import { IKeyValuePair, Records } from "../../components/common";
import { useGetEntitySchemaById } from "../../hooks";
import { IInflatedEntityAttribute } from "../../utils";

const Root = styled("div")`
    display: flex;
    gap: 16px;

    margin-y: 16px;
`;

const Title = styled(Typography)`
    font-size: 14px;
    font-weight: 500;

    margin-bottom: 16px;
`;

const AttributeKeyValuePair = styled("div")`
    display: flex;
    flex-direction: column;
    gap: 4px;

    margin-top: 16px;
`;

export interface ISelectAttribtueValueProps {
    schemaId: string;
    onChange: any;
    onBlur: any;
    value: any;
}

export const SelectAttributeValue: FunctionComponent<
    ISelectAttribtueValueProps
> = (props: ISelectAttribtueValueProps): ReactElement => {
    const { schemaId, value, onChange, onBlur } = props;

    const [attributes, setAttributes] = useState<IInflatedEntityAttribute[]>();

    const entitySchemaQuery = useGetEntitySchemaById(
        ["entitySchemas", schemaId ?? ""],
        schemaId,
    );

    useEffect(() => {
        if (entitySchemaQuery.data) {
            const allAttributes = entitySchemaQuery.data.entityGroups
                .flatMap((group) => group.entityAttributes)
                .filter(
                    (attribute) =>
                        attribute.name !== null &&
                        attribute.entityAttributeOptions.length !== 0,
                );
            setAttributes(allAttributes);
        }
    }, [entitySchemaQuery.data]);

    return (
        <>
            <Root>
                <AttributeKeyValuePair>
                    <Title>Select Attribute values</Title>
                    <Records
                        pairs={
                            attributes?.map((attribute, index) => {
                                return {
                                    key: attribute.name,
                                    keyName: attribute.name,
                                    keyId: attribute.id,
                                    keyLabel: "Attribute",
                                    keyError: false,
                                    keyHelperText: undefined,
                                    value: "",
                                    valueName: "Attribute option name",
                                    valueId: "",
                                    valueLabel: "Attribute option name",
                                    valueError: false,
                                    valueHelperText: undefined,
                                    values:
                                        attribute.entityAttributeOptions?.map(
                                            (option) => option.name,
                                        ) || [],
                                } as IKeyValuePair;
                            }) || []
                        }
                        selectField={true}
                        onAdd={() => {}}
                        onRemove={() => {}}
                        onKeyChange={onChange}
                        onKeyBlur={onBlur}
                        onValueChange={onBlur}
                        onValueBlur={onBlur}
                        disableOnAdd={true}
                        disableOnRemove={true}
                    />
                </AttributeKeyValuePair>
            </Root>
        </>
    );
};
