import {
    UseMutationOptions,
    UseMutationResult,
    useMutation,
    useQueryClient,
} from "react-query";

import { ILink } from "../../types";
import { api } from "../../utils";
import { useAuth } from "../useAuth";
import { useMessage } from "../useMessage";

export interface IUseUpdateLinkMutationParameters {
    id: string;
    name: string;
    description: string;
    startsAt: Date | null;
    endsAt: Date | null;
    destination: string;
    alias: string;
    allowIab: boolean;
    tags: string;
}

/**
 * Updates the link with the specified identifier.
 *
 * @author Samuel Rowe <samuel@houseofx.in>
 */
export const useUpdateLink = (
    invalidQueryKeys?: string | string[],
    options?: UseMutationOptions<
        ILink,
        unknown,
        IUseUpdateLinkMutationParameters,
        unknown
    >,
): UseMutationResult<ILink, unknown, IUseUpdateLinkMutationParameters> => {
    const auth = useAuth();
    const queryClient = useQueryClient();
    const { showError } = useMessage();

    return useMutation<ILink, unknown, IUseUpdateLinkMutationParameters>({
        ...options,
        mutationFn: (
            parameters: IUseUpdateLinkMutationParameters,
        ): Promise<ILink> => {
            if (!auth.token) {
                throw new Error(
                    "Cannot update link without authorization token.",
                );
            }

            return api.Link.update({
                ...parameters,
                token: auth.token,
            });
        },
        onError: (error: any) => showError(error),
        onSuccess: (
            link: ILink,
            variables: IUseUpdateLinkMutationParameters,
            context: unknown,
        ) => {
            if (invalidQueryKeys !== undefined) {
                queryClient.invalidateQueries(invalidQueryKeys);
            }

            options?.onSuccess?.(link, variables, context);
        },
    });
};
