import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useMemo,
    useState,
} from "react";

import {
    Drawer,
    Modal,
    Paper,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { Add, SpeakerNotesOff } from "@mui/icons-material";

import { AxiosError } from "axios";

import { CircularLoader } from "../../components/common";
import {
    useCreateCustomerAddress,
    useDeleteCustomerAddress,
    useGetCustomerAddresses,
    useMessage,
    useUpdateCustomerAddress,
} from "../../sdk/hooks";
import { IAddressFormValues, ICustomer } from "../../sdk/types";
import { AddressForm } from "../order/create/address/AddressForm";

import { CustomerAddressCard } from "./CustomerAddressCard";

const Root = styled("section")`
    flex: 3;
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(0, 1)};
`;

const SavedAddresses = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};

    margin-bottom: ${({ theme }) => theme.spacing(1)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")}{
        min-width: 600px;
    }
    ${theme.breakpoints.down("sm")}{
        min-width: 100%;
    }
    `}
`;

const AddressesWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const MainContainer = styled("div")`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: start;

    gap: ${({ theme }) => theme.spacing(4)};

    ${({ theme }) => theme.breakpoints.down("md")} {
        flex-direction: column;
        gap: ${({ theme }) => theme.spacing(2)};
    }
`;

const ModalContainer = styled("div")`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: fit-content;
    height: fit-content;

    background-color: #fff;
    outline: 0;

    border-radius: ${({ theme }) => theme.spacing(1)};

    max-height: 100vh;
    overflow: auto;
`;

const FormContainer = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: ${({ theme }) => theme.spacing(3)};
`;

const FormDrawer = styled(Drawer)`
    .MuiDrawer-paper {
        height: 95vh;
        border-radius: ${({ theme }) => theme.spacing(2, 2, 0, 0)};
    }
`;

const SectionDetail = styled(Paper)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
    min-width: 500px;

    padding: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
           min-width: 300px;
    }
  `}
`;

const SectionTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    color: black;
`;

const AddressSectionHeader = styled("div")`
    display: flex;
    justify-content: space-between;
`;

const EmptyContainer = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const EmptyIcon = styled(SpeakerNotesOff)`
    height: 50px;
    width: auto;
`;

export interface ICustomerAddressesProps {
    customer: ICustomer | undefined;
}

export const CustomerAddressesList: FunctionComponent<
    ICustomerAddressesProps
> = (props: ICustomerAddressesProps): ReactElement => {
    const { customer } = props;
    const [formOpen, setFormOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const { showError } = useMessage();
    const getAddressesQuery = useGetCustomerAddresses(
        ["addresses", customer?.id ?? ""],
        customer?.id,
    );
    const createAddressMutation = useCreateCustomerAddress([
        "addresses",
        customer?.id ?? "",
    ]);
    const updateAddressMutation = useUpdateCustomerAddress([
        "addresses",
        customer?.id ?? "",
    ]);
    const deleteAddressMutation = useDeleteCustomerAddress([
        "addresses",
        customer?.id ?? "",
    ]);

    const addresses = useMemo(
        () => getAddressesQuery.data?.records ?? [],
        [getAddressesQuery.data?.records],
    );
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleEditAddress = useCallback((index: number) => {
        setEditIndex(index);
    }, []);

    const handleAddAddress = useCallback(() => {
        setFormOpen(true);
    }, []);

    const handleFormClose = useCallback(() => {
        setFormOpen(false);
        setEditIndex(-1);
    }, []);

    const handleDeleteAddress = useCallback(
        (index: number, id: string) => {
            if (customer) {
                deleteAddressMutation.mutate(
                    { id, customerId: customer.id },
                    {
                        onError: (error: any) => {
                            showError(error);
                        },
                    },
                );
            }
        },
        [customer, deleteAddressMutation, showError],
    );

    const handleCreateAddress = useCallback(
        (values: IAddressFormValues) => {
            if (customer) {
                createAddressMutation.mutate(
                    {
                        ...values,
                        customerId: customer.id,
                    },
                    {
                        onSuccess: () => {
                            setFormOpen(false);
                        },
                        onError: (error: unknown) => {
                            const message =
                                ((error as AxiosError).response?.data as any)
                                    ?.message ?? (error as Error).message;
                            if (
                                message.indexOf(
                                    "fails to match the required pattern",
                                ) >= 0
                            ) {
                                showError("The specified pincode is invalid.");
                                return;
                            }
                            showError(message);
                        },
                    },
                );
            }
        },
        [createAddressMutation, customer, showError],
    );

    const handleUpdateAddress = useCallback(
        async (address: IAddressFormValues, id?: string) => {
            if (customer) {
                await updateAddressMutation.mutateAsync(
                    { ...address, id: id!, customerId: customer.id },
                    {
                        onSuccess: () => {
                            setEditIndex(-1);
                        },
                        onError: (error: any) => {
                            showError(error);
                        },
                    },
                );
            }
        },
        [customer, showError, updateAddressMutation],
    );

    const loading =
        getAddressesQuery.isLoading ||
        updateAddressMutation.isLoading ||
        createAddressMutation.isLoading ||
        deleteAddressMutation.isLoading;

    const renderForLargeScreen = () => {
        if (customer) {
            return (
                <Modal
                    open={formOpen || editIndex !== -1}
                    onClose={handleFormClose}
                >
                    <ModalContainer>
                        <FormContainer>
                            {(formOpen || addresses.length === 0) && (
                                <AddressForm
                                    customer={customer}
                                    onSave={handleCreateAddress}
                                    saving={createAddressMutation.isLoading}
                                    onClose={handleFormClose}
                                    closeable={addresses.length !== 0}
                                />
                            )}

                            {editIndex !== -1 && (
                                <AddressForm
                                    customer={customer}
                                    oldAddress={addresses[editIndex]}
                                    onSave={handleUpdateAddress}
                                    saving={updateAddressMutation.isLoading}
                                    onClose={handleFormClose}
                                    closeable={addresses.length !== 0}
                                />
                            )}
                        </FormContainer>
                    </ModalContainer>
                </Modal>
            );
        }
    };

    const renderForSmallScreen = () => {
        if (customer) {
            return (
                <FormDrawer
                    anchor={"bottom"}
                    open={formOpen || editIndex !== -1}
                    onClose={handleFormClose}
                >
                    <FormContainer>
                        {(formOpen || addresses.length === 0) && (
                            <AddressForm
                                customer={customer}
                                onSave={handleCreateAddress}
                                saving={createAddressMutation.isLoading}
                                onClose={handleFormClose}
                                closeable={addresses.length !== 0}
                            />
                        )}

                        {editIndex !== -1 && (
                            <AddressForm
                                customer={customer}
                                oldAddress={addresses[editIndex]}
                                onSave={handleUpdateAddress}
                                saving={updateAddressMutation.isLoading}
                                onClose={handleFormClose}
                                closeable={addresses.length !== 0}
                            />
                        )}
                    </FormContainer>
                </FormDrawer>
            );
        }
    };

    return (
        <>
            {loading && <CircularLoader />}
            {!loading && (
                <SectionDetail variant="outlined" elevation={0}>
                    <AddressSectionHeader>
                        <SectionTitle>Addresses</SectionTitle>
                        {customer && (
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleAddAddress}
                                endIcon={<Add />}
                            >
                                Add new address
                            </LoadingButton>
                        )}
                    </AddressSectionHeader>

                    {addresses.length === 0 && (
                        <EmptyContainer>
                            <EmptyIcon />
                            <SectionTitle>No address found</SectionTitle>
                        </EmptyContainer>
                    )}
                    <Root>
                        <MainContainer>
                            {customer && addresses.length > 0 && (
                                <>
                                    <SavedAddresses>
                                        <AddressesWrapper>
                                            {addresses.map((address, index) => (
                                                <CustomerAddressCard
                                                    key={index}
                                                    index={index}
                                                    handleEditAddress={
                                                        handleEditAddress
                                                    }
                                                    handleDeleteAddress={
                                                        handleDeleteAddress
                                                    }
                                                    {...address}
                                                    address={address}
                                                />
                                            ))}
                                        </AddressesWrapper>
                                    </SavedAddresses>
                                </>
                            )}

                            {/* for screens bigger than "sm" we use modal */}
                            {!smallScreen && renderForLargeScreen()}

                            {/* for screens smaller than "sm" we use drawer */}
                            {smallScreen && renderForSmallScreen()}
                        </MainContainer>
                    </Root>
                </SectionDetail>
            )}
        </>
    );
};
