import { UseQueryResult, useQuery } from "react-query";

import { ICustomerAddress, IExternalListPage } from "../types";
import { api } from "../utils";

import { useAuth } from "./useAuth";

export const useGetCustomerAddresses = (
    queryKeys: string[],
    customerId: string | undefined,
): UseQueryResult<IExternalListPage<ICustomerAddress>, unknown> => {
    const auth = useAuth();
    if (!auth.token) {
        throw new Error("Cannot get customer addresses without valid token.");
    }

    return useQuery(
        queryKeys,
        async () =>
            await api.addresses.getCustomerAddresses(
                auth.token!,
                customerId ?? "",
            ),
        { enabled: typeof customerId !== "undefined" },
    );
};