import axios from "axios";

import * as api from "../../types";

export interface ICreateCustoemrGroupParameter {
    token: string;
    name: string;
    description: string;
    customerIds: string[];
}

export interface IUpdateCustomerGroupParameter {
    token: string;
    name: string;
    description: string;
    customerIds: string[];
    customerGroupId: string;
}

export interface IGetCustomerGroupParameter {
    customerGroupId: string;
    token: string;
}

export interface IGetAllCustomerGroupsParameter {
    limit: number;
    token: string;
    cursor: string | null;
    direction: string;
}

export interface IDeleteCustomerGroupPrarameters {
    token: string;
    customerGroupId: string;
}

export class CustomerGroup {
    private static transform = (
        data: api.ICustomerGroup,
    ): api.ICustomerGroup => {
        return {
            ...data,
            createdAt: new Date(data.createdAt),
            updatedAt: new Date(data.updatedAt),
        };
    };

    static async create(
        parameters: ICreateCustoemrGroupParameter,
    ): Promise<api.ICustomerGroup> {
        const { token, name, description, customerIds } = parameters;
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/customer-groups`,
            {
                name,
                description,
                customerIds,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return CustomerGroup.transform(data);
    }

    static async getById(
        parameters: IGetCustomerGroupParameter,
    ): Promise<api.ICustomerGroup> {
        const { token, customerGroupId } = parameters;
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/customer-groups/${customerGroupId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return CustomerGroup.transform(data);
    }

    static async getAll(
        parameters: IGetAllCustomerGroupsParameter,
    ): Promise<api.ICustomerGroup> {
        const { token, ...body } = parameters;
        const params = new URLSearchParams([
            ["limit", body.limit.toString()],
            ["direction", body.direction],
        ]);

        if (body.cursor) {
            params.append("cursor", body.cursor);
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/customer-groups?${params}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return {
            ...data,
            records: data.records.map(CustomerGroup.transform),
        };
    }

    static async update(
        parameters: IUpdateCustomerGroupParameter,
    ): Promise<api.ICustomerGroup> {
        const { token, customerGroupId, name, description, customerIds } =
            parameters;
        const { data } = await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/customer-groups/${customerGroupId}`,
            {
                name,
                description,
                customerIds,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return CustomerGroup.transform(data);
    }

    static async delete(
        parameters: IDeleteCustomerGroupPrarameters,
    ): Promise<api.ICustomerGroup> {
        const { token, customerGroupId } = parameters;
        const { data } = await axios.delete(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/customer-groups/${customerGroupId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return CustomerGroup.transform(data);
    }
}
