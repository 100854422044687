import { FunctionComponent, ReactElement, useCallback } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Icon,
    IconButton,
    Typography,
    styled,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { useFormik } from "formik";

import { useMessage } from "../../sdk/hooks";
import { useGenerateDiscountCodes } from "../../sdk/hooks/discounts";
import { DiscountCodeStatus } from "../../utils/enums";

import {
    GeneratorForm,
    IGeneratorFormValues,
    generatorFormSchema,
} from "./GeneratorForm";

const Root = styled(Dialog)``;

const Title = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const StyledDialogTitle = styled(DialogTitle)`
    width: 100%;
    background-color: #f0f0f0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(1, 2)};
`;

const StyledDialogContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(3)};
`;

const StyledDialogActions = styled(DialogActions)`
    padding: ${({ theme }) => theme.spacing(1, 3, 3, 3)};
`;

export interface IGeneratorDialogProps {
    open: boolean;
    onClose: () => void;
    priceRuleId: string;
    invalidQueryKeys?: string | string[];
}

export const GeneratorDialog: FunctionComponent<IGeneratorDialogProps> = (
    props: IGeneratorDialogProps,
): ReactElement => {
    const { open, onClose, priceRuleId, invalidQueryKeys } = props;

    const generateMutation = useGenerateDiscountCodes(invalidQueryKeys);
    const message = useMessage();

    const formik = useFormik<IGeneratorFormValues>({
        initialValues: {
            count: 1,
            charset: "alphanumeric",
            pattern: "",
            status: DiscountCodeStatus.ACTIVE,
        },
        onSubmit: (values: IGeneratorFormValues) => {
            console.log(values);
            generateMutation.mutate(
                {
                    ...values,
                    priceRuleId,
                },
                {
                    onSuccess: (discountCodes) => {
                        message.showSuccess(
                            `Successfully generated ${discountCodes.length} discount code(s)`,
                        );
                        onClose();
                    },
                },
            );
        },
        validationSchema: generatorFormSchema,
    });

    const handleGenerate = useCallback(() => {
        formik.handleSubmit();
    }, [formik]);

    return (
        <Root
            maxWidth={"xs"}
            fullWidth={true}
            disableScrollLock={true}
            open={open}
            onClose={onClose}
        >
            <StyledDialogTitle>
                <Title>Generate Discount Codes</Title>
                <IconButton onClick={onClose}>
                    <Icon>cancel</Icon>
                </IconButton>
            </StyledDialogTitle>
            <StyledDialogContent>
                <GeneratorForm formik={formik} />
            </StyledDialogContent>
            <StyledDialogActions>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={onClose}
                    disabled={!formik.dirty}
                    size="small"
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={handleGenerate}
                    disabled={!formik.dirty || !formik.isValid}
                    size="small"
                    loading={generateMutation.isLoading}
                >
                    Generate
                </LoadingButton>
            </StyledDialogActions>
        </Root>
    );
};
