import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { IRefundOrderResponse } from "../types";
import { api } from "../utils";

import { useAuth } from "./useAuth";

export interface IRefundOrderMutationParameters {
    orderId: string;
    reason: number;
    note: string;
    amount: number;
}

export const useRefundOrder = (
    invalidQueryKeys: string[] = [],
): UseMutationResult<
    IRefundOrderResponse,
    unknown,
    IRefundOrderMutationParameters
> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation<
        IRefundOrderResponse,
        unknown,
        IRefundOrderMutationParameters
    >({
        mutationFn: async (
            parameters: IRefundOrderMutationParameters,
        ): Promise<IRefundOrderResponse> => {
            if (!auth.token) {
                throw new Error("Cannot refund order without a valid token.");
            }

            return await api.orders.Order.refundOrder({
                ...parameters,
                token: auth.token,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(invalidQueryKeys);
        },
        onError: (error: any) => {
            console.error(error);
        },
    });
};
