import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useState,
} from "react";

import {
    Avatar,
    Chip,
    Icon,
    Paper,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import {
    LoadingButton,
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from "@mui/lab";

import { AddOutlined, CheckCircle, Edit, Person } from "@mui/icons-material";

import * as Yup from "yup";
import { format } from "date-fns";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { WorkspaceToolbar } from "../../components/common";
import { useGetCustomerById, useUpdateCustomer } from "../../hooks";
import {
    useGetAuthHistory,
    useGetOrdersByCustomerId,
    useInitializeCart,
    useMessage,
} from "../../sdk/hooks";

import { AuthHistoryGrid } from "./AuthHistoryGrid";
import { CustomerAddressesList } from "./CustomerAddressesList";
import { OrderGrid } from "./OrderGrid";

const Root = styled("main")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(4)};

    padding: ${({ theme }) => theme.spacing(3)};
    margin: ${({ theme }) => theme.spacing(0, "auto")};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            gap: ${theme.spacing(3)};
    }
  `}
`;

const LeftContainer = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(4)};

    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Title = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const FieldContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(3)};
`;

const Container = styled("div")``;

const TextFieldContainer = styled("div")`
    height: 40px;
    width: 360px;
`;

const TopContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(5)};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            flex-direction: column
    }
  `}
`;

const StyledAvatar = styled(Avatar)`
    height: 80px;
    width: 80px;

    background-color: #042442;
`;

const CustomerIcon = styled(Person)`
    height: 60px;
    width: 60px;
    color: rgba(124, 172, 248, 0.5);
`;

const DetailSection = styled(Paper)`
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: ${({ theme }) => theme.spacing(2)};
    max-width: 800px;

    padding: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            display: flex;
            flex-direction: column
    }
  `}
`;

const SectionTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    color: black;
`;

const SectionDetail = styled(Paper)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
    min-width: 500px;
    max-width: 1000px;

    padding: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
           min-width: 300px;
    }
  `}
`;

const StyledTimeline = styled(Timeline)`
    padding: 0;
    margin: ${({ theme }) => theme.spacing(1, 0, 0)};

    .MuiTimelineItem-root:before {
        content: none;
    }
`;

const StyledTimelineContent = styled(TimelineContent)`
    padding: ${({ theme }) => theme.spacing(1.5, 2)};
`;

const TimelineText = styled(Typography)`
    font-size: 14px;
`;

const TimelineTitle = styled(TimelineText)`
    font-weight: 500;
    color: #626262;
`;

const StyledTimelineDot = styled(TimelineDot)`
    box-shadow: none;
`;

const RightSection = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
    gap: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
           width: 100%;
    }
  `}
`;

const StyledChip = styled(Chip)<{ customColor: string }>`
    color: ${({ customColor }) => customColor};
    justify-content: flex-start;
    max-width: 200px;
    border-color: ${({ customColor }) => customColor};
`;

const ChipsContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(3)};
`;

const StyledPersonIcon = styled(Person)`
    fill: #1976d2;
`;

export interface ICustomerForm {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    customerId: string;
    createdAt: Date;
    updatedAt: Date;
}

const validationSchema = Yup.object({
    firstName: Yup.string().min(1).required("First name is required"),
    lastName: Yup.string().min(1).required("Last name is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    emailAddress: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
    createdAt: new Yup.DateSchema(),
    updatedAt: new Yup.DateSchema(),
});

export const ViewCustomerScreen: FunctionComponent = (): ReactElement => {
    const params = useParams();

    const updateAdminGroupMutation = useUpdateCustomer("customers");
    const queryClient = useQueryClient();
    const { showError, showSuccess } = useMessage();

    let cartFound = false;

    const initializeCartMutation = useInitializeCart();

    const [initialValues, setInitialValues] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailAddress: "",
        customerId: params.customerId ?? "",
        createdAt: new Date(),
        updatedAt: new Date(),
    });

    const customerQuery = useGetCustomerById(
        params.customerId ?? "",
        "customers",
        {
            onSuccess: (customer) => {
                if (!customer) {
                    showError("Customer not found");
                }
                setInitialValues({
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    phoneNumber: customer.phoneNumber,
                    emailAddress: customer.emailAddress,
                    customerId: params.customerId ?? customer.id,
                    createdAt: customer.createdAt,
                    updatedAt: customer.updatedAt,
                });
                queryClient.invalidateQueries(customer.phoneNumber);
            },
        },
    );

    const navigate = useNavigate();

    const handleCreateCart = useCallback(() => {
        /* Initialise a new cart if customer exists but an active cart for the customer does not */
        if (customerQuery.data && !cartFound) {
            initializeCartMutation.mutate(
                { customerId: params.customerId ?? "" },
                {
                    onSuccess: (cart) => {
                        navigate(`/carts/${cart.id}`);
                    },
                },
            );
        }
    }, [
        cartFound,
        customerQuery.data,
        initializeCartMutation,
        navigate,
        params.customerId,
    ]);

    const {
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        handleBlur,
        dirty,
        isValid,
    } = useFormik<ICustomerForm>({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values0) => {
            await updateAdminGroupMutation.mutate(values0, {
                onSuccess: () => {
                    showSuccess("Customer updated successfully");
                },
            });
        },
    });

    const handleSave = useCallback(() => {
        handleSubmit();
    }, [handleSubmit]);

    const page = {
        limit: 20,
        cursor: null,
        direction: "after",
    };

    const ordersByCustomerIdQuery = useGetOrdersByCustomerId(
        params.customerId ?? "",
        page,
        "orders",
    );

    const customerAuthHistory = useGetAuthHistory(
        customerQuery.data?.phoneNumber ?? "",
        page,
        [
            "authHistory",
            params.customerId ?? "",
            customerQuery.data?.phoneNumber ?? "",
        ],
    );

    const testCustomer =
        (customerQuery.data?.emailAddress ?? "")
            .toLowerCase()
            .includes("test") ||
        (customerQuery.data?.firstName ?? "").toLowerCase().includes("test");

    const firstName = customerQuery.data?.firstName ?? "";

    const lastName = customerQuery.data?.lastName ?? "";

    return (
        <>
            <WorkspaceToolbar
                title="Customer"
                enableSearch={false}
                actions={
                    <>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleSave}
                            endIcon={<Icon>check_circle</Icon>}
                            disabled={!dirty || !isValid}
                            loading={updateAdminGroupMutation.isLoading}
                        >
                            Save
                        </LoadingButton>
                    </>
                }
                breadcrumbs={[
                    { title: "Customers", link: "/customers" },
                    {
                        title: firstName + " " + lastName,
                        link: `/customers/${params.customerId}`,
                    },
                ]}
            />
            <Container>
                <Root>
                    <TopContainer>
                        <LeftContainer>
                            <DetailSection variant="outlined" elevation={0}>
                                <SectionTitle>General</SectionTitle>
                                <FieldContainer>
                                    <TextFieldContainer>
                                        <TextField
                                            required={true}
                                            fullWidth={true}
                                            name="firstName"
                                            label="First Name"
                                            onChange={handleChange}
                                            value={values.firstName}
                                            onBlur={handleBlur}
                                            size="small"
                                            variant="outlined"
                                            error={
                                                Boolean(errors.firstName) &&
                                                touched.firstName
                                            }
                                            helperText={
                                                touched.firstName &&
                                                errors.firstName
                                            }
                                        />
                                    </TextFieldContainer>
                                    <TextFieldContainer>
                                        <TextField
                                            required={true}
                                            fullWidth={true}
                                            name="lastName"
                                            label="Last Name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.lastName}
                                            size="small"
                                            variant="outlined"
                                            error={
                                                Boolean(errors.lastName) &&
                                                touched.lastName
                                            }
                                            helperText={
                                                touched.lastName &&
                                                errors.lastName
                                            }
                                        />
                                    </TextFieldContainer>
                                </FieldContainer>
                                <FieldContainer>
                                    <TextFieldContainer>
                                        <TextField
                                            required={true}
                                            fullWidth={true}
                                            name="phoneNumber"
                                            label="Phone Number"
                                            value={values.phoneNumber}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            error={
                                                Boolean(errors.phoneNumber) &&
                                                touched.phoneNumber
                                            }
                                            helperText={
                                                touched.phoneNumber &&
                                                errors.phoneNumber
                                            }
                                        />
                                    </TextFieldContainer>
                                    <TextFieldContainer>
                                        <TextField
                                            required={true}
                                            fullWidth={true}
                                            name="emailAddress"
                                            label="Email Address"
                                            value={values.emailAddress}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            error={
                                                Boolean(errors.emailAddress) &&
                                                touched.emailAddress
                                            }
                                            helperText={
                                                touched.emailAddress &&
                                                errors.emailAddress
                                            }
                                        />
                                    </TextFieldContainer>
                                </FieldContainer>
                                <ChipsContainer>
                                    {customerQuery.data?.phoneNumberVerified ? (
                                        <StyledChip
                                            customColor="#4caf50"
                                            icon={
                                                <CheckCircle color="success" />
                                            }
                                            label="Phone Number Verified"
                                            variant="outlined"
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {testCustomer && (
                                        <StyledChip
                                            customColor="#1976d2"
                                            icon={<StyledPersonIcon />}
                                            label="Test customer"
                                            variant="outlined"
                                        />
                                    )}
                                </ChipsContainer>
                            </DetailSection>
                        </LeftContainer>
                        <RightSection>
                            <SectionDetail variant="outlined" elevation={0}>
                                <SectionTitle>Activity</SectionTitle>
                                <FieldContainer>
                                    <StyledTimeline>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <StyledTimelineDot color="secondary">
                                                    <AddOutlined fontSize="small" />
                                                </StyledTimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <StyledTimelineContent>
                                                <TimelineTitle>
                                                    Created
                                                </TimelineTitle>
                                                <TimelineText>
                                                    {`at ${format(
                                                        values.createdAt,
                                                        "dd-MM-yyyy, p",
                                                    )}`}
                                                </TimelineText>
                                            </StyledTimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineConnector />
                                                <StyledTimelineDot
                                                    variant="outlined"
                                                    color="secondary"
                                                >
                                                    <Edit
                                                        fontSize="small"
                                                        color="secondary"
                                                    />
                                                </StyledTimelineDot>
                                            </TimelineSeparator>
                                            <StyledTimelineContent>
                                                <TimelineTitle>
                                                    Last updated
                                                </TimelineTitle>
                                                <TimelineText>
                                                    {`at ${format(
                                                        values.updatedAt,
                                                        "dd-MM-yyyy, p",
                                                    )}`}
                                                </TimelineText>
                                            </StyledTimelineContent>
                                        </TimelineItem>
                                    </StyledTimeline>
                                    <Title>
                                        <StyledAvatar>
                                            <CustomerIcon />
                                        </StyledAvatar>
                                    </Title>
                                </FieldContainer>
                            </SectionDetail>
                        </RightSection>
                    </TopContainer>

                    <CustomerAddressesList customer={customerQuery.data} />

                    <OrderGrid
                        rows={ordersByCustomerIdQuery.data?.records ?? []}
                        onCreateCart={handleCreateCart}
                    />

                    {(customerAuthHistory.data?.records ?? []).length > 0 && (
                        <AuthHistoryGrid
                            rows={customerAuthHistory.data?.records ?? []}
                        />
                    )}
                </Root>
            </Container>
        </>
    );
};
