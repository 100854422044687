import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

import {
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Icon,
    IconButton,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import copy from "copy-to-clipboard";
import QRCodeGenerator from "qrcode";
import { useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";

import { WorkspaceToolbar } from "../../components/common";
import { useGetLinkById, useMessage } from "../../sdk/hooks";
import { IMessageContext } from "../../sdk/types";

const Root = styled("div")`
    min-height: 500px;
`;

const Content = styled("div")`
    padding: ${({ theme }) => theme.spacing(4)};
`;

const InfoCard = styled(Card)`
    border: 1px solid #d9d9d9;
    width: fit-content;
    padding: ${({ theme }) => theme.spacing(2)};
`;

const InfoCardContent = styled(CardContent)`
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(8)};
    align-items: center;
`;

const Left = styled("div")`
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(4)};
`;

const StyledDivider = styled(Divider)`
    height: 200px;
    width: 1px;
`;

const Right = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${({ theme }) => theme.spacing(0)};
`;

const KvPairs = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: ${({ theme }) => theme.spacing(2)};
`;

const StyledQrCode = styled("img")`
    width: 260px;
    height: 260px;
`;

const DownloadButton = styled(Button)`
    width: 210px;
`;

const Top = styled("div")`
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(8)};
`;

const TextInfo = styled("div")`
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(2)};
`;

const Texts = styled("div")`
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(0.5)};
`;

const Name = styled(Typography)`
    font-size: 18px;
    font-weight: 800;
`;

const Description = styled(Typography)`
    font-size: 14px;
    max-width: 500px;
`;

const IconValueContainer = styled("div")`
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
`;

const IconValueText = styled(Typography)`
    font-size: 12px;
`;

const KvPair = styled("div")`
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(0.5)};
`;

const KvPairIcon = styled(Icon)`
    color: ${({ theme }) => theme.palette.text.primary};
`;

const KvPairTexts = styled("div")`
    display: flex;
    flex-direction: column;
`;

const KvPairKey = styled(Typography)`
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.text.primary};
`;

const KvPairValue = styled(Typography)`
    font-size: 22px;
    font-weight: 800;
    color: ${({ theme }) => theme.palette.text.primary};
`;

const Tags = styled("div")`
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(1)};
`;

const Bottom = styled("div")`
    display: flex;
    flex-direction: row;
`;

const Routes = styled("div")`
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(2)};
    align-items: center;

    max-width: 600px;
    width: 100%;
`;

const LinkActions = styled("div")`
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;

    margin-left: ${({ theme }) => theme.spacing(1)};
`;

const StyledIcon = styled(Icon)`
    color: #bababa;
`;

const copyText = (text: string, message: IMessageContext) => {
    if (
        copy(text, {
            format: "text/plain",
        })
    ) {
        message.showSuccess("Copied to clipboard");
    } else {
        message.showError("Failed to copy to clipboard");
    }
};

export const ViewLinkScreen: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();
    const params = useParams();
    const message = useMessage();

    const getQuery = useGetLinkById(
        ["getLinkById", params.linkId!],
        params.linkId!,
        {
            enabled: true,
        },
    );

    const handleEdit = useCallback(() => {
        navigate(`/links/${params.linkId}/edit`);
    }, [navigate, params.linkId]);

    const link = getQuery.data;
    const aliasUrl = useMemo(
        () =>
            link
                ? new URL(
                      `${process.env.REACT_APP_LINK_BASE_URL}/${
                          link.alias ?? ""
                      }`,
                  )
                : null,
        [link],
    );
    const qrCodeValue = aliasUrl ? `${aliasUrl.toString()}?qrc=true` : null;

    const handleOpenAlias = useCallback(() => {
        if (aliasUrl) {
            window.open(aliasUrl.toString(), "_blank");
        }
    }, [aliasUrl]);

    const handleOpenDestination = useCallback(() => {
        if (link) {
            window.open(link.destination, "_blank");
        }
    }, [link]);

    const handleCopyAlias = useCallback(() => {
        if (aliasUrl) {
            copyText(aliasUrl.toString(), message);
        }
    }, [aliasUrl, message]);

    const handleCopyDestination = useCallback(() => {
        if (link) {
            copyText(link.destination, message);
        }
    }, [link, message]);

    const [image, setImage] = useState<string | null>(null);
    useEffect(() => {
        let mounted = true;
        (async () => {
            if (qrCodeValue && link) {
                const image0 = await QRCodeGenerator.toDataURL(qrCodeValue);
                if (mounted) {
                    setImage(image0);
                }
            }
        })();

        return () => {
            mounted = false;
        };
    }, [qrCodeValue, link]);

    const handleDownload = useCallback(() => {
        if (qrCodeValue && link && image) {
            const name = slugify(link.name, {
                replacement: "-",
                lower: true,
                strict: true,
                trim: true,
                remove: /[%^&*+~.()'"!:@/\\]/g,
            });

            const temoraryAnchor = document.createElement("a");
            temoraryAnchor.href = image;
            temoraryAnchor.download = `${name}.png`;
            temoraryAnchor.click();
        }
    }, [qrCodeValue, link, image]);

    return (
        <Root>
            <WorkspaceToolbar
                title="View Link"
                enableSearch={false}
                actions={
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        endIcon={<Icon>edit</Icon>}
                        onClick={handleEdit}
                    >
                        Edit
                    </Button>
                }
                breadcrumbs={[
                    { title: "Links", link: "/links" },
                    ...(link
                        ? [
                              {
                                  title: link?.name ?? "",
                                  link: `/links/${link?.id ?? ""}`,
                              },
                          ]
                        : []),
                ]}
            />
            <Content>
                <InfoCard elevation={0}>
                    {link && aliasUrl && qrCodeValue && (
                        <InfoCardContent>
                            <Left>
                                <Top>
                                    <TextInfo>
                                        <Texts>
                                            <Name>{link.name}</Name>
                                            <Description>
                                                {link.description}
                                            </Description>
                                        </Texts>
                                        <Texts>
                                            <IconValueContainer>
                                                <Icon fontSize="small">
                                                    account_circle
                                                </Icon>
                                                <IconValueText>
                                                    Unknown
                                                </IconValueText>
                                            </IconValueContainer>
                                            <IconValueContainer>
                                                <Icon fontSize="small">
                                                    calendar_month
                                                </Icon>
                                                <IconValueText>
                                                    {link.createdAt.toLocaleString()}
                                                </IconValueText>
                                            </IconValueContainer>
                                        </Texts>
                                        <Tags>
                                            {link.tags.split(",").map((tag) => (
                                                <Chip
                                                    key={tag}
                                                    label={tag}
                                                    size="small"
                                                />
                                            ))}
                                        </Tags>
                                    </TextInfo>
                                </Top>
                                <Bottom>
                                    <Routes>
                                        <TextField
                                            label="Alias"
                                            value={aliasUrl.toString()}
                                            size="small"
                                            fullWidth={true}
                                            InputProps={{
                                                endAdornment: (
                                                    <LinkActions>
                                                        <IconButton
                                                            size="small"
                                                            onClick={
                                                                handleOpenAlias
                                                            }
                                                        >
                                                            <Icon fontSize="small">
                                                                open_in_new
                                                            </Icon>
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            onClick={
                                                                handleCopyAlias
                                                            }
                                                        >
                                                            <Icon fontSize="small">
                                                                content_copy
                                                            </Icon>
                                                        </IconButton>
                                                    </LinkActions>
                                                ),
                                            }}
                                        />
                                        <StyledIcon>
                                            keyboard_double_arrow_down
                                        </StyledIcon>
                                        <TextField
                                            label="Destination"
                                            value={link.destination}
                                            size="small"
                                            fullWidth={true}
                                            InputProps={{
                                                endAdornment: (
                                                    <LinkActions>
                                                        <IconButton
                                                            size="small"
                                                            onClick={
                                                                handleOpenDestination
                                                            }
                                                        >
                                                            <Icon fontSize="small">
                                                                open_in_new
                                                            </Icon>
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            onClick={
                                                                handleCopyDestination
                                                            }
                                                        >
                                                            <Icon fontSize="small">
                                                                content_copy
                                                            </Icon>
                                                        </IconButton>
                                                    </LinkActions>
                                                ),
                                            }}
                                        />
                                    </Routes>
                                </Bottom>
                            </Left>
                            <StyledDivider orientation="vertical" />
                            <Right>
                                <KvPairs>
                                    <KvPair>
                                        <KvPairIcon
                                            sx={{ fontSize: 54, mt: -0.3 }}
                                            fontSize="large"
                                        >
                                            ads_click
                                        </KvPairIcon>
                                        <KvPairTexts>
                                            <KvPairKey>Clicks</KvPairKey>
                                            <KvPairValue>
                                                {link.clicks}
                                            </KvPairValue>
                                        </KvPairTexts>
                                    </KvPair>
                                    <KvPair>
                                        <KvPairIcon
                                            sx={{ fontSize: 54, mt: -0.2 }}
                                            fontSize="large"
                                        >
                                            center_focus_strong
                                        </KvPairIcon>
                                        <KvPairTexts>
                                            <KvPairKey>Scans</KvPairKey>
                                            <KvPairValue>
                                                {link.scans}
                                            </KvPairValue>
                                        </KvPairTexts>
                                    </KvPair>
                                </KvPairs>
                                {image && <StyledQrCode src={image} />}
                                {qrCodeValue && (
                                    <DownloadButton
                                        variant="contained"
                                        onClick={handleDownload}
                                        endIcon={<Icon>download</Icon>}
                                    >
                                        DOWNLOAD
                                    </DownloadButton>
                                )}
                            </Right>
                        </InfoCardContent>
                    )}
                </InfoCard>
            </Content>
        </Root>
    );
};
