import axios from "axios";
import { format } from "date-fns";

import { globalDateTimeFormat } from "../../../utils/constants";
import { IAdminGroup, IExternalListPage } from "../../types/api";

export interface ICreateAdminGroupParameter {
    token: string;
    name: string;
    description: string;
    adminIds: string[];
}
export interface IGetAdminGroupParameters {
    token: string;
    id: string;
}

export interface IUpdateAdminGroupParameters {
    token: string;
    id: string;
    name: string;
    description: string;
    adminIds: string[];
}

export interface IDeleteAdminGroupParameters {
    token: string;
    id: string;
}
export class AdminGroup {
    static transform = (data: any) => {
        return {
            ...data,
            createdAt: format(new Date(data.createdAt), globalDateTimeFormat),
            updatedAt: format(new Date(data.updatedAt), globalDateTimeFormat),
        };
    };
    static async create(
        parameters: ICreateAdminGroupParameter,
    ): Promise<IAdminGroup> {
        const { token, ...body } = parameters;
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/admin-groups`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return data;
    }

    static async list(token: string): Promise<IExternalListPage<IAdminGroup>> {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/admin-groups`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return {
            ...data,
            records: data.records.map(AdminGroup.transform),
        };
    }

    static async getById(
        parameters: IGetAdminGroupParameters,
    ): Promise<IAdminGroup> {
        const { token, id } = parameters;
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/admin-groups/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return AdminGroup.transform(data);
    }

    static async update(
        parameters: IUpdateAdminGroupParameters,
    ): Promise<IAdminGroup> {
        const { token, id, ...body } = parameters;
        const { data } = await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/admin-groups/${id}`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return data;
    }

    static async delete(
        parameters: IDeleteAdminGroupParameters,
    ): Promise<IAdminGroup> {
        const { token, id } = parameters;
        const { data } = await axios.delete(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/admin-groups/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return data;
    }
}
