import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { IOrder } from "../types";
import { api } from "../utils";

import { useAuth } from "./useAuth";

export interface ICancelOrderMutationParameters {
    orderId: string;
    reason: number;
    refundAmount: number;
    refundNote: string;
    note: string;
}

export const useCancelOrder = (
    invalidQueryKeys: string[] = [],
): UseMutationResult<IOrder, unknown, ICancelOrderMutationParameters> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation<IOrder, unknown, ICancelOrderMutationParameters>({
        mutationFn: async (
            parameters: ICancelOrderMutationParameters,
        ): Promise<IOrder> => {
            if (!auth.token) {
                throw new Error("Cannot cancel order without a valid token.");
            }

            return await api.orders.Order.cancelOrder({
                ...parameters,
                token: auth.token,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(invalidQueryKeys);
        },
    });
};
