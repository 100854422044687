import React, { FunctionComponent, useCallback } from "react";

import { IconButton, Typography, styled } from "@mui/material";

import { Add, Remove } from "@mui/icons-material";

const Root = styled("div")`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: ${({ theme }) => theme.spacing(1)};

    height: 100%;
`;

const Action = styled(IconButton)`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808080;

    &:hover {
        border-color: black;
        color: black;
    }
`;

const Count = styled(Typography)`
    font-size: 12px;

    margin: auto;
`;

export interface ICounterProps {
    value: number;
    loading: boolean;
    onChange: (value: number) => void;
}

export const Counter: FunctionComponent<ICounterProps> = (
    props: ICounterProps,
) => {
    const { loading, value, onChange } = props;

    const handleIncrement = useCallback(() => {
        onChange(value + 1);
    }, [onChange, value]);

    const handleDecrement = useCallback(() => {
        if (value - 1 >= 0) {
            onChange(value - 1);
        }
    }, [onChange, value]);

    return (
        <Root>
            <Action
                onClick={handleDecrement}
                size="small"
                disabled={loading}
                id="counter--button--decrease"
            >
                <Remove fontSize="small" />
            </Action>
            <Count id="cart--line-item--total-quantity">{value}</Count>
            <Action
                onClick={handleIncrement}
                size="small"
                disabled={loading || value === 0 || value === 5}
                id="counter--button--increase"
            >
                <Add fontSize="small" />
            </Action>
        </Root>
    );
};
