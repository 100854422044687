import { FunctionComponent, ReactElement, useCallback } from "react";

import {
    ButtonBase,
    InputAdornment,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { ChevronRight, Close } from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik } from "formik";
import { browserName } from "react-device-detect";

import { ICustomerFormValues } from "../../../sdk/types";

const Root = styled("section")`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        align-items: stretch;
    }
    `}
`;

const Header = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const FormCloseHandler = styled("div")`
    display: flex;
    justify-content: center;
`;

const FormDrawerCloseButton = styled(ButtonBase)`
    width: 32px;
    height: 32px;
    border-radius: 50%;
`;

const FormContainer = styled("form")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};
    height: fit-content;
    margin: ${({ theme }) => theme.spacing(5, 0)};

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        gap: ${theme.spacing(1.5)};
        margin-top: ${theme.spacing(2)};
    }
    `}
`;

const InputField = styled(TextField)`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    width: 100%;

    & label.Mui-focused {
        color: #a3a3a3;
    }
    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: #a3a3a3;
        }
    }

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        font-size: 12px;
        line-height: 16px;
    }
    `}
`;

const Container = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        flex-direction: column;
        align-items: stretch;
        gap: ${theme.spacing(1.5)};
    }
    `}
`;

const SaveButton = styled(LoadingButton)`
    margin: auto;
    margin-top: ${({ theme }) => theme.spacing(3)};
`;

const Title = styled(Typography)`
    line-height: 150%;
    font-size: 28px;
    font-weight: 400;
    text-align: center;
    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        font-size: 20px;
    }
    `}
`;

const InputHolder = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const phoneNumberPattern = /^\d{10}$/;

const emailAddressPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

const namePattern = /^[a-zA-Z ]{1,40}$/;

const customerFormSchema = Yup.object({
    firstName: Yup.string()
        .matches(
            namePattern,
            "The specified first name contains invalid characters",
        )
        .min(1)
        .max(40)
        .required("Enter customer's first name"),
    lastName: Yup.string()
        .matches(
            namePattern,
            "The specified last name contains invalid characters",
        )
        .min(1)
        .max(40)
        .required("Enter customer's last name"),
    phoneNumber: Yup.string()
        .matches(phoneNumberPattern, "The specified phone number is invalid.")
        .required("Enter a valid phone number"),
    emailAddress: Yup.string()
        .min(2)
        .max(100)
        .required("Enter customer's email address")
        .email()
        .matches(emailAddressPattern, "Enter a valid email address"),
});

export interface ICustomerFormProps {
    saving: boolean;
    onSave: (values: ICustomerFormValues) => void;
    onClose: () => void;
    phoneNumber: string;
}

export const CustomerForm: FunctionComponent<ICustomerFormProps> = (
    props: ICustomerFormProps,
): ReactElement => {
    const { onSave, saving, onClose, phoneNumber } = props;

    const {
        values,
        isValid,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
    } = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            phoneNumber: phoneNumber || "",
            emailAddress: "",
        },
        onSubmit: (values0: ICustomerFormValues) => {
            onSave({
                ...values0,
                phoneNumber: `+91${values0.phoneNumber}`,
            });
        },
        validationSchema: customerFormSchema,
    });

    const handleContinue = useCallback(async () => {
        handleSubmit();
    }, [handleSubmit]);

    const autoCompleteValue = browserName === "Chrome" ? "chrome-off" : "off";

    return (
        <Root>
            <Header>
                <Title>Create New Customer</Title>
                <FormCloseHandler>
                    <FormDrawerCloseButton onClick={onClose}>
                        <Close />
                    </FormDrawerCloseButton>
                </FormCloseHandler>
            </Header>
            {/* Firefox honors autoComplete="off" on form, but not on invidual fields.
             * Chrome does not honor autoComplete="off", but on individual fields with
             * autoComplete="chrome-off".
             */}
            <FormContainer autoComplete="off">
                <Container>
                    <InputHolder>
                        <InputField
                            id="address-form--input--first-name"
                            type="text"
                            label="First Name"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="firstName"
                            value={values.firstName}
                            autoComplete={autoCompleteValue}
                            required={true}
                            error={
                                Boolean(errors.firstName) && touched.firstName
                            }
                            helperText={errors.firstName && touched.firstName}
                        />
                    </InputHolder>

                    <InputHolder>
                        <InputField
                            id="address-form--input--last-name"
                            type="text"
                            label="Last Name"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="lastName"
                            value={values.lastName}
                            autoComplete={autoCompleteValue}
                            required={true}
                            error={Boolean(errors.lastName) && touched.lastName}
                            helperText={errors.lastName && touched.lastName}
                        />
                    </InputHolder>
                </Container>

                <InputHolder>
                    <InputField
                        id="address-form--input--email-address"
                        type="email"
                        label="Email Address"
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="emailAddress"
                        value={values.emailAddress}
                        autoComplete={autoCompleteValue}
                        required={true}
                        error={
                            Boolean(errors.emailAddress) && touched.emailAddress
                        }
                        helperText={errors.emailAddress && touched.emailAddress}
                    />
                </InputHolder>

                <InputHolder>
                    <InputField
                        id="address-form--input--phone-number"
                        type="tel"
                        label="Phone Number"
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="phoneNumber"
                        value={values.phoneNumber}
                        autoComplete={autoCompleteValue}
                        required={true}
                        error={
                            Boolean(errors.phoneNumber) && touched.phoneNumber
                        }
                        helperText={errors.phoneNumber && touched.phoneNumber}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    +91
                                </InputAdornment>
                            ),
                        }}
                    />
                </InputHolder>
            </FormContainer>

            <SaveButton
                id="address-form--button--continue"
                variant="contained"
                onClick={handleContinue}
                disabled={saving || !isValid}
                loading={saving}
                loadingPosition="end"
                endIcon={<ChevronRight />}
            >
                Continue
            </SaveButton>
        </Root>
    );
};
