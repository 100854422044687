import { UseMutationResult, useMutation } from "react-query";

import { ICustomer } from "../types";
import { customers } from "../utils/api";

import { useAuth } from "./useAuth";

interface IGetCustomerParameters {
    phoneNumber: string;
}

export const useGetCustomer = (): UseMutationResult<
    ICustomer,
    unknown,
    IGetCustomerParameters
> => {
    const auth = useAuth();

    return useMutation<ICustomer, unknown, IGetCustomerParameters>({
        mutationFn: async (
            parameters: IGetCustomerParameters,
        ): Promise<ICustomer> => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch customer without authorization token.",
                );
            }

            return await customers.Customer.getByPhoneNumber({
                phoneNumber: `+91${parameters.phoneNumber}`,
                token: auth.token,
            });
        },
    });
};
