import {
    IAnnotatedPriceRule,
    ICart,
    IPriceRuleValidity,
    PriceRuleValidityType,
} from "../types";

import { formatCurrency } from "./numbers";

/**
 * Utility class for generating reasons why an annotated price rule may not be applicable for a cart.
 */
export class PriceRuleValidity {
    private static getMinSubtotalMessage = (
        annotatedPriceRule: IAnnotatedPriceRule,
        cart: ICart,
    ) =>
        `Add items worth ${formatCurrency(
            annotatedPriceRule.prereqSubtotalMin - cart.totalSellingPrice,
        )} more to apply`;

    private static getExpiryMessage = () => "Discount code expired";

    private static getPaymentMethodMessage = (
        annotatedPriceRule: IAnnotatedPriceRule,
    ) =>
        `Select ${annotatedPriceRule.prereqPaymentMethod.toUpperCase()} payment method to apply`;

    private static getPrereqVariantsMessage = () =>
        "Not applicable for items in your cart";

    private static getDefaultMessage = () => "Not applicable for your cart";

    private static generators = {
        [PriceRuleValidityType.PREREQ_SUBTOTAL_MIN]:
            PriceRuleValidity.getMinSubtotalMessage,
        [PriceRuleValidityType.EXPIRATION]: PriceRuleValidity.getExpiryMessage,
        [PriceRuleValidityType.PAYMENT_METHOD]:
            PriceRuleValidity.getPaymentMethodMessage,
        [PriceRuleValidityType.PREREQ_VARIANTS]:
            PriceRuleValidity.getPrereqVariantsMessage,
    };

    /**
     * Returns the reason why the annotated price rule is not applicable for the cart.
     * @param {IAnnotatedPriceRule} annotatedPriceRule The annotated price rule to check.
     * @param {ICart} cart The cart to check against the price rule.
     * @returns {string | null} The reason why the price rule is not applicable, or null if it is applicable.
     */
    public static getReason = (
        annotatedPriceRule: IAnnotatedPriceRule,
        cart: ICart,
    ): string | null => {
        const invalidities: IPriceRuleValidity[] =
            annotatedPriceRule.validities.filter((validity) => !validity.valid);

        if (invalidities.length === 0) {
            return null;
        }

        const reasons: string[] = invalidities.map((invalidity) => {
            const messageGenerator =
                PriceRuleValidity.generators[invalidity.type];
            if (messageGenerator) {
                return messageGenerator(annotatedPriceRule, cart);
            }
            return PriceRuleValidity.getDefaultMessage();
        });

        return reasons[0];
    };
}
