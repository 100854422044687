import axios from "axios";
import { format } from "date-fns";

import { globalDateTimeFormat } from "../../../utils/constants";
import {
    IBulkOrder,
    IBulkOrderResponse,
    IExternalListPage,
} from "../../types/api";

export interface IGetBulkOrdersParameters {
    limit: number;
    cursor?: string;
    direction?: string;
    token: string;
}

export interface IGetBulkOrderParameters {
    token: string;
    bulkOrderId: string;
}

export class BulkOrder {
    static transform = (data: any): IBulkOrderResponse => {
        const { bulkOrder, bulkOrderLineItems } = data;
        return {
            bulkOrder: {
                ...bulkOrder,
                createdAt: format(
                    new Date(data.bulkOrder.createdAt),
                    globalDateTimeFormat,
                ),
            },
            bulkOrderLineItems,
        };
    };

    static async list(
        parameters: IGetBulkOrdersParameters,
    ): Promise<IExternalListPage<IBulkOrder>> {
        const { token, cursor, limit, direction } = parameters;

        const params = new URLSearchParams();

        if (cursor) {
            params.append("cursor", cursor);
        }

        if (direction) {
            params.append("direction", direction);
        }

        if (limit) {
            params.append("limit", limit.toString());
        }
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/bulk-orders?${params}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return data;
    }

    static async getById(
        parameters: IGetBulkOrderParameters,
    ): Promise<IBulkOrderResponse> {
        const { token, bulkOrderId } = parameters;
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/bulk-orders/${bulkOrderId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return this.transform(data);
    }
}
