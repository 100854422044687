import { ChangeEvent, FocusEvent, ReactElement, useCallback } from "react";

import TextField, { TextFieldProps } from "@mui/material/TextField";

import {
    FormContextType,
    RJSFSchema,
    StrictRJSFSchema,
    ariaDescribedByIds,
    examplesId,
    getInputProps,
} from "@rjsf/utils";

const labelValue = (label: any, ..._others: any) => label ?? "";

const TYPES_THAT_SHRINK_LABEL = ["date", "datetime-local", "file", "time"];

/**
 * The `BaseInputTemplate` is the template to use to render the basic `<input>` component for the `core` theme.
 * It is used as the template for rendering many of the <input> based widgets that differ by `type` and callbacks only.
 * It can be customized/overridden for other themes or individual implementations as needed.
 *
 * @param props - The `WidgetProps` for this template
 */
export const BaseInputTemplate = <
    T = any,
    S extends StrictRJSFSchema = RJSFSchema,
    F extends FormContextType = any,
>(
    props: any,
): ReactElement => {
    const {
        id,
        name, // remove this from textFieldProps
        placeholder,
        required,
        readonly,
        disabled,
        type,
        label,
        hideLabel,
        value,
        onChange,
        onChangeOverride,
        onBlur,
        onFocus,
        autofocus,
        options,
        schema,
        uiSchema,
        rawErrors = [],
        formContext,
        registry,
        InputLabelProps,
        ...textFieldProps
    } = props;
    const inputProps = getInputProps<T, S, F>(schema, type, options);
    // Now we need to pull out the step, min, max into an inner `inputProps` for material-ui
    const { step, min, max, ...rest } = inputProps;
    const otherProps = {
        inputProps: {
            step,
            min,
            max,
            ...(schema.examples ? { list: examplesId<T>(id) } : undefined),
        },
        ...rest,
    };
    const handleChange = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            onChange(value === "" ? options.emptyValue : value),
        [onChange, options.emptyValue],
    );
    const handleBlur = useCallback(
        ({ target: { value } }: FocusEvent<HTMLInputElement>) =>
            onBlur(id, value),
        [onBlur, id],
    );
    const handleFocus = useCallback(
        ({ target: { value } }: FocusEvent<HTMLInputElement>) =>
            onFocus(id, value),
        [onFocus, id],
    );

    const DisplayInputLabelProps = TYPES_THAT_SHRINK_LABEL.includes(type)
        ? {
              ...InputLabelProps,
              shrink: true,
          }
        : InputLabelProps;

    return (
        <>
            <TextField
                id={id}
                name={id}
                placeholder={placeholder}
                label={labelValue(label || undefined, hideLabel, false)}
                autoFocus={autofocus}
                required={required}
                disabled={disabled || readonly}
                {...otherProps}
                value={value || value === 0 ? value : ""}
                error={rawErrors.length > 0}
                onChange={onChangeOverride || handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                {...(textFieldProps as TextFieldProps)}
                aria-describedby={ariaDescribedByIds<T>(id, !!schema.examples)}
                size="small"
                InputLabelProps={{
                    ...DisplayInputLabelProps,
                    shrink: true,
                    style: { fontSize: 13 },
                }}
                InputProps={{ style: { fontSize: 13 } }}
            />
            {Array.isArray(schema.examples) && (
                <datalist id={examplesId<T>(id)}>
                    {(schema.examples as string[])
                        .concat(
                            schema.default &&
                                !schema.examples.includes(schema.default)
                                ? ([schema.default] as string[])
                                : [],
                        )
                        .map((example: any) => (
                            <option key={example} value={example} />
                        ))}
                </datalist>
            )}
        </>
    );
};
