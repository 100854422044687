import { useMutation, useQueryClient } from "react-query";

import { api } from "../utils";

import { useAuth } from "./useAuth";

export interface IResendPaymentLinkParameters {
    orderId: string;
}

export const useResendPaymentLink = (invalidQueryKeys: string[] = []) => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    if (!auth.token) {
        throw new Error("Cannot resend payment link without a valid token.");
    }

    return useMutation({
        mutationFn: async (values: IResendPaymentLinkParameters) =>
            await api.payments.resendPaymentLink({
                ...values,
                token: auth.token!,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries(invalidQueryKeys);
        },
    });
};
