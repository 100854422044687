import { FunctionComponent, ReactElement, useCallback } from "react";

import { Chip, Paper, Typography, styled } from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { Add, BrowserNotSupported } from "@mui/icons-material";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridToolbar,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { IOrderV1_1 } from "../../sdk/types";
import { formatCurrency } from "../../sdk/utils/numbers";
import { orderStatusByEnum } from "../../utils/enums";

const SectionHeader = styled("div")`
    display: flex;
    justify-content: space-between;
`;

const SectionDetail = styled(Paper)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
    min-width: 500px;
    justify-content: center;

    padding: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
           min-width: 300px;
    }
  `}
`;

const SectionTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    color: black;
`;

const EmptyContainer = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const EmptyIcon = styled(BrowserNotSupported)`
    height: 50px;
    width: auto;
`;

const columns: GridColDef[] = [
    {
        field: "name",
        headerName: "Order",
        flex: 0.5,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.name === "" ? "Not Synced" : params.row.name}`,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Chip label={params.value} variant="outlined" />
        ),
    },
    {
        field: "createdAt",
        headerName: "Created at",
        flex: 0.5,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(params.row.createdAt, "dd-MM-yyyy, p")}`,
    },
    {
        field: "customer",
        headerName: "Customer",
        flex: 0.4,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) => {
            if (!params.row.customer.phoneNumber) {
                return "Not Synced";
            }
            if (
                !params.row.customer.firstName ||
                !params.row.customer.lastName
            ) {
                return "NA";
            }
            return `${params.row.customer.firstName} ${params.row.customer.lastName}`;
        },
    },
    {
        field: "phoneNumber",
        headerName: "Phone number",
        flex: 0.4,
        minWidth: 175,
        valueGetter: (params: GridValueGetterParams) => {
            if (!params.row.customer.phoneNumber) {
                return "Not Synced";
            }
            return params.row.customer.phoneNumber;
        },
    },
    {
        field: "subtotalAmount",
        headerName: "Total",
        flex: 0.4,
        minWidth: 100,
        valueGetter: (params: GridValueGetterParams) => {
            return formatCurrency(params.row.payment?.amount);
        },
    },
    {
        field: "source",
        headerName: "Source",
        flex: 0.5,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.sellerId ? "Insider Sales" : "Direct"}`,
    },
    {
        field: "status",
        headerName: "Status",
        flex: 0.4,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) =>
            `${orderStatusByEnum[params.row.status]}`,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Chip label={params.value} variant="outlined" />
        ),
    },
    {
        field: "paymentMethod",
        headerName: "Payment Method",
        flex: 0.4,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) =>
            params.row.payment?.paymentMethodType.toUpperCase(),
    },
    {
        field: "gateway",
        headerName: "Gateway",
        flex: 0.4,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) =>
            params.row.payment?.gateway?.toUpperCase() ?? "NA",
    },
    {
        field: "paymentStatus",
        headerName: "Payment status",
        flex: 0.4,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) =>
            params.row.payment?.status,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Chip label={params.value} variant="outlined" />
        ),
    },
];

export interface IOrderGridProps {
    rows: IOrderV1_1[];
    onCreateCart: () => void;
}

export const OrderGrid: FunctionComponent<IOrderGridProps> = (
    props: IOrderGridProps,
): ReactElement => {
    const { rows, onCreateCart } = props;

    const navigate = useNavigate();

    const handleRowClick = useCallback(
        (params: GridRowParams) => {
            const orderId = params.row.id;
            navigate(`/orders/${orderId}`);
        },
        [navigate],
    );

    const handleCreateOrder = useCallback(() => {
        onCreateCart();
    }, [onCreateCart]);

    return (
        <SectionDetail variant="outlined" elevation={0}>
            <SectionHeader>
                <SectionTitle>Orders</SectionTitle>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleCreateOrder}
                    endIcon={<Add />}
                >
                    CREATE ORDER
                </LoadingButton>
            </SectionHeader>
            {rows.length > 0 && (
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    onRowClick={handleRowClick}
                    pageSizeOptions={[10, 20, 30]}
                    disableRowSelectionOnClick={true}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: {
                                disableToolbarButton: true,
                            },
                            printOptions: {
                                disableToolbarButton: true,
                            },
                        },
                    }}
                    disableColumnSelector={true}
                    disableDensitySelector={true}
                />
            )}
            {rows.length === 0 && (
                <EmptyContainer>
                    <EmptyIcon />
                    <SectionTitle>No orders found</SectionTitle>
                </EmptyContainer>
            )}
        </SectionDetail>
    );
};
