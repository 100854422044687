import React, { FunctionComponent, ReactElement } from "react";

import { Card, Typography, styled } from "@mui/material";

import { formatCurrency } from "../../../../sdk/utils/numbers";

const Root = styled(Card)`
    display: flex;
    width: 100%;
`;

const Content = styled("div")`
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    width: 100%;

    ${({ theme }) => theme.breakpoints.down("sm")} {
        width: 100%;
    }

    & > :not(:last-child) {
        background-color: white;
        padding: ${({ theme }) => theme.spacing(1, 2)};
    }
`;

interface IDiscountContainerProps {
    value: number;
}

const DiscountContainer = styled("span", {
    shouldForwardProp: (propName) => propName !== "value",
})<IDiscountContainerProps>`
    color: "#000000";
`;

const TotalContainer = styled("span")`
    color: black;
`;

const SubTotal = styled(Typography)`
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 40px;

    color: #1a1f36;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    border-bottom: 1px solid #3c42571f;

    padding: ${({ theme }) => theme.spacing(1, 0)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        width: 100%;
    }
`;

const PriceHolder = styled(Typography)`
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 40px;

    color: #0f7cfc;
    font-size: 14px;
    font-weight: 600;

    padding: ${({ theme }) => theme.spacing(1, 0)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        width: 100%;
    }
`;

const Delivery = styled(Typography)`
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 40px;

    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #3c42571f;
    color: black;

    padding: ${({ theme }) => theme.spacing(1, 0)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        width: 100%;
    }
`;

const FinalTotal = styled(Typography)`
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #1a1a1a;

    padding: ${({ theme }) => theme.spacing(2)};
`;

export interface ITotalProps {
    total: number;
    subtotal: number;
    savedAmount: number;
    discountValue: number;
    couponDiscount: number;
}

export const Total: FunctionComponent<ITotalProps> = (
    props: ITotalProps,
): ReactElement => {
    const { total, subtotal, savedAmount, couponDiscount, discountValue } =
        props;

    let deliveryCharges = 0;

    const tax = 0; // subtotal > 0 ? Math.ceil(subtotal * 0.065) : 0;

    const roundedCouponDiscount = Math.round(couponDiscount);

    if (total <= 299) {
        deliveryCharges = 50;
    }

    return (
        <>
            <Root elevation={0}>
                <Content>
                    <SubTotal id="total--label--subtotal">
                        <span>SUBTOTAL</span>
                        <TotalContainer id="subtotal--label--price">
                            {formatCurrency(Number(subtotal))}
                        </TotalContainer>
                    </SubTotal>
                    <PriceHolder id="total--label--fan-discount">
                        <span>Fan Discount</span>
                        <DiscountContainer
                            value={savedAmount}
                            id="fan-discount--label--price"
                        >
                            {savedAmount !== 0 && "-"}{" "}
                            {formatCurrency(savedAmount)}
                        </DiscountContainer>
                    </PriceHolder>
                    <PriceHolder id="total--label--prepaid-discount">
                        <span>Prepaid Discount</span>
                        <DiscountContainer
                            value={discountValue}
                            id="prepaid-discount--label--price"
                        >
                            {discountValue !== 0 && "-"}{" "}
                            {formatCurrency(discountValue)}
                        </DiscountContainer>
                    </PriceHolder>
                    <PriceHolder id="total--label--coupon-discount">
                        <span>Coupon Discount</span>
                        <DiscountContainer
                            value={roundedCouponDiscount}
                            id="coupon-discount--label--price"
                        >
                            {couponDiscount !== 0 && "-"}{" "}
                            {formatCurrency(roundedCouponDiscount)}
                        </DiscountContainer>
                    </PriceHolder>

                    <Delivery id="total--label--delivery">
                        <span>Shipping</span>
                        <DiscountContainer
                            value={deliveryCharges}
                            id="delivery--label--price"
                        >
                            {formatCurrency(deliveryCharges)}
                        </DiscountContainer>
                    </Delivery>

                    <FinalTotal id="total--label--net-payable">
                        Net Payable
                        <TotalContainer id="net-payable--label--price">
                            {formatCurrency(
                                Math.round(
                                    total +
                                        tax -
                                        discountValue -
                                        couponDiscount +
                                        deliveryCharges,
                                ),
                            )}
                        </TotalContainer>
                    </FinalTotal>
                </Content>
            </Root>
        </>
    );
};
