import { FunctionComponent, ReactElement, useState } from "react";

import { styled } from "@mui/material";
import { Button, Icon } from "@mui/material";

import { InstantSearch } from "react-instantsearch";
import { RefinementList, SearchBox } from "react-instantsearch";
import { Link } from "react-router-dom";

import { WorkspaceToolbar } from "../../components/common";
import { productStatusByEnum } from "../../utils/enums";

import { ProductsGrid } from "./ProductsGrid";
import { typesenseAdapter } from "./config/typesense.config.adapter";

const Root = styled("section")`
    padding: ${({ theme }) => theme.spacing(3)};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const TitleContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StatusFilterContainer = styled("div")`
    display: flex;
    flex-direction: column;
`;

const SearchContainer = styled("div")`
    display: flex;
    align-items: center;

    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: ${({ theme }) => theme.shape.borderRadius};

    overflow: hidden;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
`;

const StyledSearchBox = styled(SearchBox)`
    width: 100%;
    display: flex;

    & input {
        flex-grow: 1;
        padding: ${({ theme }) => theme.spacing(1.5)};

        border: none;
        outline: none;

        font-size: ${({ theme }) => theme.typography.body1.fontSize};
        appearance: none;
        &::-webkit-search-cancel-button {
            display: none;
        }
    }
    & button {
        padding: ${({ theme }) => theme.spacing(1.5)};

        background-color: #fff;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
`;

const StyledRefinementList = styled(RefinementList)`
    background-color: ${({ theme }) => theme.palette.background.paper};
    border-radius: ${({ theme }) => theme.shape.borderRadius};

    & .ais-RefinementList-list {
        margin-left: -40px;

        display: flex;
        flex-wrap: wrap;
        gap: ${({ theme }) => theme.spacing(1)};
    }

    & .ais-RefinementList-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        border: 1px solid ${({ theme }) => theme.palette.divider};
        border-radius: ${({ theme }) => theme.spacing(2)};

        padding: ${({ theme }) => theme.spacing(1)};

        transition: background-color 0.3s ease;
        &:hover {
            background-color: ${({ theme }) => theme.palette.action.hover};
        }
    }

    & .ais-RefinementList-label {
        display: flex;
        align-items: center;

        font-size: ${({ theme }) => theme.typography.body1.fontSize};
        margin-right: auto;
    }

    & .ais-RefinementList-checkbox {
        margin-right: ${({ theme }) => theme.spacing(1)};
    }

    & .ais-RefinementList-count {
        color: black;
        font-size: ${({ theme }) => theme.typography.caption.fontSize};
        padding: ${({ theme }) => theme.spacing(0.5, 1)};

        border-radius: ${({ theme }) => theme.shape.borderRadius};
        text-align: center;
    }
`;

export const ViewProductsScreen: FunctionComponent = (): ReactElement => {
    return (
        <InstantSearch
            searchClient={typesenseAdapter.searchClient}
            indexName={`${process.env.REACT_APP_TYPESENSE_PREFIX}admin-products`}
        >
            <Root>
                <WorkspaceToolbar
                    title="Products"
                    enableSearch={false}
                    actions={
                        <Link to="/products/new">
                            <Button
                                size="small"
                                variant="contained"
                                disableElevation={true}
                                endIcon={<Icon>add_circle</Icon>}
                            >
                                Create
                            </Button>
                        </Link>
                    }
                />
                <TitleContainer>
                    <StatusFilterContainer>
                        Filter By Status
                        <StyledRefinementList
                            attribute="status"
                            transformItems={(items) =>
                                items
                                    .filter((item) => item.label.trim() !== "")
                                    .map((item) => ({
                                        ...item,
                                        label: productStatusByEnum[
                                            parseInt(item.label)
                                        ],
                                    }))
                            }
                        />
                    </StatusFilterContainer>

                    <SearchContainer>
                        <StyledSearchBox />
                    </SearchContainer>
                </TitleContainer>

                <ProductsGrid />
            </Root>
        </InstantSearch>
    );
};
