import { FunctionComponent, ReactElement } from "react";

import {
    Divider,
    Paper,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
    styled,
} from "@mui/material";

import { InfoOutlined } from "@mui/icons-material";

import { format } from "date-fns";

import { IRefund, IRefundHistory } from "../../sdk/types";
import { formatCurrency } from "../../sdk/utils/numbers";

const SectionDetail = styled(Paper)`
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing(1.5)};

    padding: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
           min-width: 300px;
    }
  `}
`;

const SectionTitle = styled(Typography)`
    font-size: 20px;
    font-weight: 600;
    color: black;

    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
`;

const TimelineText = styled(Typography)`
    font-size: 14px;
`;

const RefundDetails = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(0.5)};

    padding: ${({ theme }) => theme.spacing(0, 0.5)};
`;

const InfoIcon = styled(InfoOutlined)`
    background-color: #febe10;
    fill: white;
    border-radius: 50%;
    height: 28px;
    width: 28px;
`;

const StyledStepLabel = styled(Typography)`
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
`;

const CompleteContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1.5)};
    align-items: start;
`;

const RefundContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const ReferenceText = styled(TimelineText)`
    font-size: 13px;
`;

const RefundIndex = styled(SectionTitle)`
    font-size: 16px;
`;

const RefundHeader = styled("div")`
    display: flex;
    flex-direction: column;
`;

export interface IRefundSectionProps {
    refundHistoriesMap: (IRefundHistory[] | undefined)[] | undefined;
    refundAmountById: (refundId: string) => number;
    returnIcon: (status: number) => JSX.Element;
    refunds: IRefund[] | undefined;
}

export const RefundSection: FunctionComponent<IRefundSectionProps> = (
    props: IRefundSectionProps,
): ReactElement => {
    const { refundHistoriesMap, refundAmountById, returnIcon, refunds } = props;
    return (
        <SectionDetail variant="outlined" elevation={0}>
            <SectionTitle>Refund status</SectionTitle>
            {refundHistoriesMap?.map((refundHistories, id) => {
                const processedHistory = refundHistories?.find(
                    (history) => history.nativeStatus === 1,
                );
                return (
                    <>
                        <RefundHeader>
                            <RefundIndex>Refund #{id + 1}</RefundIndex>
                            <ReferenceText>{refunds?.[id]?.id}</ReferenceText>
                        </RefundHeader>
                        {processedHistory && (
                            <>
                                <CompleteContainer>
                                    <InfoIcon />
                                    <RefundContainer>
                                        <StyledStepLabel>
                                            Refund of{" "}
                                            {formatCurrency(
                                                refundAmountById(
                                                    processedHistory.refundId,
                                                ) / 100,
                                            )}{" "}
                                            sent to customer's payment source
                                        </StyledStepLabel>
                                        <TimelineText>
                                            {" "}
                                            Refund completed on{" "}
                                            {format(
                                                new Date(
                                                    processedHistory.createdAt,
                                                ),
                                                "dd MMM yyyy",
                                            )}
                                        </TimelineText>
                                    </RefundContainer>
                                </CompleteContainer>
                            </>
                        )}
                        <Stepper orientation="vertical">
                            {refundHistories?.map((refundHistory, index) => (
                                <Step
                                    key={index}
                                    active={true}
                                    completed={refundHistory.nativeStatus === 1}
                                >
                                    <StepLabel
                                        icon={returnIcon(
                                            refundHistory.nativeStatus,
                                        )}
                                    >
                                        <StyledStepLabel>
                                            {refundHistory.nativeStatus === 0 &&
                                                "Refund initiated"}
                                            {refundHistory.nativeStatus === 1 &&
                                                "Refund processed"}
                                            {refundHistory.nativeStatus !== 0 &&
                                                refundHistory.nativeStatus !==
                                                    1 &&
                                                "Refund failed"}
                                        </StyledStepLabel>
                                    </StepLabel>

                                    <StepContent>
                                        <RefundDetails>
                                            <TimelineText>
                                                {format(
                                                    new Date(
                                                        refundHistory.createdAt,
                                                    ),
                                                    "dd MMM yyyy, p",
                                                )}
                                            </TimelineText>
                                            <TimelineText>
                                                {formatCurrency(
                                                    refundAmountById(
                                                        refundHistory.refundId,
                                                    ) / 100,
                                                )}
                                            </TimelineText>
                                        </RefundDetails>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>

                        {(refundHistoriesMap ?? []).length - 1 !== id && (
                            <Divider />
                        )}
                    </>
                );
            })}
        </SectionDetail>
    );
};
