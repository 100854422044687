/* eslint-disable no-restricted-syntax */

/* eslint-disable no-continue */
import { PaymentMethodType } from "../../utils/enums";
import { IPriceRule } from "../types";

// eslint-disable-next-line no-shadow
enum PriceRuleValidityType {
    PREREQ_SUBTOTAL_MIN = "prereq_subtotal_min",
    EXPIRATION = "expiration",
    PAYMENT_METHOD = "payment_method",
    // ENTITLED_VARIANTS = "entitled_variants",
    PREREQ_VARIANTS = "prereq_variants",
}

export interface IPriceRuleValidity {
    type: PriceRuleValidityType;
    valid: boolean;
}

export interface IAnnotatedPriceRule extends IPriceRule {
    validities: IPriceRuleValidity[];
    applicable: boolean;
}

const MIN_DATE = -8640000000000000;
const MAX_DATE = 8640000000000000;

/**
 * A price rule can enforce upto 999 product variants. If a number beyond this
 * is specified, all the prereq product variants are expected to be in the cart.
 */
const MAX_PREPREQ_VARIANTS_REQUIRED = 999;

export interface IAnnotatePriceRuleParameters {
    subtotal: number;
    paymentMethod: PaymentMethodType;
    variantIds: string[];
    priceRules: IPriceRule[];
}

/* TODO: Move this to the backend when the Cart API is done. */
export class PriceRuleService {
    private static checkSubtotalValidity = (
        priceRule: IPriceRule,
        subtotal: number,
    ): IPriceRuleValidity => {
        /* The subtotal validity should be ignored if the prerequisite is negative. */
        return {
            valid: subtotal < 0 || subtotal >= priceRule.prereqSubtotalMin,
            type: PriceRuleValidityType.PREREQ_SUBTOTAL_MIN,
        };
    };

    /**
     * The caller can specify any custom date against which they want to validate
     * the date range.
     *
     * @param priceRule
     * @param currentDate
     */
    private static checkDateRangeValidity = (
        priceRule: IPriceRule,
        currentDate: Date,
    ): IPriceRuleValidity => {
        const startDate = new Date(priceRule.startsAt || MIN_DATE);
        const endDate = new Date(priceRule.endsAt || MAX_DATE);

        return {
            valid: startDate <= currentDate && currentDate <= endDate,
            type: PriceRuleValidityType.EXPIRATION,
        };
    };

    private static checkPaymentMethodValidity = (
        priceRule: IPriceRule,
        paymentMethod: PaymentMethodType,
    ): IPriceRuleValidity => {
        /* NOTE: We expect prereqPaymentMethod to be >= 1. */
        return {
            type: PriceRuleValidityType.PAYMENT_METHOD,
            valid:
                !priceRule.prereqPaymentMethod ||
                priceRule.prereqPaymentMethod === paymentMethod,
        };
    };

    private static checkPrereqVariantsValidity = (
        priceRule: IPriceRule,
        variantIds: string[],
    ): IPriceRuleValidity => {
        /*
         * If the price rule does not enforce any product variants either by
         * `prereqVariants` being empty or `prereqvariantsRequired` being
         * <= 0, then we return true by default.
         */
        if (
            priceRule.prereqVariants.length === 0 ||
            priceRule.prereqVariantsRequired <= 0
        ) {
            return {
                valid: true,
                type: PriceRuleValidityType.PREREQ_VARIANTS,
            };
        }

        const requiredUniqueVariantIds = new Set<string>();
        for (const prereqVariant of priceRule.prereqVariants) {
            requiredUniqueVariantIds.add(prereqVariant.shopifyVariantId);
        }

        /*
         * The `prereqVariantsRequired` attribute works on unique product
         * variants as opposed to count of product variants.
         *
         * TODO: Should we associate required for each prereq product variant
         * in the price rule?
         */
        const uniqueCartVariantIds = new Set<string>();
        for (const variantId of variantIds) {
            uniqueCartVariantIds.add(variantId);
        }

        /*
         * Determine the count of variant IDs that are required by the price
         * and are present in the cart.
         */
        let validVariantsCount = 0;
        for (const cartVariantId of uniqueCartVariantIds.values()) {
            if (requiredUniqueVariantIds.has(cartVariantId)) {
                // eslint-disable-next-line no-plusplus
                validVariantsCount++;
            }
        }

        /*
         * A price rule can enforce upto 999 product variants. If a number
         * beyond this is specified, all the prereq product variants are
         * expected to be in the cart.
         */
        if (priceRule.prereqVariantsRequired >= MAX_PREPREQ_VARIANTS_REQUIRED) {
            return {
                valid: validVariantsCount === requiredUniqueVariantIds.size,
                type: PriceRuleValidityType.PREREQ_VARIANTS,
            };
        }

        /*
         * The cart should have at least `prereqVariantsRequired` unique
         * product variants.
         */
        return {
            valid: validVariantsCount >= priceRule.prereqVariantsRequired,
            type: PriceRuleValidityType.PREREQ_VARIANTS,
        };
    };

    public static annotatePriceRules = (
        parameters: IAnnotatePriceRuleParameters,
    ): Record<string, IAnnotatedPriceRule> => {
        const { subtotal, paymentMethod, priceRules, variantIds } = parameters;

        const annotatedPriceRules: Record<string, IAnnotatedPriceRule> = {};

        for (const priceRule of priceRules) {
            const subtotalValidity = PriceRuleService.checkSubtotalValidity(
                priceRule,
                subtotal,
            );
            const dateRangeValidity = PriceRuleService.checkDateRangeValidity(
                priceRule,
                new Date(),
            );
            const paymentMethodValidity =
                PriceRuleService.checkPaymentMethodValidity(
                    priceRule,
                    paymentMethod,
                );
            const prereqVariantsValidity =
                PriceRuleService.checkPrereqVariantsValidity(
                    priceRule,
                    variantIds,
                );

            const validities = [
                subtotalValidity,
                dateRangeValidity,
                paymentMethodValidity,
                prereqVariantsValidity,
            ];
            const applicable = validities.reduce(
                (accumulator: boolean, validity: IPriceRuleValidity) =>
                    accumulator && validity.valid,
                true,
            );

            annotatedPriceRules[priceRule.id] = {
                ...priceRule,
                validities,
                applicable,
            };
        }

        return annotatedPriceRules;
    };
}
