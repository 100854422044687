import { UseQueryResult, useQuery } from "react-query";

import { TUseQueryOptions } from "../../../hooks";
import { IBulkOrderResponse } from "../../types";
import { api } from "../../utils";
import { useAuth } from "../useAuth";

export const useGetBulkOrderById = (
    queryKey: string,
    bulkOrderId: string,
    options?: TUseQueryOptions<any, unknown, IBulkOrderResponse, string>,
): UseQueryResult<IBulkOrderResponse, unknown> => {
    const auth = useAuth();
    return useQuery(
        queryKey,
        async () => {
            if (!auth.token) {
                throw new Error(
                    `Cannot fetch bulk order with ID: ${bulkOrderId} without authorization token.`,
                );
            }
            return await api.BulkOrder.getById({
                bulkOrderId,
                token: auth.token,
            });
        },
        options,
    );
};
