import { useMutation, useQueryClient } from "react-query";

import { useAuth, useMessage } from "../../sdk/hooks";
import { api } from "../../sdk/utils";
import { DiscountCodeStatus } from "../../utils/enums";

export interface IUpdateDiscountParameter {
    /** Identifier of the discount code to update */
    id: string;

    /** Updated value of the discount code */
    code: string;

    /** Updated status of the discount code */
    status: DiscountCodeStatus;
}

export const useUpdateDiscount = (queryKey: string | string[]) => {
    const auth = useAuth();
    const queryClient = useQueryClient();
    const { showError, showSuccess } = useMessage();

    return useMutation({
        mutationFn: async (parameters: IUpdateDiscountParameter) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot update discount code without authorization token.",
                );
            }

            return await api.discounts.updateDiscount({
                ...parameters,
                token: auth.token,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(queryKey);
            showSuccess("Discount code updated successfully");
        },
        onError: () => {
            showError("Something went wrong");
        },
    });
};
