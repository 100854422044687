import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";

import { IconButton, InputBase, styled } from "@mui/material";

import { Cancel, Search as SearchIcon } from "@mui/icons-material";

import { useToggleCallbacks } from "../../sdk/hooks";

interface ISearchContainerProps {
    expanded: boolean;
}

const SearchContainer = styled("div", {
    shouldForwardProp: (propName) => propName !== "expanded",
})<ISearchContainerProps>`
    display: flex;
    align-items: center;

    width: ${({ expanded }) => (expanded ? "250px" : "48px")};
    border-radius: ${({ theme }) => theme.spacing(4)};
    padding: ${({ theme }) => theme.spacing(0.5)};

    background-color: #f0f0f0;
    transition: width 0.3s ease;
`;

const SearchInput = styled(InputBase)`
    margin-left: ${({ theme }) => theme.spacing(1)};
    flex: 1;
`;

export interface ISearchBarProps {
    value: string;
    onClear: () => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: () => void;
}

export const SearchBar: FunctionComponent<ISearchBarProps> = (
    props: ISearchBarProps,
): ReactElement => {
    const { value, onChange, onSubmit, onClear } = props;
    const [expanded, setExpanded] = useState(false);
    const [handleExpand] = useToggleCallbacks(setExpanded);
    const searchRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                searchRef.current &&
                !searchRef.current.contains(event.target as Node)
            ) {
                if (!value) {
                    setExpanded(false);
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [value]);

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
                onSubmit();
            }
        },
        [onSubmit],
    );

    return (
        <SearchContainer expanded={expanded} ref={searchRef}>
            <IconButton onClick={handleExpand}>
                <SearchIcon />
            </IconButton>
            {expanded && (
                <SearchInput
                    placeholder="Search..."
                    inputProps={{ "aria-label": "search" }}
                    onChange={onChange}
                    value={value}
                    onKeyDown={handleKeyDown}
                    autoFocus={true}
                    endAdornment={
                        <IconButton onClick={onClear}>
                            <Cancel />
                        </IconButton>
                    }
                />
            )}
        </SearchContainer>
    );
};
