import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import {
    Alert,
    Button,
    ButtonBase,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    TextField as MuiTextField,
    Select,
    SelectChangeEvent,
    Step,
    StepLabel,
    Stepper,
    Typography,
    styled,
} from "@mui/material";

import { Close } from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useCancelOrder, useMessage } from "../../sdk/hooks";
import { IGetOrderResponse, IRefundFormValues } from "../../sdk/types";
import { OrderStatus } from "../../utils/enums";

import { RefundForm } from "./RefundForm";

const Root = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledContent = styled(DialogContent)`
    width: 100%;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const OrderCancelForm = styled("form")`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing(1)};

    width: 100%;

    ${({ theme }) => theme.breakpoints.down("md")} {
        align-items: stretch;
    }
`;

const StyledStepper = styled(Stepper)`
    width: 100%;
    padding: ${({ theme }) => theme.spacing(2, 0)};
`;

const StyledStep = styled(Step)`
    .Mui-completed {
        color: #4eae49;
    }
`;

const CancelFormControl = styled(FormControl)`
    width: 100%;
    margin: ${({ theme }) => theme.spacing(1, 0, 2, 0)};
    gap: ${({ theme }) => theme.spacing(2.5)};
`;

const Header = styled(DialogTitle)`
    width: 100%;
    background-color: #f0f0f0;

    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const FormCloseHandler = styled("div")`
    display: flex;
    justify-content: center;
`;

const FormDrawerCloseButton = styled(ButtonBase)`
    width: 32px;
    height: 32px;
    border-radius: 50%;
`;

const FormHeading = styled(Typography)`
    font-size: 20px;
    font-weight: 500;
`;

const Wrapper = styled("div")`
    width: 100%;
    display: flex;
    gap: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => theme.breakpoints.down("md")} {
        flex-direction: column;
        padding: ${({ theme }) => theme.spacing(2, 0)};
        gap: ${({ theme }) => theme.spacing(2)};
    }
`;

const OrderAlert = styled(Alert)`
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing(2)};
`;

const Subtitle = styled(Typography)`
    font-size: 14px;
    font-weight: 400;

    color: #222222;
`;

const TextField = styled(MuiTextField)`
    & .MuiOutlinedInput-root {
        font-size: 16px;
    }
`;

export enum CancelOrderStep {
    ORDER = 1,
    REFUND = 2,
}

export const stepNames: Record<CancelOrderStep, string> = {
    [CancelOrderStep.ORDER]: "Order Details",
    [CancelOrderStep.REFUND]: "Refund Details",
};

const steps = Object.values(CancelOrderStep).filter(
    (value) => typeof value !== "string",
);

export interface ICancelOrderForm {
    reason: number;
    note: string;
}

const cancelReasons = {
    "Expected delivery time is too long": 1,
    "I don't like the product": 2,
    "Change payment mode": 3,
    "Wrong delivery address provided": 4,
    Other: 5,
    "Wrong product selected": 7,
    "Not interested": 8,
    "Ordered from marketplace": 9,
    "Coupon code not applied": 10,
};

export const validationSchema = Yup.object({
    reason: Yup.number()
        .oneOf(Object.values(cancelReasons))
        .required("Please select a valid reason"),
    note: Yup.string().max(512).trim(),
});

export interface ICancelOrderDialogProps {
    order: IGetOrderResponse;
    formOpen: boolean;
    onClose: () => void;
}

export const CancelOrderDialog: FunctionComponent<ICancelOrderDialogProps> = (
    props: ICancelOrderDialogProps,
): ReactElement => {
    const { order, formOpen, onClose } = props;
    const [currentStep, setCurrentStep] = useState(CancelOrderStep.ORDER);

    const handlePrevious = useCallback(() => {
        setCurrentStep(currentStep - 1);
    }, [currentStep]);

    const handleNext = useCallback(() => {
        setCurrentStep(currentStep + 1);
    }, [currentStep]);

    const orderStatusMutation = useCancelOrder(["getOrderById"]);
    const message = useMessage();

    const formik = useFormik<ICancelOrderForm>({
        initialValues: {
            reason: cancelReasons["Expected delivery time is too long"],
            note: "",
        },
        onSubmit: () => {
            return;
        },
        validationSchema,
    });

    const handleReasonSelect = useCallback(
        (event: SelectChangeEvent<number>) => {
            formik.setFieldValue("reason", Number(event.target.value));
        },
        [formik],
    );

    const handleFormSubmit = useCallback(
        (values: IRefundFormValues) => {
            orderStatusMutation.mutate(
                {
                    orderId: order.id,
                    reason: values.reason,
                    refundAmount: values.amount,
                    refundNote: values.note,
                    note: formik.values.note,
                },
                {
                    onSuccess: () => {
                        onClose();
                        message.showSuccess(
                            "The order was successfully cancelled.",
                        );
                    },
                    onError: (error: any) => message.showError(error),
                },
            );
        },
        [orderStatusMutation, order.id, formik.values.note, onClose, message],
    );

    return (
        <Dialog
            open={formOpen}
            onClose={onClose}
            fullWidth={true}
            maxWidth="xs"
        >
            <Root>
                <Header>
                    <FormHeading>Cancel Order</FormHeading>
                    <FormCloseHandler>
                        <FormDrawerCloseButton onClick={onClose}>
                            <Close />
                        </FormDrawerCloseButton>
                    </FormCloseHandler>
                </Header>
                <StyledContent>
                    {order.status === OrderStatus.SHIPPED && (
                        <OrderAlert severity="warning">
                            The order status is SHIPPED, the operations team
                            needs to be notified about this so that the delivery
                            of the order can be interrupted.
                        </OrderAlert>
                    )}
                    <StyledStepper activeStep={1} alternativeLabel>
                        {steps.map((step) => (
                            <StyledStep
                                key={step}
                                active={currentStep === step}
                                completed={(step as number) < currentStep}
                            >
                                <StepLabel>
                                    {stepNames[step as CancelOrderStep]}
                                </StepLabel>
                            </StyledStep>
                        ))}
                    </StyledStepper>
                    <Wrapper>
                        {currentStep === CancelOrderStep.ORDER && (
                            <OrderCancelForm>
                                <Subtitle>Select cancellation reason</Subtitle>
                                <CancelFormControl required={true}>
                                    <InputLabel id="cancel-reason-label">
                                        Reason
                                    </InputLabel>
                                    <Select
                                        value={formik.values.reason}
                                        onChange={handleReasonSelect}
                                        label="Reason"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {Object.entries(cancelReasons).map(
                                            ([key, value]) => (
                                                <MenuItem
                                                    key={key}
                                                    value={value}
                                                >
                                                    {key}
                                                </MenuItem>
                                            ),
                                        )}
                                    </Select>
                                    <TextField
                                        label="Note"
                                        name="note"
                                        multiline={true}
                                        rows={4}
                                        value={formik.values.note}
                                        onChange={formik.handleChange}
                                        error={
                                            Boolean(formik.errors.note) &&
                                            formik.touched.note
                                        }
                                        helperText={
                                            formik.touched.note &&
                                            formik.errors.note
                                        }
                                    />
                                </CancelFormControl>
                            </OrderCancelForm>
                        )}
                        {currentStep === CancelOrderStep.REFUND && (
                            <OrderCancelForm>
                                <RefundForm
                                    debounce={orderStatusMutation.isLoading}
                                    onCancel={handlePrevious}
                                    onSubmit={handleFormSubmit}
                                    /*
                                     * TODO: Fix this. This is a placeholder value.
                                     */
                                    order={order}
                                    cancelButtonText="Previous"
                                />
                            </OrderCancelForm>
                        )}
                    </Wrapper>

                    {currentStep !== CancelOrderStep.REFUND && (
                        <DialogActions>
                            <Button variant="contained" onClick={handleNext}>
                                Next
                            </Button>
                        </DialogActions>
                    )}
                </StyledContent>
            </Root>
        </Dialog>
    );
};
