import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { IProduct } from "../../sdk/types";
import { api } from "../../sdk/utils";
import { IProductVariant } from "../../types";

export interface ICreateProductParameters {
    title: string;
    description: string;
    tagLine: string;
    featureScore: number;
    bestSellerScore: number;
    slug: string;
    shopifyProductId: string;
    variants: IProductVariant[];
    status: number;
    meta: Record<string, unknown>;
    schemaId?: string;
    value?: object;
}

export const useCreateProduct = (
    invalidQueryKeys: string | string[],
): UseMutationResult<IProduct, unknown, ICreateProductParameters> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation<IProduct, unknown, ICreateProductParameters>({
        mutationFn: async (parameters: ICreateProductParameters) => {
            console.log("Creating product with parameters: ", parameters);
            if (!auth.token) {
                throw new Error(
                    "Cannot create products without authorization token.",
                );
            }

            return await api.Products.createProduct({
                ...parameters,
                token: auth.token,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(invalidQueryKeys);
        },
    });
};
