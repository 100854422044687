import { FunctionComponent, ReactElement, useState } from "react";

import { Button, Typography, styled } from "@mui/material";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridToolbar,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";

import { EmptyRecords } from "../../components/common";
import { useGetAllCustomerGroups } from "../../hooks";
import { IPage } from "../../types";
import { globalDateTimeFormat } from "../../utils/constants";

const Root = styled("section")`
    padding: ${({ theme }) => theme.spacing(1)};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const TitleContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: ${({ theme }) => theme.spacing(2, 1)};
`;

const EmptyRecordsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
`;

const Title = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`;

const GroupsTitle = styled(Typography)`
    font-size: 16px;
    color: ${({ theme }) => theme.palette.text.primary};
`;

const Description = styled(Typography)`
    font-size: 13px;
    color: grey;
`;

const StyledCell = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: start;
`;

const columns: GridColDef[] = [
    {
        field: "name",
        headerName: "Name",
        flex: 0.8,
        renderCell: (params: GridRenderCellParams) => (
            <StyledCell>
                <GroupsTitle>{params.row.name}</GroupsTitle>
                <Description>{params.row.description}</Description>
            </StyledCell>
        ),
    },
    {
        field: "createdAt",
        headerName: "Created on",
        flex: 0.2,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
    {
        field: "updatedAt",
        headerName: "Updated on",
        flex: 0.2,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.updatedAt), globalDateTimeFormat)}`,
    },
];

export const CustomerGroupsScreen: FunctionComponent = (): ReactElement => {
    const [page, setPage] = useState<IPage>({
        limit: 20,
        cursor: null,
        direction: "after",
    });

    const customerGroupsQuery = useGetAllCustomerGroups(
        [
            "customerGroups",
            page.limit.toString(),
            page.cursor ?? "",
            page.direction,
        ],
        {
            limit: page.limit,
            cursor: page.cursor,
            direction: page.direction,
        },
    );

    const navigate = useNavigate();

    const handleRowClick = (params: GridRowParams) => {
        const customerGroupId = params.row.id;
        navigate(`/customer-groups/${customerGroupId}`);
    };
    return (
        <Root>
            <TitleContainer>
                <Title>Customer Groups</Title>
                <Link to="/customer-groups/new">
                    <Button variant="contained">Create Customer Group</Button>
                </Link>
            </TitleContainer>

            {customerGroupsQuery.data?.records?.length === 0 && (
                <EmptyRecords>
                    <EmptyRecordsContainer>
                        <Title>Manage customer groups</Title>
                        <Link to="/customer-groups/new">
                            <Button variant="contained">
                                Create customer group
                            </Button>
                        </Link>
                    </EmptyRecordsContainer>
                </EmptyRecords>
            )}

            {(customerGroupsQuery?.data?.records ?? []).length > 0 && (
                <DataGrid
                    rows={customerGroupsQuery?.data?.records ?? []}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                        },
                    }}
                    disableColumnSelector
                    disableDensitySelector
                    onRowClick={handleRowClick}
                    pageSizeOptions={[10, 20, 30]}
                    disableRowSelectionOnClick={true}
                    checkboxSelection={true}
                />
            )}
        </Root>
    );
};
