import { UseMutationResult, useMutation } from "react-query";

import { ICustomer } from "../types/api";
import { customers } from "../utils/api";

import { useAuth } from "./useAuth";

export interface IUseCreateCustomerMutationParameters {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
}

export const useCreateCustomer = (): UseMutationResult<
    ICustomer,
    unknown,
    IUseCreateCustomerMutationParameters
> => {
    const auth = useAuth();

    return useMutation<
        ICustomer,
        unknown,
        IUseCreateCustomerMutationParameters
    >({
        mutationFn: async (
            parameters: IUseCreateCustomerMutationParameters,
        ): Promise<ICustomer> => {
            if (!auth.user || !auth.token) {
                throw new Error("Cannot create customer without token!");
            }

            return await customers.Customer.create({
                token: auth.token,
                ...parameters,
            });
        },
    });
};
