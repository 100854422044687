import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { IEntityAttribute, api } from "../../utils";
import { EntityAttributeType } from "../../utils/enums";

export interface ICreateEntityAttributeParameter {
    name: string;
    required: boolean;
    groupId: string;
    type: EntityAttributeType;
    exclusive?: boolean;
    min?: number;
    max?: number;
    pattern?: string;
    default?: JSON;
}

export const useCreateEntityAttribute = (): UseMutationResult<
    IEntityAttribute,
    unknown,
    ICreateEntityAttributeParameter
> => {
    const auth = useAuth();
    return useMutation({
        mutationFn: async (parameters: ICreateEntityAttributeParameter) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot create entity attribute without authorization token.",
                );
            }

            return await api.EntityAttribute.create({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
