import axios from "axios";

import * as api from "../../types/api";

export interface ICreateCustomerAddressParameters {
    addressLine1: string;
    addressLine2: string;
    city: string;
    countryCode: string;
    company: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    stateCode: string;
    zip: string;
    name: string;
    defaultAddress: boolean;
    token: string;
}

/**
 * Create a new customer address.
 * Meow meow meow
 */
export const createCustomerAddress = async (
    parameters: ICreateCustomerAddressParameters,
): Promise<api.ICustomerAddress> => {
    const { token, ...address } = parameters;
    const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/storefronts/v1.1/customers/addresses`,
        address,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );

    return {
        ...data,
        createdAt: new Date(data.createdAt),
        updatedAt: new Date(data.updatedAt),
    };
};

/**
 * Get all the addresses of the customer.
 */
export const getCustomerAddresses = async (
    token: string,
    customerId: string,
): Promise<api.IExternalListPage<api.ICustomerAddress>> => {
    const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/storefronts/v1.1/customers/addresses?customerId=${customerId}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );

    return {
        cursors: { ...data.cursors },
        records: data.records.map((record: api.ICustomerAddress) => ({
            ...record,
            createdAt: new Date(record.createdAt),
            updatedAt: new Date(record.updatedAt),
        })),
    };
};

export interface IGetCustomerAddressByIdParameters {
    addressId: string;
    token: string;
}

export const getCustomerAddressById = async (
    parameters: IGetCustomerAddressByIdParameters,
): Promise<api.ICustomerAddress> => {
    const { token, addressId } = parameters;
    const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/storefronts/v1.1/customers/addresses/${addressId}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );

    return {
        ...data,
        createdAt: new Date(data.createdAt),
        updatedAt: new Date(data.updatedAt),
    };
};

export interface IUpdateCustomerAddressParameters {
    id: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    countryCode: string;
    company: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    stateCode: string;
    zip: string;
    name: string;
    defaultAddress: boolean;
    token: string;
}

/**
 * Update fields of the specified customer address identifier.
 */
export const updateCustomerAddress = async (
    parameters: IUpdateCustomerAddressParameters,
): Promise<api.ICustomerAddress> => {
    const { id, token, ...address } = parameters;
    const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/storefronts/v1.1/customers/addresses/${id}`,
        address,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return {
        ...data,
        createdAt: new Date(data.createdAt),
        updatedAt: new Date(data.updatedAt),
    };
};

/**
 * Delete the specified customer address.
 */
export const deleteCustomerAddress = async (
    addressId: string,
    customerId: string,
    token: string,
): Promise<api.ICustomerAddress> => {
    const { data } = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/storefronts/v1.1/customers/addresses/${addressId}?customerId=${customerId}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return {
        ...data,
        createdAt: new Date(data.createdAt),
        updatedAt: new Date(data.updatedAt),
    };
};

export interface IPinCodeMappingParameters {
    lookup: number;
    token: string;
}

export const getPincodes = async (
    parameters: IPinCodeMappingParameters,
): Promise<api.IPincodeMapping> => {
    const { token, lookup } = parameters;
    const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/storefronts/v1.1/pincodes?lookup=${lookup}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return {
        ...data,
    };
};
