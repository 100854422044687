import axios from "axios";

import * as api from "../../types";
import {
    CustomerSelection,
    DiscountType,
    PriceRuleCategoryType,
    PriceRuleStatus,
} from "../../../utils/enums";

export interface ICreatePriceRuleParameter {
    allocationMethod: api.PriceRuleAllocationMethod;
    customerSelection: CustomerSelection;
    prereqCustomerGroupIds: string[];
    discountType: DiscountType;
    startsAt: Date;
    endsAt: Date | null;
    usageLimitByCustomer: number | null;
    usageLimitByTotal: number | null;
    discountValue: number;
    prereqQuantityMin: number | null;
    prereqSubtotalMin: number | null;
    token: string;
    name: string;
    description: string;
    category: PriceRuleCategoryType;
    prereqPaymentMethod: string | null;
    prereqVariantsRequired: number;
    variantIds: string[];
    status: PriceRuleStatus;
    maxDiscountAmount: number | null;
}

export const createPriceRule = async (
    parameters: ICreatePriceRuleParameter,
): Promise<api.IPriceRule> => {
    const { token, ...body } = parameters;
    const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/discounts/price-rules`,
        body,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return transform(data);
};

export interface IUpdatePriceRuleParameter {
    allocationMethod: api.PriceRuleAllocationMethod;
    customerSelection: CustomerSelection;
    prereqCustomerGroupIds: string[];
    discountType: DiscountType;
    startsAt: Date;
    endsAt: Date | null;
    usageLimitByCustomer: number | null;
    usageLimitByTotal: number | null;
    discountValue: number;
    prereqQuantityMin: number | null;
    prereqSubtotalMin: number | null;
    id: string;
    token: string;
    name: string;
    description: string;
    category: PriceRuleCategoryType;
    prereqPaymentMethod: string | null;
    prereqVariantsRequired: number;
    variantIds: string[];
    status: PriceRuleStatus;
    maxDiscountAmount: number | null;
}

export const updatePriceRule = async (
    parameters: IUpdatePriceRuleParameter,
): Promise<api.IPriceRule> => {
    const { id, token, ...body } = parameters;
    const { data } = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/discounts/price-rules/${id}`,
        body,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return transform(data);
};

export interface IDeletePriceRuleParameter {
    token: string;
    id: string;
}

export const deletePriceRule = async (
    parameters: IDeletePriceRuleParameter,
): Promise<api.IPriceRule> => {
    /**Reject is id is undefined */
    const { id, token } = parameters;
    const { data } = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/discounts/price-rules/${id}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return transform(data);
};

export interface IGetAllPriceRulesProps {
    token: string;
    limit: number;
    cursor: string | null;
    direction: string;
}

export const fetchAllPriceRule = async (
    parameters: IGetAllPriceRulesProps,
): Promise<api.IExternalListPage<api.IPriceRuleResponse>> => {
    const { token, ...body } = parameters;
    const params = new URLSearchParams([
        ["limit", body.limit.toString()],
        ["direction", body.direction],
    ]);

    if (body.cursor) {
        params.append("cursor", body.cursor);
    }
    const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/discounts/price-rules?${params}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return { ...data, records: data.records.map(transform) };
};

export const fetchPriceRuleById = async (
    token: string,
    priceRuleId: string | undefined,
): Promise<api.IPriceRuleResponse> => {
    const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/discounts/price-rules/${priceRuleId}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return transform(data);
};

export interface IGetPriceRulesInput {
    category?: PriceRuleCategoryType;
    customerId?: string | null;
}

export const getPriceRulesForCustomer = async (
    input: IGetPriceRulesInput,
    token: string,
): Promise<api.IExternalListPage<api.IPriceRule>> => {
    const params = new URLSearchParams(
        Object.fromEntries(
            Object.entries(input)
                .filter(([, value]) => value)
                .map(([key, value]) => [key, value.toString()]),
        ),
    );
    const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/discounts/price-rules/customer`,
        {
            params,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return {
        ...data,
        records: data.records.map(transform),
    };
};

const transform = (data: api.IPriceRuleResponse): api.IPriceRuleResponse => {
    const { prereqVariants, createdAt, updatedAt, startsAt, endsAt, ...rest } =
        data;
    return {
        prereqVariants: prereqVariants,
        ...rest,
        variantIds: [...prereqVariants.map((variant) => variant.variantId)],
        startsAt: new Date(startsAt),
        endsAt: endsAt ? new Date(endsAt) : null,
        createdAt: new Date(createdAt),
        updatedAt: new Date(updatedAt),
    };
};
