import { FunctionComponent, ReactElement, useCallback } from "react";

import { Button, Typography, styled } from "@mui/material";

import { IAnnotatedPriceRule } from "../../../../sdk/types";

interface IDiscountRootProps {
    value: boolean;
}

const Root = styled("div", {
    shouldForwardProp: (propName) => propName !== "value",
})<IDiscountRootProps>`
    &:not(:last-child) {
        border-bottom: 1px #d9d9d9 dashed;
        padding-bottom: ${({ theme }) => theme.spacing(2)};
    }
    color: ${({ value }) => (value ? "black" : "gray")};
`;

const DiscountTitleRow = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DiscountCode = styled(Typography)`
    font-weight: 600;
`;

const DiscountDescription = styled(Typography)`
    font-size: 14px;
`;

const ApplyButton = styled(Button)`
    padding: 0;

    &:hover {
        background-color: black;
        color: white;
    }
`;

const RemoveButton = styled(Button)`
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 700;
    color: #494949;
    padding: 0;

    &:hover {
        text-decoration: underline;
        background-color: white;
    }
`;

const NotApplicable = styled(Typography)`
    font-size: 14px;
    font-weight: 700;
    color: gray;
`;

export interface IDiscountProps {
    discount: IAnnotatedPriceRule;
    appliedDiscount: string;
    isDiscountApplied: boolean;
    applicable: boolean;
    onApply: (code?: string) => void;
    onRemove: () => void;
    setOpen: (open: boolean) => void;
    reason: string | null;
}

export const Discount: FunctionComponent<IDiscountProps> = (
    props: IDiscountProps,
): ReactElement => {
    const {
        discount,
        reason,
        appliedDiscount,
        isDiscountApplied,
        applicable,
        onApply,
        onRemove,
        setOpen,
    } = props;

    const handleApplyDiscount = useCallback(() => {
        onApply(discount.discounts[0].code);
        setOpen(false);
    }, [discount.discounts, onApply, setOpen]);

    const handleRemoveDiscount = useCallback(() => {
        onRemove();
        setOpen(false);
    }, [onRemove, setOpen]);

    return (
        <Root key={discount.id} value={applicable}>
            <DiscountTitleRow>
                <DiscountCode>{discount.discounts[0]?.code}</DiscountCode>
                {(!isDiscountApplied ||
                    appliedDiscount !== discount.discounts[0]?.code) &&
                    applicable && (
                        <ApplyButton
                            variant="outlined"
                            onClick={handleApplyDiscount}
                        >
                            Apply
                        </ApplyButton>
                    )}
                {isDiscountApplied &&
                    appliedDiscount === discount.discounts[0]?.code && (
                        <RemoveButton onClick={handleRemoveDiscount}>
                            Remove
                        </RemoveButton>
                    )}
            </DiscountTitleRow>
            <DiscountDescription>{discount.description}</DiscountDescription>
            {!applicable && <NotApplicable>{reason}</NotApplicable>}
        </Root>
    );
};
