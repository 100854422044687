import axios from "axios";

import { IEntityAttributeOption } from "../types";

export interface ICreateEntityAttributeOptionParams {
    token: string;
    name: string;
    value: JSON;
    attributeId: string;
}

export interface IUpdateEntityAttributeOptionParams
    extends ICreateEntityAttributeOptionParams {
    optionId: string;
}

export interface IDeleteEntityAttributeOptionParams {
    token: string;
    optionId: string;
}

export class EntityAttributeOption {
    private static transform(
        data: IEntityAttributeOption,
    ): IEntityAttributeOption {
        return {
            ...data,
            createdAt: new Date(data.createdAt),
            updatedAt: new Date(data.updatedAt),
        };
    }

    public static async create(
        parameters: ICreateEntityAttributeOptionParams,
    ): Promise<IEntityAttributeOption> {
        const { token, name, value, attributeId } = parameters;
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups/attributes/options`,
            {
                name,
                value,
                attributeId,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return this.transform(data);
    }

    public static async getById(
        optionId: string,
        token: string,
    ): Promise<IEntityAttributeOption> {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups/attributes/options/${optionId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        return this.transform(data);
    }

    public static async getAllByAttributeId(
        token: string,
        attributeId: string,
    ): Promise<IEntityAttributeOption[]> {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups/attributes/options/${attributeId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return data.map(this.transform);
    }

    public static async delete(
        parameters: IDeleteEntityAttributeOptionParams,
    ): Promise<void> {
        const { token, optionId } = parameters;
        await axios.delete(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups/attributes/options/${optionId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
    }

    public static async update(
        parameters: IUpdateEntityAttributeOptionParams,
    ): Promise<IEntityAttributeOption> {
        const { token, optionId, name, value } = parameters;
        const { data } = await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups/attributes/options/${optionId}`,
            {
                name,
                value,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return this.transform(data);
    }
}
