import {
    UseMutationOptions,
    UseMutationResult,
    useMutation,
    useQueryClient,
} from "react-query";

import { DiscountCodeStatus } from "../../../utils/enums";
import { IDiscountCode } from "../../types";
import { api } from "../../utils";
import { useAuth } from "../useAuth";
import { useMessage } from "../useMessage";

export interface IUseGenerateDiscountCodesMutationParameters {
    count: number;
    charset: "numbers" | "letters" | "alphanumeric";
    pattern: string;
    status: DiscountCodeStatus;
    priceRuleId: string;
}

/**
 * Generates discount codes in bulk.
 *
 * @author Samuel Rowe <samuel@houseofx.in>
 */
export const useGenerateDiscountCodes = (
    invalidQueryKeys?: string | string[],
    options?: UseMutationOptions<
        IDiscountCode[],
        unknown,
        IUseGenerateDiscountCodesMutationParameters,
        unknown
    >,
): UseMutationResult<
    IDiscountCode[],
    unknown,
    IUseGenerateDiscountCodesMutationParameters
> => {
    const auth = useAuth();
    const queryClient = useQueryClient();
    const { showError } = useMessage();

    return useMutation<
        IDiscountCode[],
        unknown,
        IUseGenerateDiscountCodesMutationParameters
    >({
        mutationFn: (
            parameters: IUseGenerateDiscountCodesMutationParameters,
        ): Promise<IDiscountCode[]> => {
            if (!auth.token) {
                throw new Error(
                    "Cannot generate discount codes without authorization token.",
                );
            }

            return api.discounts.generate({
                ...parameters,
                token: auth.token,
            });
        },
        onError: (error: any) => showError(error),
        onSuccess: (
            discountCodes: IDiscountCode[],
            variables: IUseGenerateDiscountCodesMutationParameters,
            context: unknown,
        ) => {
            if (invalidQueryKeys !== undefined) {
                queryClient.invalidateQueries(invalidQueryKeys);
            }

            options?.onSuccess?.(discountCodes, variables, context);
        },
    });
};
