import { UseQueryResult, useQuery } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { ICustomerGroup } from "../../sdk/types";
import { api } from "../../sdk/utils";

export const useGetCustomerGroupById = (
    queryKey: string | string[],
    customerGroupId: string,
): UseQueryResult<ICustomerGroup> => {
    const auth = useAuth();

    return useQuery(
        [queryKey, customerGroupId],
        async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch customer group without authorization token.",
                );
            }

            return await api.CustomerGroup.getById({
                customerGroupId,
                token: auth.token,
            });
        },
        { enabled: customerGroupId !== "new" && customerGroupId !== "" },
    );
};
