import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import {
    Card,
    CardContent,
    CircularProgress,
    Paper,
    Typography,
    styled,
} from "@mui/material";

import { CreditCard } from "@mui/icons-material";

import { IAnnotatedPriceRule } from "../../../../sdk/services";
import { formatCurrency } from "../../../../sdk/utils/numbers";
import {
    DiscountType,
    PaymentMethodType,
    PaymentPlatform,
} from "../../../../utils/enums";

import { PaymentMethodTile } from "./PaymentMethodTile";

const Root = styled(Card)`
    width: 100%;
`;

const Content = styled(CardContent)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};

    background-color: #f5f5f5;
    padding: ${({ theme }) => theme.spacing(4)};

    margin-bottom: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => theme.breakpoints.down("md")} {
        padding: ${({ theme }) => theme.spacing(2)};
        gap: ${({ theme }) => theme.spacing(2)};
    }
`;

const Header = styled("div")`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(2)};
    align-items: center;
`;

const Title = styled(Typography)`
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
`;

const Note = styled(Typography)`
    font-size: 14px;
`;

const DiscountHolder = styled(Paper)`
    padding: ${({ theme }) => theme.spacing(2, 1)};
`;

export interface IPaymentMethodsProps {
    selectedMethod: string;
    selectedPlatform: PaymentPlatform;
    onSelect: (id: string, platform: number) => void;
    annotatedPriceRules: Record<string, IAnnotatedPriceRule>;
    restrictedPaymentMethods: PaymentMethodType[];
    changingPaymentMethod: boolean;
}

export const PaymentMethods: FunctionComponent<IPaymentMethodsProps> = (
    props: IPaymentMethodsProps,
): ReactElement => {
    const {
        selectedMethod,
        selectedPlatform,
        onSelect,
        annotatedPriceRules,
        restrictedPaymentMethods,
        changingPaymentMethod,
    } = props;

    let discountOffered = "";
    const discount = Object.entries(annotatedPriceRules).find(
        ([, priceRule]) =>
            priceRule.prereqPaymentMethod === PaymentMethodType.PREPAID,
    );

    if (discount) {
        switch (discount[1].discountType) {
            case DiscountType.FIXED: {
                discountOffered = formatCurrency(discount[1].discountValue);
                break;
            }

            case DiscountType.PERCENTAGE: {
                discountOffered = `${discount[1].discountValue}%`;
                break;
            }
            default: {
                throw new Error(
                    `Unknown discount type "${discount[1].discountType}"`,
                );
            }
        }
    }

    const handleDiscountSelect = useCallback(
        (id: string, platform: number) => {
            if (platform) {
                onSelect(id, platform);
            }
        },
        [onSelect],
    );

    /* TODO: use feature flags */
    const activatePrepaid = true;
    const activateCod = true;
    const activatePayInThree = true;

    const paymentMethods = [
        {
            method: "prepaid",
            code: "PREPAID",
            description: `Get additional ${discountOffered} instant discount on prepaid orders`,
            platform: PaymentPlatform.JUSPAY,
            id: "prepaid",
        },
        {
            method: "prepaid",
            code: "PAY IN 3 EMIs",
            description: `Get additional ${discountOffered} instant discount on prepaid orders`,
            platform: PaymentPlatform.BHARAT_X,
            id: "pay-in-3",
        },
        {
            method: "cod",
            code: "CASH ON DELIVERY",
            description: "",
            platform: PaymentPlatform.JUSPAY,
            id: "cod",
        },
    ];

    return (
        <Root elevation={0}>
            <Content>
                <Header>
                    <CreditCard />
                    <Title>Payment Method</Title>
                    {changingPaymentMethod && <CircularProgress size={16} />}
                </Header>
                {paymentMethods.map(
                    (paymentMethod) =>
                        ((paymentMethod.platform === PaymentPlatform.JUSPAY &&
                            ((paymentMethod.method === "cod" && activateCod) ||
                                (paymentMethod.method === "prepaid" &&
                                    activatePrepaid))) ||
                            (paymentMethod.platform ===
                                PaymentPlatform.BHARAT_X &&
                                activatePayInThree)) &&
                        !restrictedPaymentMethods.includes(
                            paymentMethod.method as PaymentMethodType,
                        ) && (
                            <DiscountHolder
                                key={paymentMethod.id}
                                elevation={0}
                            >
                                <PaymentMethodTile
                                    id={paymentMethod.method}
                                    code={paymentMethod.code}
                                    description={paymentMethod.description}
                                    selected={
                                        selectedMethod ===
                                            paymentMethod.method &&
                                        selectedPlatform ===
                                            paymentMethod.platform
                                    }
                                    onSelect={handleDiscountSelect}
                                    platform={paymentMethod.platform}
                                    changingPaymentMethod={
                                        changingPaymentMethod
                                    }
                                />
                            </DiscountHolder>
                        ),
                )}
                <Note>
                    Note: Selecting a prepaid payment method will send a payment
                    link to the customer&apos;s mobile number after the order is
                    created.
                </Note>
            </Content>
        </Root>
    );
};
