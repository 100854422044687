import { UseQueryResult, useQuery } from "react-query";

import { ICollection } from "../types";
import { collections } from "../utils/api";

import { useAuth } from "./useAuth";

export const useGetCollectionById = (
    collectionId: string,
): UseQueryResult<ICollection> => {
    const auth = useAuth();

    return useQuery(
        "getCollection",
        async () => {
            if (!auth.token) {
                throw new Error(
                    `Cannot fetch product collection with ${collectionId} without authorization token.`,
                );
            }

            return await collections.ProductCollection.getCollectionById({
                id: collectionId,
                token: auth.token,
            });
        },
        { enabled: collectionId !== "new" && collectionId !== "" },
    );
};
