import React, { useCallback } from "react";

import {
    Button,
    IconButton,
    Modal,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { Close } from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useCreateCustomer } from "../../hooks";
import { useMessage } from "../../sdk/hooks";

interface ICreateCustomerModalProps {
    onClose: () => void;
    openModal: boolean;
}

const Container = styled("div")`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 50%;
    height: fit-content;

    background-color: #fff;
    outline: 0;

    border-radius: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(4, 6)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        padding: ${({ theme }) => theme.spacing(4, 2)};
    }
`;

const ButtonContainer = styled("div")`
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;

    margin: ${({ theme }) => theme.spacing(1, 0)};
`;

const HeadingContainer = styled("div")`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: ${({ theme }) => theme.spacing(1, 0)};
`;

const StyledButton = styled(Button)`
    margin-top: ${({ theme }) => theme.spacing(2)};
`;

const ModalTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const phoneNumberPattern = /^\d{10}$/;

const validationSchema = Yup.object({
    firstName: Yup.string().required("Please enter first name"),
    lastName: Yup.string().required("Please enter last name"),
    emailAddress: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter email address"),
    phoneNumber: Yup.string()
        .matches(phoneNumberPattern, "The specified phone number is invalid")
        .required("Enter a valid phone number"),
});

const CreateCustomerModal: React.FC<ICreateCustomerModalProps> = (
    props: ICreateCustomerModalProps,
) => {
    const { onClose, openModal } = props;
    const createCustomer = useCreateCustomer();

    const { showError, showSuccess } = useMessage();

    const {
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        touched,
        errors,
        dirty,
    } = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: "",
            lastName: "",
            emailAddress: "",
            phoneNumber: "",
        },
        onSubmit: (values0) => {
            createCustomer.mutate(
                {
                    ...values0,
                    phoneNumber: `+91${values0.phoneNumber}`,
                },
                {
                    onSuccess: () => {
                        showSuccess("Customer created successfully");
                        onClose();
                    },
                    onError: () => {
                        showError("Error creating customer");
                    },
                },
            );
        },
        validationSchema,
    });

    const handleButtonClick = useCallback(() => {
        handleSubmit();
    }, [handleSubmit]);

    return (
        <Modal onClose={onClose} open={openModal}>
            <Container>
                <HeadingContainer>
                    <ModalTitle>Create New Customer</ModalTitle>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </HeadingContainer>
                <TextField
                    fullWidth
                    label="First Name"
                    name={"firstName"}
                    margin="normal"
                    variant="outlined"
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange("firstName")}
                    error={Boolean(errors.firstName && touched.firstName)}
                />
                <TextField
                    fullWidth
                    label="Last Name"
                    name={"lastName"}
                    margin="normal"
                    variant="outlined"
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange("lastName")}
                    error={Boolean(errors.lastName && touched.lastName)}
                />
                <TextField
                    fullWidth
                    label="Email Address"
                    name={"emailAddress"}
                    margin="normal"
                    variant="outlined"
                    value={values.emailAddress}
                    onBlur={handleBlur}
                    onChange={handleChange("emailAddress")}
                    error={Boolean(errors.emailAddress && touched.emailAddress)}
                />
                <TextField
                    fullWidth
                    label="Phone Number"
                    name={"phoneNumber"}
                    margin="normal"
                    variant="outlined"
                    value={values.phoneNumber}
                    onBlur={handleBlur}
                    onChange={handleChange("phoneNumber")}
                    error={Boolean(errors.phoneNumber && touched.phoneNumber)}
                />
                <ButtonContainer>
                    <StyledButton
                        variant="contained"
                        onClick={handleButtonClick}
                        disabled={!dirty}
                    >
                        Save
                    </StyledButton>
                </ButtonContainer>
            </Container>
        </Modal>
    );
};

export default CreateCustomerModal;
