import React, { FunctionComponent, ReactElement, useCallback } from "react";

import { Chip, styled } from "@mui/material";

import { GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Pagination, useHits } from "react-instantsearch";
import { useNavigate } from "react-router-dom";

import { productStatusByEnum } from "../../utils/enums";

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }
`;

const StyledPaginationContainer = styled("div")`
    .ais-Pagination-list {
        display: flex;
        justify-content: center;

        padding: 16px;
        list-style: none;
    }

    .ais-Pagination-item {
        margin: 0 4px;
    }

    .ais-Pagination-link {
        padding: 8px 16px;

        border: 1px solid ${({ theme }) => theme.palette.divider};
        border-radius: 4px;

        color: ${({ theme }) => theme.palette.text.primary};

        text-decoration: none;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: ${({ theme }) => theme.palette.action.hover};
        }
    }

    .ais-Pagination-item--disabled .ais-Pagination-link {
        color: ${({ theme }) => theme.palette.action.disabled};
        cursor: not-allowed;
    }

    .ais-Pagination-item--selected .ais-Pagination-link {
        background-color: #042442;
        color: white;
        cursor: default;
    }
`;

const columns: GridColDef[] = [
    {
        field: "productTitle",
        headerName: "Title",
        flex: 0.4,
        minWidth: 300,
    },
    {
        field: "status",
        headerName: "Status",
        flex: 0.3,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Chip
                label={productStatusByEnum[params.value]}
                size="small"
                variant="outlined"
            />
        ),
    },
    {
        field: "bestSellerScore",
        headerName: "Bestseller Score",
        flex: 0.2,
        minWidth: 170,
    },
    {
        field: "featureScore",
        headerName: "Feature Score",
        flex: 0.2,
        minWidth: 150,
    },
    {
        field: "tagLine",
        headerName: "Tag Line",
        flex: 1,
    },
];

export const ProductsGrid: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();
    const { hits } = useHits();

    const handleRowClick = useCallback(
        (params: GridRowParams) => {
            navigate(`/products/${params.row.id}`);
        },
        [navigate],
    );

    return (
        <>
            <StyledDataGrid
                autoHeight={true}
                rows={hits}
                columns={columns}
                onRowClick={handleRowClick}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        csvOptions: {
                            disableToolbarButton: false,
                        },
                        printOptions: {
                            disableToolbarButton: true,
                        },
                    },
                }}
            />
            <StyledPaginationContainer>
                <Pagination />
            </StyledPaginationContainer>
        </>
    );
};
