import { useMemo } from "react";

import { UseQueryResult, useQuery } from "react-query";

import { IExternalListPage, IGetOrderResponse, IOrderV1_1 } from "../types";
import { getActualAmountOfOrder } from "../utils";
import { orders } from "../utils/api";

import { useAuth } from "./useAuth";

export interface IGetOrdersByCustomerIdParameters {
    limit: number;
    cursor: string | null;
    direction: string;
}

export const useGetOrdersByCustomerId = (
    customerId: string,
    parameters: IGetOrdersByCustomerIdParameters,
    queryKey: string | string[],
): UseQueryResult<IExternalListPage<IOrderV1_1>, unknown> => {
    const auth = useAuth();

    const result = useQuery({
        queryKey,
        queryFn: async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch orders without authorization token.",
                );
            }

            return await orders.Order.getOrdersByCustomerId({
                ...parameters,
                token: auth.token,
                customerId,
            });
        },
    });

    const mapOrderToActualAmount = useMemo(() => {
        const orderActualAmountMap: Record<string, number> = {};
        if (result.data) {
            for (const order of result.data.records) {
                orderActualAmountMap[order.id] = getActualAmountOfOrder(order);
            }
        }
        return orderActualAmountMap;
    }, [result.data]);

    if (result.data) {
        for (const order of result.data.records) {
            order.actualAmount = mapOrderToActualAmount[order.id];
        }
    }

    return result;
};
