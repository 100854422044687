import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";

import {
    IconButton,
    Paper,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { AddCircle } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";

import * as Yup from "yup";
import {
    DataGrid,
    GridColDef,
    GridRowSelectionModel,
    GridToolbar,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { StickyBottomBar } from "../../components/common";
import {
    useCreateCustomerGroup,
    useDeleteCustomerGroup,
    useGetAllCustomers,
    useGetCustomerGroupById,
    useUpdateCustomerGroup,
} from "../../hooks";
import { useMessage, useToggleCallbacks } from "../../sdk/hooks";
import { IPage } from "../../types";
import { globalDateTimeFormat } from "../../utils/constants";

import CustomersTableModal from "./CustomersTableModal";

const Root = styled("main")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            gap: ${theme.spacing(3)};
    }
  `}

    padding: ${({ theme }) => theme.spacing(3)};
    margin: ${({ theme }) => theme.spacing(0, "auto")};
`;

const LeftContainer = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};
    width: 100%;

    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Title = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const FieldContainer = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const Container = styled("div")`
    height: 100vh;
`;

const TextFieldContainer = styled("div")`
    height: 40px;
    width: 250px;
`;

const LongTextFieldContainer = styled("div")`
    height: 40px;
    width: 500px;
`;

const TopContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            flex-direction: column
    }
  `}
`;

const TableHeader = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: ${({ theme }) => theme.spacing(6)};
`;

const NoCustomersContainer = styled(Paper)`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 200px;
`;

const NoCustomersText = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    color: grey;
`;

export interface ICustomerGroupForm {
    name: string;
    description: string;
    customerIds: string[];
    customerGroupId: string;
}

const validationSchema = Yup.object({
    name: Yup.string().min(1).required("Name is required"),
    description: Yup.string().min(1).required("Description is required"),
    customerIds: Yup.array()
        .of(Yup.string().uuid())
        .required("Atleast one customer is required"),
});

const columns: GridColDef[] = [
    {
        field: "firstName",
        headerName: "First name",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "lastName",
        headerName: "Last name",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "phoneNumber",
        headerName: "Phone number",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "emailAddress",
        headerName: "Email address",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "createdAt",
        headerName: "Created on",
        flex: 1,
        minWidth: 300,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
    {
        field: "updatedAt",
        headerName: "Updated on",
        flex: 1,
        minWidth: 300,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
];

export const CustomerGroupScreen: FunctionComponent = (): ReactElement => {
    const params = useParams();
    const createNewCustomerGroup = params.id === "new";
    const navigate = useNavigate();

    const customerGroupQuery = useGetCustomerGroupById(
        "customerGroups",
        params.id ?? "",
    );

    const updateCustomerGroupMutation =
        useUpdateCustomerGroup("customerGroups");
    const deleteCustomerGroupMutation =
        useDeleteCustomerGroup("customerGroups");
    const createCustomerGroupMutation = useCreateCustomerGroup();
    const [page, setPage] = useState<IPage>({
        limit: 20,
        cursor: "",
        direction: "after",
    });
    const customerQuery = useGetAllCustomers("customers", {
        limit: page.limit,
        cursor: page.cursor,
        direction: page.direction,
    });

    const [pageTitle, setPageTitle] = useState("");
    const [action, setAction] = useState("");
    const [snackbarAction, setSnackbarAction] = useState("");

    const { showError, showSuccess } = useMessage();

    const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>(
        customerGroupQuery.data?.customerIds ?? [],
    );

    useEffect(() => {
        if (createNewCustomerGroup) {
            setPageTitle("Create");
            setSelectedCustomerIds([]);
            setAction("Select");
            setSnackbarAction("Added");
        } else {
            setPageTitle("Edit");
            setAction("Update");
            setSelectedCustomerIds(customerGroupQuery.data?.customerIds ?? []);
            setSnackbarAction("Updated");
        }
    }, [createNewCustomerGroup, customerGroupQuery.data?.customerIds]);

    const {
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
        handleBlur,
    } = useFormik<ICustomerGroupForm>({
        initialValues: {
            name: customerGroupQuery.data?.name ?? "",
            description: customerGroupQuery.data?.description ?? "",
            customerIds: selectedCustomerIds,
            customerGroupId: params.id ?? "",
        },
        validationSchema,

        onSubmit: async (values) => {
            if (createNewCustomerGroup) {
                createCustomerGroupMutation.mutate(
                    {
                        name: values.name,
                        description: values.description,
                        customerIds: values.customerIds,
                    },
                    {
                        onSuccess: () => {
                            showSuccess("Customer group created successfully");
                        },
                        onError: () => {
                            showError("Customer Group creation failed");
                        },
                    },
                );
            }
            if (!createNewCustomerGroup) {
                updateCustomerGroupMutation.mutate(
                    {
                        name: values.name,
                        description: values.description,
                        customerIds: values.customerIds,
                        customerGroupId: values.customerGroupId,
                    },
                    {
                        onSuccess: () => {
                            showSuccess("Customer group updated successfully");
                        },
                        onError: () => {
                            showError("Customer Group updation failed");
                        },
                    },
                );
            }
        },
    });

    const existingCustomerIds = customerGroupQuery.data?.customerIds ?? [];

    useEffect(() => {
        if (createNewCustomerGroup) {
            setFieldValue("customerIds", selectedCustomerIds);
        }
        if (!createNewCustomerGroup) {
            setFieldValue("name", customerGroupQuery.data?.name);
            setFieldValue("description", customerGroupQuery.data?.description);
            setFieldValue("id", customerGroupQuery.data?.id);
            setFieldValue("customerIds", selectedCustomerIds);
        }
    }, [
        customerGroupQuery.data,
        createNewCustomerGroup,
        selectedCustomerIds,
        setFieldValue,
    ]);

    const handleDelete = useCallback(() => {
        deleteCustomerGroupMutation.mutate(
            {
                customerGroupId: params.id ?? "",
            },
            {
                onSuccess: () => {
                    showError("Customer group deleted successfully");
                    navigate("/customer-groups");
                },
            },
        );
    }, [deleteCustomerGroupMutation, navigate, params.id, showError]);

    const handleSelectedCustomerIds = useCallback(
        (newSelection: GridRowSelectionModel) => {
            setSelectedCustomerIds(newSelection as string[]);
        },
        [],
    );

    const selectedCustomers =
        customerQuery?.data?.records.filter((customer) =>
            selectedCustomerIds.includes(customer.id),
        ) ?? [];

    useEffect(() => {
        if (!createNewCustomerGroup && selectedCustomers.length === 0) {
            setSelectedCustomerIds(customerGroupQuery?.data?.customerIds ?? []);
        }
    }, [
        createNewCustomerGroup,
        customerGroupQuery?.data?.customerIds,
        selectedCustomers.length,
    ]);

    const handleSave = useCallback(() => {
        handleSubmit();
    }, [handleSubmit]);

    const handleDiscard = useCallback(() => {
        navigate("/customer-groups");
    }, [navigate]);

    const [openDialog, setOpenDialog] = useState(false);

    const [handleOpen, handleClose] = useToggleCallbacks(setOpenDialog);

    return (
        <>
            <Container>
                <Root>
                    <TopContainer>
                        <LeftContainer>
                            <Title>{pageTitle} customer group</Title>
                            <FieldContainer>
                                <TextFieldContainer>
                                    <TextField
                                        fullWidth={true}
                                        name="name"
                                        label="Name"
                                        placeholder="Name"
                                        value={values.name}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.name) && touched.name
                                        }
                                        helperText={touched.name && errors.name}
                                    />
                                </TextFieldContainer>
                            </FieldContainer>
                            <FieldContainer>
                                <LongTextFieldContainer>
                                    <TextField
                                        fullWidth={true}
                                        name="description"
                                        label="Description"
                                        value={values.description}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        rows={4}
                                        multiline={true}
                                        error={
                                            Boolean(errors.description) &&
                                            touched.description
                                        }
                                        helperText={
                                            touched.description &&
                                            errors.description
                                        }
                                    />
                                </LongTextFieldContainer>
                            </FieldContainer>
                        </LeftContainer>
                    </TopContainer>
                    <FieldContainer>
                        <TableHeader>
                            <Title>Customers</Title>
                            {!createNewCustomerGroup && (
                                <IconButton onClick={handleOpen}>
                                    <EditIcon
                                        color="primary"
                                        fontSize="small"
                                    />
                                </IconButton>
                            )}
                            {createNewCustomerGroup && (
                                <IconButton onClick={handleOpen}>
                                    <AddCircle color="primary" />
                                </IconButton>
                            )}
                        </TableHeader>
                        {(selectedCustomers ?? []).length > 0 && (
                            <DataGrid
                                rows={selectedCustomers ?? []}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 20,
                                        },
                                    },
                                }}
                                rowSelectionModel={selectedCustomerIds}
                                onRowSelectionModelChange={
                                    handleSelectedCustomerIds
                                }
                                pageSizeOptions={[10, 20, 30]}
                                disableRowSelectionOnClick={true}
                                checkboxSelection={true}
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        csvOptions: {
                                            disableToolbarButton: true,
                                        },
                                        printOptions: {
                                            disableToolbarButton: true,
                                        },
                                    },
                                }}
                                disableColumnSelector={true}
                                disableDensitySelector={true}
                            />
                        )}
                    </FieldContainer>
                    {openDialog && (
                        <CustomersTableModal
                            onClose={handleClose}
                            openModal={openDialog}
                            onSelectCustomerIds={handleSelectedCustomerIds}
                            selectedCustomerIds={selectedCustomerIds}
                            action={action}
                            snackbarAction={snackbarAction}
                            existingCustomerIds={existingCustomerIds}
                        />
                    )}
                    {createNewCustomerGroup &&
                        selectedCustomers?.length === 0 && (
                            <NoCustomersContainer
                                variant="outlined"
                                elevation={0}
                            >
                                <NoCustomersText>
                                    No customers have been selected.
                                </NoCustomersText>
                            </NoCustomersContainer>
                        )}
                </Root>
            </Container>
            {createNewCustomerGroup && (
                <StickyBottomBar
                    cancelText={"Discard"}
                    submitText={"Save"}
                    onCancel={handleDiscard}
                    onSubmit={handleSave}
                />
            )}
            {!createNewCustomerGroup && (
                <StickyBottomBar
                    cancelText={"Delete"}
                    submitText={"Save"}
                    onCancel={handleDelete}
                    onSubmit={handleSave}
                />
            )}
        </>
    );
};
