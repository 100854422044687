import React, { FunctionComponent, ReactElement } from "react";

import {
    Avatar,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Rating,
    Select,
    SelectChangeEvent,
    Typography,
    styled,
} from "@mui/material";

import { formatDistanceToNow } from "date-fns";

import { IAdmin, ICustomerReview } from "../../sdk/types";

const NameInitials = styled(Avatar)<{ backgroundColor: string }>`
    background-color: ${(props) => props.backgroundColor};
    font-size: 18px;

    height: 40px;
    width: 40px;

    ${({ theme }) => theme.breakpoints.down("sm")} {
        height: 24px;
        width: 24px;

        font-size: 10px;
    }
`;

const Container = styled("div")``;

const ReviewdAt = styled(Typography)`
    font-size: 13px;
    color: ${({ theme }) => theme.palette.grey[700]};
`;

const CustomerName = styled(Typography)`
    font-weight: 500;
    font-size: 16px;

    ${({ theme }) => theme.breakpoints.down("sm")} {
        font-size: 14px;
    }
`;

const ReviewDetailsContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const Root = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CustomerDetails = styled(Box)`
    display: flex;
    align-items: center;
    flex-direction: row;

    gap: ${({ theme }) => theme.spacing(2)};
`;

const AssignContainer = styled("div")`
    display: flex;
    height: 50px;
    width: 200px;
`;

const StyledInputLabel = styled(InputLabel)`
    margin-top: ${({ theme }) => theme.spacing(-1)};
`;

export interface ICustomerReviewHeaderProps {
    review: ICustomerReview;
    backgroundColor: string;
    admins: IAdmin[];
    assignee: string | null;
    onChange: (event: SelectChangeEvent) => void;
}

export const CustomerReviewHeader: FunctionComponent<
    ICustomerReviewHeaderProps
> = (props: ICustomerReviewHeaderProps): ReactElement => {
    const { review, backgroundColor, admins, assignee, onChange } = props;

    const enableSlackAssignment = false;

    return (
        <Root>
            <CustomerDetails>
                <NameInitials backgroundColor={backgroundColor}>{`${(
                    review.customer.firstName ?? ""
                ).charAt(0)}${(review.customer.lastName ?? "").charAt(
                    0,
                )}`}</NameInitials>
                <Container>
                    <CustomerName>
                        {review.customer.firstName} {review.customer.lastName}
                    </CustomerName>
                    <ReviewdAt>{review.customer.phoneNumber}</ReviewdAt>
                    <ReviewDetailsContainer>
                        <Rating
                            value={review.rating}
                            precision={0.5}
                            readOnly
                            size="small"
                        />
                        <ReviewdAt>
                            {formatDistanceToNow(new Date(review.createdAt), {
                                addSuffix: true,
                            })}
                        </ReviewdAt>
                    </ReviewDetailsContainer>
                </Container>
            </CustomerDetails>
            {enableSlackAssignment && (
                <AssignContainer>
                    <FormControl fullWidth={true}>
                        <StyledInputLabel>Assigned to</StyledInputLabel>
                        <Select
                            value={assignee ?? ""}
                            size={"small"}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            onChange={(event) => onChange(event)}
                            label="Assigned to"
                        >
                            {admins.map((admin) => (
                                <MenuItem
                                    key={admin.id}
                                    value={
                                        admin.firstName + " " + admin.lastName
                                    }
                                >
                                    {admin.firstName + " " + admin.lastName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </AssignContainer>
            )}
        </Root>
    );
};
