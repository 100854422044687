import { UseQueryResult, useQuery } from "react-query";

import { api } from "../../sdk/utils";
import { PriceRuleCategoryType } from "../../utils/enums";
import { IExternalListPage, IPriceRule } from "../types";

import { useAuth } from "./useAuth";

export const useGetPriceRulesForCustomer = (
    queryName = "getPriceRules",
    category: PriceRuleCategoryType = PriceRuleCategoryType.UI_DISCOUNT_CODES,
    customerId: string | null = null,
): UseQueryResult<IExternalListPage<IPriceRule>> => {
    const auth = useAuth();
    return useQuery([queryName, category, customerId], async () => {
        if (!auth.token) {
            throw new Error(
                "Cannot get price rules without authorization token.",
            );
        }
        return await api.priceRule.getPriceRulesForCustomer(
            { category, customerId },
            auth.token,
        );
    });
};
