import { useQuery } from "react-query";

import { api } from "../utils";

import { useAuth } from "./useAuth";

export const useFetchActiveCarts = (queryKeys: string[]) => {
    const auth = useAuth();

    if (!auth.token) {
        throw new Error("Cannot fetch carts without valid token.");
    }

    const { isLoading, data, error, isError, isIdle, isSuccess, isFetching } =
        useQuery(
            queryKeys,
            async () => await api.carts.getActiveAdminCarts(auth.token!),
        );

    return {
        isLoading,
        data,
        error,
        isError,
        isIdle,
        isSuccess,
        isFetching,
    };
};
