import axios from "axios";

import * as api from "../../types/api";
import { IUseCreateCustomerMutationParameters } from "../../hooks/useCreateCustomer";

const transform = (customer: any) => ({
    ...customer,
    createdAt: new Date(customer.createdAt),
    updatedAt: new Date(customer.updatedAt),
});

export interface IGetCustomerParameters {
    phoneNumber: string;
    token: string;
}

export interface ICreateCustomerParameters
    extends IUseCreateCustomerMutationParameters {
    token: string;
}

export class Customer {
    public static async getByPhoneNumber(
        parameters: IGetCustomerParameters,
    ): Promise<api.ICustomer> {
        const { phoneNumber, token } = parameters;
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/storefronts/v1.1/customers/phone-number/${phoneNumber}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return transform(data);
    }

    public static async create(
        parameters: ICreateCustomerParameters,
    ): Promise<api.ICustomer> {
        const { phoneNumber, firstName, lastName, emailAddress, token } =
            parameters;
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/storefronts/v1.1/customers`,
            {
                phoneNumber,
                firstName,
                lastName,
                emailAddress,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return transform(data);
    }
}
