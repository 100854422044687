import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import { Button, Icon, Typography, styled } from "@mui/material";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridToolbar,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";

import {
    CircularLoader,
    EmptyRecords,
    WorkspaceToolbar,
} from "../../components/common";
import { useGetAllEntitySchemas } from "../../hooks";
import { IPage } from "../../types";
import { globalDateTimeFormat } from "../../utils/constants";

const Root = styled("div")`
    display: flex;
    flex-direction: column;
`;

const EmptyRecordsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
`;

const Title = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`;

const SchemaTitle = styled(Typography)`
    font-size: 16px;
    color: ${({ theme }) => theme.palette.text.primary};
`;

const Description = styled(Typography)`
    font-size: 13px;
    color: grey;
`;

const StyledCell = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: start;
`;

const columns: GridColDef[] = [
    {
        field: "name",
        headerName: "Name",
        flex: 0.8,
        renderCell: (params: GridRenderCellParams) => (
            <StyledCell>
                <SchemaTitle>{params.row.name}</SchemaTitle>
                <Description>{params.row.description}</Description>
            </StyledCell>
        ),
    },
    {
        field: "createdAt",
        headerName: "Created on",
        flex: 0.2,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
    {
        field: "updatedAt",
        headerName: "Updated on",
        flex: 0.2,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.updatedAt), globalDateTimeFormat)}`,
    },
];

export const ViewEntitySchemasScreen: FunctionComponent = (): ReactElement => {
    const [page, setPage] = useState<IPage>({
        limit: 20,
        cursor: null,
        direction: "after",
    });
    const getAllQuery = useGetAllEntitySchemas(["getAllEntitySchemas"], {
        limit: page.limit,
        cursor: page.cursor,
        direction: page.direction,
    });
    const navigate = useNavigate();

    const handleRowClick = useCallback(
        (params: GridRowParams) => {
            navigate(`/entity-schemas/${params.row.id}`);
        },
        [navigate],
    );

    if (getAllQuery.isLoading) {
        return <CircularLoader />;
    }

    return (
        <Root>
            <WorkspaceToolbar
                title="Entity Schemas"
                enableSearch={false}
                actions={
                    <Link to="/entity-schemas/new">
                        <Button
                            size="small"
                            variant="contained"
                            disableElevation={true}
                            endIcon={<Icon>add_circle</Icon>}
                        >
                            Create
                        </Button>
                    </Link>
                }
            />

            {getAllQuery.data?.records?.length === 0 && (
                <EmptyRecords>
                    <EmptyRecordsContainer>
                        <Title>Manage entity schemas</Title>
                        <Link to="/entity-schemas/new">
                            <Button variant="contained">
                                Create entity schema
                            </Button>
                        </Link>
                    </EmptyRecordsContainer>
                </EmptyRecords>
            )}

            {(getAllQuery?.data?.records ?? []).length > 0 && (
                <DataGrid
                    rows={getAllQuery?.data?.records ?? []}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                            },
                        },
                    }}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: { disableToolbarButton: true },
                            printOptions: { disableToolbarButton: true },
                        },
                    }}
                    disableColumnSelector
                    disableDensitySelector
                    onRowClick={handleRowClick}
                    pageSizeOptions={[10, 20, 30]}
                    disableRowSelectionOnClick={true}
                    checkboxSelection={true}
                />
            )}
        </Root>
    );
};
