import { FunctionComponent, ReactElement, ReactNode } from "react";

import {
    Breadcrumbs as MuiBreadcrumbs,
    Typography,
    styled,
} from "@mui/material";

import { NavigateNext } from "@mui/icons-material";

import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.palette.grey[700]};

    display: flex;
    align-items: center;
`;

const StyledTypography = styled(Typography)`
    color: black;
`;

interface IBreadcrumb {
    title: string;
    icon?: ReactNode;
    link: string;
}

export interface IBreadcrumbsProps {
    items: IBreadcrumb[];
}

export const Breadcrumbs: FunctionComponent<IBreadcrumbsProps> = (
    props: IBreadcrumbsProps,
): ReactElement => {
    const { items } = props;
    return (
        <MuiBreadcrumbs separator={<NavigateNext fontSize="small" />}>
            {items.map((item, index) =>
                index === items.length - 1 ? (
                    <StyledTypography>
                        {item?.icon && item.icon}
                        {item.title}
                    </StyledTypography>
                ) : (
                    <StyledLink to={item.link}>
                        {item?.icon && item.icon}
                        {item.title}
                    </StyledLink>
                ),
            )}
        </MuiBreadcrumbs>
    );
};
