import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { api } from "../utils";

import { useAuth } from "./useAuth";

export interface IUseMarkOrderConvertedMutationParameters {
    cartId: string;
}

/**
 * Marks the cart's status as order converted.
 */
export const useMarkOrderConverted = (
    invalidQueryKeys: string[] = [],
): UseMutationResult<
    void,
    unknown,
    IUseMarkOrderConvertedMutationParameters
> => {
    const queryClient = useQueryClient();
    const { token } = useAuth();

    return useMutation<void, unknown, IUseMarkOrderConvertedMutationParameters>(
        {
            mutationFn: (
                parameters: IUseMarkOrderConvertedMutationParameters,
            ): Promise<void> => {
                if (!token) {
                    throw new Error(
                        "Cannot mark cart as order converted without authorization token.",
                    );
                }

                return api.carts.markCartAsOrderConverted({
                    cartId: parameters.cartId,
                    token,
                });
            },
            onSuccess: async () => {
                queryClient.invalidateQueries(invalidQueryKeys);
            },
        },
    );
};
