import axios from "axios";

import { IEntityGroup } from "../types";

export interface ICreateEntityGroupParams {
    token: string;
    entitySchemaId: string;
    name: string;
    description: string;
}

export interface IUpdateEntityGroupParams {
    token: string;
    groupId: string;
    name: string;
    description: string;
}

export interface IDeleteEntityGroupParams {
    token: string;
    groupId: string;
}

export class EntityGroup {
    private static transform(data: IEntityGroup): IEntityGroup {
        return {
            ...data,
            createdAt: new Date(data.createdAt),
            updatedAt: new Date(data.updatedAt),
        };
    }

    public static async create(
        parameters: ICreateEntityGroupParams,
    ): Promise<IEntityGroup> {
        const { token, entitySchemaId, name, description } = parameters;
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups`,
            {
                entitySchemaId,
                name,
                description,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        return this.transform(data);
    }

    public static async getById(
        groupId: string,
        token: string,
    ): Promise<IEntityGroup> {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups/${groupId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        return this.transform(data);
    }

    public static async getAllBySchemaId(
        token: string,
        schemaId: string,
    ): Promise<IEntityGroup[]> {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups/${schemaId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return data.map(this.transform);
    }

    public static async delete(
        parameters: IDeleteEntityGroupParams,
    ): Promise<void> {
        const { token, groupId } = parameters;
        await axios.delete(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups/${groupId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
    }

    public static async update(
        parameters: IUpdateEntityGroupParams,
    ): Promise<IEntityGroup> {
        const { token, groupId, name, description } = parameters;
        const { data } = await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups/${groupId}`,
            {
                name,
                description,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return this.transform(data);
    }
}
