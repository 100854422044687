import {
    UseMutationOptions,
    UseMutationResult,
    useMutation,
    useQueryClient,
} from "react-query";

import { useAuth, useMessage } from "../sdk/hooks";
import { ICustomer } from "../sdk/types";
import { api } from "../sdk/utils";

export interface IUpdateCustomerParameter {
    customerId: string;
    emailAddress: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
}

export const useUpdateCustomer = (
    invalidQueryKeys: string | string[],
    options?: UseMutationOptions<
        ICustomer,
        unknown,
        IUpdateCustomerParameter,
        unknown
    >,
): UseMutationResult<ICustomer, unknown, IUpdateCustomerParameter> => {
    const auth = useAuth();
    const { showError } = useMessage();
    const queryClient = useQueryClient();

    return useMutation<ICustomer, unknown, IUpdateCustomerParameter>({
        ...options,
        mutationFn: async (parameters: IUpdateCustomerParameter) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot update customer without authorization token.",
                );
            }

            return await api.Customer.update({
                ...parameters,
                token: auth.token,
            });
        },
        onError: (error: any) => showError(error),
        onSuccess: (
            data: ICustomer,
            variables: IUpdateCustomerParameter,
            context: unknown,
        ) => {
            if (invalidQueryKeys !== undefined) {
                queryClient.invalidateQueries(invalidQueryKeys);
            }

            options?.onSuccess?.(data, variables, context);
        },
    });
};
