import axios from "axios";

import * as api from "../../types/api";
import { IAdmin, IExternalListPage } from "../../types/api";

export interface IGetAllAdminsProps {
    limit?: number;
    cursor?: string | null;
    direction?: string;
    token: string;
}

export class Admin {
    static transform = (data: api.IAdmin): api.IAdmin => {
        return {
            ...data,
            createdAt: new Date(data.createdAt),
            updatedAt: new Date(data.updatedAt),
        };
    };

    static async list(
        parameters: IGetAllAdminsProps,
    ): Promise<IExternalListPage<IAdmin>> {
        const { token, ...body } = parameters;
        const params = new URLSearchParams([
            ["limit", body?.limit?.toString() ?? ""],
            ["direction", body.direction ?? ""],
        ]);

        if (body.cursor) {
            params.append("cursor", body.cursor);
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/admins?${params}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return { ...data, records: data.records.map(this.transform) };
    }
}
