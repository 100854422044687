import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

import {
    Avatar,
    Button,
    Drawer,
    InputAdornment,
    Modal,
    TextField,
    Tooltip,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import { RemoveShoppingCartOutlined, Search } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import { CircularLoader } from "../../../components/common";
import {
    useCreateCustomer,
    useFetchActiveCarts,
    useGetCartByCustomerId,
    useGetCustomer,
    useInitializeCart,
    useMessage,
} from "../../../sdk/hooks";
import { ICart, ICustomer, ICustomerFormValues } from "../../../sdk/types";

import { ActiveCart } from "./ActiveCart";
import { CustomerForm } from "./CustomerForm";

const Root = styled("main")`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};

    padding: ${({ theme }) => theme.spacing(3)};
`;

const Title = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`;

const Subtitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const ListContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const PhoneNumber = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const MobileNumberInput = styled(TextField)`
    border-radius: 0px;
    max-width: 350px;

    ${({ theme }) => theme.breakpoints.down("md")} {
        max-width: 100%;
    }
`;

const ErrorMessage = styled(Typography)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: red;
`;

const CustomerContainer = styled("div")`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing(1)};

    padding: ${({ theme }) => theme.spacing(1, 2)};
    background-color: #f2f2f2;

    ${({ theme }) => theme.breakpoints.down("md")} {
        flex-direction: column;
    }
`;

const ErrorContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(1, 2)};
    background-color: #f2f2f2;
    gap: ${({ theme }) => theme.spacing(1)};

    ${({ theme }) => theme.breakpoints.down("md")} {
        flex-direction: column;
    }
`;

const CustomerLabel = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
`;

const ModalContainer = styled("div")`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: fit-content;
    height: fit-content;

    background-color: #fff;
    outline: 0;

    border-radius: ${({ theme }) => theme.spacing(1)};

    max-height: 100vh;
    overflow: auto;
`;

const FormContainer = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: ${({ theme }) => theme.spacing(3)};
`;

const FormDrawer = styled(Drawer)`
    .MuiDrawer-paper {
        height: 95vh;
        border-radius: ${({ theme }) => theme.spacing(2, 2, 0, 0)};
    }
`;

const EmptyContainer = styled("div")`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: ${({ theme }) => theme.spacing(2)};

    border: 1px solid #d9d9d9;
    border-radius: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(2)};
`;

const SelectedCustomer = styled("span")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};

    ${({ theme }) => theme.breakpoints.down("md")} {
        flex-direction: column;
        align-items: start;
        gap: 0;
    }
`;

export const SelectCart: FunctionComponent = (): ReactElement => {
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [error, setError] = useState<string>();
    const fetchCartsQuery = useFetchActiveCarts(["fetchCarts"]);
    const [customer, setCustomer] = useState<ICustomer | undefined>();
    const [customerCart, setCustomerCart] = useState<ICart | undefined>();
    const [formOpen, setFormOpen] = useState(false);
    let cartFound = false;
    const getCartByCustomerId = useGetCartByCustomerId(
        customer?.id ?? undefined,
        !cartFound,
    );
    const getCustomerMutation = useGetCustomer();
    const createCustomerMutation = useCreateCustomer();
    const initializeCartMutation = useInitializeCart();
    const navigate = useNavigate();
    const theme = useTheme();
    const message = useMessage();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    let loading = fetchCartsQuery.isLoading;

    const carts = useMemo(
        () => fetchCartsQuery.data?.records ?? [],
        [fetchCartsQuery.data?.records],
    );

    useEffect(() => {
        if (getCartByCustomerId.data) {
            setCustomerCart(getCartByCustomerId.data);
        }
    }, [getCartByCustomerId.data]);

    const handleUpdatePhoneNumber = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newPhone = event.target.value;
            setPhoneNumber(newPhone);
            setCustomerCart(undefined);
            setCustomer(undefined);
            cartFound = false;

            if (newPhone.length === 10) {
                const searchedCart = carts.find(
                    (cart) => `+91${newPhone}` === cart.customer?.phoneNumber,
                );
                setCustomerCart(searchedCart);
                cartFound = typeof searchedCart !== "undefined";
                if (!cartFound) {
                    getCustomerMutation.mutate(
                        {
                            phoneNumber: newPhone,
                        },
                        {
                            onSuccess: (customer: ICustomer) => {
                                setCustomer(customer);
                                /* Reaching here means that we're sure that a customer with the searched phoneNumber exists
                                 * but an active cart associated with this customer does not exist in the first page of `fetchCartsQuery`
                                 * Therefore, we try fetching the cart by `customerId`, without creating a new one. This is when
                                 * `useGetCartByCustomerId` will be enabled.
                                 */
                                setError("");
                            },
                            onError: () => {
                                setError(
                                    "Couldn't find customer with the given phone number.",
                                );
                            },
                        },
                    );
                } else {
                    setError("");
                }
            }
        },
        [
            cartFound,
            carts,
            getCustomerMutation,
            phoneNumber,
            phoneNumber.length,
        ],
    );

    const showAllCarts = phoneNumber.length !== 10 || !customerCart;

    const handleCreateCart = useCallback(() => {
        /* Initialise a new cart if customer exists but an active cart for the customer does not */
        if (customer && !cartFound && !customerCart) {
            initializeCartMutation.mutate(
                { customerId: customer.id },
                {
                    onSuccess: (cart) => {
                        navigate(`/carts/${cart.id}`);
                    },
                },
            );
        }
    }, [cartFound, customer, customerCart, initializeCartMutation, navigate]);

    const handleOpenForm = useCallback(() => {
        setFormOpen(true);
    }, []);

    const handleCloseForm = useCallback(() => {
        setFormOpen(false);
    }, []);

    const handleCreateCustomer = useCallback(
        (values: ICustomerFormValues) => {
            createCustomerMutation.mutate(values, {
                onSuccess: (data) => {
                    setFormOpen(false);
                    setCustomer(data);
                    setError("");
                    message.showSuccess(
                        `Customer created with ${data.phoneNumber}`,
                    );
                },
                onError: () => {
                    message.showError("Could not create customer");
                },
            });
        },
        [createCustomerMutation, message],
    );

    const renderForLargeScreen = () => {
        if (!customer) {
            return (
                <Modal
                    open={formOpen && !getCustomerMutation.isLoading}
                    onClose={handleCloseForm}
                >
                    <ModalContainer>
                        <FormContainer>
                            {(formOpen || !customer) && (
                                <CustomerForm
                                    onSave={handleCreateCustomer}
                                    saving={createCustomerMutation.isLoading}
                                    onClose={handleCloseForm}
                                    phoneNumber={phoneNumber}
                                />
                            )}
                        </FormContainer>
                    </ModalContainer>
                </Modal>
            );
        }
    };

    const renderForSmallScreen = () => {
        if (!customer) {
            return (
                <FormDrawer
                    anchor={"bottom"}
                    open={formOpen && !getCustomerMutation.isLoading}
                    onClose={handleCloseForm}
                >
                    <FormContainer>
                        {(formOpen || !customer) && (
                            <CustomerForm
                                onSave={handleCreateCustomer}
                                saving={createCustomerMutation.isLoading}
                                onClose={handleCloseForm}
                                phoneNumber={phoneNumber}
                            />
                        )}
                    </FormContainer>
                </FormDrawer>
            );
        }
    };

    const customerDetails =
        customer &&
        customer.firstName &&
        customer.lastName &&
        customer.emailAddress;

    if (loading) {
        return <CircularLoader />;
    }

    return (
        <Root>
            <Title>Select or Create Cart</Title>
            <PhoneNumber>
                <Subtitle>Search by Customer</Subtitle>
                <MobileNumberInput
                    id="request-otp--input--mobile-number"
                    placeholder="Customer Phone Number"
                    type="number"
                    name="phoneNumber"
                    disabled={loading}
                    value={phoneNumber}
                    autoComplete={phoneNumber}
                    onChange={handleUpdatePhoneNumber}
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                +91
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
                {error && (
                    <ErrorContainer>
                        <ErrorMessage>{error}</ErrorMessage>
                        <Button variant="contained" onClick={handleOpenForm}>
                            Create Customer
                        </Button>
                    </ErrorContainer>
                )}
            </PhoneNumber>
            {customer && phoneNumber.length === 10 && (
                <CustomerContainer>
                    {customerDetails && (
                        <SelectedCustomer>
                            <CustomerLabel>Selected Customer:</CustomerLabel>
                            <Typography>
                                {`${customer.firstName} ${customer.lastName}, ${customer.phoneNumber}, ${customer.emailAddress}`}
                            </Typography>
                        </SelectedCustomer>
                    )}
                    {!customerDetails && (
                        <>
                            <SelectedCustomer>
                                <CustomerLabel>
                                    Selected Customer:
                                </CustomerLabel>
                                <Typography>
                                    {`${customer.phoneNumber} - Customer details not
                            found`}
                                </Typography>
                            </SelectedCustomer>
                            <Tooltip
                                enterTouchDelay={0}
                                placement="top"
                                title="This feature will be enabled soon"
                            >
                                <div>
                                    <Button variant="contained" disabled>
                                        Edit Customer
                                    </Button>
                                </div>
                            </Tooltip>
                        </>
                    )}
                </CustomerContainer>
            )}
            {!getCartByCustomerId.data && !cartFound && customer && (
                <ErrorContainer>
                    <ErrorMessage>{`No carts active for ${customer.phoneNumber}`}</ErrorMessage>
                    <Button variant="contained" onClick={handleCreateCart}>
                        Create Cart
                    </Button>
                </ErrorContainer>
            )}
            <ListContainer>
                <Subtitle>Recently Created Carts</Subtitle>
                {showAllCarts && carts.length === 0 && (
                    <EmptyContainer>
                        <Avatar sizes="100px">
                            <RemoveShoppingCartOutlined fontSize="medium" />
                        </Avatar>
                        You don't have any active carts
                    </EmptyContainer>
                )}
                {/* Display 5 recently created carts that are active */}
                {showAllCarts &&
                    carts.length > 0 &&
                    carts
                        .slice(0, 5)
                        .map((cart) => (
                            <ActiveCart cart={cart} key={cart.id} />
                        ))}
                {!showAllCarts && <ActiveCart cart={customerCart} />}
            </ListContainer>
            {/* for screens bigger than "sm" we use modal */}
            {!smallScreen && renderForLargeScreen()}

            {/* for screens smaller than "sm" we use drawer */}
            {smallScreen && renderForSmallScreen()}
        </Root>
    );
};
