import { ReactNode, useCallback } from "react";

import * as api from "../types/api";
import { AuthContext } from "../contexts";
import { useLocalStorageItem } from "../hooks";
import { misc } from "../utils";

export interface IAuthProviderProps {
    children: ReactNode;
}

export const AuthProvider = (props: IAuthProviderProps) => {
    const { children } = props;

    const [token, setToken] = useLocalStorageItem<string>(
        "adminToken",
        "",
        /* Keep the serialize/deserialize functions outside the component to avoid
         * unecessary re-renders.
         */
        misc.serializeString,
        misc.deserializeString,
    );
    const [user, setUser] = useLocalStorageItem<api.IAdmin | null>(
        "admin",
        null,
        JSON.stringify,
        JSON.parse,
    );

    const handleLogout = useCallback(() => {
        setUser(null);
        setToken("");
    }, [setToken, setUser]);

    return (
        <AuthContext.Provider
            value={{
                token,
                user,
                setUser,
                setToken,
                logout: handleLogout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
