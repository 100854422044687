import { Dispatch, SetStateAction, useEffect, useState } from "react";

export interface IObjectWithToString {
    toString: () => string;
}

/**
 * Do not modify the item in local storage directly. Always use `setValue`
 * returned by this hook. Otherwise `value` will become stale.
 *
 * @param key
 *        The item in local storage to read and write.
 * @param fallbackValue
 *        The default value used when the item is missing in local storage.
 */
export const useLocalStorageItem = <T extends IObjectWithToString | null>(
    key: string,
    fallbackValue: T,
    serialize: (value: T) => string,
    deserialize: (value: string) => T,
): readonly [T, Dispatch<SetStateAction<T>>] => {
    const [value, setValue] = useState(() => {
        const stored = localStorage.getItem(key);
        return stored ? deserialize(stored) : fallbackValue;
    });

    useEffect(() => {
        localStorage.setItem(key, serialize(value));
    }, [key, serialize, value]);

    return [value, setValue] as const;
};
