export enum OrderStatus {
    CREATED = 1,
    SHIPPED = 2,
    RETURNED = 3,
    CANCELLED = 4,
    FAILED = 5,
    SYNCED = 6,
    DELIVERED = 7,
    REFUNDED = 8,
    PREBOOKING_ORDER_CREATED = 9,
    PREBOOKED = 10,
    CUSTOMER_CONFIRMATION_AWAITED = 11,
    CUSTOMER_CONFIRMATION_RECEIVED = 12,
}

export enum NativePaymentStatus {
    NEW = "NEW",
    MISMATCH = "MISMATCH",
    CANCELLED = "CANCELLED",
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
    ABANDONED = "ABANDONED",
    REPLACED = "REPLACED",
    PENDING = "PENDING",
}

export enum CancelReason {
    DELIVERY_TIME_TOO_LONG = 1,
    DISLIKE_PRODUCT = 2,
    CHANGE_PAYMENT = 3,
    WRONG_ADDRESS = 4,
    OTHER = 5,
    PAYMENT_FAILURE = 6,
}

export enum OrderConfirmationSource {
    CUSTOMER = 1,
    AUTOMATIC = 2,
}

export const ConfirmationSourceNames: Record<OrderConfirmationSource, string> =
    {
        [OrderConfirmationSource.CUSTOMER]: "Customer",
        [OrderConfirmationSource.AUTOMATIC]: "Automatic",
    };

export enum AllocationMethod {
    LOWEST_LINE_ITEMS = 1,
    HIGHEST_LINE_ITEMS = 2,
    COMBINED_LINE_ITEMS = 3,
    EACH_LINE_ITEM = 4,
}

export const AllocationMethodNames: Record<AllocationMethod, string> = {
    [AllocationMethod.LOWEST_LINE_ITEMS]: "Lowest line items",
    [AllocationMethod.HIGHEST_LINE_ITEMS]: "Highest line items",
    [AllocationMethod.COMBINED_LINE_ITEMS]: "Combined line items",
    [AllocationMethod.EACH_LINE_ITEM]: "Each line item",
};

export enum ProductStatus {
    DRAFT = 1,
    PUBLISHED = 2,
    ARCHIVED = 3,
}

export const productStatusMap = {
    [ProductStatus.DRAFT]: "Draft",
    [ProductStatus.PUBLISHED]: "Published",
    [ProductStatus.ARCHIVED]: "Archived",
};

export enum CustomerSelection {
    ALL = 1,
    PREREQ = 2,
}

export enum DiscountType {
    FIXED = 1,
    PERCENTAGE = 2,
}

export const DiscountTypeNames: Record<DiscountType, string> = {
    [DiscountType.FIXED]: "Fixed / Flat discount",
    [DiscountType.PERCENTAGE]: "Percentage discount",
};

export enum PriceRuleStatus {
    DRAFT = 1,
    ACTIVE = 2,
    ARCHIVED = 3,
}

export const priceRuleStatusNames: Record<number, string> = {
    [PriceRuleStatus.DRAFT]: "Draft",
    [PriceRuleStatus.ACTIVE]: "Active",
    [PriceRuleStatus.ARCHIVED]: "Archived",
};

export enum DiscountCodeStatus {
    DRAFT = 1,
    ACTIVE = 2,
    ARCHIVED = 3,
}

export const discountCodeStatusNames: Record<number, string> = {
    [PriceRuleStatus.DRAFT]: "Draft",
    [PriceRuleStatus.ACTIVE]: "Active",
    [PriceRuleStatus.ARCHIVED]: "Archived",
};

export const productStatusNames: Record<number, string> = {
    [PriceRuleStatus.DRAFT]: "Draft",
    [PriceRuleStatus.ACTIVE]: "Active",
    [PriceRuleStatus.ARCHIVED]: "Archived",
};

export const orderStatusByEnum: Record<number, string> = {
    [OrderStatus.CREATED]: "Drafted",
    [OrderStatus.SHIPPED]: "Shipped",
    [OrderStatus.RETURNED]: "Returned",
    [OrderStatus.CANCELLED]: "Cancelled",
    [OrderStatus.FAILED]: "Failed",
    [OrderStatus.SYNCED]: "Synced",
    [OrderStatus.DELIVERED]: "Delivered",
    [OrderStatus.REFUNDED]: "Refunded",
    [OrderStatus.PREBOOKING_ORDER_CREATED]: "Prebooking draft",
    [OrderStatus.PREBOOKED]: "Prebooked",
    [OrderStatus.CUSTOMER_CONFIRMATION_AWAITED]: "Confirmation awaited",
    [OrderStatus.CUSTOMER_CONFIRMATION_RECEIVED]: "Confirmation received",
};

export enum AuthenticationMethod {
    OTP = 1,
    OTPLESS = 2,
    TRUECALLER = 3,
    TOKEN = 4,
}

export const authenticationMethodByEnum: Record<number, string> = {
    [AuthenticationMethod.OTP]: "OTP",
    [AuthenticationMethod.OTPLESS]: "OTPless",
    [AuthenticationMethod.TRUECALLER]: "Truecaller",
    [AuthenticationMethod.TOKEN]: "Token",
};

export enum AuthenticationStatus {
    PENDING = 1,
    INVALID_OTP = 2,
    EXPIRED_OTP = 3,
    OTP_NOT_FOUND = 4,
    MAX_ATTEMPTS_EXCEEDED = 5,
    INVALID_USER = 6,
    INVALID_CREDENTIALS = 7,
    SUCCESS = 8,
    /**
     * The customer denied access to their Truecaller profile.
     */
    ACCESS_DENIED = 9,
}

export const authenticationStatusByEnum: Record<number, string> = {
    [AuthenticationStatus.PENDING]: "Pending",
    [AuthenticationStatus.INVALID_OTP]: "Invalid OTP",
    [AuthenticationStatus.EXPIRED_OTP]: "Expired OTP",
    [AuthenticationStatus.OTP_NOT_FOUND]: "OTP Not Found",
    [AuthenticationStatus.MAX_ATTEMPTS_EXCEEDED]: "Max Attempts Exceeded",
    [AuthenticationStatus.INVALID_USER]: "Invalid User",
    [AuthenticationStatus.INVALID_CREDENTIALS]: "Invalid Credentials",
    [AuthenticationStatus.SUCCESS]: "Success",
    [AuthenticationStatus.ACCESS_DENIED]: "Access Denied",
};

export const productStatusByEnum: Record<number, string> = {
    [ProductStatus.DRAFT]: "draft",
    [ProductStatus.PUBLISHED]: "published",
    [ProductStatus.ARCHIVED]: "archived",
};

export enum PurchaseRequirements {
    NO_REQUIREMENTS = "NO_REQUIREMENTS",
    MIN_PURCHASE_AMOUNT = "MIN_PURCHASE_AMOUNT",
    MIN_QUANTITY_AMOUNT = "MIN_QUANTITY_AMOUNT",
}

export enum PriceRuleCategoryType {
    PAYMENT_METHODS = 1,
    UI_DISCOUNT_CODES = 2,
    PRIVATE_DISCOUNTS = 3,
}

export const PriceRuleCategoryNames: Record<PriceRuleCategoryType, string> = {
    [PriceRuleCategoryType.PAYMENT_METHODS]: "Payment method",
    [PriceRuleCategoryType.UI_DISCOUNT_CODES]: "Public",
    [PriceRuleCategoryType.PRIVATE_DISCOUNTS]: "Private",
};

export enum PaymentMethodType {
    COD = "cod",
    PREPAID = "prepaid",
}

export enum PaymentPlatform {
    JUSPAY = 1,
    BHARAT_X = 2,
}

export const PaymentMethodNames: Record<PaymentMethodType, string> = {
    [PaymentMethodType.COD]: "COD",
    [PaymentMethodType.PREPAID]: "Prepaid",
};

export enum RefundStatusType {
    PENDING = 0,
    SUCCESS = 1,
    FAILED = 2,
    UNKNOWN = 3,
    ERROR = 4,
}

export const RefundStatusNames: Record<RefundStatusType, string> = {
    [RefundStatusType.PENDING]: "Pending",
    [RefundStatusType.SUCCESS]: "Success",
    [RefundStatusType.FAILED]: "Failed",
    [RefundStatusType.UNKNOWN]: "Unknown",
    [RefundStatusType.ERROR]: "Error",
};

export enum RefundReason {
    ORDER_CANCELLED = 1,
    DAMAGED_PRODUCT = 2,
    WRONG_PRODUCT = 3,
    OTHER = 4,
}

export const RefundReasonNames: Record<RefundReason, string> = {
    [RefundReason.ORDER_CANCELLED]: "Order cancelled",
    [RefundReason.DAMAGED_PRODUCT]: "Damaged product",
    [RefundReason.WRONG_PRODUCT]: "Wrong product delivered",
    [RefundReason.OTHER]: "Other",
};

export enum RefundPlatformType {
    JUSPAY = 0,
}

export const RefundPlatformNames: Record<RefundPlatformType, string> = {
    [RefundPlatformType.JUSPAY]: "Juspay",
};

export enum AdminRole {
    ROOT = 1,
    ADMIN = 2,
    MANAGER = 3,
    USER = 4,
    GUEST = 5,
}

export enum Department {
    DEFAULT = 1,
    FOUNDERS = 2,
    TECH = 3,
    OPERATIONS = 4,
    MARKETING = 5,
    DESIGN = 6,
    FINANCE = 7,
    HUMAN_RESOURCES = 8,
    CUSTOMER_SERVICE = 9,
    GROWTH = 10,
    BRAND = 11,
}

export enum AdminStatus {
    INVITED = 1,
    ACTIVE = 2,
    INACTIVE = 3,
    BANNED = 4,
}

export const userStatusNames: Record<number, string> = {
    [AdminStatus.INVITED]: "Invited",
    [AdminStatus.ACTIVE]: "Active",
    [AdminStatus.INACTIVE]: "Inactive",
    [AdminStatus.BANNED]: "Blocked",
};

export const userRoleNames: Record<number, string> = {
    [AdminRole.ROOT]: "Root",
    [AdminRole.ADMIN]: "Admin",
    [AdminRole.MANAGER]: "Manager",
    [AdminRole.USER]: "User",
    [AdminRole.GUEST]: "Guest",
};

export const userDepartmentNames: Record<number, string> = {
    [Department.DEFAULT]: "Default",
    [Department.FOUNDERS]: "Founder",
    [Department.TECH]: "Technology",
    [Department.OPERATIONS]: "Operations",
    [Department.MARKETING]: "Marketing",
    [Department.DESIGN]: "Design",
    [Department.FINANCE]: "Finance",
    [Department.HUMAN_RESOURCES]: "Human Resources",
    [Department.CUSTOMER_SERVICE]: "Customer Service",
    [Department.GROWTH]: "Growth",
    [Department.BRAND]: "Brand",
};

export enum EntityAttributeType {
    INTEGER = 1,
    NUMBER = 2,
    BOOLEAN = 3,
    SELECT = 4,
    CHECKLIST = 5,
    SINGLE_LINE = 6,
    MULTILINE = 7,
    MARKDOWN = 8,
    DATE = 9,
    DATE_TIME = 10,
    TIME = 11,
}

export const entityAttributeNames: Record<number, string> = {
    [EntityAttributeType.INTEGER]: "Integer",
    [EntityAttributeType.NUMBER]: "Number",
    [EntityAttributeType.BOOLEAN]: "Boolean",
    [EntityAttributeType.SELECT]: "Select",
    [EntityAttributeType.CHECKLIST]: "Checklist",
    [EntityAttributeType.SINGLE_LINE]: "Single Line",
    [EntityAttributeType.MULTILINE]: "Multiline",
    [EntityAttributeType.MARKDOWN]: "Markdown",
    [EntityAttributeType.DATE]: "Date",
    [EntityAttributeType.DATE_TIME]: "Date Time",
    [EntityAttributeType.TIME]: "Time",
};

export enum OrderSource {
    DIRECT = "Direct",
    INSIDER_SALES = "Insider Sales",
}
