import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { IProduct } from "../../sdk/types";
import { api } from "../../sdk/utils";

export interface IUpdateProductParameters {
    id: string;
    title: string;
    description: string;
    tagLine: string;
    featureScore: number;
    bestSellerScore: number;
    slug: string;
    shopifyProductId: string;
    status: number;
    meta: Record<string, unknown>;
    schemaId?: string;
    value?: object;
}

export const useUpdateProduct = (
    invalidQueryKeys: string | string[],
): UseMutationResult<IProduct, unknown, IUpdateProductParameters> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation<IProduct, unknown, IUpdateProductParameters>({
        mutationFn: async (parameters: IUpdateProductParameters) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot udpate product without authorization token.",
                );
            }

            return await api.Products.updateProduct({
                ...parameters,
                token: auth.token,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(invalidQueryKeys);
        },
    });
};
