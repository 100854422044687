import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import {
    Button,
    CircularProgress,
    Icon,
    Typography,
    styled,
} from "@mui/material";

import { FileDownload } from "@mui/icons-material";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridValueFormatterParams,
    GridValueGetterParams,
    useGridApiRef,
} from "@mui/x-data-grid";
import { formatDistance } from "date-fns";
import { useQueryClient } from "react-query";

import { Paper, StatusChip } from "../../components/common";
import { IDiscountCode } from "../../sdk/types";
import { DiscountCodeStatus, discountCodeStatusNames } from "../../utils/enums";

import { DiscountCodeDialog } from "./DiscountCodeDialog";

const Container = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const TextFieldWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(4)};
`;

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }
    .MuiDataGrid-row:hover {
        cursor: pointer;
    }
`;

const StyledDataGridHeading = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SubTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;

    color: #626262;
`;

const VariantButtonContainer = styled("div")`
    display: flex;
    justify-content: flex-end;
    column-gap: ${({ theme }) => theme.spacing(1)};

    width: 100%;
`;

const AddVariantButton = styled(Button)`
    width: max-content;
`;

const columns: GridColDef[] = [
    {
        field: "code",
        headerName: "Code",
        flex: 1,
        minWidth: 150,
    },
    {
        field: "status",
        headerName: "Status",
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => (
            <StatusChip
                label={discountCodeStatusNames[params.row.status]}
                value={params.row.status}
            />
        ),
        valueFormatter: (params: GridValueFormatterParams) =>
            discountCodeStatusNames[params.value],
    },
    {
        field: "createdBy",
        headerName: "Created by",
        flex: 0.7,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) => {
            const createdBy = params.row.createdBy;
            return createdBy
                ? `${createdBy.firstName} ${createdBy.lastName}`
                : "NA";
        },
    },
    {
        field: "createdAt",
        headerName: "Created",
        flex: 0.7,
        minWidth: 150,
        valueFormatter: (params: GridValueFormatterParams) => {
            return params.value
                ? new Date(params.value).toLocaleString()
                : "Not Saved";
        },
        renderCell: (params: GridRenderCellParams) =>
            params.row.createdAt
                ? `${formatDistance(
                      new Date(params.row.createdAt),
                      new Date(),
                  )} ago`
                : "Not Saved",
    },
];

export interface IDiscountCodeDataGridProps {
    discountCodes: IDiscountCode[];
    createNewPriceRule: boolean;
    onAdd: (index: number, value: string, status: DiscountCodeStatus) => void;
    priceRuleId: string | undefined;
}

export const DiscountCodeDataGrid: FunctionComponent<
    IDiscountCodeDataGridProps
> = (props: IDiscountCodeDataGridProps): ReactElement => {
    const { discountCodes, onAdd, createNewPriceRule, priceRuleId } = props;
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedCode, setSelectedCode] = useState<IDiscountCode | null>(
        null,
    );
    const [selectedCodeIndex, setSelectedCodeIndex] = useState<number | null>(
        null,
    );
    const queryClient = useQueryClient();
    const loading = queryClient.isMutating() === 1;

    const dataGridRef = useGridApiRef();

    const handleOpenVariantDialog = useCallback(() => {
        setOpenEditDialog(true);
        setSelectedCode(null);
        setOpenEditDialog(true);
    }, []);

    const handleCloseDialog = useCallback(() => {
        setOpenEditDialog(false);
        setSelectedCodeIndex(null);
    }, []);

    const handleRowClick = useCallback((params: GridRowParams) => {
        setSelectedCode(params.row);
        setOpenEditDialog(true);
        setSelectedCodeIndex(params.row.position - 1);
    }, []);

    const handleExportClick = useCallback(() => {
        dataGridRef.current.exportDataAsCsv({
            fileName: "discount_codes",
        });
    }, [dataGridRef]);

    return (
        <Paper>
            <TextFieldWrapper>
                <Container>
                    <StyledDataGridHeading>
                        <SubTitle>Discount Codes</SubTitle>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={handleExportClick}
                            startIcon={<FileDownload />}
                        >
                            EXPORT AS CSV
                        </Button>
                    </StyledDataGridHeading>
                    {discountCodes.length > 0 && !loading && (
                        <StyledDataGrid
                            rows={
                                discountCodes?.map((code, index) => ({
                                    ...code,
                                    id: code.id === "" ? index : code.id,
                                })) ?? []
                            }
                            columns={columns}
                            onRowClick={handleRowClick}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 20,
                                    },
                                },
                            }}
                            pageSizeOptions={[10, 20, 30]}
                            disableRowSelectionOnClick={true}
                            apiRef={dataGridRef}
                        />
                    )}
                    {discountCodes.length >= 0 && (
                        <VariantButtonContainer>
                            <AddVariantButton
                                variant="outlined"
                                onClick={handleOpenVariantDialog}
                                size="small"
                                startIcon={<Icon>add_circle</Icon>}
                            >
                                Add discount code
                            </AddVariantButton>
                        </VariantButtonContainer>
                    )}
                    {loading && <CircularProgress />}
                </Container>
            </TextFieldWrapper>
            {openEditDialog && (
                <DiscountCodeDialog
                    discountCodes={discountCodes}
                    discountCode={selectedCode}
                    open={openEditDialog}
                    onClose={handleCloseDialog}
                    onAdd={onAdd}
                    priceRuleId={priceRuleId}
                    selectedCodeIndex={selectedCodeIndex}
                    createNewPriceRule={createNewPriceRule}
                />
            )}
        </Paper>
    );
};
