import React, { FunctionComponent, useCallback } from "react";

import { styled } from "@mui/material";

const Tab = styled("div")`
    width: 100%;

    font-family: "Poppins";

    padding: ${({ theme }) => theme.spacing(1, 2)};
    margin-top: ${({ theme }) => theme.spacing(1)};

    cursor: pointer;
`;

const SelectedTab = styled(Tab)`
    background-color: #f0f0f0;
    font-weight: 500;
`;

export interface IFilterTabProps {
    selected: boolean;
    onSelect: (value: string) => void;
    value: string;
    label: string;
}

export const FilterTab: FunctionComponent<IFilterTabProps> = (
    props: IFilterTabProps,
) => {
    const { selected, onSelect, value, label } = props;

    const handleClick = useCallback(() => {
        onSelect(value);
    }, [onSelect, value]);

    return selected ? (
        <SelectedTab>{label}</SelectedTab>
    ) : (
        <Tab onClick={handleClick}>{label}</Tab>
    );
};
