import { FunctionComponent, ReactElement, useCallback } from "react";

import {
    Alert,
    Button,
    ButtonBase,
    Dialog,
    DialogTitle,
    Typography,
    styled,
} from "@mui/material";

import { Close } from "@mui/icons-material";

const Header = styled(DialogTitle)`
    width: 100%;
    background-color: #f0f0f0;

    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const FormCloseHandler = styled("div")`
    display: flex;
    justify-content: center;
`;

const FormDrawerCloseButton = styled(ButtonBase)`
    width: 32px;
    height: 32px;
    border-radius: 50%;
`;

const Body = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(2)};
`;

const Actions = styled("div")`
    width: 100%;

    display: flex;
    justify-content: end;
    gap: ${({ theme }) => theme.spacing(1)};

    padding: ${({ theme }) => theme.spacing(2)};
`;

export interface ISaveAlertProps {
    open: boolean;
    onCancel: () => void;
    onSave: () => void;
    showWarning?: boolean;
    warningText?: string;
}

export const SaveAlert: FunctionComponent<ISaveAlertProps> = (
    props: ISaveAlertProps,
): ReactElement => {
    const { open, onCancel, onSave, showWarning = false, warningText } = props;

    return (
        <Dialog open={open} onClose={onCancel} fullWidth maxWidth="sm">
            <Header>
                Save changes
                <FormCloseHandler>
                    <FormDrawerCloseButton onClick={onCancel}>
                        <Close />
                    </FormDrawerCloseButton>
                </FormCloseHandler>
            </Header>
            <Body>
                <Typography>
                    Are you sure about the changes you made?
                </Typography>
                {showWarning && warningText && (
                    <Alert severity="warning">{warningText}</Alert>
                )}
            </Body>
            <Actions>
                <Button variant="outlined" size="small" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" size="small" onClick={onSave}>
                    Confirm and Save
                </Button>
            </Actions>
        </Dialog>
    );
};
