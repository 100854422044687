import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { api } from "../../utils";

export interface IDeleteEntityAttributeOptionParameters {
    optionId: string;
}

export const useDeleteEntityAttributeOption = (
    queryKey: string | string[],
): UseMutationResult<void, unknown, IDeleteEntityAttributeOptionParameters> => {
    const auth = useAuth();
    return useMutation({
        mutationFn: async (
            parameters: IDeleteEntityAttributeOptionParameters,
        ) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot delete entity attribute option without authorization token.",
                );
            }

            return await api.EntityAttributeOption.delete({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
