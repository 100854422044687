import { UseQueryResult, useQuery } from "react-query";

import { IAuthHistory } from "../../types";
import { IExternalListPage } from "../types";
import { api } from "../utils";

import { useAuth } from "./useAuth";

export interface IGetAuthHistoryParameters {
    limit: number;
    cursor: string | null;
    direction: string;
}

export const useGetAuthHistory = (
    phoneNumber: string,
    parameters: IGetAuthHistoryParameters,
    queryKey: string | string[],
): UseQueryResult<IExternalListPage<IAuthHistory>, unknown> => {
    const auth = useAuth();

    return useQuery<IExternalListPage<IAuthHistory>, unknown>(
        queryKey,
        async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch authentication histories without authorization token.",
                );
            }

            return await api.Customer.getAuthHistory(phoneNumber, {
                token: auth.token,
                ...parameters,
            });
        },
    );
};
