import { useMemo } from "react";

import { useGetAllProducts } from "./useGetAllProducts";

interface IGetProductVariantParams {
    key: string | string[];
    variantIds: string[];
}

export const useGetProductVariantByIds = (
    parameters: IGetProductVariantParams,
) => {
    const { key, variantIds } = parameters;
    const products = useGetAllProducts(key);

    const productVariants = useMemo(() => {
        if (products.isSuccess) {
            const variants: Record<string, any> = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const product of Object.values(products.data as any)) {
                // eslint-disable-next-line no-restricted-syntax
                for (const variant of (product as any).variants) {
                    variants[variant.shopifyVariantId] = {
                        ...variant,
                        product,
                    };
                }
            }
            return variantIds.map((id) => variants[id]).filter(Boolean);
        }
        return [];
    }, [products.data, products.isSuccess, variantIds]);

    return { variants: productVariants, isLoading: products.isLoading };
};
