import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#000000",
        },
        secondary: {
            main: "#042442",
        },
    },
    typography: {
        fontFamily: "Poppins, Roboto, Arial",
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    "& > div.MuiDialogContent-root": { paddingTop: "24px" },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
        },
    },
});
