import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { ICart } from "../types";
import { carts } from "../utils/api";

import { useAuth } from "./useAuth";

export interface IUseChangeCartPaymentMethodMutationParameters {
    cartId: string;
    paymentMethod: string;
}

export const useChangeCartPaymentMethod = (
    invalidQueryKeys: string[] = [],
): UseMutationResult<
    ICart,
    unknown,
    IUseChangeCartPaymentMethodMutationParameters
> => {
    const queryClient = useQueryClient();
    const auth = useAuth();

    return useMutation<
        ICart,
        unknown,
        IUseChangeCartPaymentMethodMutationParameters
    >({
        mutationFn: async (
            parameters: IUseChangeCartPaymentMethodMutationParameters,
        ): Promise<ICart> => {
            const { cartId, paymentMethod } = parameters;

            if (!auth.token) {
                throw new Error(
                    "Cannot update payment method without a valid token.",
                );
            }

            return await carts.changePaymentMethod({
                cartId,
                paymentMethod,
                token: auth.token,
            });
        },
        onSuccess: async () => {
            queryClient.invalidateQueries({
                queryKey: invalidQueryKeys,
            });
        },
    });
};
