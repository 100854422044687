import { useMutation } from "react-query";

import { api } from "../utils";

import { useAuth } from "./useAuth";

interface IUseLookupPincodeParameters {
    lookup: number;
}

export const useLookupPincode = () => {
    const auth = useAuth();

    const { mutate, mutateAsync, isLoading, data, error, isError, isSuccess } =
        useMutation({
            mutationFn: async (parameters: IUseLookupPincodeParameters) => {
                if (!auth.token) {
                    throw new Error(
                        "Cannot fetch pincode details without authorization token.",
                    );
                }
                return await api.addresses.getPincodes({
                    ...parameters,
                    token: auth.token,
                });
            },
        });

    return {
        mutate,
        isLoading,
        data,
        error,
        isError,
        isSuccess,
        mutateAsync,
    };
};
