import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import {
    Button,
    Chip,
    FormControl,
    FormHelperText,
    Icon,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    ListItemText as MuiListItemText,
    Select,
    TextField,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import {
    ArrowDropDown,
    InfoRounded,
    Visibility,
    VisibilityOff,
} from "@mui/icons-material";

import * as Yup from "yup";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import {
    CircularLoader,
    MarkdownEdior,
    Paper,
    WorkspaceToolbar,
} from "../../components/common";
import {
    useCreateProduct,
    useGetAllEntitySchemas,
    useGetProduct,
    useUpdateProduct,
} from "../../hooks";
import { useMessage } from "../../sdk/hooks";
import { formatCurrency } from "../../sdk/utils/numbers";
import { IPage, IProduct, IProductVariant } from "../../types";
import { ProductStatus, productStatusByEnum } from "../../utils/enums";

import { PreviewEntitySchema } from "./PreviewEntitySchema";
import { SelectAttributeValue } from "./SelectAttributeValue";
import { VariantDialog } from "./VariantDialog";

const Content = styled("div")`
    padding: ${({ theme }) => theme.spacing(0, 3, 3, 3)};

    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      flex-direction:column;
      gap: ${theme.spacing(3)};
    }
  `}
`;

const Root = styled("main")``;

const LeftContainer = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};
    flex: 1;

    max-width: 800px;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      max-width: unset;
    }
    
  `}
`;

const RightContainer = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};

    max-width: 700px;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          padding-top: 0px;
    }
    
  `}
`;

const Container = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const DescriptionContainer = styled("div")`
    border: 1px solid #c4c4c4;
    border-radius: ${({ theme }) => theme.spacing(0.5)};

    height: 500px;
    overflow: auto;
`;

const TextFieldWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(4)};
`;

const StatusWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const SectionTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    color: #626262;

    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }
    .MuiDataGrid-row:hover {
        cursor: pointer;
    }
`;

const StatusCell = styled("div")`
    text-transform: capitalize;
`;

const TextFieldContainer = styled("div")`
    height: 40px;
    width: 100%;
`;

const SubTitle = styled(Typography)`
    font-weight: 500;
`;

const Row = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    width: 100%;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          flex-direction: column;
    }
  `}
`;

const StyledJsonTextField = styled(TextField)`
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 14px;
    line-height: 1.5;
    padding: ${({ theme }) => theme.spacing(1)};
    background-color: #f5f5f5;
`;

const SelectField = styled(Select)`
    height: 40px;
`;

const ListItemText = styled(MuiListItemText)`
    text-transform: capitalize;
`;

const VariantButtonContainer = styled("div")`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const AddVariantButton = styled(Button)`
    width: max-content;
`;

const CreateSchemaButton = styled(Button)`
    width: max-content;
`;

const StyledArrowDown = styled(ArrowDropDown)`
    margin-right: ${({ theme }) => theme.spacing(3)};
`;

const SelectSchemaContainer = styled(Container)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const columns: GridColDef[] = [
    {
        field: "title",
        headerName: "Title",
        flex: 1,
    },
    {
        field: "shopifyVariantId",
        headerName: "Shopify Variant Id",
        flex: 1,
    },
    {
        field: "price",
        headerName: "Selling Price",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            formatCurrency(params.row.price),
    },
    {
        field: "mrp",
        headerName: "MRP",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            formatCurrency(params.row.mrp),
    },
    {
        field: "sku",
        headerName: "SKU",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            params.row.sku.trim() ?? "NA",
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
            <StatusCell>
                <Chip
                    label={productStatusByEnum[params.row.status]}
                    variant="outlined"
                    size="small"
                />
            </StatusCell>
        ),
    },
];

const variantValidationSchema = Yup.object({
    title: Yup.string().min(1).required("Please enter a valid variant name"),
    shopifyVariantId: Yup.string()
        .min(1)
        .required("Please enter a valid shopify variant identifier"),
    price: Yup.number()
        .integer()
        .required("Please enter a selling price for the variant"),
    shippingPrice: Yup.number()
        .integer()
        .required("Please enter a shipping price for the variant"),
    mrp: Yup.number().integer().required("Please enter a MRP for the variant"),
    sku: Yup.string(),
});

const validationSchema = Yup.object({
    title: Yup.string().min(1).required("Please enter a valid title"),
    description: Yup.string().max(1024),
    tagLine: Yup.string().max(256),
    slug: Yup.string().required("Please enter a valid slug"),
    featureScore: Yup.number().min(0).max(1).required(),
    bestSellerScore: Yup.number().min(0).max(1).required(),
    shopifyProductId: Yup.string()
        .min(1)
        .required("Please enter a Shopify product identifier"),
    variants: Yup.array().of(variantValidationSchema),
    status: Yup.number()
        .integer()
        .oneOf([
            ProductStatus.ARCHIVED,
            ProductStatus.DRAFT,
            ProductStatus.PUBLISHED,
        ])
        .required(),
    bestSeller: Yup.boolean(),
    schemaId: Yup.string(),
    value: Yup.object(),
    meta: Yup.string()
        .required("Metadata is required")
        .test("is-json", "Invalid JSON format", (value) => {
            if (!value) return false;
            try {
                JSON.parse(value);
                return true;
            } catch (error) {
                return false;
            }
        }),
});

const statuses = Object.keys(productStatusByEnum);

export interface ICreateProductSchema extends Omit<IProduct, "meta"> {
    meta: string;
    schemaId?: string;
    value?: object;
}

export const ViewProductScreen: FunctionComponent = (): ReactElement => {
    const params = useParams();
    const create = params.id === "new";
    const productId = params.id;

    const [openEditVariantDialog, setOpenEditVariantDialog] = useState(false);
    const [selectedVariant, setSelectedVariant] =
        useState<IProductVariant | null>(null);
    const [selectedVariantPosition, setSelectedVariantPosition] = useState<
        number | null
    >(null);

    const [initialValues, setInitialValues] = useState<ICreateProductSchema>({
        title: "",
        description: "",
        tagLine: "",
        slug: "",
        featureScore: 1,
        bestSellerScore: 1,
        shopifyProductId: "",
        variants: [],
        status: ProductStatus.DRAFT,
        schemaId: "",
        value: {},
        meta: "{}",
    });

    const getQuery = useGetProduct(
        ["products", params?.id ?? ""],
        params?.id ?? "",
        {
            enabled: params.id !== undefined && params.id !== "new",
            onSuccess: (product) => {
                setInitialValues({
                    title: product.title,
                    description: product.description,
                    tagLine: product.tagLine,
                    slug: product.slug,
                    featureScore: product.featureScore,
                    bestSellerScore: product.bestSellerScore,
                    variants: product.variants,
                    shopifyProductId: product.shopifyProductId,
                    status: product.status,
                    schemaId: product.schemaId ?? "",
                    value: product.value ?? {},
                    meta: JSON.stringify(product.meta),
                });
            },
        },
    );

    const createMutation = useCreateProduct("products");
    const updateMutation = useUpdateProduct("products");

    const [page, setPage] = useState<IPage>({
        limit: 20,
        cursor: null,
        direction: "after",
    });
    const getEntitySchemas = useGetAllEntitySchemas(["getAllEntitySchemas"], {
        limit: page.limit,
        cursor: page.cursor,
        direction: page.direction,
    });

    const navigate = useNavigate();
    const message = useMessage();

    const [selectSchema, setSelectSchema] = useState<string>("");
    const [previewSchema, setPreviewSchema] = useState<boolean>();

    const {
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
        handleBlur,
        dirty,
        isValid,
    } = useFormik<ICreateProductSchema>({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: (values0) => {
            const meta = JSON.parse(values0.meta);

            if (create) {
                createMutation.mutate(
                    {
                        title: values0.title,
                        description: values0.description,
                        tagLine: values0.tagLine.trim(),
                        slug: values0.slug,
                        featureScore: values0.featureScore,
                        bestSellerScore: values0.bestSellerScore,
                        shopifyProductId: values0.shopifyProductId,
                        variants: values0.variants.map((variant0) => ({
                            ...variant0,
                            price: variant0.price * 100,
                            mrp: variant0.mrp * 100,
                            shippingPrice: variant0.shippingPrice * 100,
                        })),
                        status: values0.status,
                        meta,
                        schemaId: values0.schemaId,
                        value: values0.value,
                    },
                    {
                        onSuccess: (product) => {
                            navigate(`/products/${product.id}`, {
                                replace: true,
                            });
                            message.showSuccess("Product created successfully");
                        },
                    },
                );
            } else {
                updateMutation.mutate(
                    {
                        id: params.id ?? "",
                        title: values0.title,
                        description: values0.description,
                        tagLine: values0.tagLine.trim(),
                        slug: values0.slug,
                        featureScore: values0.featureScore,
                        bestSellerScore: values0.bestSellerScore,
                        shopifyProductId: values0.shopifyProductId,
                        status: values0.status,
                        meta,
                        schemaId: values0.schemaId,
                        value: values0.value,
                    },
                    {
                        onSuccess: () => {
                            message.showSuccess("Product updated successfully");
                        },
                    },
                );
            }
        },
    });

    console.log(errors);

    const handleOpenVariantDialog = useCallback(() => {
        if (create) {
            setOpenEditVariantDialog(true);
            setSelectedVariant(null);
            setOpenEditVariantDialog(true);
        } else {
            navigate(`/products/${productId}/variants/new/edit`);
        }
    }, [create, navigate, productId]);

    const handleCloseVariantDialog = useCallback(() => {
        setOpenEditVariantDialog(false);
        setSelectedVariantPosition(null);
    }, []);

    const handleRowClick = useCallback(
        (params: GridRowParams) => {
            if (create) {
                setSelectedVariant(params.row);
                setOpenEditVariantDialog(true);
                setSelectedVariantPosition(params.row.position - 1);
            } else {
                navigate(
                    `/products/${productId}/variants/${params.row.id}/edit`,
                );
            }
        },
        [create, navigate, productId],
    );

    const handleUpdateVariant = useCallback(
        (variant: IProductVariant, index: number) => {
            const updatedVariants: IProductVariant[] = [...values.variants];
            updatedVariants[index] = variant;
            setFieldValue("variants", updatedVariants);
        },
        [setFieldValue, values.variants],
    );

    const handleSave = useCallback(() => handleSubmit(), [handleSubmit]);

    const handleDescriptionChange = useCallback(
        (description: string) => {
            setFieldValue("description", description);
        },
        [setFieldValue],
    );

    const handlePreviewSchema = useCallback(() => {
        setPreviewSchema(!previewSchema);
    }, [previewSchema]);

    const handleSelectSchema = useCallback(
        (schemaId: string) => {
            setSelectSchema(schemaId);
        },
        [setSelectSchema],
    );

    if (getQuery.isLoading && getEntitySchemas.isLoading) {
        return <CircularLoader />;
    }

    return (
        <Root>
            <WorkspaceToolbar
                title={create ? "Create Product" : `Edit Product`}
                enableSearch={false}
                sticky={true}
                breadcrumbs={[
                    { title: "Products", link: "/products" },
                    {
                        title: create
                            ? "Create Product"
                            : getQuery.data?.title ?? "",
                        link: `/products/${params.productId}`,
                    },
                ]}
                actions={
                    <>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="small"
                            endIcon={<Icon>check_circle</Icon>}
                            onClick={handleSave}
                            disabled={!dirty || !isValid}
                            loading={
                                createMutation.isLoading ||
                                updateMutation.isLoading
                            }
                        >
                            Save
                        </LoadingButton>
                    </>
                }
            />

            <Content>
                <LeftContainer>
                    {/* General */}
                    <Paper>
                        <SectionTitle>General</SectionTitle>
                        <TextFieldWrapper>
                            <Row>
                                <TextFieldContainer>
                                    <TextField
                                        required={true}
                                        label="Title"
                                        fullWidth={true}
                                        name="title"
                                        value={values.title}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.title) &&
                                            touched.title
                                        }
                                        helperText={
                                            touched.title && errors.title
                                        }
                                        placeholder="Indian Oud"
                                    />
                                </TextFieldContainer>
                            </Row>
                            <Row>
                                <TextFieldContainer>
                                    <TextField
                                        label="Tag Line"
                                        fullWidth={true}
                                        name="tagLine"
                                        value={values.tagLine}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.tagLine) &&
                                            touched.tagLine
                                        }
                                        helperText={
                                            touched.tagLine && errors.tagLine
                                        }
                                    />
                                </TextFieldContainer>
                            </Row>

                            <DescriptionContainer>
                                <MarkdownEdior
                                    value={values.description}
                                    onChange={handleDescriptionChange}
                                />
                            </DescriptionContainer>
                            <Row>
                                <TextFieldContainer>
                                    <TextField
                                        label="Feature Score"
                                        fullWidth={true}
                                        name="featureScore"
                                        value={values.featureScore}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.featureScore) &&
                                            touched.featureScore
                                        }
                                        helperText={
                                            touched.featureScore &&
                                            errors.featureScore
                                        }
                                    />
                                </TextFieldContainer>
                                <TextFieldContainer>
                                    <TextField
                                        label="Best Seller Score"
                                        fullWidth={true}
                                        name="bestSellerScore"
                                        value={values.bestSellerScore}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.bestSellerScore) &&
                                            touched.bestSellerScore
                                        }
                                        helperText={
                                            touched.bestSellerScore &&
                                            errors.bestSellerScore
                                        }
                                    />
                                </TextFieldContainer>
                            </Row>
                            <Row>
                                <TextFieldContainer>
                                    <TextField
                                        required={true}
                                        label="Shopify Product Identifier"
                                        fullWidth={true}
                                        name="shopifyProductId"
                                        value={values.shopifyProductId}
                                        onChange={handleChange}
                                        size="small"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.shopifyProductId) &&
                                            touched.shopifyProductId
                                        }
                                        helperText={
                                            touched.shopifyProductId &&
                                            errors.shopifyProductId
                                        }
                                    />
                                </TextFieldContainer>
                                <TextFieldContainer />
                            </Row>
                        </TextFieldWrapper>
                    </Paper>
                    {/* SEO  */}

                    {/* Metadata */}
                    <Paper>
                        <SectionTitle>Metadata</SectionTitle>
                        <TextFieldWrapper>
                            <StyledJsonTextField
                                required={true}
                                label="Metadata"
                                multiline={true}
                                rows={12}
                                fullWidth={true}
                                name="meta"
                                value={values.meta}
                                onChange={handleChange}
                                size="small"
                                variant="outlined"
                                onBlur={handleBlur}
                                error={Boolean(errors.meta) && touched.meta}
                                helperText={touched.meta && errors.meta}
                            />
                        </TextFieldWrapper>
                    </Paper>
                    <Paper>
                        <SectionTitle>SEO</SectionTitle>
                        <TextFieldWrapper>
                            <TextFieldContainer>
                                <TextField
                                    required={true}
                                    label="Slug"
                                    fullWidth={true}
                                    name="slug"
                                    value={values.slug}
                                    onChange={handleChange}
                                    size="small"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    error={Boolean(errors.slug) && touched.slug}
                                    helperText={touched.slug && errors.slug}
                                    placeholder="indian-oud"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                ifeelblanko.com/products/
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </TextFieldContainer>
                        </TextFieldWrapper>
                    </Paper>
                    {/* Schema */}
                    {getEntitySchemas.data?.records &&
                        getEntitySchemas.data?.records.length > 0 && (
                            <Paper>
                                <TextFieldWrapper>
                                    <Container>
                                        <SubTitle>Schema</SubTitle>
                                        {getEntitySchemas.data?.records
                                            .length === 0 && (
                                            <Container>
                                                <Typography>
                                                    No entity schemas found.
                                                    Please create one.
                                                </Typography>
                                                <CreateSchemaButton>
                                                    Create Entity Schema
                                                </CreateSchemaButton>
                                            </Container>
                                        )}
                                        <SelectSchemaContainer>
                                            {
                                                <Select
                                                    label="Select Schema"
                                                    name="schemaId"
                                                    size="small"
                                                    defaultValue={
                                                        getEntitySchemas.data
                                                            ?.records[0].name
                                                    }
                                                    fullWidth={true}
                                                    value={values.schemaId}
                                                    onChange={(event) => {
                                                        handleChange(event);
                                                        handleSelectSchema(
                                                            event.target.value,
                                                        );
                                                    }}
                                                    onBlur={handleBlur}
                                                    error={
                                                        Boolean(
                                                            errors.schemaId,
                                                        ) && touched.schemaId
                                                    }
                                                >
                                                    {getEntitySchemas.data?.records.map(
                                                        (schema) => (
                                                            <MenuItem
                                                                key={schema.id}
                                                                value={
                                                                    schema.id
                                                                }
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        schema.name
                                                                    }
                                                                />
                                                            </MenuItem>
                                                        ),
                                                    )}
                                                </Select>
                                            }
                                            <Tooltip title="Preview entity schema">
                                                <IconButton>
                                                    {previewSchema ? (
                                                        <Visibility
                                                            onClick={
                                                                handlePreviewSchema
                                                            }
                                                        />
                                                    ) : (
                                                        <VisibilityOff
                                                            onClick={
                                                                handlePreviewSchema
                                                            }
                                                        />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                        </SelectSchemaContainer>
                                        {selectSchema && (
                                            <SelectAttributeValue
                                                schemaId={selectSchema}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.value}
                                            />
                                        )}
                                    </Container>
                                </TextFieldWrapper>
                            </Paper>
                        )}
                    {/* Variants  */}
                    <Paper>
                        <TextFieldWrapper>
                            <Container>
                                <SubTitle>Variants</SubTitle>
                                {values.variants.length > 0 && (
                                    <StyledDataGrid
                                        rows={values.variants ?? []}
                                        columns={columns}
                                        onRowClick={handleRowClick}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 20,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[10, 20, 30]}
                                        disableRowSelectionOnClick={true}
                                    />
                                )}

                                {values.variants.length >= 0 && (
                                    <VariantButtonContainer>
                                        <AddVariantButton
                                            variant="outlined"
                                            onClick={handleOpenVariantDialog}
                                        >
                                            + Add variants
                                        </AddVariantButton>
                                    </VariantButtonContainer>
                                )}
                            </Container>
                        </TextFieldWrapper>
                    </Paper>
                </LeftContainer>
                <RightContainer>
                    <Paper>
                        <StatusWrapper>
                            <Container>
                                <FormControl
                                    size="small"
                                    fullWidth={true}
                                    required={true}
                                >
                                    <InputLabel>Status</InputLabel>
                                    <SelectField
                                        label="Status"
                                        name="status"
                                        size="small"
                                        fullWidth={true}
                                        IconComponent={(props) => (
                                            <StyledArrowDown {...props} />
                                        )}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Tooltip
                                                    enterTouchDelay={0}
                                                    placement="top"
                                                    title={
                                                        <>
                                                            Status of the
                                                            product. Only
                                                            published product
                                                            are visible on the
                                                            website.
                                                        </>
                                                    }
                                                >
                                                    <InfoRounded
                                                        htmlColor="#626262"
                                                        fontSize="small"
                                                    />
                                                </Tooltip>
                                            </InputAdornment>
                                        }
                                        value={values.status}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                            Boolean(errors.status) &&
                                            touched.status
                                        }
                                    >
                                        {statuses.map((status) => (
                                            <MenuItem
                                                key={status}
                                                value={status}
                                            >
                                                <ListItemText
                                                    primary={
                                                        productStatusByEnum[
                                                            Number(status)
                                                        ]
                                                    }
                                                />
                                            </MenuItem>
                                        ))}
                                    </SelectField>
                                    {Boolean(errors.status) &&
                                        touched.status && (
                                            <FormHelperText>
                                                {errors.status}
                                            </FormHelperText>
                                        )}
                                </FormControl>
                            </Container>
                        </StatusWrapper>
                    </Paper>
                    {previewSchema && (
                        <Paper>
                            <SubTitle>Preview Entity Schema</SubTitle>
                            <PreviewEntitySchema
                                schemaId={values.schemaId ?? ""}
                            />
                        </Paper>
                    )}
                </RightContainer>
                {openEditVariantDialog && (
                    <VariantDialog
                        variants={
                            create
                                ? values.variants
                                : getQuery.data?.variants ?? []
                        }
                        variant={selectedVariant}
                        open={openEditVariantDialog}
                        onClose={handleCloseVariantDialog}
                        onUpdateVariant={handleUpdateVariant}
                        selectedVariantPosition={selectedVariantPosition}
                        productId={params.id ?? ""}
                        createNewProduct={create}
                    />
                )}
            </Content>
        </Root>
    );
};
