import { UseQueryResult, useQuery } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { IExternalListPage } from "../../sdk/types";
import { IEntitySchema, api } from "../../utils";

export interface IGetAllEntitySchemaParameter {
    limit: number;
    cursor: string | null;
    direction: string;
}

export const useGetAllEntitySchemas = (
    queryKey: string | string[],
    parameters: IGetAllEntitySchemaParameter,
): UseQueryResult<IExternalListPage<IEntitySchema>, unknown> => {
    const auth = useAuth();

    return useQuery({
        queryKey,
        queryFn: async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch entity schemas without authorization token.",
                );
            }

            return await api.EntitySchema.getAll({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
