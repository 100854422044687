import axios from "axios";

export interface ICreatePaymentParameters {
    amount: string;
    returnUrl: string;
    description: string;
    orderId: string;
    test: boolean;
    token: string;
}

export const createPayment = async (parameters: ICreatePaymentParameters) => {
    const { token, ...body } = parameters;
    const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/payments/sessions`,
        body,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return response.data;
};

export interface IGetPaymentByOrderIdParameters {
    orderId: string;
    token: string;
}

export const getPaymentByOrderId = async (
    parameters: IGetPaymentByOrderIdParameters,
) => {
    const { token, orderId } = parameters;
    const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/orders/${orderId}/payment`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return response.data;
};

export interface IGetPaymentStatusParameters {
    paymentId: string;
    token: string;
}

export const getPaymentStatus = async (
    parameters: IGetPaymentStatusParameters,
) => {
    const { token, paymentId } = parameters;
    const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/payments/${paymentId}/check-status`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return response.data;
};

export interface IResendPaymentParameters {
    orderId: string;
    token: string;
}

export const resendPaymentLink = async (
    parameters: IResendPaymentParameters,
) => {
    const { token, orderId } = parameters;
    const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/orders/${orderId}/resend-payment`,
        {},
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return response.data;
};
