import { UseMutationResult, useMutation } from "react-query";

import { Authentication } from "../../sdk/utils/api/auth";

export interface IAcceptInvitationParameter {
    token: string;
}

export const useAcceptInvitation = (): UseMutationResult<
    void,
    unknown,
    IAcceptInvitationParameter
> => {
    return useMutation({
        mutationFn: async (parameters: IAcceptInvitationParameter) => {
            return await Authentication.acceptInvitation({
                ...parameters,
            });
        },
    });
};
