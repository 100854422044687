import axios from "axios";

import { EntityAttributeType } from "../enums";
import { IEntityAttribute } from "../types";

export interface ICreateEntityAttributeParams {
    token: string;
    name: string;
    groupId: string;
    type: EntityAttributeType;
    required: boolean;
    exlusive?: boolean;
    min?: number;
    max?: number;
    default?: JSON;
    pattern?: string;
}

export interface IUpdateEntityAttributeParameter {
    token: string;
    name: string;
    type: EntityAttributeType;
    required: boolean;
    groupId: string;
    exlusive?: boolean;
    min?: number;
    max?: number;
    default?: JSON;
    pattern?: string;
    attributeId: string;
}

export interface IDeleteEntityAttributeParameter {
    token: string;
    attributeId: string;
}

export class EntityAttribute {
    private static transform(data: IEntityAttribute): IEntityAttribute {
        return {
            ...data,
            createdAt: new Date(data.createdAt),
            updatedAt: new Date(data.updatedAt),
        };
    }

    public static async create(
        parameters: ICreateEntityAttributeParams,
    ): Promise<IEntityAttribute> {
        const {
            token,
            name,
            groupId,
            type,
            required,
            exlusive,
            min,
            max,
            pattern,
        } = parameters;
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups/attributes`,
            {
                name,
                groupId,
                type,
                required,
                exlusive,
                min,
                max,
                pattern,
                default: parameters.default,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        return this.transform(data);
    }

    public static async getById(
        attributeId: string,
        token: string,
    ): Promise<IEntityAttribute> {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups/attributes/${attributeId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        return this.transform(data);
    }

    public static async getAllByGroupId(
        token: string,
        groupId: string,
    ): Promise<IEntityAttribute[]> {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups/attributes/${groupId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return data.map(this.transform);
    }

    public static async delete(
        parameters: IDeleteEntityAttributeParameter,
    ): Promise<void> {
        const { attributeId, token } = parameters;
        await axios.delete(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups/attributes/${attributeId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
    }

    public static async update(
        parameters: IUpdateEntityAttributeParameter,
    ): Promise<IEntityAttribute> {
        const {
            token,
            attributeId,
            name,
            type,
            required,
            groupId,
            exlusive,
            min,
            max,
            pattern,
        } = parameters;
        const { data } = await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/groups/attributes/${attributeId}`,
            {
                name,
                type,
                required,
                groupId,
                exlusive,
                min,
                max,
                pattern,
                default: parameters.default,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return this.transform(data);
    }
}
