import { useContext } from "react";

import { MessageContext } from "../contexts";
import { IMessageContext } from "../types";

export const useMessage = (): IMessageContext => {
    const context = useContext(MessageContext);

    if (!context) {
        throw new Error("Cannot find message context!");
    }

    return context;
};
