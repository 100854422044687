import { UseQueryResult, useQuery } from "react-query";

import { ICustomerGroup, IExternalListPage } from "../types";
import { api } from "../utils";

import { useAuth } from "./useAuth";

export interface IFetchAllCustomerGroupsParameters {
    limit: number;
    cursor: string | null;
    direction: string;
}

export const useGetAllCustomerGroups = (
    queryKey: string | string[],
    parameters: IFetchAllCustomerGroupsParameters,
): UseQueryResult<IExternalListPage<ICustomerGroup>, unknown> => {
    const auth = useAuth();

    return useQuery({
        queryKey,
        queryFn: async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch customer groups without authorization token.",
                );
            }

            return await api.CustomerGroup.getAll({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
