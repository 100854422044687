import { UseQueryResult, useQuery } from "react-query";

import { useAuth } from "../../sdk/hooks/useAuth";
import { IAdminGroup, IExternalListPage } from "../../sdk/types";
import { adminGroups } from "../../sdk/utils/api";

export const useGetAdminGroups = (): UseQueryResult<
    IExternalListPage<IAdminGroup>
> => {
    const auth = useAuth();

    return useQuery<IExternalListPage<IAdminGroup>>("adminGroups", async () => {
        if (!auth.token) {
            throw new Error(
                "Cannot fetch admin groups without authorization token.",
            );
        }

        return await adminGroups.AdminGroup.list(auth.token);
    });
};
