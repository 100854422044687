import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import {
    Divider,
    List as MuiList,
    ListItemButton as MuiListItemButton,
    ListItemText as MuiListItemText,
    Typography,
    styled,
} from "@mui/material";

import { ImageNotSupported } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import { Paper, StatusChip } from "../../components/common";
import { IProductVariant } from "../../types";
import { productStatusByEnum, productStatusNames } from "../../utils/enums";

const Root = styled("div")`
    min-width: 300px;

    border: 1px solid #d9d9d9;
    border-radius: ${({ theme }) => theme.spacing(1)};
`;

const Container = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const List = styled(MuiList)`
    padding-bottom: 0;
`;

const ListItemButton = styled(MuiListItemButton)`
    width: 100%;
`;

const TextContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};
    flex-direction: column;
`;

const ChipContainer = styled("div")`
    width: fit-content;
`;

interface IListItemTextProps {
    selected: boolean;
}

const ListItemText = styled(MuiListItemText, {
    shouldForwardProp: (propName) => propName !== "value",
})<IListItemTextProps>`
    flex: 0;
    margin: 0;

    & .MuiTypography-root {
        font-size: 14px;
        font-weight: ${({ selected }) => (selected ? "500" : "400")};
    }
`;

const Title = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    padding: ${({ theme }) => theme.spacing(1, 2)};
`;

const ProductImage = styled("img")`
    height: 50px;
    width: 50px;
    object-fit: cover;

    border-radius: ${({ theme }) => theme.spacing(1)};
`;

const BrokenImage = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;

    aspect-ratio: 5/6;
    height: 50px;
    width: 50px;
    background-color: #fff;

    border: 2px dashed rgba(235, 235, 235, 1);
    border-radius: ${({ theme }) => theme.spacing(1)};
`;

export interface IProductContainerProps {
    variants: IProductVariant[];
    currentVariantId: string;
    productId: string;
}

export const VariantContainer: FunctionComponent<IProductContainerProps> = (
    props: IProductContainerProps,
): ReactElement => {
    const { variants, currentVariantId, productId } = props;

    const navigate = useNavigate();

    const handleChangeVariant = useCallback(
        (variantId: string) => {
            navigate(`/products/${productId}/variants/${variantId}/edit`);
        },
        [navigate, productId],
    );

    return (
        <Root>
            <>
                <Title>Variants</Title>
                <List>
                    {variants.map((variant) => (
                        <ListItemButton
                            selected={currentVariantId === variant.id}
                            onClick={() => handleChangeVariant(variant.id)}
                        >
                            <Container>
                                {variant?.images?.[0]?.objectUrl && (
                                    <ProductImage
                                        src={variant.images[0].objectUrl}
                                        alt={variant.title}
                                    />
                                )}
                                {!variant?.images?.[0]?.objectUrl && (
                                    <BrokenImage>
                                        <ImageNotSupported />
                                    </BrokenImage>
                                )}
                                <TextContainer>
                                    <ListItemText
                                        selected={
                                            currentVariantId === variant.id
                                        }
                                        primary={variant.title}
                                    />
                                    <ChipContainer>
                                        <StatusChip
                                            label={
                                                productStatusByEnum[
                                                    variant.status
                                                ]
                                            }
                                            value={variant.status}
                                        />
                                    </ChipContainer>
                                </TextContainer>
                            </Container>
                        </ListItemButton>
                    ))}
                </List>
            </>
        </Root>
    );
};
