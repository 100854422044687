import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { IEntityGroup, api } from "../../utils";

export interface ICreateEntityGroupParameter {
    name: string;
    description: string;
    entitySchemaId: string;
}

export const useCreateEntityGroup = (
    queryKey: string | string[],
): UseMutationResult<IEntityGroup, unknown, ICreateEntityGroupParameter> => {
    const auth = useAuth();
    return useMutation({
        mutationFn: async (parameters: ICreateEntityGroupParameter) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot create entity group without authorization token.",
                );
            }

            return await api.EntityGroup.create({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
