import { useMutation } from "react-query";

import { useAuth, useMessage } from "../../sdk/hooks";
import { PriceRuleAllocationMethod } from "../../sdk/types";
import { api } from "../../sdk/utils";
import {
    CustomerSelection,
    DiscountType,
    PriceRuleCategoryType,
    PriceRuleStatus,
} from "../../utils/enums";

export interface IUpdatePriceRuleParameter {
    allocationMethod: PriceRuleAllocationMethod;
    customerSelection: CustomerSelection;
    prereqCustomerGroupIds: string[];
    discountType: DiscountType;
    startsAt: Date;
    endsAt: Date | null;
    usageLimitByCustomer: number | null;
    usageLimitByTotal: number | null;
    discountValue: number;
    prereqQuantityMin: number | null;
    prereqSubtotalMin: number | null;
    id: string;
    name: string;
    description: string;
    category: PriceRuleCategoryType;
    prereqPaymentMethod: string | null;
    prereqVariantsRequired: number;
    variantIds: string[];
    status: PriceRuleStatus;
    maxDiscountAmount: number | null;
}

export const useUpdatePriceRule = (queryKey: string | string[]) => {
    const auth = useAuth();
    const { showError, showSuccess } = useMessage();
    return useMutation({
        mutationFn: async (parameters: IUpdatePriceRuleParameter) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot update price rule without authorization token.",
                );
            }

            return await api.priceRule.updatePriceRule({
                ...parameters,
                token: auth.token,
            });
        },
        onSuccess: () => {
            showSuccess("Discount updated successfully");
        },
        onError: () => {
            showError("Something went wrong");
        },
    });
};
