import { FunctionComponent, ReactElement, useCallback } from "react";

import { Button, Typography, styled } from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";

import { useAcceptInvitation } from "../../hooks";
import { useMessage } from "../../sdk/hooks";

const Root = styled("main")`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: ${({ theme }) => theme.spacing(3)};
    gap: ${({ theme }) => theme.spacing(3)};
`;

const AcceptInvitationButton = styled(Button)`
    max-width: 500px;
`;

export const AcceptInvitationScreen: FunctionComponent = (): ReactElement => {
    const useAcceptInvitationMutation = useAcceptInvitation();
    const { search } = useLocation();
    const token = new URLSearchParams(search).get("token");
    const navigate = useNavigate();
    const { showError, showSuccess } = useMessage();

    const handleAcceptInvitation = useCallback(async () => {
        if (token) {
            useAcceptInvitationMutation.mutate(
                { token },
                {
                    onSuccess: () => {
                        showSuccess("Invitation accepted successfully!");
                        navigate("/login");
                    },
                    onError: () => {
                        showError("Something went wrong!");
                    },
                },
            );
        }
    }, [navigate, showError, showSuccess, token, useAcceptInvitationMutation]);

    return (
        <Root>
            <Typography variant="h6">
                Click on the button below to accept the invitation
            </Typography>
            <AcceptInvitationButton
                variant="contained"
                color="primary"
                onClick={handleAcceptInvitation}
            >
                Accept Invitation
            </AcceptInvitationButton>
        </Root>
    );
};
