import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import {
    Button,
    ButtonBase,
    Dialog,
    DialogContent,
    DialogTitle,
    InputAdornment,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { ChevronRight } from "@mui/icons-material";

import { X } from "@phosphor-icons/react";

import {
    IAnnotatedPriceRule,
    ICart,
    IDiscountCodeWithDescription,
} from "../../../../sdk/types";
import { PriceRuleValidity } from "../../../../sdk/utils";

import { Discount } from "./Discount";

const Header = styled(DialogTitle)`
    width: 100%;
    background-color: #f0f0f0;

    display: flex;
    justify-content: space-between;
    align-items: start;
`;

const FormCloseHandler = styled("div")`
    display: flex;
    justify-content: center;
`;

const FormDrawerCloseButton = styled(ButtonBase)`
    width: 32px;
    height: 32px;
    border-radius: 50%;
`;

const Title = styled(Typography)`
    line-height: 150%;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: #3a3a3a;

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        font-size: 20px;
    }
    `}
`;

const StyledDialogContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${({ theme }) => theme.spacing(2)};

    max-height: 450px;
`;

const StyledButton = styled(Button)`
    color: gray;
    text-transform: none;
`;

const InputContainer = styled("div")`
    padding: ${({ theme }) => theme.spacing(1)};
`;

export interface IDiscountListProps {
    allDiscountCodes: IAnnotatedPriceRule[];
    applicableDiscountCodes: IDiscountCodeWithDescription[];
    appliedDiscount: string;
    isDiscountApplied: boolean;
    onApply: (code?: string) => void;
    onRemove: () => void;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    description: string;
    error: string;
    loading: boolean;
    cart: ICart;
}

export const DiscountList: FunctionComponent<IDiscountListProps> = (
    props: IDiscountListProps,
): ReactElement => {
    const {
        applicableDiscountCodes,
        allDiscountCodes,
        appliedDiscount,
        isDiscountApplied,
        description,
        loading,
        onApply,
        onRemove,
        onChange,
        value,
        error,
        cart,
    } = props;
    const [open, setOpen] = useState(false);

    const handleToggleDialog = useCallback(
        () => setOpen((oldOpen) => !oldOpen),
        [],
    );

    const handleApplyDiscount = useCallback(
        () => onApply(value),
        [onApply, value],
    );

    return (
        <>
            {cart && (
                <Dialog
                    open={open}
                    maxWidth={"xs"}
                    fullWidth={true}
                    onClose={handleToggleDialog}
                >
                    <Header>
                        <Title>Available Coupons</Title>
                        <FormCloseHandler>
                            <FormDrawerCloseButton onClick={handleToggleDialog}>
                                <X size={16} />
                            </FormDrawerCloseButton>
                        </FormCloseHandler>
                    </Header>
                    <InputContainer>
                        <TextField
                            id="discounts--input--discount-code"
                            placeholder="COUPON CODE"
                            fullWidth={true}
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!isDiscountApplied && (
                                            <Button
                                                onClick={handleApplyDiscount}
                                                disabled={!value || loading}
                                            >
                                                APPLY
                                            </Button>
                                        )}
                                        {isDiscountApplied && (
                                            <Button
                                                onClick={onRemove}
                                                disabled={loading}
                                            >
                                                Remove
                                            </Button>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                            disabled={isDiscountApplied}
                            value={isDiscountApplied ? appliedDiscount : value}
                            onChange={onChange}
                            helperText={error !== "" ? error : description}
                            color={error ? "error" : "primary"}
                        />
                    </InputContainer>
                    <StyledDialogContent dividers={true}>
                        {allDiscountCodes.map((discount) => (
                            <Discount
                                key={discount.id}
                                reason={PriceRuleValidity.getReason(
                                    discount,
                                    cart,
                                )}
                                discount={discount}
                                onApply={onApply}
                                onRemove={onRemove}
                                appliedDiscount={appliedDiscount}
                                isDiscountApplied={isDiscountApplied}
                                setOpen={setOpen}
                                applicable={Boolean(
                                    applicableDiscountCodes.find(
                                        (applicableDiscount) =>
                                            applicableDiscount.code ===
                                            discount.discounts[0]?.code,
                                    ),
                                )}
                            />
                        ))}
                    </StyledDialogContent>
                </Dialog>
            )}
            {allDiscountCodes.length === 0 && (
                <InputContainer>
                    <TextField
                        id="discounts--input--discount-code"
                        placeholder="COUPON CODE"
                        fullWidth={true}
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {!isDiscountApplied && (
                                        <Button
                                            onClick={handleApplyDiscount}
                                            disabled={!value || loading}
                                        >
                                            APPLY
                                        </Button>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                        disabled={isDiscountApplied}
                        value={isDiscountApplied ? appliedDiscount : value}
                        onChange={onChange}
                        helperText={error !== "" ? error : description}
                        color={error ? "error" : "primary"}
                    />
                </InputContainer>
            )}
            {allDiscountCodes.length > 0 && (
                <StyledButton
                    onClick={handleToggleDialog}
                    endIcon={<ChevronRight />}
                >
                    {`View ${allDiscountCodes.length} available coupons`}
                </StyledButton>
            )}
        </>
    );
};
