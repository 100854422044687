import {
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from "react-query";

import { useAuth } from "../../sdk/hooks";
import { ICustomerReviewModeration } from "../../sdk/types";
import { api } from "../../sdk/utils";

type TUseQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryKey extends QueryKey,
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "queryFn">;

export interface IUseGetModerationList {
    limit?: number;
    reviewId: string;
    options?: TUseQueryOptions<
        any,
        unknown,
        ICustomerReviewModeration[],
        string[]
    >;
}

export const useGetModerationList = (
    parameters: IUseGetModerationList,
): UseQueryResult<ICustomerReviewModeration[]> => {
    const { limit, reviewId, options } = parameters;
    const auth = useAuth();

    return useQuery({
        queryKey: options?.queryKey ?? [],
        queryFn: async (props) => {
            const { pageParam = "" } = props;

            return await api.v1_1.CustomerReviews.listModerationActivity({
                token: auth.token,
                limit: limit ?? 200,
                cursor: pageParam,
                reviewId,
            });
        },
        ...options,
    });
};
