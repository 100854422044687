import { FunctionComponent, ReactElement, useCallback } from "react";

import {
    ButtonBase,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { ChevronRight, Close } from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik } from "formik";
import { browserName } from "react-device-detect";

import { useLookupPincode } from "../../../../sdk/hooks";
import {
    IAddressFormValues,
    ICustomer,
    ICustomerAddress,
    IPincodeMapping,
} from "../../../../sdk/types";
import { stateCodes } from "../../../../utils/constants";

const Root = styled("section")`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        align-items: stretch;
    }
    `}
`;

const Header = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const FormCloseHandler = styled("div")`
    display: flex;
    justify-content: center;
`;

const FormDrawerCloseButton = styled(ButtonBase)`
    width: 32px;
    height: 32px;
    border-radius: 50%;
`;

const FormContainer = styled("form")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};
    height: fit-content;
    margin-top: ${({ theme }) => theme.spacing(5)};
    margin-bottom: ${({ theme }) => theme.spacing(5)};

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        gap: ${theme.spacing(1.5)};
        margin-top: ${theme.spacing(2)};
    }
    `}
`;

const InputField = styled(TextField)`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    width: 100%;

    & label.Mui-focused {
        color: #a3a3a3;
    }
    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: #a3a3a3;
        }
    }

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        font-size: 12px;
        line-height: 16px;
    }
    `}
`;

const SelectField = styled(Select)`
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    width: 200px;

    & .MuiSelect-select.label {
        color: #a3a3a3;
    }
    & .MuiSelect-select {
        &.Mui-focused fieldset {
            border-color: #a3a3a3;
        }
    }

    ${({ theme }) => `
        ${theme.breakpoints.down("sm")}{
            font-size: 12px;
            line-height: 16px;

            width: 100%;
        }
    `}
`;

const SelectInputLabel = styled(InputLabel)``;

const SelectMenuItem = styled(MenuItem)`
    .MuiMenuItem-root {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
    }
`;

const Container = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        flex-direction: column;
        align-items: stretch;
        gap: ${theme.spacing(1.5)};
    }
    `}
`;

const ErrorMessage = styled(Typography)`
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: red;
`;

const SaveButton = styled(LoadingButton)`
    margin: auto;
    margin-top: ${({ theme }) => theme.spacing(3)};
`;

const Title = styled(Typography)`
    line-height: 150%;
    font-size: 28px;
    font-weight: 400;
    text-align: center;
    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        font-size: 20px;
    }
    `}
`;

const InputHolder = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const phoneNumberPattern = /^\d{10}$/;

const emailAddressPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

const namePattern = /^[a-zA-Z ]{1,40}$/;

const pincodePattern = /^[1-9][0-9]{5}$/;

const addressPattern = /^[a-zA-Z0-9\s.,'#-/\\]*$/;

const whiteSpacePattern = /^((?![\r\n\t]|\s{2,}).)*$/;

const addressFormSchema = Yup.object({
    addressLine1: Yup.string()
        .min(1)
        .max(128)
        .matches(
            addressPattern,
            "The specified address line contains invalid characters",
        )
        .matches(
            whiteSpacePattern,
            "The address line should not contain newlines, tabs, or consecutive spaces",
        )
        .required("Enter your address"),
    addressLine2: Yup.string()
        .min(0)
        .max(128)
        .matches(
            addressPattern,
            "The specified address line contains invalid characters",
        )
        .matches(
            whiteSpacePattern,
            "The address line should not contain newlines, tabs, or consecutive spaces",
        )
        .default(""),
    city: Yup.string()
        .min(2)
        .max(100)
        .matches(
            addressPattern,
            "The specified city contains invalid characters",
        )
        .matches(
            whiteSpacePattern,
            "The address line should not contain newlines, tabs, or consecutive spaces",
        )
        .required("Enter your city")
        .trim("The city cannot include leading and trailing spaces")
        .strict(true),
    company: Yup.string()
        .min(1)
        .max(40)
        .matches(
            addressPattern,
            "The specified company contains invalid characters",
        )
        .matches(
            whiteSpacePattern,
            "The address line should not contain newlines, tabs, or consecutive spaces",
        )
        .default(""),
    firstName: Yup.string()
        .matches(
            namePattern,
            "The specified first name contains invalid characters",
        )
        .min(1)
        .max(40)
        .required("Enter your first name"),
    lastName: Yup.string()
        .matches(
            namePattern,
            "The specified last name contains invalid characters",
        )
        .min(1)
        .max(40)
        .required("Enter your last name"),
    phoneNumber: Yup.string()
        .matches(phoneNumberPattern, "The specified phone number is invalid.")
        .required("Enter a valid phone number"),
    alternatePhoneNumber: Yup.string().matches(
        phoneNumberPattern,
        "The specified alternate phone number is invalid.",
    ),
    emailAddress: Yup.string()
        .min(2)
        .max(100)
        .required("Enter your email")
        .email()
        .matches(emailAddressPattern, "Enter a valid email address"),
    stateCode: Yup.string().min(1).max(8).required("Enter your state code"),
    zip: Yup.string()
        .matches(pincodePattern, "The specified pincode is invalid")
        .min(1)
        .max(16)
        .required("Enter your pincode"),
    defaultAddress: Yup.boolean().default(false),
    name: Yup.string()
        .min(1)
        .max(24)
        .matches(
            addressPattern,
            "The specified address line contains invalid characters",
        )
        .matches(
            whiteSpacePattern,
            "The address line should not contain newlines, tabs, or multiple spaces",
        )
        .required("Enter the address name"),
});

export interface IAddressFormProps {
    oldAddress?: ICustomerAddress;
    saving: boolean;
    onSave: (values: IAddressFormValues, id?: string) => void;
    onClose: () => void;
    closeable: boolean;
    customer: ICustomer;
}

/**
 * NOTE: This will break for foreign phone numbers!
 */
const normalizePhoneNumber = (phoneNumber?: string): string => {
    if (
        phoneNumber &&
        phoneNumber.length >= 3 &&
        phoneNumber.substring(0, 3) === "+91"
    ) {
        return phoneNumber.substring(3);
    }
    return phoneNumber ?? "";
};

export const AddressForm: FunctionComponent<IAddressFormProps> = (
    props: IAddressFormProps,
): ReactElement => {
    const { onSave, oldAddress, saving, onClose, closeable, customer } = props;
    const lookupPincodeMutation = useLookupPincode();

    const {
        values,
        isValid,
        errors,
        touched,
        status,
        setStatus,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
    } = useFormik({
        initialValues: oldAddress
            ? {
                  ...oldAddress,
                  phoneNumber: normalizePhoneNumber(oldAddress.phoneNumber),
                  alternatePhoneNumber: normalizePhoneNumber(
                      oldAddress.alternatePhoneNumber,
                  ),
              }
            : {
                  addressLine1: "",
                  addressLine2: "",
                  city: "",
                  countryCode: "IND",
                  company: "unknown",
                  firstName: customer?.firstName || "",
                  lastName: customer?.lastName || "",
                  phoneNumber: customer?.phoneNumber.slice(3) || "",
                  alternatePhoneNumber: "",
                  emailAddress: customer?.emailAddress || "",
                  stateCode: "",
                  zip: "",
                  name: "unknown",
                  defaultAddress: false,
              },
        onSubmit: (values0: IAddressFormValues) => {
            onSave(
                {
                    ...values0,
                    phoneNumber: `+91${values0.phoneNumber}`,
                    alternatePhoneNumber: values0.alternatePhoneNumber
                        ? `+91${values0.alternatePhoneNumber}`
                        : "",
                },
                oldAddress?.id,
            );
        },
        validationSchema: addressFormSchema,
    });

    const handleUpdatePincode = useCallback(
        (event: any) => {
            const newZip = event.target.value;

            if (newZip.length === 6) {
                lookupPincodeMutation.mutate(
                    {
                        lookup: parseInt(newZip, 10),
                    },
                    {
                        onSuccess: (mapping: IPincodeMapping) => {
                            setFieldValue("city", mapping.district);
                            const stateNameByCode = Object.fromEntries(
                                Object.entries(stateCodes).map(
                                    ([code, name]) => [
                                        name.toLowerCase(),
                                        code,
                                    ],
                                ),
                            );

                            /*
                             * The reason why have used `setTimeout` here is because `setFieldValue` is async in nature.
                             * And since two states are updated back to back we get stale closure update.
                             * All the state updates are not bashed into one.
                             * Hence we delay the state update of the second variable.
                             */
                            setTimeout(
                                () =>
                                    setFieldValue(
                                        "stateCode",
                                        stateNameByCode[
                                            mapping.state.toLowerCase()
                                        ],
                                    ),
                                0,
                            );
                            setStatus({
                                zip: undefined,
                            });
                        },
                        onError: () => {
                            setStatus({
                                zip: "The specified pincode is invalid.",
                            });
                        },
                    },
                );
            }

            handleChange(event);
        },
        [handleChange, lookupPincodeMutation, setFieldValue, setStatus],
    );

    const autoCompleteValue = browserName === "Chrome" ? "chrome-off" : "off";

    const handleContinue = useCallback(async () => {
        handleSubmit();
    }, [handleSubmit]);

    return (
        <Root>
            <Header>
                <Title>Create Delivery Address</Title>
                <FormCloseHandler>
                    {closeable && (
                        <FormDrawerCloseButton onClick={onClose}>
                            <Close />
                        </FormDrawerCloseButton>
                    )}
                </FormCloseHandler>
            </Header>
            {/* Firefox honors autoComplete="off" on form, but not on invidual fields.
             * Chrome does not honor autoComplete="off", but on individual fields with
             * autoComplete="chrome-off".
             */}
            <FormContainer autoComplete="off">
                <InputHolder>
                    <InputField
                        id="address-form--input--first-name"
                        type="text"
                        label="First Name"
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="firstName"
                        value={values.firstName}
                        autoComplete={autoCompleteValue}
                        required={true}
                    />
                    {errors.firstName && touched.firstName && (
                        <ErrorMessage>{errors.firstName}</ErrorMessage>
                    )}
                </InputHolder>

                <InputHolder>
                    <InputField
                        id="address-form--input--last-name"
                        type="text"
                        label="Last Name"
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="lastName"
                        value={values.lastName}
                        autoComplete={autoCompleteValue}
                        required={true}
                    />
                    {errors.lastName && touched.lastName && (
                        <ErrorMessage>{errors.lastName}</ErrorMessage>
                    )}
                </InputHolder>

                <InputHolder>
                    <InputField
                        id="address-form--input--address-line1"
                        type="text"
                        label="Address Line 1"
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="addressLine1"
                        value={values.addressLine1}
                        autoComplete={autoCompleteValue}
                        required={true}
                    />
                    {errors.addressLine1 && touched.addressLine1 && (
                        <ErrorMessage>{errors.addressLine1}</ErrorMessage>
                    )}
                </InputHolder>

                <InputHolder>
                    <InputField
                        id="address-form--input--address-line2"
                        label="Address Line 2"
                        type="text"
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="addressLine2"
                        value={values.addressLine2}
                        autoComplete={autoCompleteValue}
                    />
                    {errors.addressLine2 && touched.addressLine2 && (
                        <ErrorMessage>{errors.addressLine2}</ErrorMessage>
                    )}
                </InputHolder>

                <Container>
                    <InputHolder>
                        <InputField
                            id="address-form--input--pin-code"
                            type="string"
                            label="Pincode"
                            size="small"
                            onChange={handleUpdatePincode}
                            onBlur={handleBlur}
                            name="zip"
                            value={values.zip}
                            autoComplete={autoCompleteValue}
                            required={true}
                        />
                        {errors.zip && touched.zip && (
                            <ErrorMessage>{errors.zip}</ErrorMessage>
                        )}
                        {!errors.zip && status?.zip && touched.zip && (
                            <ErrorMessage>{status.zip}</ErrorMessage>
                        )}
                    </InputHolder>

                    <InputHolder>
                        <InputField
                            id="address-form--input--city"
                            type="text"
                            label="City"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="city"
                            value={values.city}
                            autoComplete={autoCompleteValue}
                            required={true}
                        />
                        {errors.city && touched.city && (
                            <ErrorMessage>{errors.city}</ErrorMessage>
                        )}
                    </InputHolder>

                    <InputHolder>
                        <FormControl>
                            <SelectInputLabel
                                size="small"
                                id="state-select-label"
                                required={true}
                            >
                                State
                            </SelectInputLabel>
                            <SelectField
                                name="stateCode"
                                label="State"
                                labelId="state-select-label"
                                id="state-select"
                                size="small"
                                variant="outlined"
                                value={values.stateCode}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            >
                                {Object.entries(stateCodes).map((item) => (
                                    <SelectMenuItem
                                        key={item[0]}
                                        value={item[0]}
                                    >
                                        {item[1]}
                                    </SelectMenuItem>
                                ))}
                            </SelectField>
                        </FormControl>
                        {errors.stateCode && touched.stateCode && (
                            <ErrorMessage>{errors.stateCode}</ErrorMessage>
                        )}
                    </InputHolder>
                </Container>

                <InputHolder>
                    <InputField
                        id="address-form--input--email-address"
                        type="email"
                        label="Email Address"
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="emailAddress"
                        value={values.emailAddress}
                        autoComplete={autoCompleteValue}
                        required={true}
                    />
                    {errors.emailAddress && touched.emailAddress && (
                        <ErrorMessage>{errors.emailAddress}</ErrorMessage>
                    )}
                </InputHolder>

                <InputHolder>
                    <InputField
                        id="address-form--input--phone-number"
                        type="tel"
                        label="Phone Number"
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="phoneNumber"
                        value={values.phoneNumber}
                        autoComplete={autoCompleteValue}
                        required={true}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    +91
                                </InputAdornment>
                            ),
                        }}
                    />
                    {errors.phoneNumber && touched.phoneNumber && (
                        <ErrorMessage>{errors.phoneNumber}</ErrorMessage>
                    )}
                </InputHolder>

                <InputHolder>
                    <InputField
                        id="address-form--input--alternate-phone-number"
                        type="tel"
                        label="Alternate Phone Number"
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="alternatePhoneNumber"
                        value={values.alternatePhoneNumber}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    +91
                                </InputAdornment>
                            ),
                        }}
                        autoComplete={autoCompleteValue}
                    />
                    {errors.alternatePhoneNumber &&
                        touched.alternatePhoneNumber && (
                            <ErrorMessage>
                                {errors.alternatePhoneNumber}
                            </ErrorMessage>
                        )}
                </InputHolder>
            </FormContainer>

            <SaveButton
                id="address-form--button--continue"
                variant="contained"
                onClick={handleContinue}
                disabled={saving || !isValid}
                loading={saving}
                loadingPosition="end"
                endIcon={<ChevronRight />}
            >
                Continue
            </SaveButton>
        </Root>
    );
};
