import { FunctionComponent, ReactElement, useCallback } from "react";

import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    Typography,
    styled,
} from "@mui/material";

import { ChevronRight, ShoppingBag } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import { ICart } from "../../../sdk/types";
import { formatCurrency } from "../../../sdk/utils/numbers";

const Cart = styled(ListItem)`
    border: 1px solid rgba(0, 0, 0, 0.2);

    cursor: pointer;
`;

const CartDetail = styled("div")`
    font-size: 14px;
    display: flex;
    gap: ${({ theme }) => theme.spacing(0.5)};

    ${({ theme }) => theme.breakpoints.down("md")} {
        flex-direction: column;
        gap: 0;
    }
`;

const CartDetailLabel = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
    color: #7b7b7b;
`;

const CartDetailValue = styled(Typography)`
    font-size: 16px;
    font-weight: 400;
    color: #222222;
`;

const ListContents = styled("div")`
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.down("md")} {
        gap: ${({ theme }) => theme.spacing(0.5)};
    }
`;

interface IActiveCartProps {
    cart: ICart;
}

export const ActiveCart: FunctionComponent<IActiveCartProps> = (
    props: IActiveCartProps,
): ReactElement => {
    const { cart } = props;
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate(`/carts/${cart.id}`);
    }, [cart.id, navigate]);

    const getCartItemsString = () => {
        const items = cart.items
            .slice(0, 3)
            .map((item) => item.title)
            .join(", ");

        const additionalItemsLength = cart.items.length - 3;

        const additional =
            additionalItemsLength > 0
                ? `+${additionalItemsLength} products`
                : "";

        return `${items} ${additional}`;
    };

    return (
        <Cart
            onClick={handleClick}
            secondaryAction={
                <IconButton edge="end" aria-label="delete">
                    <ChevronRight />
                </IconButton>
            }
        >
            <ListItemAvatar>
                <Avatar>
                    <ShoppingBag />
                </Avatar>
            </ListItemAvatar>
            <ListContents>
                <CartDetail>
                    <CartDetailLabel>Customer:</CartDetailLabel>
                    <CartDetailValue>{`${cart.customer?.firstName} ${cart.customer?.lastName}`}</CartDetailValue>
                    <CartDetailValue>
                        {cart.customer?.phoneNumber}
                    </CartDetailValue>
                </CartDetail>
                <CartDetail>{`${cart.items.length} ${
                    cart.items.length === 1 ? "item" : "items"
                }`}</CartDetail>
                <CartDetail>{`${getCartItemsString()}`}</CartDetail>
                {formatCurrency(cart.totalSellingPrice)}
            </ListContents>
        </Cart>
    );
};
