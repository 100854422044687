import { FunctionComponent, ReactElement } from "react";

import { CircularProgress, Typography, styled } from "@mui/material";

const ProgressContainer = styled("div")`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: calc(100vh - 16px);
    width: 100%;

    row-gap: ${({ theme }) => theme.spacing(2)};
`;

export const CircularLoader: FunctionComponent = (): ReactElement => {
    return (
        <ProgressContainer>
            <CircularProgress size={32} />
            <Typography>Loading...</Typography>
        </ProgressContainer>
    );
};
