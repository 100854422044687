import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { ICart } from "../types";
import { carts } from "../utils/api";

import { useAuth } from "./useAuth";

export interface IUseRemoveDiscountMutationParameters {
    cartId: string;
    discountCode: string;
}

export const useRemoveDiscount = (
    invalidQueryKeys: string[] = [],
): UseMutationResult<ICart, unknown, IUseRemoveDiscountMutationParameters> => {
    const queryClient = useQueryClient();
    const { token } = useAuth();

    return useMutation<ICart, unknown, IUseRemoveDiscountMutationParameters>({
        mutationFn: async (
            parameters: IUseRemoveDiscountMutationParameters,
        ): Promise<ICart> => {
            const { cartId, discountCode } = parameters;

            if (!token) {
                throw new Error(
                    "Cannot remove discount without a valid token.",
                );
            }

            return await carts.removeDiscountCode({
                cartId,
                discountCode,
                token: token ?? undefined,
            });
        },
        onSuccess: async () => {
            queryClient.invalidateQueries({
                queryKey: invalidQueryKeys,
            });
        },
    });
};
