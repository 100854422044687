import {
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from "react-query";

import { ILink } from "../../types";
import { api } from "../../utils";
import { useAuth } from "../useAuth";

export type TUseQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryKey extends QueryKey,
> = Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn"
>;

/**
 * Retrieves the link with the specified identifier.
 *
 * @author Samuel Rowe <samuel@houseofx.in>
 */
export const useGetLinkById = (
    queryKey: string[],
    linkId: string,
    options?: TUseQueryOptions<any, unknown, ILink, string[]>,
): UseQueryResult<ILink, unknown> => {
    const auth = useAuth();

    return useQuery(
        queryKey,
        async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch link without authorization token.",
                );
            }

            return await api.Link.getById({
                linkId,
                token: auth.token,
            });
        },
        options,
    );
};
