import {
    ChangeEventHandler,
    FocusEventHandler,
    FunctionComponent,
    ReactElement,
    ReactNode,
    useCallback,
} from "react";

import {
    Icon,
    IconButton,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    styled,
} from "@mui/material";

const Root = styled("div")`
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(1)};
`;

const Actions = styled("div")`
    display: flex;
    flex-direction: row;
    height: fit-content;
`;

export interface IRecordProps {
    index: number;
    onRemove: (index: number) => void;

    keyValue: string;
    keyLabel: string;
    keyName: string;
    keyId: string;
    keyError: boolean;
    keyHelperText?: string;
    onKeyChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onKeyBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;

    valueValue: string;
    valueValues: string[];
    valueLabel: string;
    valueName: string;
    valueId: string;
    valueError: boolean;
    valueHelperText?: string;
    onValueChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onValueSelect?: (
        event: SelectChangeEvent<string>,
        child: ReactNode,
    ) => void;
    onValueBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    selectField: boolean;
    disableOnRemove: boolean;
}

export const Record: FunctionComponent<IRecordProps> = (
    props: IRecordProps,
): ReactElement => {
    const {
        index,
        onRemove,

        keyValue,
        keyLabel,
        keyName,
        keyId,
        keyError,
        keyHelperText,
        onKeyChange,
        onKeyBlur,

        valueValue,
        valueValues,
        valueLabel,
        valueName,
        valueId,
        valueError,
        valueHelperText,
        onValueChange,
        onValueSelect,
        onValueBlur,
        selectField,
        disableOnRemove,
    } = props;

    const handleRemove = useCallback(() => {
        onRemove(index);
    }, [index, onRemove]);

    return (
        <Root>
            <TextField
                id={keyId}
                name={keyName}
                label={keyLabel}
                size="small"
                value={keyValue}
                error={keyError}
                helperText={keyHelperText}
                onChange={onKeyChange}
                onBlur={onKeyBlur}
            />
            {!selectField && (
                <TextField
                    id={valueId}
                    name={valueName}
                    label={valueLabel}
                    value={valueValue}
                    size="small"
                    error={valueError}
                    helperText={valueHelperText}
                    onChange={onValueChange}
                    onBlur={onValueBlur}
                />
            )}
            {selectField && (
                <Select
                    label={valueLabel}
                    name={valueName}
                    size="small"
                    defaultValue={valueValues[0]}
                    onChange={onValueSelect}
                    onBlur={onValueBlur}
                >
                    {valueValues.map((value, index) => (
                        <MenuItem key={index} value={value}>
                            <ListItemText primary={value} />
                        </MenuItem>
                    ))}
                </Select>
            )}
            {!disableOnRemove && (
                <Actions>
                    <IconButton onClick={handleRemove}>
                        <Icon fontSize="small">cancel</Icon>
                    </IconButton>
                </Actions>
            )}
        </Root>
    );
};
