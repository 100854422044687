import axios from "axios";

import {
    IExternalListPage,
    IGetOrderResponse,
    IOrder,
    IRefundOrderResponse,
} from "../../types/api";

export const transform = (data: any): IOrder => {
    return {
        ...data,
        canceledAt: data.canceledAt ? new Date(data.canceledAt) : null,
        closedAt: data.closedAt ? new Date(data.closedAt) : null,
        createdAt: new Date(data.createdAt),
        updatedAt: new Date(data.updatedAt),
    };
};

export interface IGetOrdersByCustomerId {
    token: string;
    customerId: string;
    limit: number;
    cursor: string | null;
    direction: string;
}

export interface IGetAllOrderProps {
    startDate: Date;
    endDate: Date;
    status: string[];
    limit: number;
    token: string;
    cursor: string | null;
    direction: string;
}

export interface IGetOrderByIdParameters {
    orderId: string;
    token: string;
}

export interface ICreateOrderParameters {
    storeId: string;
    billingCustomerAddressId: string;
    note?: string;
    shippingCustomerAddressId: string;
    test?: boolean;
    orderLineItems: {
        variantId: string;
        quantity: number;
        price: number;
    }[];
    token: string;
}
export interface IGetOrderLineItemsParameters {
    orderIds: string;
    token: string;
}

export interface ICancelOrderParameters {
    orderId: string;
    reason: number;
    refundAmount: number;
    refundNote: string;
    token: string;
    note: string;
}
export interface IRefundOrderParameters {
    orderId: string;
    reason: number;
    note: string;
    amount: number;
    token: string;
}

export interface IGetShopifyOrderIdParameters {
    orderId: string;
    token: string;
}

export class Order {
    static async getOrders(token: string): Promise<IExternalListPage<IOrder>> {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/orders`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return {
            ...data,
            records: data.records.map(transform),
        };
    }

    static async getOrder(
        parameters: IGetOrderByIdParameters,
    ): Promise<IOrder> {
        const { token, orderId } = parameters;
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/orders/${orderId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return transform(data);
    }

    static async createOrder(
        parameters: ICreateOrderParameters,
    ): Promise<IOrder> {
        const { token, ...body } = parameters;
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/orders`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return transform(data);
    }

    static async getOrderLineItems(paramaters: IGetOrderLineItemsParameters) {
        const { token, orderIds } = paramaters;

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/order-line-items/?orderIds=${orderIds}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return data;
    }

    static async cancelOrder(
        paramaters: ICancelOrderParameters,
    ): Promise<IOrder> {
        const { token, orderId, reason, refundAmount, refundNote } = paramaters;

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/orders/${orderId}/cancel`,
            {
                reason,
                refundAmount,
                refundNote,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return transform(data);
    }

    static async refundOrder(
        paramaters: IRefundOrderParameters,
    ): Promise<IRefundOrderResponse> {
        const { token, orderId, reason, note, amount } = paramaters;

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/orders/${orderId}/refund`,
            {
                reason,
                note,
                amount,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        return data;
    }

    static async getShopifyOrderId(paramaters: IGetShopifyOrderIdParameters) {
        const { token, orderId } = paramaters;

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/orders/${orderId}/shopify-order-id`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return data;
    }

    static async getOrdersByCustomerId(
        parameters: IGetOrdersByCustomerId,
    ): Promise<IExternalListPage<IGetOrderResponse>> {
        const { token, customerId, ...body } = parameters;

        const params = new URLSearchParams([
            ["limit", body?.limit?.toString() ?? ""],
            ["direction", body.direction ?? "after"],
        ]);

        if (body.cursor) {
            params.append("cursor", body.cursor);
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/customers/${customerId}/orders?${params}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        return {
            ...data,
            records: data.records.map(transform),
        };
    }
}
