import { UseQueryResult, useQuery } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { ICustomer } from "../../sdk/types";
import { api } from "../../sdk/utils";
import { TUseQueryOptions } from "../eav";

export const useGetCustomerById = (
    customerId: string,
    queryKey: string,
    options?: TUseQueryOptions<any, unknown, ICustomer, string>,
): UseQueryResult<ICustomer, unknown> => {
    const auth = useAuth();
    return useQuery(
        queryKey,
        async () => {
            if (!auth.token) {
                throw new Error(
                    `Cannot fetch customer with ${customerId} without authorization token.`,
                );
            }
            return await api.Customer.getById({
                customerId,
                token: auth.token,
            });
        },
        options,
    );
};
