import { UseQueryResult, useQuery } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { ICustomer, IExternalListPage } from "../../sdk/types";
import { api } from "../../sdk/utils";

export interface IGetAllCustomerParameter {
    limit: number;
    cursor: string | null;
    direction: string;
}

export const useGetAllCustomers = (
    queryKey: string | string[],
    parameters: IGetAllCustomerParameter,
): UseQueryResult<IExternalListPage<ICustomer>, unknown> => {
    const auth = useAuth();

    return useQuery({
        queryKey,
        queryFn: async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch customers without authorization token.",
                );
            }

            return await api.Customer.getAll({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
