import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { IEntityAttributeOption, api } from "../../utils";

export interface IUpdateEntityAttributeOptionParameter {
    name: string;
    value: JSON;
    attributeId: string;
    optionId: string;
}

export const useUpdateEntityAttributeOption = (
    queryKey: string | string[],
): UseMutationResult<
    IEntityAttributeOption,
    unknown,
    IUpdateEntityAttributeOptionParameter
> => {
    const auth = useAuth();
    return useMutation({
        mutationFn: async (
            parameters: IUpdateEntityAttributeOptionParameter,
        ) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot update entity attribute without authorization token.",
                );
            }

            return await api.EntityAttributeOption.update({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
