import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import {
    Autocomplete,
    AutocompleteInputChangeReason,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Icon,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import * as Yup from "yup";
import {
    DatePicker,
    LocalizationProvider,
    TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import {
    IKeyValuePair,
    Records,
    WorkspaceToolbar,
} from "../../components/common";
import {
    useCreateLink,
    useGetLinkById,
    useMessage,
    useUpdateLink,
} from "../../sdk/hooks";
import { ILink } from "../../sdk/types";

const Root = styled("div")``;

const Form = styled("form")`
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(4)};
`;

const StyledTextField = styled(TextField)``;

const LinkPrefix = styled(Typography)`
    font-size: 12px;
    color: grey;
    margin-right: ${({ theme }) => theme.spacing(0.5)};
`;

const Section = styled(Card)`
    border: 1px solid #d9d9d9;
    max-width: 800px;
    box-shadow: none;
`;

const StyledContent = styled(CardContent)`
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(2)};
`;

const SectionTitle = styled("div")`
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(1)};
`;

const SectionTitleText = styled(Typography)`
    font-size: 14px;
    font-weight: 500;
`;

const StyledTooltip = styled(Tooltip)`
    cursor: default;
`;

const UrlRoutingContainer = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: ${({ theme }) => theme.spacing(2)};
`;

const IabFormGroup = styled(FormGroup)`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const DestinationContainer = styled("div")`
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(1)};
    width: 100%;
`;

const DateTimeContainer = styled("div")`
    display: flex;
    flex-direction: row;
    column-gap: ${({ theme }) => theme.spacing(1)};
`;

const internalOptions = [
    "campaign",
    "marketing",
    "ops",
    "social_media",
    "tech",
    "test",
];

const useDynamicAutocomplete = (initialOptions: string[]) => {
    const [options, setOptions] = useState<string[]>(initialOptions);

    const handleInputChange = useCallback(
        (
            _event: React.SyntheticEvent,
            value: string,
            reason: AutocompleteInputChangeReason,
        ) => {
            let newOptions = [...options];
            if (reason === "input") {
                // TODO: Do not add a new entry if the `value` is the prefix
                // of an existing !predefined value.
                const index = options.findIndex((option) => option === value);
                if (index >= 0) {
                    newOptions[index] = value;
                } else {
                    newOptions.push(value);
                }
            }
            setOptions(newOptions);
        },
        [options],
    );

    return { options, handleInputChange };
};

type TQueryParam = Pick<IKeyValuePair, "key" | "value">;

interface IEditLinkForm {
    name: string;
    description: string;
    tags: string[];
    alias: string;
    destination: string;
    allowIab: boolean;
    queryParams: TQueryParam[];
    startsAt: Date | null;
    endsAt: Date | null;
}

const validationSchema = Yup.object({
    name: Yup.string().required("Please enter valid name"),
    description: Yup.string(),
    tags: Yup.array().of(Yup.string()),
    alias: Yup.string().required("Please enter valid alias URL"),
    destination: Yup.string()
        .url("Destination must be a valid URL")
        .required("Please enter valid destination URL"),
    allowIab: Yup.boolean().required(),
    queryParams: Yup.array().of(
        Yup.object({
            key: Yup.string().required("Please enter valid query param key"),
            value: Yup.string().required(
                "Please enter valid query param value",
            ),
        }),
    ),
    startsAt: Yup.date().nullable(),
    endsAt: Yup.date().nullable(),
});

export const EditLinkScreen: FunctionComponent = (): ReactElement => {
    const { options, handleInputChange } =
        useDynamicAutocomplete(internalOptions);

    const params = useParams();
    const navigate = useNavigate();
    const message = useMessage();

    const newLink = params.linkId === "new";

    const [initialValues, setInitialValues] = useState<IEditLinkForm>({
        name: "",
        description: "",
        tags: [],
        alias: "",
        destination: "",
        allowIab: false,
        queryParams: [
            { key: "utm_source", value: "" },
            { key: "utm_medium", value: "" },
            { key: "utm_campaign", value: "" },
            { key: "utm_term", value: "" },
            { key: "utm_content", value: "" },
        ],
        startsAt: null,
        endsAt: null,
    });

    const createMutation = useCreateLink();
    const updateMutation = useUpdateLink(["getLinkById", params.linkId ?? ""]);

    const formik = useFormik<IEditLinkForm>({
        enableReinitialize: true,
        initialValues,
        onSubmit: (values0) => {
            const {
                name,
                description,
                tags,
                alias,
                destination,
                allowIab,
                queryParams,
                startsAt,
                endsAt,
            } = values0;

            /* The query parameters can be added from both the Destination text
             * field and the Query Parameters records. We need to combine both
             * the sets here.
             */
            const destinationQps = new URLSearchParams();
            /* Extract query parameters from the destination text field. */
            const destinationUrl = new URL(destination);
            for (const [key, value] of destinationUrl.searchParams) {
                destinationQps.append(key, value);
            }
            /* Extract query params from the key-value pairs. */
            for (const queryParam of queryParams) {
                destinationQps.append(queryParam.key, queryParam.value);
            }

            /*
             * Although I have not watched the movie, but I'm proud of myself
             * for coming up with beautiful variable names. XD
             */
            let finalDestination = `${destinationUrl.protocol}//${destinationUrl.host}${destinationUrl.pathname}`;
            if (destinationQps.size > 0) {
                finalDestination += `?${destinationQps.toString()}`;
            }

            if (newLink) {
                createMutation.mutate(
                    {
                        name,
                        description,
                        tags: tags.join(","),
                        alias: alias,
                        destination: finalDestination,
                        allowIab: Boolean(allowIab),
                        startsAt,
                        endsAt,
                    },
                    {
                        onSuccess: (link) => {
                            navigate(`/links/${link.id}`);
                            message.showSuccess("Link created successfully");
                        },
                    },
                );
            } else {
                updateMutation.mutate(
                    {
                        id: params.linkId!,
                        name,
                        description,
                        tags: tags.join(","),
                        alias: alias,
                        destination: finalDestination,
                        allowIab: Boolean(allowIab),
                        startsAt,
                        endsAt,
                    },
                    {
                        onSuccess: () => {
                            message.showSuccess("Link updated successfully");
                        },
                    },
                );
            }
        },
        validationSchema,
    });

    const getFlatQueryParams = (
        urlAsString: string,
    ): { queryParams: TQueryParam[]; url: URL } => {
        const url = new URL(urlAsString);
        const queryParams: TQueryParam[] = [];
        for (const [key, value] of url.searchParams.entries()) {
            if (Array.isArray(value)) {
                for (const item of value) {
                    queryParams.push({
                        key,
                        value: item,
                    });
                }
            } else {
                queryParams.push({ key, value });
            }
        }
        return { queryParams, url };
    };

    const getQuery = useGetLinkById(
        ["getLinkById", params.linkId ?? ""],
        params.linkId ?? "",
        {
            enabled: params.linkId !== undefined && params.linkId !== "new",
            onSuccess: (link: ILink) => {
                const { queryParams, url } = getFlatQueryParams(
                    link.destination,
                );

                setInitialValues({
                    name: link.name,
                    description: link.description,
                    tags: link.tags.split(","),
                    alias: link.alias,
                    destination: `${url.protocol}//${url.host}${url.pathname}`,
                    allowIab: link.allowIab,
                    queryParams,
                    startsAt: null,
                    endsAt: null,
                });
            },
        },
    );

    const handleSave = useCallback(() => {
        formik.handleSubmit();
    }, [formik]);

    const handleBreakdownDestination = useCallback(() => {
        try {
            const { url, queryParams } = getFlatQueryParams(
                formik.values.destination,
            );
            formik.setValues({
                ...formik.values,
                queryParams: [...formik.values.queryParams, ...queryParams],
                destination: `${url.protocol}//${url.host}${url.pathname}`,
            });
        } catch (error: unknown) {
            message.showError((error as Error).message);
        }
    }, [formik, message]);

    const handleTagsChange = useCallback(
        (_event: unknown, newTags: string[]) => {
            formik.setFieldValue("tags", newTags);
        },
        [formik],
    );

    const handleAddQp = useCallback(() => {
        formik.setFieldValue("queryParams", [
            ...formik.values.queryParams,
            {
                key: "",
                value: "",
            },
        ]);
    }, [formik]);

    const handleRemoveQp = useCallback(
        (index: number) => {
            const newQueryParams = [...formik.values.queryParams];
            newQueryParams.splice(index, 1);
            formik.setFieldValue("queryParams", newQueryParams);
        },
        [formik],
    );

    return (
        <Root>
            <WorkspaceToolbar
                title="Create Link"
                enableSearch={false}
                actions={
                    <>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="small"
                            endIcon={<Icon>check_circle</Icon>}
                            onClick={handleSave}
                            disabled={!formik.dirty || !formik.isValid}
                            loading={
                                createMutation.isLoading ||
                                updateMutation.isLoading
                            }
                        >
                            Save
                        </LoadingButton>
                    </>
                }
            />
            <Form>
                <Section>
                    <StyledContent>
                        <SectionTitle>
                            <SectionTitleText>General</SectionTitleText>
                        </SectionTitle>
                        <StyledTextField
                            name="name"
                            label="Name"
                            size="small"
                            required={true}
                            fullWidth={false}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(
                                formik.touched.name && formik.errors.name,
                            )}
                            helperText={
                                formik.touched.name && formik.errors.name
                            }
                        />
                        <StyledTextField
                            name="description"
                            label="Description"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(
                                formik.touched.description &&
                                    formik.errors.description,
                            )}
                            helperText={
                                formik.touched.description &&
                                formik.errors.description
                            }
                            multiline={true}
                            rows={5}
                            value={formik.values.description}
                        />

                        <Autocomplete
                            multiple={true}
                            size="small"
                            getOptionLabel={(option) => option}
                            defaultValue={[]}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Tags"
                                />
                            )}
                            options={options}
                            onInputChange={handleInputChange}
                            value={formik.values.tags}
                            onChange={handleTagsChange}
                            onBlur={formik.handleBlur}
                        />
                    </StyledContent>
                </Section>
                <Section>
                    <StyledContent>
                        <SectionTitle>
                            <SectionTitleText>Routing</SectionTitleText>
                            <StyledTooltip
                                enterTouchDelay={0}
                                placement="top"
                                title={"Configures the routing of the new URL"}
                            >
                                <Icon
                                    style={{ color: "#626262" }}
                                    fontSize="small"
                                >
                                    info
                                </Icon>
                            </StyledTooltip>
                        </SectionTitle>

                        <UrlRoutingContainer>
                            <StyledTextField
                                name="alias"
                                label="Alias"
                                size="small"
                                required={true}
                                fullWidth={true}
                                InputProps={{
                                    startAdornment: (
                                        <LinkPrefix>
                                            {process.env
                                                .REACT_APP_LINK_BASE_URL ??
                                                "https://links.ifeelblanko.com"}
                                            /
                                        </LinkPrefix>
                                    ),
                                }}
                                value={formik.values.alias}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(
                                    formik.touched.alias && formik.errors.alias,
                                )}
                                helperText={
                                    formik.touched.alias && formik.errors.alias
                                }
                            />

                            <Icon sx={{ color: "#bababa" }}>
                                keyboard_double_arrow_down
                            </Icon>

                            <DestinationContainer>
                                <StyledTextField
                                    name="destination"
                                    label="Destination"
                                    size="small"
                                    required={true}
                                    fullWidth={true}
                                    value={formik.values.destination}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(
                                        formik.touched.destination &&
                                            formik.errors.destination,
                                    )}
                                    helperText={
                                        formik.touched.destination &&
                                        formik.errors.destination
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                size="small"
                                                sx={{ ml: 1 }}
                                                disabled={
                                                    !formik.values
                                                        .destination ||
                                                    Boolean(
                                                        formik.errors
                                                            .destination,
                                                    )
                                                }
                                                onClick={
                                                    handleBreakdownDestination
                                                }
                                            >
                                                <Icon>move_down</Icon>
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </DestinationContainer>
                        </UrlRoutingContainer>

                        <IabFormGroup>
                            <FormControlLabel
                                label="Allow In-App Browser"
                                control={
                                    <Checkbox
                                        name="allowIab"
                                        onChange={(event) =>
                                            formik.setFieldValue(
                                                "allowIab",
                                                event.target.checked,
                                            )
                                        }
                                        onBlur={formik.handleBlur}
                                        checked={formik.values.allowIab}
                                    />
                                }
                            />
                            <StyledTooltip
                                enterTouchDelay={0}
                                placement="top"
                                title={
                                    "Prevents apps like Instagram from opening links within their in-app browsers. While we try to open links outside these browsers, this functionality may not be supported on all platforms, such as iOS."
                                }
                            >
                                <Icon
                                    style={{ color: "#626262" }}
                                    fontSize="small"
                                >
                                    info
                                </Icon>
                            </StyledTooltip>
                        </IabFormGroup>

                        <SectionTitle>
                            <SectionTitleText>
                                Query Parameters
                            </SectionTitleText>
                            <StyledTooltip
                                enterTouchDelay={0}
                                placement="top"
                                title={
                                    "Add marketing campaign trackers and other query parameters"
                                }
                            >
                                <Icon
                                    style={{ color: "#626262" }}
                                    fontSize="small"
                                >
                                    info
                                </Icon>
                            </StyledTooltip>
                        </SectionTitle>

                        <Records
                            pairs={formik.values.queryParams.map(
                                (qp, index) => ({
                                    ...qp,
                                    keyName: `queryParams[${index}].key`,
                                    keyId: `queryParams[${index}].key`,
                                    keyLabel: "Key",
                                    keyError:
                                        Boolean(
                                            formik.touched.queryParams?.[index]
                                                ?.key,
                                        ) &&
                                        Boolean(
                                            (
                                                formik.errors.queryParams?.[
                                                    index
                                                ] as any
                                            )?.key,
                                        ),
                                    keyHelperText: (
                                        formik.errors.queryParams?.[
                                            index
                                        ] as any
                                    )?.key,
                                    valueName: `queryParams[${index}].value`,
                                    valueId: `queryParams[${index}].value`,
                                    valueLabel: "Value",
                                    valueError:
                                        Boolean(
                                            formik.touched.queryParams?.[index]
                                                ?.value,
                                        ) &&
                                        Boolean(
                                            (
                                                formik.errors.queryParams?.[
                                                    index
                                                ] as any
                                            )?.value,
                                        ),
                                    valueHelperText: (
                                        formik.errors.queryParams?.[
                                            index
                                        ] as any
                                    )?.value,
                                    values: [],
                                }),
                            )}
                            onAdd={handleAddQp}
                            onRemove={handleRemoveQp}
                            onKeyChange={formik.handleChange}
                            onKeyBlur={formik.handleBlur}
                            onValueChange={formik.handleChange}
                            onValueBlur={formik.handleBlur}
                            selectField={false}
                            disableOnAdd={false}
                            disableOnRemove={false}
                        />
                    </StyledContent>
                </Section>

                {false && (
                    <Section>
                        <StyledContent>
                            <SectionTitle>
                                <SectionTitleText>Activation</SectionTitleText>
                                <StyledTooltip
                                    enterTouchDelay={0}
                                    placement="top"
                                    title={
                                        "Specifies the duration within which the link is going to remain active."
                                    }
                                >
                                    <Icon
                                        style={{ color: "#626262" }}
                                        fontSize="small"
                                    >
                                        info
                                    </Icon>
                                </StyledTooltip>
                            </SectionTitle>

                            <DateTimeContainer>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DatePicker label="Start date" />

                                    <TimePicker label="Start time" />
                                </LocalizationProvider>
                            </DateTimeContainer>

                            <DateTimeContainer>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DatePicker label="End date" />

                                    <TimePicker label="End time" />
                                </LocalizationProvider>
                            </DateTimeContainer>
                        </StyledContent>
                    </Section>
                )}
            </Form>
        </Root>
    );
};
