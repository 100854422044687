import { useMemo } from "react";

import { UseQueryResult, useQuery } from "react-query";

import { useAuth } from "../sdk/hooks";
import { IExternalListPage, IGetOrderResponse, IOrderV1_1 } from "../sdk/types";
import { api, getActualAmountOfOrder } from "../sdk/utils";

export interface IFetchAllOrdersParameters {
    startDate: Date;
    endDate: Date;
    limit: number;
    cursor: string | null;
    direction: string;
    paymentMethods?: string[];
    paymentStatuses?: string[];
    orderStatuses?: string[];
    orderSources?: string[];
    names?: string[];
    phoneNumbers?: string[];
    sellers?: string[];
}

export const useGetAllOrders = (
    queryKey: string | string[],
    parameters: IFetchAllOrdersParameters,
): UseQueryResult<IExternalListPage<IOrderV1_1>, unknown> => {
    const auth = useAuth();

    const result = useQuery({
        queryKey,
        queryFn: async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch orders without authorization token.",
                );
            }

            return await api.v1_1.orders.getAllOrders({
                ...parameters,
                token: auth.token,
            });
        },
    });

    const mapOrderToActualAmount = useMemo(() => {
        const orderActualAmountMap: Record<string, number> = {};
        result.data?.records.forEach((order: IGetOrderResponse) => {
            orderActualAmountMap[order.id] = getActualAmountOfOrder(order);
        });
        return orderActualAmountMap;
    }, [result.data]);

    if (result.data) {
        result.data.records.forEach((order: IGetOrderResponse) => {
            // eslint-disable-next-line no-param-reassign
            order.actualAmount = mapOrderToActualAmount[order.id];
        });
    }

    return result;
};
