import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useRef,
    useState,
} from "react";

import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    styled,
} from "@mui/material";

import { ArrowDropDownIcon } from "@mui/x-date-pickers";

import { CustomerReviewStatus, ICustomerReview } from "../../sdk/types";

const ButtonContainer = styled("div")`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;

    gap: ${({ theme }) => theme.spacing(2)};
`;

export interface ICustomerReviewFooterProps {
    review: ICustomerReview;
    onReject: (event: React.MouseEvent) => void;
    onApproval: (event: React.MouseEvent) => void;
    onApprovalWithComment: (event: React.MouseEvent) => void;
}

const options = ["APPROVE", "APPROVE WITH COMMENT"];

export const CustomerReviewFooter: FunctionComponent<
    ICustomerReviewFooterProps
> = (props: ICustomerReviewFooterProps): ReactElement => {
    const { review, onApproval, onApprovalWithComment, onReject } = props;

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleMenuItemClick = useCallback(
        (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
            setSelectedIndex(index);
            setOpen(false);
            event.stopPropagation();
        },
        [],
    );

    const handleToggle = useCallback((event: React.MouseEvent) => {
        setOpen((prevOpen) => !prevOpen);
        event.stopPropagation();
    }, []);

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        event.stopPropagation();
        setOpen(false);
    };

    return (
        <div>
            <ButtonContainer>
                <ButtonGroup variant="outlined" ref={anchorRef}>
                    <Button
                        onClick={(event) =>
                            selectedIndex === 0
                                ? onApproval(event)
                                : onApprovalWithComment(event)
                        }
                        disabled={
                            review.status === CustomerReviewStatus.APPROVED
                        }
                        color="primary"
                        size="small"
                        variant="outlined"
                    >
                        {options[selectedIndex]}
                    </Button>
                    <Button size="small" onClick={handleToggle}>
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper
                    sx={{ zIndex: 1 }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition={true}
                    disablePortal={true}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom"
                                        ? "center top"
                                        : "center bottom",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener
                                    onClickAway={handleClose}
                                    disabled={
                                        review.status ===
                                        CustomerReviewStatus.APPROVED
                                    }
                                >
                                    <MenuList>
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                selected={
                                                    index === selectedIndex
                                                }
                                                disabled={
                                                    review.status ===
                                                    CustomerReviewStatus.APPROVED
                                                }
                                                onClick={(event) =>
                                                    handleMenuItemClick(
                                                        event,
                                                        index,
                                                    )
                                                }
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                <Button
                    onClick={(event) => onReject(event)}
                    disabled={review.status === CustomerReviewStatus.REJECTED}
                    color="error"
                    size="small"
                    variant="outlined"
                >
                    Reject
                </Button>
            </ButtonContainer>
        </div>
    );
};
