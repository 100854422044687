import { FunctionComponent, ReactElement } from "react";

import { Typography, styled } from "@mui/material";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { format } from "date-fns";

import { EmptyRecords } from "../../components/common";
import { IRefund } from "../../sdk/types";
import { formatCurrency } from "../../sdk/utils/numbers";
import {
    RefundReason,
    RefundReasonNames,
    RefundStatusNames,
    RefundStatusType,
} from "../../utils/enums";

const Root = styled("section")`
    padding: ${({ theme }) => theme.spacing(1)};
    display: flex;
    flex-direction: column;
`;

const EmptyRecordsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
`;

const Title = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`;

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }

    .MuiDataGrid-row:hover {
        cursor: pointer;
    }
`;

const columns: GridColDef[] = [
    {
        field: "createdAt",
        headerName: "Created at",
        flex: 0.75,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), "dd-MM-yyyy, p")}`,
    },
    {
        field: "amount",
        headerName: "Amount",
        flex: 0.4,
        minWidth: 100,
        valueGetter: (params: GridValueGetterParams) => {
            return formatCurrency(params.row.amount / 100);
        },
    },
    {
        field: "reason",
        headerName: "Reason",
        flex: 1,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams) =>
            `${RefundReasonNames[params.row.reason as RefundReason]} ${
                params.row.note ? `(${params.row.note})` : ""
            }`,
    },
    {
        field: "status",
        headerName: "Status",
        flex: 0.4,
        minWidth: 100,
        valueGetter: (params: GridValueGetterParams) =>
            `${RefundStatusNames[params.row.status as RefundStatusType]}`,
    },
];

export interface IRefundsListProps {
    refunds: IRefund[];
}

/**
 * Presentational component for the refunds list
 */
export const RefundList: FunctionComponent<IRefundsListProps> = (
    props: IRefundsListProps,
): ReactElement => {
    const { refunds } = props;

    return (
        <Root>
            {refunds.length === 0 && (
                <EmptyRecords>
                    <EmptyRecordsContainer>
                        <Title>No refunds initiated</Title>
                    </EmptyRecordsContainer>
                </EmptyRecords>
            )}
            {refunds.length > 0 && (
                <StyledDataGrid
                    rows={refunds}
                    columns={columns}
                    hideFooter={true}
                />
            )}
        </Root>
    );
};
