import { FunctionComponent, ReactElement } from "react";

import { Chip, Paper, Typography, styled } from "@mui/material";

import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridToolbar,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";

import { IAuthHistory } from "../../types";
import {
    authenticationMethodByEnum,
    authenticationStatusByEnum,
} from "../../utils/enums";

const SectionDetail = styled(Paper)`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
    min-width: 500px;

    padding: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
           min-width: 300px;
    }
  `}
`;

const SectionTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    color: black;
`;

const columns: GridColDef[] = [
    {
        field: "method",
        headerName: "Method",
        flex: 0.5,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) =>
            `${authenticationMethodByEnum[params.row.method]}`,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Chip label={params.value} variant="outlined" />
        ),
    },
    {
        field: "status",
        headerName: "Status",
        flex: 0.5,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) =>
            `${authenticationStatusByEnum[params.row.status]}`,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Chip label={params.value} variant="outlined" />
        ),
    },
    {
        field: "storeId",
        headerName: "Store ",
        flex: 0.5,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.storeId === null ? "N/A" : params.row.storeId}`,
    },
    {
        field: "createdAt",
        headerName: "Created at",
        flex: 0.5,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), "dd-MM-yyyy, p")}`,
    },
];

export interface IAuthHistoryGrid {
    rows: IAuthHistory[];
}

export const AuthHistoryGrid: FunctionComponent<IAuthHistoryGrid> = (
    props: IAuthHistoryGrid,
): ReactElement => {
    const { rows } = props;

    return (
        <SectionDetail variant="outlined" elevation={0}>
            <SectionTitle>Authentication history</SectionTitle>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10, 20, 30]}
                disableRowSelectionOnClick={true}
                slots={{ toolbar: GridToolbar }}
                disableColumnSelector={true}
                disableDensitySelector={true}
            />
        </SectionDetail>
    );
};
