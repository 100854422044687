import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Paper,
    SelectChangeEvent,
    styled,
} from "@mui/material";

import { ExpandMore } from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useGetModerationList, useVerifyCustomerReview } from "../../hooks";
import { useAuth, useGetAdmins, useMessage } from "../../sdk/hooks";
import {
    CustomerReviewStatus,
    ICustomerReview,
    IVerifyCustomerReview,
} from "../../sdk/types";

import { CustomerReviewDetails } from "./CustomerReviewDetails";
import { CustomerReviewFooter } from "./CustomerReviewFooter";
import { CustomerReviewHeader } from "./CustomerReviewHeader";
import { CustomerReviewHistory } from "./CustomerReviewHistory";
import { ModerateCustomerReviewCard } from "./ModerateCustomerReviewCard";

const Root = styled(Accordion)`
    height: 100%;
    border: 1px solid #d9d9d9;
    border-radius: ${({ theme }) => theme.spacing(1)};
`;

const StyledAccordionSummary = styled(AccordionSummary)`
    display: flex;

    align-items: flex-start;
    flex: 1;

    .MuiAccordionSummary-content {
        width: 100%;
    }

    padding: ${({ theme }) => theme.spacing(1)};
`;

const DetailsContainer = styled("div")`
    width: 850px;
`;

const StyledPaper = styled(Paper)`
    display: flex;
    max-height: 340px;
`;

const ExpandIcon = styled(ExpandMore)`
    margin: ${({ theme }) => theme.spacing(2, 0, 0, 2)};
`;

export interface ICustomerReviewProps {
    review: ICustomerReview;
    backgroundColor: string;
    onUpdateReviewStatus: (reviewId: string, status: number) => void;
}

const validationSchema = Yup.object({
    comment: Yup.string().max(1024).nullable(),
    flaggedReason: Yup.string().max(1024).nullable(),
    flaggedAt: Yup.date().nullable(),
    status: Yup.number(),
    reviewId: Yup.string().max(36),
    assignedTo: Yup.string().uuid().max(36).nullable(),
    assignedBy: Yup.string().uuid().max(36).nullable(),
    moderatedBy: Yup.string().uuid().max(36).nullable(),
});

export const CustomerReview: FunctionComponent<ICustomerReviewProps> = (
    props: ICustomerReviewProps,
): ReactElement => {
    const { review, backgroundColor, onUpdateReviewStatus } = props;

    const [expanded, setExpanded] = useState(false);

    const handleToggle = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded]);

    const moderationListQuery = useGetModerationList({
        reviewId: review.id ?? "",
        options: {
            queryKey: [review.id],
        },
    });

    const moderationList = useMemo(
        () => moderationListQuery.data ?? [],
        [moderationListQuery.data],
    );

    const moderators = moderationList
        .map((moderation) => moderation.moderatedBy ?? "")
        .filter((moderator) => moderator !== null && moderator !== "");
    const getAdmins = useGetAdmins(moderators ?? []);

    const admins = useMemo(() => {
        return (
            getAdmins.data?.records.filter(
                (record) =>
                    record.firstName !== null || record.lastName !== null,
            ) ?? []
        );
    }, [getAdmins.data?.records]);
    const latestAssignedTo = useMemo(() => {
        return (
            moderationList
                .sort(
                    (a, b) =>
                        new Date(b.updatedAt).getTime() -
                        new Date(a.updatedAt).getTime(),
                )
                .map((moderation) => moderation.assignedTo)
                .filter(
                    (assignedTo) => assignedTo !== null && assignedTo !== "",
                )[0] || null
        );
    }, [moderationList]);

    const getAdmin = useCallback(
        (moderator: string) => {
            const admin = admins.find((record) => record.id === moderator);
            return admin;
        },
        [admins],
    );

    const verifyCustomerReviewMutation = useVerifyCustomerReview(
        "verifyCustomerReview",
    );

    const lastAssignedName = useMemo(() => {
        const admin = getAdmin(latestAssignedTo ?? "");
        return admin ? `${admin.firstName} ${admin.lastName}` : "";
    }, [latestAssignedTo, getAdmin]);

    const [assignee, setAssignee] = useState<string | null>(lastAssignedName);

    useEffect(() => {
        setAssignee(lastAssignedName);
    }, [lastAssignedName]);
    const handleSelectChange = useCallback((event: SelectChangeEvent) => {
        setAssignee(event.target.value);
    }, []);

    const { showError, showSuccess } = useMessage();

    const [showReviewVerification, setShowReviewVerification] = useState(false);
    const [moderatedStatus, setModeratedStatus] = useState(3);

    const [moderatedReviewId, setModeratedReviewId] = useState<string | null>(
        null,
    );

    const handleReviewVerification = useCallback(() => {
        setShowReviewVerification(false);
        setModeratedReviewId(null);
    }, []);
    const assignedToId = admins.find((admin) =>
        assignee?.includes(admin.firstName),
    )?.id;

    const { values, handleSubmit, setFieldValue, handleBlur, handleChange } =
        useFormik({
            initialValues: {
                status: review.status,
                reviewId: review.id,
                comment: null,
                flaggedReason: null,
                assignedBy: null,
                assignedTo: assignedToId ?? null,
                moderatedBy: null,
                flaggedAt: null,
            },
            onSubmit: (values0: IVerifyCustomerReview) => {
                verifyCustomerReviewMutation.mutate(
                    {
                        values: values0,
                    },
                    {
                        onError: (error: any) => {
                            console.error("Mutation error:", error);
                            showError(error);
                        },
                        onSuccess: () => {
                            showSuccess("Review moderated successfully");
                            onUpdateReviewStatus(review.id, moderatedStatus);
                        },
                    },
                );
            },
            validationSchema,
            enableReinitialize: true,
        });

    const { user } = useAuth();

    const handleApprove = useCallback(
        (event: React.MouseEvent) => {
            setModeratedStatus(CustomerReviewStatus.APPROVED);
            setModeratedReviewId(review.id);
            setFieldValue("status", CustomerReviewStatus.APPROVED, true);
            setFieldValue("assignedBy", user?.id);
            setFieldValue("assignedTo", assignedToId ?? null, true);
            setFieldValue("comment", null, true);
            handleSubmit();
            event.stopPropagation();
        },
        [assignedToId, handleSubmit, review.id, setFieldValue, user?.id],
    );

    const handleApproveWithComment = useCallback(
        (event: React.MouseEvent) => {
            setModeratedStatus(CustomerReviewStatus.APPROVED);
            setModeratedReviewId(review.id);
            setFieldValue("status", CustomerReviewStatus.APPROVED, true);
            setFieldValue("assignedBy", user?.id);
            setFieldValue("assignedTo", assignedToId ?? null, true);
            setShowReviewVerification(true);
            event.stopPropagation();
        },
        [assignedToId, review.id, setFieldValue, user?.id],
    );

    const handleReject = useCallback(
        (event: React.MouseEvent) => {
            setModeratedStatus(CustomerReviewStatus.REJECTED);
            setModeratedReviewId(review.id);
            setFieldValue("status", CustomerReviewStatus.REJECTED, true);
            setFieldValue("assignedBy", user?.id);
            setFieldValue("assignedTo", assignedToId ?? null, true);
            setShowReviewVerification(true);
            event.stopPropagation();
        },
        [assignedToId, review.id, setFieldValue, user?.id],
    );

    return (
        <>
            <Root
                variant="outlined"
                expanded={expanded}
                onChange={handleToggle}
            >
                <StyledAccordionSummary expandIcon={<ExpandIcon />}>
                    <DetailsContainer>
                        <CustomerReviewHeader
                            review={review}
                            backgroundColor={backgroundColor}
                            admins={admins}
                            assignee={assignee}
                            onChange={handleSelectChange}
                        />
                        <CustomerReviewDetails review={review} />
                        {!expanded && (
                            <CustomerReviewFooter
                                review={review}
                                onApproval={handleApprove}
                                onApprovalWithComment={handleApproveWithComment}
                                onReject={handleReject}
                            />
                        )}
                    </DetailsContainer>
                </StyledAccordionSummary>
                <AccordionDetails>
                    <CustomerReviewHistory
                        moderationList={moderationList}
                        review={review}
                        expanded={expanded}
                        onUpdateReviewStatus={onUpdateReviewStatus}
                        getAdmin={getAdmin}
                        onApproval={handleApprove}
                        onApprovalWithComment={handleApproveWithComment}
                        onReject={handleReject}
                    />
                </AccordionDetails>
            </Root>
            {moderatedReviewId === review.id && showReviewVerification && (
                <StyledPaper variant="outlined">
                    <ModerateCustomerReviewCard
                        onClose={handleReviewVerification}
                        reviewId={review.id}
                        status={moderatedStatus}
                        onUpdateReviewStatus={onUpdateReviewStatus}
                        values={values}
                        onSubmit={handleSubmit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        loading={verifyCustomerReviewMutation.isLoading}
                    />
                </StyledPaper>
            )}
        </>
    );
};
