import { FunctionComponent, ReactElement } from "react";

import {
    BoldItalicUnderlineToggles,
    CodeToggle,
    CreateLink,
    InsertTable,
    InsertThematicBreak,
    ListsToggle,
    MDXEditor,
    UndoRedo,
    headingsPlugin,
    linkDialogPlugin,
    linkPlugin,
    listsPlugin,
    quotePlugin,
    tablePlugin,
    thematicBreakPlugin,
    toolbarPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";

export interface IMarkdownEdiorProps {
    value: string;
    onChange: (value: string) => void;
}

export const MarkdownEdior: FunctionComponent<IMarkdownEdiorProps> = (
    props: IMarkdownEdiorProps,
): ReactElement => {
    const { value, onChange } = props;

    return (
        <MDXEditor
            markdown={value}
            onChange={onChange}
            plugins={[
                headingsPlugin(),
                listsPlugin(),
                quotePlugin(),
                linkPlugin(),
                linkDialogPlugin(),
                tablePlugin(),
                thematicBreakPlugin(),
                toolbarPlugin({
                    toolbarContents: () => (
                        <>
                            <UndoRedo />
                            <BoldItalicUnderlineToggles />
                            <CodeToggle />
                            <CreateLink />
                            <InsertTable />
                            <InsertThematicBreak />
                            <ListsToggle />
                        </>
                    ),
                }),
            ]}
        />
    );
};
