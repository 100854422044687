import axios from "axios";
import { format } from "date-fns";

import { globalDateFormat } from "../../../../utils/constants";
import {
    ICreateOrderResponse,
    IExternalListPage,
    IGetOrderResponse,
    IRefund,
} from "../../../types/api";

export const transform = (data: any): IGetOrderResponse => {
    return {
        ...data,
        canceledAt: data.canceledAt ? new Date(data.canceledAt) : null,
        closedAt: data.closedAt ? new Date(data.closedAt) : null,
        createdAt: new Date(data.createdAt),
        updatedAt: new Date(data.updatedAt),
        refunds: data.refunds?.map((refund: IRefund) => ({
            ...refund,
            createdAt: new Date(refund.createdAt),
        })),
    };
};

export interface ICreateOrderParameters {
    customerId: string;
    billingCustomerAddressId: string;
    note?: string;
    shippingCustomerAddressId: string;
    test?: boolean;
    cartId: string;
    token: string;
    platform: number;
}

/**
 * Create an order.
 *
 * This hook calls API version 1.1.
 */
export const createOrder = async (
    parameters: ICreateOrderParameters,
): Promise<ICreateOrderResponse> => {
    const { token, ...body } = parameters;
    const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/orders`,
        body,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return data;
};

/**
 * Fetch all orders.
 *
 * This hook calls API version 1.1.
 */
export interface IGetAllOrderProps {
    startDate: Date;
    endDate: Date;
    limit: number;
    token: string;
    cursor: string | null;
    direction: string;
    paymentMethods?: string[];
    paymentStatuses?: string[];
    orderSources?: string[];
    orderStatuses?: string[];
    names?: string[];
    phoneNumbers?: string[];
    sellers?: string[];
}

export const getAllOrders = async (
    parameters: IGetAllOrderProps,
): Promise<IExternalListPage<IGetOrderResponse>> => {
    const { token, ...body } = parameters;
    const startDate = format(body.startDate, globalDateFormat);
    const endDate = format(body.endDate, globalDateFormat);

    const params = new URLSearchParams([
        ["startDate", startDate],
        ["endDate", endDate],
        ["limit", body.limit.toString()],
        ...(body.orderStatuses ?? []).map((orderStatus) => [
            "orderStatuses",
            orderStatus,
        ]),
        ...(body.paymentMethods ?? []).map((paymentMethod) => [
            "paymentMethods",
            paymentMethod,
        ]),
        ...(body.paymentStatuses ?? []).map((paymentStatus) => [
            "paymentStatuses",
            paymentStatus,
        ]),
        ...(body.orderSources ?? []).map((orderSource) => [
            "orderSources",
            orderSource,
        ]),
        ...(body.names ?? []).map((name) => ["names", name]),
        ...(body.phoneNumbers ?? []).map((phoneNumber) => [
            "phoneNumbers",
            phoneNumber,
        ]),
        ...(body.sellers ?? []).map((seller) => ["sellers", seller]),
        ["direction", body.direction],
    ]);

    if (body.cursor) {
        params.append("cursor", body.cursor);
    }

    const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/orders/all?${params}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return {
        ...data,
        records: data.records.map(transform),
    };
};

export interface IGetOrderParameters {
    orderId: string;
    token: string;
}

export const getOrder = async (
    parameters: IGetOrderParameters,
): Promise<IGetOrderResponse> => {
    const { token, orderId } = parameters;
    const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/orders/${orderId}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
    return transform(data);
};
