import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { useAuth } from "../../sdk/hooks/useAuth";
import { IAdminGroup } from "../../sdk/types";
import { adminGroups } from "../../sdk/utils/api";

export interface IUseUpdateAdminGroupMutationParameters {
    name: string;
    description: string;
    id: string;
    adminIds: string[];
}

export const useUpdateAdminGroup = (
    invalidQueryKeys: string | string[],
): UseMutationResult<
    unknown,
    IAdminGroup,
    IUseUpdateAdminGroupMutationParameters
> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation<
        unknown,
        IAdminGroup,
        IUseUpdateAdminGroupMutationParameters
    >({
        mutationFn: async (
            parameters: IUseUpdateAdminGroupMutationParameters,
        ) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot update admin group without authorization token.",
                );
            }
            return await adminGroups.AdminGroup.update({
                token: auth.token,
                ...parameters,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(invalidQueryKeys);
        },
    });
};
