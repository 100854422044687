import { UseMutationResult, useMutation } from "react-query";

import { PaymentPlatform } from "../../utils/enums";
import { ICreateOrderResponse } from "../types";
import { orders } from "../utils/api/v1.1";

import { useAuth } from "./useAuth";
import { useMessage } from "./useMessage";

/* TODO: Add prebooking later */
export interface IUseCreateOrderMutationParameters {
    customerId: string;
    billingCustomerAddressId: string;
    note?: string;
    shippingCustomerAddressId: string;
    test?: boolean;
    cartId: string;
    platform: PaymentPlatform;
}

/**
 * Create a new (draft) order.`.
 */
export const useCreateOrder = (): UseMutationResult<
    ICreateOrderResponse,
    unknown,
    IUseCreateOrderMutationParameters
> => {
    const auth = useAuth();
    const { showError } = useMessage();

    return useMutation<
        ICreateOrderResponse,
        unknown,
        IUseCreateOrderMutationParameters
    >({
        mutationFn: (
            parameters: IUseCreateOrderMutationParameters,
        ): Promise<ICreateOrderResponse> => {
            if (!auth.token) {
                throw new Error(
                    "Cannot create order without authorization token.",
                );
            }

            return orders.createOrder({
                ...parameters,
                token: auth.token,
            });
        },
        onError: () => showError("Something went wrong"),
    });
};
