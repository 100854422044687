import { UseQueryResult, useQuery } from "react-query";

import { ICart } from "../types";
import { carts } from "../utils/api";

import { useAuth } from "./useAuth";

export const useFetchCart = (
    cartId: string | undefined,
    queryName = "fetchCart",
): UseQueryResult<ICart | null, unknown> => {
    const auth = useAuth();
    return useQuery<ICart | null, unknown>(
        [queryName, cartId],
        async (): Promise<ICart | null> => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch cart without authorization token.",
                );
            }
            if (!cartId) {
                return null;
            }
            return await carts.getCart({
                cartId,
                token: auth.token,
            });
        },
    );
};
