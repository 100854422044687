import { FunctionComponent, ReactElement, useCallback } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    ListItemText as MuiListItemText,
    Select,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { Close } from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useCreateDiscount, useUpdateDiscount } from "../../hooks";
import { useMessage } from "../../sdk/hooks";
import { IDiscountCode } from "../../sdk/types";
import { DiscountCodeStatus, discountCodeStatusNames } from "../../utils/enums";

const StyledDialog = styled(Dialog)``;

const Title = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const StyledDialogTitle = styled(DialogTitle)`
    width: 100%;
    background-color: #f0f0f0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(1, 2)};
`;

const StyledDialogActions = styled(DialogActions)`
    padding: ${({ theme }) => theme.spacing(1, 3, 3, 3)};
`;

const Cancel = styled(Close)`
    size: 24px;

    ${({ theme }) => theme.breakpoints.down("sm")} {
        size: 12px;
    }
`;

const StyledDialogContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(3)};
`;

const GridContainer = styled("div")`
    width: 100%;

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: ${({ theme }) => theme.spacing(4)};
`;

const TextFieldContainer = styled("div")`
    height: 40px;
`;

const SelectField = styled(Select)`
    height: 40px;
`;

const ListItemText = styled(MuiListItemText)`
    text-transform: capitalize;
`;

const Container = styled("div")``;

const statuses = Object.keys(discountCodeStatusNames);

const validationSchema = Yup.object({
    code: Yup.string().required("Please enter valid discount code"),
    status: Yup.number()
        .integer()
        .oneOf([
            DiscountCodeStatus.DRAFT,
            DiscountCodeStatus.ACTIVE,
            DiscountCodeStatus.ARCHIVED,
        ])
        .required(),
});

export interface IDiscountCodeDialogProps {
    discountCodes: IDiscountCode[];
    discountCode: IDiscountCode | null;
    open: boolean;
    onClose: () => void;
    priceRuleId: string | undefined;
    onAdd: (index: number, code: string, status: DiscountCodeStatus) => void;
    selectedCodeIndex: number | null;
    createNewPriceRule: boolean;
}

export const DiscountCodeDialog: FunctionComponent<IDiscountCodeDialogProps> = (
    props: IDiscountCodeDialogProps,
): ReactElement => {
    const {
        discountCode,
        discountCodes,
        open,
        onClose,
        onAdd,
        selectedCodeIndex,
        createNewPriceRule,
        priceRuleId,
    } = props;
    const createNewDiscount = discountCode === null;
    const createDiscountCodeMutation = useCreateDiscount([
        "priceRule",
        priceRuleId ?? "",
    ]);
    const updateDiscountCodeMutation = useUpdateDiscount([
        "priceRule",
        priceRuleId ?? "",
    ]);
    const message = useMessage();

    const {
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        handleBlur,
        dirty,
    } = useFormik({
        initialValues: {
            id: discountCode?.id ?? `${discountCodes.length + 1}`,
            code: discountCode?.code ?? "",
            status: discountCode?.status ?? DiscountCodeStatus.DRAFT,
        },
        onSubmit: (values0) => {
            if (selectedCodeIndex !== null) {
                const { id, code, status } = values0;
                updateDiscountCodeMutation.mutate({
                    id,
                    code,
                    status,
                });
            }
            if (
                selectedCodeIndex === null &&
                !createNewPriceRule &&
                priceRuleId
            ) {
                createDiscountCodeMutation.mutate(
                    {
                        priceRuleId,
                        codes: [{ code: values0.code, status: values0.status }],
                    },
                    {
                        onSuccess: () => {
                            message.showSuccess(
                                "Discount code created successfully",
                            );
                        },
                        onError: () => {
                            message.showError("Something went wrong");
                        },
                    },
                );
            } else if (selectedCodeIndex === null) {
                onAdd(discountCodes.length, values0.code, values0.status);
            }
            onClose();
        },
        validationSchema,
    });

    const handleSaveDiscount = useCallback(() => {
        handleSubmit();
    }, [handleSubmit]);

    return (
        <StyledDialog
            maxWidth={"xs"}
            fullWidth={true}
            disableScrollLock={true}
            open={open}
            onClose={onClose}
        >
            <StyledDialogTitle>
                <Title>
                    {createNewDiscount
                        ? "Create Discount Code"
                        : "Edit Discount Code"}
                </Title>
                <IconButton onClick={onClose}>
                    <Cancel />
                </IconButton>
            </StyledDialogTitle>
            <StyledDialogContent>
                <GridContainer>
                    <Container>
                        <TextFieldContainer>
                            <TextField
                                label="Code"
                                required={true}
                                fullWidth={true}
                                name="code"
                                value={values.code}
                                onChange={handleChange}
                                size="small"
                                variant="outlined"
                                onBlur={handleBlur}
                                error={Boolean(errors.code) && touched.code}
                                helperText={touched.code && errors.code}
                            />
                        </TextFieldContainer>
                    </Container>
                    <Container>
                        <FormControl size="small" fullWidth={true}>
                            <InputLabel>Status</InputLabel>
                            <SelectField
                                label="Status"
                                fullWidth={true}
                                required={true}
                                name="status"
                                size="small"
                                value={values.status}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(errors.status) && touched.status}
                            >
                                {statuses.map((status) => (
                                    <MenuItem key={status} value={status}>
                                        <ListItemText
                                            primary={
                                                discountCodeStatusNames[
                                                    Number(status)
                                                ]
                                            }
                                        />
                                    </MenuItem>
                                ))}
                            </SelectField>
                            {Boolean(errors.status) && touched.status && (
                                <FormHelperText>{errors.status}</FormHelperText>
                            )}
                        </FormControl>
                    </Container>
                </GridContainer>
            </StyledDialogContent>
            <StyledDialogActions>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={onClose}
                    disabled={!dirty}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSaveDiscount}
                    disabled={!dirty}
                >
                    Save
                </Button>
            </StyledDialogActions>
        </StyledDialog>
    );
};
