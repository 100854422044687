export const TypesenseConfig = {
    apiKey: process.env.REACT_APP_TYPESENSE_SEARCH_API_KEY ?? "xyz",
    nodes: [
        {
            host: process.env.REACT_APP_TYPESENSE_HOST ?? "localhost",
            port: process.env.REACT_APP_TYPESENSE_PORT
                ? Number(process.env.REACT_APP_TYPESENSE_PORT)
                : 8108,
            protocol: process.env.NODE_ENV === "development" ? "http" : "https",
        },
    ],
    connectionTimeoutSeconds: 10,
    numRetries: 8,
};
