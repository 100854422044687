import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useMemo,
} from "react";

import { StepIcon, styled } from "@mui/material";
import {
    Paper,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";

import { AddCircle, Cancel, CheckCircle } from "@mui/icons-material";

import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";

import {
    CustomerReviewStatusMap,
    IAdmin,
    ICustomerReview,
    ICustomerReviewModeration,
} from "../../sdk/types";
import { formatDate } from "../../sdk/utils/numbers";

import { CustomerReviewFooter } from "./CustomerReviewFooter";

const Root = styled("div")`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing(2, 7)};
    gap: ${({ theme }) => theme.spacing(2)};
`;

const StyledPaper = styled(Paper)`
    display: flex;

    padding: ${({ theme }) => theme.spacing(3)};
`;

const ClickablePaper = styled(StyledPaper)`
    cursor: pointer;
`;

const PaperHeader = styled(Typography)`
    font-size: 20px;
    font-weight: 500;
    color: gray;
`;

const PrimaryImage = styled("img")`
    width: 150px;
    height: 150px;

    object-fit: cover;
`;

const DetailText = styled(Typography)`
    font-size: 14px;
    width: 500px;
`;

const DetailsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing(0.5)};
`;

export interface ICustomerReviewHistoryProps {
    moderationList: ICustomerReviewModeration[];
    review: ICustomerReview;
    expanded: boolean;
    onUpdateReviewStatus: (reviewId: string, status: number) => void;
    onReject: (event: React.MouseEvent) => void;
    onApproval: (event: React.MouseEvent) => void;
    onApprovalWithComment: (event: React.MouseEvent) => void;
    getAdmin: (moderator: string) => IAdmin | undefined;
}

export const CustomerReviewHistory: FunctionComponent<
    ICustomerReviewHistoryProps
> = (props: ICustomerReviewHistoryProps): ReactElement => {
    const {
        moderationList,
        review,
        expanded,
        onApproval,
        onApprovalWithComment,
        onReject,
        getAdmin,
    } = props;

    const sortedModerationList = useMemo(() => {
        return moderationList
            .filter((moderation) => moderation.moderatedBy)
            .sort(
                (a, b) =>
                    new Date(b.updatedAt).getTime() -
                    new Date(a.updatedAt).getTime(),
            );
    }, [moderationList]);
    const navigate = useNavigate();

    const steps = useMemo(() => {
        return sortedModerationList.map((moderation, index) => {
            let label = "";
            let description = "";
            const status = moderation.status;

            if (moderation.moderatedBy) {
                label = `${
                    CustomerReviewStatusMap[moderation.status]
                } ${formatDistanceToNow(new Date(moderation.updatedAt))} ago`;
                description = `by ${
                    getAdmin(moderation.moderatedBy)?.firstName +
                    " " +
                    getAdmin(moderation.moderatedBy)?.lastName
                }`;
                description += moderation.comment
                    ? ` with comment - ${moderation.comment}`
                    : "";
            }
            if (moderation.assignedBy && moderation.assignedTo) {
                label = `${
                    CustomerReviewStatusMap[moderation.status]
                } ${formatDistanceToNow(new Date(moderation.updatedAt))} ago`;
                const assignedToAdmin = getAdmin(moderation.assignedTo ?? "");
                const assignedByAdmin = getAdmin(moderation.assignedBy ?? "");
                description = `Assigned ${formatDistanceToNow(
                    new Date(moderation.updatedAt),
                )} ago to ${
                    (assignedToAdmin?.firstName ?? "") +
                    " " +
                    (assignedToAdmin?.lastName ?? "")
                }\nby ${
                    (assignedByAdmin?.firstName ?? "") +
                    " " +
                    (assignedByAdmin?.lastName ?? "")
                }`;
                description += moderation.comment
                    ? ` with comment - ${moderation.comment}`
                    : "";
            }

            return {
                label,
                description,
                status,
            };
        });
    }, [getAdmin, sortedModerationList]);

    const handleOrderRedirect = useCallback(
        (orderId: string) => {
            navigate(`/orders/${orderId}`);
        },
        [navigate],
    );

    const handleProductRedirect = useCallback(
        (productId: string, variantId: string) => {
            navigate(`/products/${productId}/variants/${variantId}/edit`);
        },
        [navigate],
    );

    return (
        <Root>
            <ClickablePaper
                variant="outlined"
                onClick={() =>
                    handleProductRedirect(
                        review.variant.productId,
                        review.variant?.id ?? "",
                    )
                }
            >
                <PrimaryImage
                    src={review.variant.images?.[0]?.objectUrl ?? ""}
                />
                <DetailsContainer>
                    <PaperHeader>Reviewing</PaperHeader>
                    <DetailText>
                        {review.variant.product.title +
                            " " +
                            review.variant.title}
                    </DetailText>
                    <DetailText>{review.variant.id}</DetailText>
                </DetailsContainer>
            </ClickablePaper>
            <ClickablePaper
                variant="outlined"
                onClick={() => handleOrderRedirect(review.order?.id ?? "")}
            >
                <DetailsContainer>
                    <PaperHeader>Order</PaperHeader>
                    <DetailText>{review.order?.name ?? ""}</DetailText>
                    <DetailText>{review.order?.id}</DetailText>
                    {review.order?.updatedAt && (
                        <DetailText>
                            Ordered on{" "}
                            {formatDate(new Date(review.order.updatedAt))}
                        </DetailText>
                    )}
                </DetailsContainer>
            </ClickablePaper>
            {moderationList.length > 0 && (
                <StyledPaper variant="outlined">
                    <Stepper orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={index} active={true}>
                                <StepLabel
                                    icon={
                                        <StepIcon
                                            icon={
                                                step.status === 1 ? (
                                                    <CheckCircle />
                                                ) : step.status === 2 ? (
                                                    <Cancel />
                                                ) : null
                                            }
                                        />
                                    }
                                >
                                    {step.label}
                                </StepLabel>
                                <StepContent>
                                    <DetailText>{step.description}</DetailText>
                                </StepContent>
                            </Step>
                        ))}
                        <Step active={true}>
                            <StepLabel icon={<StepIcon icon={<AddCircle />} />}>
                                {`Created on ${formatDate(
                                    new Date(review.createdAt),
                                )}`}
                            </StepLabel>
                        </Step>
                    </Stepper>
                </StyledPaper>
            )}
            {expanded && (
                <CustomerReviewFooter
                    review={review}
                    onApproval={onApproval}
                    onApprovalWithComment={onApprovalWithComment}
                    onReject={onReject}
                />
            )}
        </Root>
    );
};
