import { FunctionComponent, ReactElement } from "react";

import { Typography, styled } from "@mui/material";

import { InstantSearch } from "react-instantsearch";
import { SearchBox } from "react-instantsearch";

import { CustomersGrid } from "./CustomersGrid";
import { typesenseAdapter } from "./config/typesense.config.adapter";

const Root = styled("section")`
    padding: ${({ theme }) => theme.spacing(3)};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const TitleContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Heading = styled(Typography)`
    font-size: ${({ theme }) => theme.spacing(3)};
    font-weight: 500;
`;
const SearchContainer = styled("div")`
    display: flex;

    align-items: center;

    border: 1px solid ${({ theme }) => theme.palette.divider};

    border-radius: ${({ theme }) => theme.shape.borderRadius};

    overflow: hidden;

    transition: border-color 0.3s ease, box-shadow 0.3s ease;
`;

const StyledSearchBox = styled(SearchBox)`
    width: 100%;

    display: flex;

    & input {
        flex-grow: 1;

        padding: ${({ theme }) => theme.spacing(1.5)};

        border: none;

        outline: none;

        font-size: ${({ theme }) => theme.typography.body1.fontSize};

        appearance: none;
        &::-webkit-search-cancel-button {
            display: none;
        }
    }
    & button {
        padding: ${({ theme }) => theme.spacing(1.5)};

        background-color: #fff;

        border: none;

        cursor: pointer;

        transition: background-color 0.3s ease;
    }
`;

export const ViewCustomersScreen: FunctionComponent = (): ReactElement => {
    return (
        <InstantSearch
            searchClient={typesenseAdapter.searchClient}
            indexName={`${process.env.REACT_APP_TYPESENSE_PREFIX}customers`}
        >
            <Root>
                <TitleContainer>
                    <Heading>Customers</Heading>
                    <SearchContainer>
                        <StyledSearchBox />
                    </SearchContainer>
                </TitleContainer>

                <CustomersGrid />
            </Root>
        </InstantSearch>
    );
};
