import React, { ChangeEvent, FunctionComponent, useCallback } from "react";

import {
    Button,
    FormControlLabel,
    Paper,
    Radio,
    Typography,
    styled,
} from "@mui/material";

import { CheckCircle, Delete, Edit } from "@mui/icons-material";

import { ICustomerAddress } from "../../../../sdk/types";
import { stateCodes } from "../../../../utils/constants";

const Root = styled(Paper)`
    display: flex;

    width: 100%;
    box-shadow: none;

    padding: ${({ theme }) => theme.spacing(1, 2)};

    &:hover {
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    }
`;

const AddressText = styled("div")`
    display: flex;
    flex-direction: column;
    width: 90%;

    padding: ${({ theme }) => theme.spacing(2, 1, 1, 1)};
    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        padding: ${theme.spacing(1)};
    }
    `}
`;

const FullAddress = styled(Typography)`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        font-size: 12px;
    }
    `}
`;

const OtherDetails = styled(Typography)`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        font-size: 12px;
    }
    `}
`;

const MobileText = styled(Typography)`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    line-height: 20px;
    margin-top: ${({ theme }) => theme.spacing(1)};

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        font-size: 12px;
        margin-top: ${theme.spacing(0.5)};
    }
    `}
`;

const UtilContainer = styled("div")`
    display: flex;
    column-gap: ${({ theme }) => theme.spacing(1)};
    justify-content: end;
    margin-top: ${({ theme }) => theme.spacing(2)};
    color: gray;
`;

const FullName = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
`;

const ActionButton = styled(Button)`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};

    text-transform: none;
`;

const LabelGroup = styled(FormControlLabel)`
    place-self: start;
    margin-top: ${({ theme }) => theme.spacing(1)};
`;

export interface IExistingAddressCard {
    address: ICustomerAddress;
    isSelected: boolean;
    index: number;
    onEditAddress: (index: number, id: string) => void;
    onDeleteAddress: (index: number, id: string) => void;
    onSelectAddress: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const AddressCard: FunctionComponent<IExistingAddressCard> = (
    props: IExistingAddressCard,
) => {
    const {
        address,
        index,
        isSelected,
        onEditAddress,
        onDeleteAddress,
        onSelectAddress,
    } = props;
    const {
        id,
        addressLine1,
        addressLine2,
        city,
        firstName,
        lastName,
        phoneNumber,
        alternatePhoneNumber,
        emailAddress,
        stateCode,
        zip,
    } = address;

    const state = stateCodes[stateCode];

    const handleEdit = useCallback(() => {
        onEditAddress(index, id);
    }, [onEditAddress, id, index]);

    const handleDelete = useCallback(() => {
        onDeleteAddress(index, id);
    }, [onDeleteAddress, id, index]);

    const createChangeEvent = (
        value: string,
    ): ChangeEvent<HTMLInputElement> => {
        const event: any = {
            target: {
                value,
            },
        };

        return event;
    };

    const handleCardClick = useCallback(() => {
        onSelectAddress(createChangeEvent(index.toString()));
    }, [onSelectAddress, index]);

    return (
        <Root variant="outlined" onClick={handleCardClick}>
            <LabelGroup
                value={index}
                control={
                    <Radio
                        id="address-card--radio--address"
                        size="small"
                        checked={isSelected}
                        checkedIcon={<CheckCircle color="success" />}
                    />
                }
                label=""
                name="address"
            />
            <AddressText>
                <FullName>
                    {firstName} {lastName}
                </FullName>
                <FullAddress>{addressLine1}</FullAddress>
                <FullAddress>{addressLine2}</FullAddress>
                <OtherDetails>
                    {city}, {state} - {zip}
                </OtherDetails>
                <MobileText>Mobile - {phoneNumber}</MobileText>
                {alternatePhoneNumber && (
                    <MobileText>
                        Alternate Mobile - {alternatePhoneNumber}
                    </MobileText>
                )}
                <MobileText>Email Address - {emailAddress}</MobileText>

                <UtilContainer>
                    <ActionButton
                        id="address-card--button--remove"
                        variant="text"
                        size="small"
                        color="inherit"
                        onClick={handleDelete}
                    >
                        <Delete />
                        Remove
                    </ActionButton>
                    <ActionButton
                        id="address-card--button--add"
                        variant="text"
                        size="small"
                        color="inherit"
                        onClick={handleEdit}
                    >
                        <Edit />
                        Edit
                    </ActionButton>
                </UtilContainer>
            </AddressText>
        </Root>
    );
};
