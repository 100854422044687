import { UseMutationResult, useMutation } from "react-query";

import { ILink } from "../../types";
import { api } from "../../utils";
import { useAuth } from "../useAuth";
import { useMessage } from "../useMessage";

export interface IUseCreateLinkMutationParameters {
    name: string;
    description: string;
    startsAt: Date | null;
    endsAt: Date | null;
    destination: string;
    alias: string;
    allowIab: boolean;
    tags: string;
}

/**
 * Creates a new link.
 *
 * @author Samuel Rowe <samuel@houseofx.in>
 */
export const useCreateLink = (): UseMutationResult<
    ILink,
    unknown,
    IUseCreateLinkMutationParameters
> => {
    const auth = useAuth();
    const { showError } = useMessage();

    return useMutation<ILink, unknown, IUseCreateLinkMutationParameters>({
        mutationFn: (
            parameters: IUseCreateLinkMutationParameters,
        ): Promise<ILink> => {
            if (!auth.token) {
                throw new Error(
                    "Cannot create link without authorization token.",
                );
            }

            return api.Link.create({
                ...parameters,
                token: auth.token,
            });
        },
        onError: (error: any) => showError(error),
    });
};
