import { UseQueryResult, useQuery } from "react-query";

import { IAdmin, IExternalListPage } from "../types";
import { api } from "../utils";

import { useAuth } from "./useAuth";

export interface IGetAllAdminsParameters {
    limit: number;
    cursor: string | null;
    direction: string;
}

export const useGetAdmins = (
    queryKey: string | string[],
    parameters?: IGetAllAdminsParameters,
): UseQueryResult<IExternalListPage<IAdmin>, unknown> => {
    const auth = useAuth();

    return useQuery<IExternalListPage<IAdmin>, unknown>({
        queryKey,
        queryFn: async () => {
            if (!auth.token) {
                throw new Error(
                    "Cannot fetch admins without authorization token.",
                );
            }

            return await api.admins.Admin.list({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
