import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";

import { Button, TextField, Typography, styled } from "@mui/material";

import { AddCircle } from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik } from "formik";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { useNavigate, useParams } from "react-router-dom";

import EmptyImage from "../../assessts/empty_image.svg";
import { Paper, StickyBottomBar } from "../../components/common";
import {
    useCreateCollection,
    useDeleteCollection,
    useGetCollectionById,
    useMessage,
    useUpdateCollection,
} from "../../sdk/hooks";

import { VariantEditor } from "./VariantEditor";

const Root = styled("main")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(3)};

    margin: ${({ theme }) => theme.spacing(0, "auto")};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            gap: ${theme.spacing(3)};
    }
  `}
`;

const PaperContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(3)};
    flex-direction: column;
`;

const Row = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    width: 100%;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          flex-direction: column;
    }
  `}
`;

const LeftContainer = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(4)};
    flex-grow: 1;

    width: 100%;
    max-width: 800px;
`;

const Title = styled(Typography)`
    font-size: 22px;
    font-weight: 600;
    text-transform: capitalize;
`;

const VariantContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(3)};
    flex-direction: column;
`;

const ButtonContainer = styled("div")`
    margin-top: ${({ theme }) => theme.spacing(3)};
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const SectionTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
    color: #626262;

    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const FieldContainer = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    flex: 1;
`;

const ProductContainer = styled("section")`
    max-width: 800px;
`;

const Container = styled("div")``;

const TextFieldContainer = styled("div")`
    height: 40px;
`;

const MultilineTextFieldContainer = styled("div")`
    width: 100%;
`;

const TopContainer = styled("div")`
    display: flex;
    height: 100%;

    margin-bottom: ${({ theme }) => theme.spacing(4)};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            flex-direction: column
    }
  `}
`;

const TableHeader = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const NoProductsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    width: 100%;
    height: 300px;
`;

const NoProductsText = styled(Typography)`
    font-size: 18px;
    font-weight: 500;

    color: grey;
`;

const EmptyImageContainer = styled("img")`
    height: 220px;
    width: 220px;
`;

export interface IWeightedVariants {
    manualScore: number;
    id: string;
}

export interface IUpdateCollectionForm {
    title: string;
    description: string;
    slug: string;
    sortOrder: number;
    id: string;
    variants: IWeightedVariants[];
}

const validationSchema = Yup.object({
    title: Yup.string().min(1).required("Please enter a valid title"),
    description: Yup.string()
        .min(1)
        .required("Please enter a valid description"),
    slug: Yup.string().min(1),
    sortOrder: Yup.number().min(1).default(1),
    variants: Yup.array()
        .of(
            Yup.object({
                id: Yup.string().uuid(),
                manualScore: Yup.number().integer(),
            }),
        )
        .required("Please select products"),
});

export const ProductCollectionScreen: FunctionComponent = (): ReactElement => {
    const params = useParams();
    const createNewCollection = params.id === "new";
    const navigate = useNavigate();

    const collectionQuery = useGetCollectionById(params.id ?? "");

    const createCollectionMutation = useCreateCollection();

    const updateCollectionMutation = useUpdateCollection("collections");
    const deleteCollectionMutation = useDeleteCollection("collections");

    const [pageTitle, setPageTitle] = useState("");

    const { showError, showSuccess } = useMessage();

    const [selectedVariants, setSelectedVariants] = useState<
        IWeightedVariants[]
    >([]);

    useEffect(() => {
        if (createNewCollection) {
            setPageTitle("Create");
            setSelectedVariants([]);
        } else {
            setPageTitle("Edit");
            setSelectedVariants(
                collectionQuery.data?.products.map((product) => ({
                    id: product.variant.id,
                    manualScore: product.manualScore,
                })) ?? [],
            );
        }
    }, [collectionQuery.data?.products, createNewCollection]);

    const {
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
        handleBlur,
        setFormikState,
    } = useFormik<IUpdateCollectionForm>({
        initialValues: {
            title: "",
            description: "",
            slug: "",
            sortOrder: 1,
            id: params.id ?? "",
            variants: selectedVariants,
        },
        validationSchema,
        onSubmit: async (values) => {
            if (createNewCollection) {
                await createCollectionMutation.mutate(values, {
                    onSuccess: () => {
                        showSuccess("Collection created successfully");
                        navigate("/product-collections");
                    },
                });
            }
            if (!createNewCollection) {
                await updateCollectionMutation.mutate(values, {
                    onSuccess: () => {
                        showSuccess("Collection updated successfully");
                        navigate("/product-collections");
                    },
                });
            }
        },
    });

    const handleDeleteVariant = useCallback(
        (index: number) => {
            setFormikState((prev) => {
                const updatedVariants = prev.values.variants
                    .filter((variant, index0) => index0 !== index)
                    .map((variant, index) => ({
                        ...variant,
                        manualScore: index,
                    }));
                return {
                    ...prev,
                    values: { ...prev.values, variants: updatedVariants },
                };
            });
        },
        [setFieldValue, values.variants],
    );

    const handleAddVariant = useCallback(() => {
        setFieldValue("variants", [
            ...values.variants,
            { id: "", manualScore: values.variants.length },
        ]);
    }, [setFieldValue, values.variants]);

    const handleSaveVariant = useCallback(
        (index: number, variantId: string) => {
            setFormikState((prev) => {
                const updatedVariants = [...prev.values.variants].map(
                    (variant0, index0) =>
                        index0 === index
                            ? { ...variant0, id: variantId }
                            : variant0,
                );
                return {
                    ...prev,
                    values: { ...prev.values, variants: updatedVariants },
                };
            });
        },
        [setFieldValue, values.variants],
    );

    useEffect(() => {
        if (!createNewCollection) {
            setFieldValue("id", collectionQuery.data?.id);
            setFieldValue("title", collectionQuery.data?.title);
            setFieldValue("description", collectionQuery.data?.description);
            setFieldValue("slug", collectionQuery.data?.slug);
            setFieldValue("variants", selectedVariants);
        }
    }, [
        collectionQuery.data,
        createNewCollection,
        selectedVariants,
        setFieldValue,
        values.title,
    ]);

    const handleDelete = useCallback(() => {
        deleteCollectionMutation.mutate(
            {
                id: params.id ?? "",
            },
            {
                onSuccess: () => {
                    showError("Collection deleted successfully");
                    navigate("/product-collections");
                },
            },
        );
    }, [deleteCollectionMutation, params.id, showError]);

    const handleSave = useCallback(() => {
        handleSubmit();
    }, [handleSubmit]);

    const handleDiscard = useCallback(() => {
        navigate("/product-collections");
    }, [navigate]);

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) return;
            const variants = Array.from(values.variants);
            const reorderedVariant = variants.filter(
                (variant, index) => index === result.source.index,
            )[0];
            variants.splice(result.source.index, 1);
            variants.splice(result.destination.index, 0, reorderedVariant);
            setFieldValue(
                "variants",
                variants.map((variant, index) => ({
                    ...variant,
                    manualScore: index,
                })),
            );
        },
        [values.variants],
    );

    return (
        <>
            <Container>
                <Root>
                    <TopContainer>
                        <LeftContainer>
                            <Title>{pageTitle} product collection</Title>
                            {/* General */}
                            <Paper>
                                <SectionTitle>General</SectionTitle>
                                <PaperContainer>
                                    <Row>
                                        <FieldContainer>
                                            <TextFieldContainer>
                                                <TextField
                                                    required={true}
                                                    fullWidth={true}
                                                    name="title"
                                                    label="Title"
                                                    placeholder="Title"
                                                    value={values.title}
                                                    onChange={handleChange}
                                                    size="small"
                                                    variant="outlined"
                                                    onBlur={handleBlur}
                                                    error={
                                                        Boolean(errors.title) &&
                                                        touched.title
                                                    }
                                                    helperText={
                                                        touched.title &&
                                                        errors.title
                                                    }
                                                />
                                            </TextFieldContainer>
                                        </FieldContainer>
                                        <FieldContainer>
                                            <TextFieldContainer>
                                                <TextField
                                                    required={true}
                                                    fullWidth={true}
                                                    name="slug"
                                                    label="Slug"
                                                    placeholder="Slug"
                                                    value={values.slug}
                                                    onChange={handleChange}
                                                    size="small"
                                                    variant="outlined"
                                                    onBlur={handleBlur}
                                                    error={
                                                        Boolean(errors.slug) &&
                                                        touched.slug
                                                    }
                                                    helperText={
                                                        touched.slug &&
                                                        errors.slug
                                                    }
                                                />
                                            </TextFieldContainer>
                                        </FieldContainer>
                                    </Row>
                                    <MultilineTextFieldContainer>
                                        <TextField
                                            required={true}
                                            multiline={true}
                                            rows={5}
                                            fullWidth={true}
                                            name="description"
                                            label="Description"
                                            placeholder="Description"
                                            value={values.description}
                                            onChange={handleChange}
                                            size="small"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            error={
                                                Boolean(errors.description) &&
                                                touched.description
                                            }
                                            helperText={
                                                touched.description &&
                                                errors.description
                                            }
                                        />
                                    </MultilineTextFieldContainer>
                                </PaperContainer>
                            </Paper>
                        </LeftContainer>
                    </TopContainer>
                    {/* Variants */}
                    <ProductContainer>
                        <Paper>
                            <TableHeader>
                                <Title>Variants</Title>
                            </TableHeader>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="products">
                                    {(provided) => (
                                        <VariantContainer
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {(values.variants ?? []).length >
                                                0 &&
                                                values.variants
                                                    .sort(
                                                        (
                                                            firstVariant,
                                                            secondVariant,
                                                        ) =>
                                                            firstVariant.manualScore -
                                                            secondVariant.manualScore,
                                                    )
                                                    .map((variant, index) => (
                                                        <VariantEditor
                                                            variant={variant}
                                                            onDeleteVariant={
                                                                handleDeleteVariant
                                                            }
                                                            onSaveVariant={
                                                                handleSaveVariant
                                                            }
                                                            index={index}
                                                            selectedVariants={
                                                                values.variants
                                                            }
                                                        />
                                                    ))}
                                            {provided.placeholder}
                                        </VariantContainer>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {values.variants.length > 0 && (
                                <ButtonContainer>
                                    <Button
                                        startIcon={<AddCircle />}
                                        variant="outlined"
                                        onClick={handleAddVariant}
                                    >
                                        Add products
                                    </Button>
                                </ButtonContainer>
                            )}
                            {createNewCollection &&
                                values.variants.length === 0 && (
                                    <NoProductsContainer>
                                        <EmptyImageContainer src={EmptyImage} />
                                        <NoProductsText>
                                            No products selected
                                        </NoProductsText>
                                        <Button
                                            startIcon={<AddCircle />}
                                            variant="outlined"
                                            onClick={handleAddVariant}
                                        >
                                            Add products
                                        </Button>
                                    </NoProductsContainer>
                                )}
                        </Paper>
                    </ProductContainer>
                </Root>
            </Container>
            {createNewCollection && (
                <StickyBottomBar
                    cancelText="Discard"
                    submitText="Save"
                    onCancel={handleDiscard}
                    onSubmit={handleSubmit}
                />
            )}
            {!createNewCollection && (
                <StickyBottomBar
                    cancelText="Delete"
                    submitText="Save"
                    onCancel={handleDelete}
                    onSubmit={handleSave}
                />
            )}
        </>
    );
};
