import { UseQueryResult, useQuery } from "react-query";

import { productStatusByEnum } from "../../utils/enums";
import { IProduct } from "../types";
import { api } from "../utils";

import { useAuth } from "./useAuth";

export interface IFetchProductsParameters {
    status: string[];
}

export const useGetAllProducts = (
    queryKey: string | string[],
    parameters?: IFetchProductsParameters,
): UseQueryResult<IProduct[], unknown> => {
    const auth = useAuth();

    return useQuery(queryKey, async (): Promise<IProduct[]> => {
        if (!auth.token) {
            throw new Error(
                "Cannot fetch products without authorization token.",
            );
        }

        const defaultProductStatus = Object.keys(productStatusByEnum);

        return await api.Products.fetchAllProducts({
            status: parameters?.status ?? defaultProductStatus,
            token: auth.token,
        });
    });
};
