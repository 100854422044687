import { FunctionComponent, ReactElement, useCallback } from "react";

import { Radio, Typography, styled } from "@mui/material";

import { CheckCircle } from "@mui/icons-material";

const Root = styled("div")`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: ${({ theme }) => theme.spacing(1)};
`;

const Group = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(0.5)};
`;

const Code = styled(Typography)`
    margin-top: ${({ theme }) => theme.spacing(0.5)};
    font-weight: 500;
`;

const Description = styled(Typography)`
    font-size: 12px;
    margin: 0px;
`;

export interface IPaymentMethodTileProps {
    id: string;
    code: string;
    selected: boolean;
    description: string;
    onSelect: (id: string, platform: number) => void;
    platform: number;
    changingPaymentMethod: boolean;
}

export const PaymentMethodTile: FunctionComponent<IPaymentMethodTileProps> = (
    props: IPaymentMethodTileProps,
): ReactElement => {
    const {
        onSelect,
        id,
        selected,
        code,
        description,
        platform,
        changingPaymentMethod,
    } = props;

    const handleClick = useCallback(() => {
        onSelect(id, platform);
    }, [onSelect, id, platform]);

    return (
        <Root onClick={handleClick}>
            <Radio
                id="payment-method--radio--payment"
                checked={selected}
                size="small"
                color="default"
                disabled={selected || changingPaymentMethod}
                checkedIcon={<CheckCircle color="success" />}
            />
            <Group>
                <Code gutterBottom={false}>{code}</Code>
                <Description>{description}</Description>
            </Group>
        </Root>
    );
};
