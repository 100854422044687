import axios from "axios";

import * as api from "../../types";
import { IProductVariant } from "../../../types";

export interface IFetchAllProductsProps {
    status: string[];
    token: string;
}

export interface ICreateProductProps {
    title: string;
    description: string;
    tagLine: string;
    featureScore: number;
    bestSellerScore: number;
    slug: string;
    shopifyProductId: string;
    variants: IProductVariant[];
    status: number;
    token: string;
    meta: Record<string, unknown>;
    schemaId?: string;
    value?: object;
}

export interface IUpdateProductParameters {
    id: string;
    title: string;
    description: string;
    tagLine: string;
    featureScore: number;
    bestSellerScore: number;
    slug: string;
    shopifyProductId: string;
    status: number;
    token: string;
    schemaId?: string;
    value?: object;
}

export interface ICreateProductVariantParameters {
    productId: string;
    title: string;
    shopifyVariantId: string;
    price: number;
    mrp: number;
    shippingPrice: number;
    sku: string;
    position: number;
    status: number;
    token: string;
}

export interface IUpdateProductVariantParameters {
    id: string;
    productId: string;
    title: string;
    shopifyVariantId: string;
    price: number;
    mrp: number;
    shippingPrice: number;
    sku: string;
    position: number;
    status: number;
    token: string;
}

export interface IFetchProductParameters {
    slug: string | undefined;
    token: string;
}

export class Products {
    private static transform(data: any): api.IProduct {
        return {
            ...data,
            createdAt: new Date(data.createdAt),
            updatedAt: new Date(data.updatedAt),
        };
    }

    public static async fetchAllProducts(
        parameters: IFetchAllProductsProps,
    ): Promise<api.IProduct[]> {
        const { token, ...body } = parameters;

        const params = new URLSearchParams([
            ...body.status.map((status) => ["status", status]),
        ]);

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/products`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
                params,
            },
        );

        return data.map(this.transform);
    }

    public static async fetchProduct(
        parameters: IFetchProductParameters,
    ): Promise<api.IProduct> {
        const { slug, token } = parameters;
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/products/${slug}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return this.transform(data);
    }

    public static async createProduct(
        parameters: ICreateProductProps,
    ): Promise<api.IProduct> {
        const { token, ...body } = parameters;

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/products`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return this.transform(data);
    }

    public static async updateProduct(
        parameters: IUpdateProductParameters,
    ): Promise<api.IProduct> {
        const { token, id, ...body } = parameters;

        const { data } = await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/products/${id}`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return this.transform(data);
    }

    public static async createVariant(
        parameters: ICreateProductVariantParameters,
    ): Promise<api.IProduct> {
        const { token, productId, ...body } = parameters;

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/products/${productId}/variants`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return this.transform(data);
    }

    public static async updateVariant(
        parameters: IUpdateProductVariantParameters,
    ): Promise<api.IProduct> {
        const { token, id, productId, ...body } = parameters;

        const { data } = await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/products/${productId}/variants/${id}`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );
        return this.transform(data);
    }
}
