import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";

import {
    Autocomplete,
    Chip,
    IconButton,
    TextField,
    styled,
} from "@mui/material";

import { Delete, ImageNotSupported } from "@mui/icons-material";
import { DragIndicator } from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik } from "formik";
import { Draggable } from "react-beautiful-dnd";

import { IWeightedVariant, useGetAllProducts } from "../../sdk/hooks";
import { formatCurrency } from "../../sdk/utils/numbers";
import { IVariantCollectionEntry } from "../../types";
import { ProductStatus, productStatusMap } from "../../utils/enums";

const Root = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};

    min-width: 700px;

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            min-width: 300px;
    }
  `}
`;

const TextFieldContainer = styled("div")`
    flex: 1;
`;

const ProductImage = styled("img")`
    height: 70px;
    width: 70px;
    object-fit: cover;

    border-radius: ${({ theme }) => theme.spacing(1)};
`;

const VariantActionContainer = styled("div")`
    display: flex;
    align-items: center;
`;

const VariantContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const VariantPrice = styled("div")`
    color: ${({ theme }) => theme.palette.grey[700]};
`;

const BrokenImage = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;

    aspect-ratio: 5/6;
    height: 70px;
    width: 70px;
    background-color: #f4f4f4;
`;

const VariantLabel = styled("div")`
    width: 380px;

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            width: 200px;
    }
  `}
`;

const VariantDetails = styled("div")``;

const VariantHeader = styled("div")`
    display: flex;

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            flex-direction: column;
    }
  `}
`;

export interface IStatusProps {
    status: ProductStatus;
}

const statusColorMap: Record<ProductStatus, string> = {
    [ProductStatus.DRAFT]: "gray",
    [ProductStatus.PUBLISHED]: "green",
    [ProductStatus.ARCHIVED]: "red",
};

const StyledChip = styled(Chip)<IStatusProps>`
    height: 24px;
    width: 100px;

    color: ${({ status }) => statusColorMap[status]};
    border-color: ${({ status }) => statusColorMap[status]};
    position: absolute;
    right: 0;
    left: 80%;
`;

const validationSchema = Yup.object({
    id: Yup.string().required("Please enter valid product"),
});

interface IProductLabel {
    id: string;
    label: string;
}

export interface IVariantEditorProps {
    variant: IWeightedVariant;
    onDeleteVariant: (index: number) => void;
    onSaveVariant: (index: number, variantId: string) => void;
    index: number;
    selectedVariants: IWeightedVariant[];
}

export const VariantEditor: FunctionComponent<IVariantEditorProps> = (
    props: IVariantEditorProps,
): ReactElement => {
    const { variant, onDeleteVariant, onSaveVariant, index, selectedVariants } =
        props;
    const productsQuery = useGetAllProducts("products");

    const products = (productsQuery.data ?? []).flatMap((product) =>
        product.variants
            .filter((variant) => variant.price > 0)
            .map((variant) => {
                return {
                    id: variant.id,
                    label: `${product.title} - ${variant.title}`,
                };
            })
            .filter(
                (variant0) =>
                    variant0.id === variant.id ||
                    !selectedVariants
                        .map((selectedVariant) => selectedVariant.id)
                        .includes(variant0.id),
            ),
    );
    const [editable, setEditable] = useState(false);
    const handleToggleEdit = useCallback(() => {
        setEditable((isEditable) => !isEditable);
    }, []);

    const { values, handleSubmit, handleBlur, touched, errors, setFieldValue } =
        useFormik({
            enableReinitialize: true,
            initialValues: {
                id: variant.id,
                label:
                    products.find((product) => product.id === variant.id)
                        ?.label ?? "",
            },
            onSubmit: (values0) => {
                onSaveVariant(index, values0.id);
                handleToggleEdit();
            },
            validationSchema,
        });

    const handleAutocompleteChange = useCallback(
        (
            event: React.SyntheticEvent<Element, Event>,
            value: IProductLabel | null,
        ) => {
            if (value) {
                setFieldValue("id", value.id);
                setFieldValue("label", value.label);
                onSaveVariant(index, value.id);
            }
        },
        [],
    );

    const handleCancel = useCallback(() => {
        if (variant.id === "") {
            onDeleteVariant(index);
        } else {
            setFieldValue("id", variant.id);
            setFieldValue(
                "label",
                products.find((product) => product.id === variant.id)?.label ??
                    "",
            );
            handleToggleEdit();
        }
    }, [variant]);

    useEffect(() => {
        setEditable(values.id === "" && variant.id === "");
    }, [variant]);

    const variants: Record<string, IVariantCollectionEntry> = {};

    if (productsQuery.data) {
        // eslint-disable-next-line no-restricted-syntax
        for (const product of Object.values(productsQuery.data)) {
            // eslint-disable-next-line no-restricted-syntax
            for (const variant of product.variants) {
                variants[variant.id] = { ...variant };
            }
        }
    }

    return (
        <Draggable key={index} draggableId={index.toString()} index={index}>
            {(provided) => (
                <Root {...provided.draggableProps} ref={provided.innerRef}>
                    <IconButton {...provided.dragHandleProps}>
                        <DragIndicator />
                    </IconButton>
                    <TextFieldContainer>
                        <Autocomplete
                            value={{ id: values.id, label: values.label }}
                            onChange={handleAutocompleteChange}
                            disableClearable={true}
                            size="small"
                            options={products}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props}>
                                        <VariantContainer>
                                            {variants[option.id]?.images[0]
                                                ?.objectUrl && (
                                                <ProductImage
                                                    src={
                                                        variants[option.id]
                                                            ?.images[0]
                                                            ?.objectUrl ?? ""
                                                    }
                                                />
                                            )}
                                            {!variants[option.id]?.images[0]
                                                ?.objectUrl && (
                                                <BrokenImage>
                                                    <ImageNotSupported />
                                                </BrokenImage>
                                            )}
                                            <VariantDetails>
                                                <VariantHeader>
                                                    <VariantLabel>
                                                        {option.label}
                                                    </VariantLabel>
                                                    <StyledChip
                                                        variant="outlined"
                                                        status={
                                                            variants[option.id]
                                                                .status
                                                        }
                                                        label={
                                                            productStatusMap[
                                                                variants[
                                                                    option.id
                                                                ].status
                                                            ]
                                                        }
                                                    />
                                                </VariantHeader>
                                                <VariantPrice>
                                                    {formatCurrency(
                                                        variants[option.id]
                                                            .price,
                                                    )}
                                                </VariantPrice>
                                            </VariantDetails>
                                        </VariantContainer>
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    name="variantId"
                                    {...params}
                                    value={variant.id}
                                    error={Boolean(errors.id) && touched.id}
                                    helperText={touched.id && errors.id}
                                    onBlur={handleBlur}
                                />
                            )}
                        />
                    </TextFieldContainer>
                    {editable ? (
                        <VariantActionContainer>
                            <IconButton onClick={handleCancel}>
                                <Delete />
                            </IconButton>
                        </VariantActionContainer>
                    ) : (
                        <VariantActionContainer>
                            <IconButton onClick={() => onDeleteVariant(index)}>
                                <Delete />
                            </IconButton>
                        </VariantActionContainer>
                    )}
                </Root>
            )}
        </Draggable>
    );
};
