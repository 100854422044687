import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { Box, Button, Paper, Typography, styled } from "@mui/material";

import { CircularLoader, EmptyRecords } from "../../components/common";
import { useGetCustomerReviews } from "../../hooks";

import { CustomerReview } from "./CustomerReview";

const Root = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};

    padding: ${({ theme }) => theme.spacing(3)};
`;

const IconContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(3)};

    margin: ${({ theme }) => theme.spacing(2, 0)};
`;

const Title = styled(Typography)`
    font-weight: 500;
    font-size: 24px;

    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Container = styled("div")`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${({ theme }) => theme.spacing(3.5)};
`;

const EmptyRecordsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
`;

const StyledButton = styled(Button)`
    width: 100px;
`;

const StyledPaper = styled(Paper)`
    display: flex;
    max-height: 340px;
`;

const CustomerReviewDetail = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(3)};
`;

const limit = 20;
const reviewsPerPage = 5;

export const CustomerReviewsScreen: FunctionComponent = (): ReactElement => {
    const [startIndex, setStartIndex] = useState(0);

    const getCustomerReviewsQuery = useGetCustomerReviews({
        options: { queryKey: "customerReviews" },
        limit,
    });

    const customerReviews = useMemo(() => {
        return getCustomerReviewsQuery.data?.reviews.records ?? [];
    }, [getCustomerReviewsQuery.data?.reviews.records]);

    const [localReviews, setLocalReviews] = useState(customerReviews);

    useEffect(() => {
        setLocalReviews(customerReviews);
    }, [customerReviews]);

    const updateReviewStatus = (reviewId: string, status: number) => {
        setLocalReviews((prevReviews) =>
            prevReviews.map((review) =>
                review.id === reviewId ? { ...review, status } : review,
            ),
        );
    };

    const renderReviews = useMemo(() => {
        return localReviews
            ?.filter(
                (review) =>
                    review.customer.firstName || review.customer.lastName,
            )
            .slice(startIndex, startIndex + reviewsPerPage);
    }, [localReviews, startIndex]);

    const rootRef = useRef<HTMLDivElement>(null);

    const handleNextPage = useCallback(() => {
        if (startIndex + reviewsPerPage >= customerReviews.length) {
            return;
        }
        setStartIndex((prevStartIndex) => prevStartIndex + reviewsPerPage);
        if (rootRef.current) {
            rootRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }, [startIndex, customerReviews?.length]);

    const handlePreviousPage = useCallback(() => {
        if (startIndex === 0) {
            return;
        }
        setStartIndex((prevStartIndex) =>
            Math.max(0, prevStartIndex - reviewsPerPage),
        );
    }, [startIndex]);

    if (getCustomerReviewsQuery.isLoading) {
        return <CircularLoader />;
    }

    if (customerReviews.length === 0) {
        return (
            <EmptyRecords>
                <EmptyRecordsContainer>
                    <Title>No reviews found</Title>
                </EmptyRecordsContainer>
            </EmptyRecords>
        );
    }

    if (customerReviews.length === 0 || getCustomerReviewsQuery.isError) {
        return (
            <Title>
                Apologies, an error occurred. Please refresh the page or try
                again later.
            </Title>
        );
    }

    const randomBackgroundColors = [
        "#ff2d96",
        "#4caf50",
        "#2196f3",
        "#ff9800",
        "#9c27b0",
    ];

    return (
        <Root ref={rootRef}>
            <Title>Customer Reviews</Title>
            <Container>
                {getCustomerReviewsQuery.isSuccess &&
                    renderReviews.length > 0 &&
                    renderReviews.map((review, index) => (
                        <CustomerReviewDetail key={index}>
                            <CustomerReview
                                key={review.id}
                                review={review}
                                backgroundColor={
                                    randomBackgroundColors[
                                        index % randomBackgroundColors.length
                                    ]
                                }
                                onUpdateReviewStatus={updateReviewStatus}
                            />
                        </CustomerReviewDetail>
                    ))}
            </Container>

            {renderReviews.length > 0 && (
                <IconContainer>
                    <StyledButton
                        onClick={handlePreviousPage}
                        variant="outlined"
                        disabled={startIndex === 0}
                    >
                        Previous
                    </StyledButton>
                    <StyledButton
                        onClick={handleNextPage}
                        variant="outlined"
                        disabled={
                            startIndex + reviewsPerPage + 1 >=
                            customerReviews?.length
                        }
                    >
                        Next
                    </StyledButton>
                </IconContainer>
            )}
        </Root>
    );
};
