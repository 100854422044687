import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks/useAuth";
import { IAdminGroup } from "../../sdk/types";
import { adminGroups } from "../../sdk/utils/api";

export interface IUseCreateAdminGroupMutationParameters {
    name: string;
    description: string;
    adminIds: string[];
}

export const useCreateAdminGroup = (): UseMutationResult<
    IAdminGroup,
    unknown,
    IUseCreateAdminGroupMutationParameters
> => {
    const auth = useAuth();

    return useMutation<
        IAdminGroup,
        unknown,
        IUseCreateAdminGroupMutationParameters
    >({
        mutationFn: (parameters: IUseCreateAdminGroupMutationParameters) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot create admin group without authorization token.",
                );
            }

            return adminGroups.AdminGroup.create({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
