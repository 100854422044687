import { FunctionComponent, ReactElement, useCallback } from "react";

import {
    Alert,
    Button,
    DialogActions,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { Done } from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik } from "formik";
import { browserName } from "react-device-detect";

import { IGetOrderResponse, IRefundFormValues } from "../../sdk/types";
import { formatCurrency } from "../../sdk/utils/numbers";
import { paymentSuccessfulTerminalStatuses } from "../../utils/constants";
import {
    PaymentMethodType,
    RefundReason,
    RefundStatusType,
} from "../../utils/enums";

const Container = styled("section")`
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: ${({ theme }) => theme.spacing(1)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      width: 100%;
    }`}
`;

const RefundFormContainer = styled("form")`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    ${({ theme }) => theme.breakpoints.down("md")} {
        align-items: stretch;
    }
`;

const RefundFormControl = styled(FormControl)`
    width: 100%;
    margin: ${({ theme }) => theme.spacing(1, 0, 1, 0)};
`;

const Subtitle = styled(Typography)`
    font-size: 14px;
    font-weight: 400;

    color: #222222;
`;

const SubmitButton = styled(LoadingButton)`
    font-size: 14px;
    font-weight: 400;

    &:disabled {
        color: #ffffff;
    }

    ${({ theme }) => theme.breakpoints.down("md")} {
        width: 100%;
        font-size: 14px;
        margin-top: 0px;
    }
`;

const CancelButton = styled(Button)`
    font-size: 14px;
    font-weight: 400;

    border-color: #222222;
    color: #222222;

    ${({ theme }) => theme.breakpoints.down("md")} {
        width: 100%;
        font-size: 14px;
        margin-top: 0px;
    }
`;

export interface IRefundFormProps {
    order: IGetOrderResponse;
    onCancel: () => void;
    onSubmit: (values: IRefundFormValues) => void;
    debounce: boolean;
    cancelButtonText: string;
}

const refundReasons = {
    "Order cancelled": RefundReason.ORDER_CANCELLED,
    "Damaged product": RefundReason.DAMAGED_PRODUCT,
    "Wrong product delivered": RefundReason.WRONG_PRODUCT,
    Other: RefundReason.OTHER,
};

const refundFormSchema = Yup.object({
    note: Yup.string().min(1).max(512).required(),
    reason: Yup.number().integer().required(),
    amount: Yup.number().integer().min(0).required(),
});

export const RefundForm: FunctionComponent<IRefundFormProps> = (
    props: IRefundFormProps,
): ReactElement => {
    const { onCancel, order, onSubmit, debounce, cancelButtonText } = props;

    const disableScrollWheelInput = useCallback(
        (event: React.WheelEvent<HTMLDivElement>) => {
            event.currentTarget.querySelector("input")?.blur();
        },
        [],
    );

    const refunds = order?.refunds ?? [];
    const payment = order.payment;
    const refundableAmount =
        payment.paymentMethodType === PaymentMethodType.PREPAID &&
        paymentSuccessfulTerminalStatuses.includes(payment.status)
            ? payment.amount -
              refunds.reduce(
                  (acc, refund) =>
                      refund.status === RefundStatusType.FAILED
                          ? acc
                          : acc + refund.amount / 100,
                  0,
              )
            : 0;

    const { values, isValid, handleSubmit, handleChange, handleBlur } =
        useFormik({
            validateOnMount: true,
            initialValues: {
                note: refundableAmount === 0 ? "Not eligible for refund" : "",
                reason: refundReasons["Order cancelled"],
                amount: refundableAmount,
            },
            onSubmit: (values0: IRefundFormValues) => {
                onSubmit(values0);
            },
            validationSchema: refundFormSchema,
        });

    const handleFormSubmit = useCallback(async () => {
        handleSubmit();
    }, [handleSubmit]);

    const autoCompleteValue = browserName === "Chrome" ? "chrome-off" : "off";

    return (
        <Container>
            <Subtitle>
                Refundable amount: {`${formatCurrency(refundableAmount)}`}
            </Subtitle>
            {refundableAmount === 0 && (
                <Alert severity="info">Order is not refundable.</Alert>
            )}
            {refundableAmount > 0 && (
                <RefundFormContainer>
                    <RefundFormControl required>
                        <TextField
                            id="refund-amount-label"
                            type="number"
                            label="Amount"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="amount"
                            value={values.amount}
                            autoComplete={autoCompleteValue}
                            required={true}
                            onWheel={disableScrollWheelInput}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        ₹
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </RefundFormControl>
                    <RefundFormControl required>
                        <InputLabel id="refund-reason-label">Reason</InputLabel>
                        <Select
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="reason"
                            value={values.reason}
                            autoComplete={autoCompleteValue}
                            required={true}
                            label="Reason"
                            size="small"
                            variant="outlined"
                        >
                            {Object.entries(refundReasons).map(
                                ([key, value]) => (
                                    <MenuItem key={key} value={value}>
                                        {key}
                                    </MenuItem>
                                ),
                            )}
                        </Select>
                    </RefundFormControl>
                    <RefundFormControl required>
                        <TextField
                            id="refund-note-label"
                            type="text"
                            label="Note"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="note"
                            value={values.note}
                            autoComplete={autoCompleteValue}
                            required={true}
                        />
                    </RefundFormControl>
                </RefundFormContainer>
            )}
            <DialogActions>
                <CancelButton
                    id="orders--button--cancel-refund"
                    type="reset"
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}
                >
                    {cancelButtonText}
                </CancelButton>
                <SubmitButton
                    id="orders--button--submit-refund"
                    variant="contained"
                    color="primary"
                    disabled={!isValid || debounce}
                    loading={debounce}
                    loadingPosition="end"
                    endIcon={<Done />}
                    onClick={handleFormSubmit}
                >
                    Submit
                </SubmitButton>
            </DialogActions>
        </Container>
    );
};
