import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";

import {
    Button,
    Step,
    StepLabel,
    Stepper,
    Typography,
    styled,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";

import { CircularLoader } from "../../../components/common/CircularLoader";
import { useCreateOrder, useFetchCart, useMessage } from "../../../sdk/hooks";
import { ICart, ICustomerAddress, IProductVariant } from "../../../sdk/types";
import { PaymentMethodType, PaymentPlatform } from "../../../utils/enums";

import { OrderSummary } from "./OrderSummary";
import { CustomerAddresses } from "./address/CustomerAddresses";
import { PaymentOptions } from "./payment";
import { SelectProducts } from "./products/SelectProducts";

const Root = styled("main")`
    min-height: 100vh;
    padding: ${({ theme }) => theme.spacing(3)};
`;

const Wrapper = styled("div")`
    width: 100%;
    display: flex;
    gap: ${({ theme }) => theme.spacing(3)};

    padding: ${({ theme }) => theme.spacing(3, 0)};

    ${({ theme }) => theme.breakpoints.down("md")} {
        flex-direction: column;
        padding: ${({ theme }) => theme.spacing(2, 0)};
        gap: ${({ theme }) => theme.spacing(2)};
    }
`;

const Title = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`;

const ActionContainer = styled("div")`
    background-color: #ffffff;

    position: sticky;
    z-index: 1;
    bottom: 0;

    display: flex;
    justify-content: flex-end;
    gap: ${({ theme }) => theme.spacing(2)};

    width: 100%;
    padding: ${({ theme }) => theme.spacing(1, 2)};
    border-top: 1px solid rgba(0, 0, 0, 0.12);

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      padding: ${theme.spacing(2)}
    }
    
  `}
`;

const ErrorContainer = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: calc(100vh - 64px);
`;

const StyledStepper = styled(Stepper)`
    padding-top: ${({ theme }) => theme.spacing(2)};
`;

const StyledStep = styled(Step)`
    .Mui-completed {
        color: #4eae49;
    }
`;

export enum CreateOrderStep {
    CUSTOMER = 1,
    PRODUCTS = 2,
    PAYMENT = 3,
}

export const stepNames: Record<CreateOrderStep, string> = {
    [CreateOrderStep.CUSTOMER]: "Customer Details",
    [CreateOrderStep.PRODUCTS]: "Select Products",
    [CreateOrderStep.PAYMENT]: "Payment and Discounts",
};

const steps = Object.values(CreateOrderStep).filter(
    (value) => typeof value !== "string",
);

export interface ICartItem extends IProductVariant {
    quantity: number;
}

export const CreateOrderScreen: FunctionComponent = (): ReactElement => {
    const params = useParams();
    const fetchCartQuery = useFetchCart(params.id);
    const [currentStep, setCurrentStep] = useState(CreateOrderStep.CUSTOMER);
    const [cart, setCart] = useState<ICart>();
    const [selectedAddress, setSelectedAddress] = useState<ICustomerAddress>();
    const [paymentMethod, setPaymentMethod] = useState("prepaid");
    const [paymentPlatform, setPaymentPlatform] = useState<PaymentPlatform>(
        PaymentPlatform.JUSPAY,
    );
    const [description, setDescription] = useState("");
    const createOrderMutation = useCreateOrder();
    const navigate = useNavigate();
    const message = useMessage();

    const handlePrevious = useCallback(() => {
        setCurrentStep(currentStep - 1);
    }, [currentStep]);

    const handleNext = useCallback(() => {
        setCurrentStep(currentStep + 1);
    }, [currentStep]);

    const handleUpdateCart = useCallback((newCart: ICart) => {
        setCart(newCart);
    }, []);

    useEffect(() => {
        if (fetchCartQuery.data) {
            setCart(fetchCartQuery.data);
            const alreadyAppliedDiscountCode =
                fetchCartQuery.data.appliedCartPriceRules.length > 0
                    ? {
                          code: fetchCartQuery.data.appliedCartPriceRules[0]
                              .discountCode,
                          description:
                              fetchCartQuery.data.appliedCartPriceRules[0]
                                  .description,
                          id: fetchCartQuery.data.appliedCartPriceRules[0]
                              .priceRuleId,
                      }
                    : null;
            setDescription(alreadyAppliedDiscountCode?.description ?? "");
            setPaymentMethod(fetchCartQuery.data.paymentMethodType);
        }
    }, [fetchCartQuery.data]);

    const loading = fetchCartQuery.isLoading || createOrderMutation.isLoading;

    const handleCheckout = useCallback(() => {
        if (cart && cart.customer && selectedAddress) {
            createOrderMutation.mutate(
                {
                    customerId: cart.customer.id,
                    billingCustomerAddressId: selectedAddress.id,
                    note: "An order created on Powerhouse.",
                    shippingCustomerAddressId: selectedAddress.id,
                    test: false,
                    cartId: cart.id,
                    platform: paymentPlatform,
                },
                {
                    onSuccess: (response) => {
                        if (
                            response.payment.paymentMethodType ===
                            PaymentMethodType.COD
                        ) {
                            navigate(`/orders/${response.id}?status=converted`);
                            message.showSuccess(
                                "COD order created successfully",
                            );
                        } else {
                            navigate(`/orders/${response.id}`);
                            message.showSuccess(
                                "Order created successfully, awaiting payment.",
                            );
                        }
                    },
                },
            );
        } else {
            throw new Error("Control should not reach here");
        }
    }, [
        cart,
        createOrderMutation,
        message,
        navigate,
        paymentPlatform,
        selectedAddress,
    ]);

    if (loading) {
        return <CircularLoader />;
    }

    return (
        <>
            {cart && (
                <>
                    <Root>
                        <Title>Create Order</Title>
                        <StyledStepper activeStep={1} alternativeLabel>
                            {steps.map((step) => (
                                <StyledStep
                                    key={step}
                                    active={currentStep === step}
                                    completed={(step as number) < currentStep}
                                >
                                    <StepLabel>
                                        {stepNames[step as CreateOrderStep]}
                                    </StepLabel>
                                </StyledStep>
                            ))}
                        </StyledStepper>
                        <Wrapper>
                            {currentStep === CreateOrderStep.PRODUCTS && (
                                <SelectProducts cart={cart} setCart={setCart} />
                            )}
                            {currentStep === CreateOrderStep.CUSTOMER && (
                                <CustomerAddresses
                                    customer={cart?.customer}
                                    selectedAddress={selectedAddress}
                                    setSelectedAddress={setSelectedAddress}
                                />
                            )}
                            {currentStep === CreateOrderStep.PAYMENT && (
                                <PaymentOptions
                                    loading={fetchCartQuery.isLoading}
                                    paymentMethod={paymentMethod}
                                    paymentPlatform={paymentPlatform}
                                    setPaymentMethod={setPaymentMethod}
                                    setPaymentPlatform={setPaymentPlatform}
                                    cart={cart}
                                    alreadyAppliedDiscountCode={
                                        cart.appliedCartPriceRules.length > 0
                                            ? {
                                                  code: cart
                                                      .appliedCartPriceRules[0]
                                                      .discountCode,
                                                  description:
                                                      cart
                                                          .appliedCartPriceRules[0]
                                                          .description,
                                                  id: cart
                                                      .appliedCartPriceRules[0]
                                                      .priceRuleId,
                                              }
                                            : null
                                    }
                                    description={description}
                                />
                            )}
                            <OrderSummary
                                cartId={cart?.id}
                                cartItems={cart?.items ?? []}
                                customer={cart?.customer}
                                address={selectedAddress}
                                onChange={handleUpdateCart}
                            />
                        </Wrapper>
                    </Root>
                    <ActionContainer>
                        <Button
                            variant="outlined"
                            disabled={currentStep === CreateOrderStep.CUSTOMER}
                            onClick={handlePrevious}
                        >
                            Previous
                        </Button>
                        {currentStep !== CreateOrderStep.PAYMENT && (
                            <Button
                                variant="contained"
                                disabled={
                                    (currentStep === CreateOrderStep.PRODUCTS &&
                                        cart?.items.length === 0) ||
                                    (currentStep === CreateOrderStep.CUSTOMER &&
                                        !selectedAddress)
                                }
                                onClick={handleNext}
                            >
                                Next
                            </Button>
                        )}
                        {currentStep === CreateOrderStep.PAYMENT && (
                            <Button
                                variant="contained"
                                disabled={
                                    !cart ||
                                    cart.items.length === 0 ||
                                    !cart.customer ||
                                    !selectedAddress
                                }
                                onClick={handleCheckout}
                            >
                                Create Order
                            </Button>
                        )}
                    </ActionContainer>
                </>
            )}
            {!cart && (
                <ErrorContainer>
                    <Title>Cart not found!</Title>
                </ErrorContainer>
            )}
        </>
    );
};
