import React, { FunctionComponent, ReactElement, ReactNode } from "react";

import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../../sdk/hooks";

export interface IRequireAuthProps {
    children?: ReactNode;
}

export const RequireAuth: FunctionComponent<IRequireAuthProps> = (
    props: IRequireAuthProps,
): ReactElement => {
    const { children } = props;
    const { user, token } = useAuth();
    const location = useLocation();

    return (
        <>
            {(user === null || token === null) && (
                <Navigate to="/login" state={{ path: location.pathname }} />
            )}
            {user !== null && children}
        </>
    );
};
