import { FunctionComponent, ReactElement, useCallback } from "react";

import { Button, Card, CardContent, Typography, styled } from "@mui/material";

import { ICart } from "../../../../sdk/types";
import { formatCurrency } from "../../../../sdk/utils/numbers";

import { DiscountList } from "./DiscountList";

const Root = styled(Card)`
    width: 100%;
    padding: ${({ theme }) => theme.spacing(2)};
    background-color: #f5f5f5;
`;

const Content = styled(CardContent)`
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(2)};

    &.MuiCardContent-root {
        padding: 0;
    }
`;

const Wrapper = styled("div")`
    display: flex;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const CouponText = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
`;

const RemoveButton = styled(Button)`
    padding: ${({ theme }) => theme.spacing(0, 1)};
    font-weight: 400;

    &:hover {
        background-color: black;
        color: white;
    }
`;

export interface IDiscountsProps {
    discountCode: string;
    description: string;
    error: string;
    applied: boolean;
    appliedDiscountCode: string;
    verifying: boolean;
    couponDiscount: number;
    cart: ICart;

    onChange: (discountCode: string) => void;
    onApply: () => void;
    onRemove: () => void;
}

export const Discounts: FunctionComponent<IDiscountsProps> = (
    props: IDiscountsProps,
): ReactElement => {
    const {
        discountCode,
        description,
        onChange,
        onApply,
        error,
        applied,
        appliedDiscountCode,
        onRemove,
        verifying,
        couponDiscount,
        cart,
    } = props;

    const handleDiscountCodeChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
        },
        [onChange],
    );

    return (
        <Root elevation={0}>
            <Content>
                {applied && (
                    <Wrapper>
                        {couponDiscount > 0 && (
                            <CouponText>
                                Saved {formatCurrency(couponDiscount)} with{" "}
                                {appliedDiscountCode} 🎉
                            </CouponText>
                        )}
                        {couponDiscount === 0 && (
                            <CouponText>
                                {appliedDiscountCode} applied successfully 🎉
                            </CouponText>
                        )}
                        <RemoveButton variant="outlined" onClick={onRemove}>
                            Remove
                        </RemoveButton>
                    </Wrapper>
                )}
                <DiscountList
                    allDiscountCodes={cart.publicAnnotatedPriceRules}
                    applicableDiscountCodes={cart.applicableDiscountCodes}
                    appliedDiscount={appliedDiscountCode}
                    isDiscountApplied={applied}
                    onApply={onApply}
                    onRemove={onRemove}
                    value={discountCode}
                    onChange={handleDiscountCodeChange}
                    description={description}
                    error={error}
                    loading={verifying}
                    cart={cart}
                />
            </Content>
        </Root>
    );
};
