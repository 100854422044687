import React, { FunctionComponent, useCallback } from "react";

import {
    Button,
    FormControlLabel,
    Paper,
    Typography,
    styled,
} from "@mui/material";

import { Delete, Edit, Email, Smartphone } from "@mui/icons-material";

import { ICustomerAddress } from "../../sdk/types";
import { stateCodes } from "../../utils/constants";

const Root = styled(Paper)`
    display: flex;
    width: 100%;
    box-shadow: none;

    padding: ${({ theme }) => theme.spacing(1, 2)};
`;

const AddressText = styled("div")`
    display: flex;
    flex-direction: column;
    width: 100%;

    padding: ${({ theme }) => theme.spacing(2, 1, 1, 1)};

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        padding: ${theme.spacing(1)};
    }
    `}
`;

const FullAddress = styled(Typography)`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        font-size: 12px;
    }
    `}
`;

const OtherDetails = styled(Typography)`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        font-size: 12px;
    }
    `}
`;

const MobileText = styled(Typography)`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    display: flex;
    align-items: center;

    line-height: 20px;
    margin-top: ${({ theme }) => theme.spacing(1)};

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        font-size: 12px;
        
        margin-top: ${theme.spacing(0.5)};
    }
    `}
`;

const UtilContainer = styled("div")`
    display: flex;
    column-gap: ${({ theme }) => theme.spacing(1)};
    justify-content: end;
    color: gray;

    margin-top: ${({ theme }) => theme.spacing(2)};
`;

const FullName = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
`;

const ActionButton = styled(Button)`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};

    text-transform: none;
`;

const LabelGroup = styled(FormControlLabel)`
    place-self: start;
    margin-top: ${({ theme }) => theme.spacing(1)};
`;

export interface IExistingAddressCard {
    address: ICustomerAddress;
    index: number;
    handleEditAddress: (index: number, id: string) => void;
    handleDeleteAddress: (index: number, id: string) => void;
}

export const CustomerAddressCard: FunctionComponent<IExistingAddressCard> = (
    props: IExistingAddressCard,
) => {
    const { address, index, handleEditAddress, handleDeleteAddress } = props;
    const {
        id,
        addressLine1,
        addressLine2,
        city,
        firstName,
        lastName,
        phoneNumber,
        alternatePhoneNumber,
        emailAddress,
        stateCode,
        zip,
    } = address;

    const state = stateCodes[stateCode];

    const handleEdit = useCallback(() => {
        handleEditAddress(index, id);
    }, [handleEditAddress, id, index]);

    const handleDelete = useCallback(() => {
        handleDeleteAddress(index, id);
    }, [handleDeleteAddress, id, index]);

    return (
        <Root variant="outlined">
            <AddressText>
                <FullName>
                    {firstName} {lastName}
                </FullName>
                <FullAddress>{addressLine1}</FullAddress>
                <FullAddress>{addressLine2}</FullAddress>
                <OtherDetails>
                    {city}, {state} - {zip}
                </OtherDetails>
                <MobileText>
                    {<Smartphone />} - {phoneNumber}
                </MobileText>
                {alternatePhoneNumber && (
                    <MobileText>
                        Alternate {<Smartphone />} - {alternatePhoneNumber}
                    </MobileText>
                )}
                <MobileText>
                    {<Email />} - {emailAddress}
                </MobileText>
                <UtilContainer>
                    <ActionButton
                        id="address-card--button--remove"
                        variant="text"
                        size="small"
                        color="inherit"
                        onClick={handleDelete}
                    >
                        <Delete />
                        Remove
                    </ActionButton>
                    <ActionButton
                        id="address-card--button--add"
                        variant="text"
                        size="small"
                        color="inherit"
                        onClick={handleEdit}
                    >
                        <Edit />
                        Edit
                    </ActionButton>
                </UtilContainer>
            </AddressText>
        </Root>
    );
};
