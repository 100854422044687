import axios from "axios";

import { ILink } from "../../types";

export interface ICreateParams {
    token: string;
    name: string;
    description: string;
    startsAt: Date | null;
    endsAt: Date | null;
    destination: string;
    alias: string;
    allowIab: boolean;
    tags: string;
}

export interface ICreateFromFileParams {
    token: string;
    file: File;
}

export interface IUpdateParams {
    id: string;
    token: string;
    name?: string;
    description?: string;
    startsAt?: Date | null;
    endsAt?: Date | null;
    destination?: string;
    alias?: string;
    allowIab?: boolean;
    tags?: string;
}

export interface IGetByIdParams {
    token: string;
    linkId: string;
}

export interface IListAllParams {
    token: string;
}

export interface IListAllReturnType {
    records: ILink[];
    cursor: {
        previous: string | null;
        next: string | null;
    };
}

/**
 * Wrapper for making REST API calls to the Link Builder module.
 *
 * @author Samuel Rowe <samuel@houseofx.in>
 */
export class Link {
    private static transform(data: any): ILink {
        return {
            ...data,
            createdAt: new Date(data.createdAt),
            updatedAt: new Date(data.updatedAt),
        };
    }

    public static async create(params: ICreateParams): Promise<ILink> {
        const { token, ...body } = params;
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/links`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return Link.transform(data);
    }

    public static async createFromFile(
        params: ICreateFromFileParams,
    ): Promise<ILink[]> {
        const { token, file } = params;

        const formData = new FormData();
        formData.append("file", file);
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/links/import`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        return data.map(Link.transform);
    }

    public static async update(params: IUpdateParams): Promise<ILink> {
        const { token, id, ...body } = params;
        const { data } = await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/links/${id}`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return Link.transform(data);
    }

    public static async getById(params: IGetByIdParams): Promise<ILink> {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/links/${params.linkId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${params.token}`,
                },
            },
        );
        return Link.transform(data);
    }

    public static async listAll(
        params: IListAllParams,
    ): Promise<IListAllReturnType> {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/links`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${params.token}`,
                },
            },
        );
        return {
            ...data,
            records: data.records.map(Link.transform),
        };
    }
}
