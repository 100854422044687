import React, { FunctionComponent, ReactElement, ReactNode } from "react";

import { Paper, styled } from "@mui/material";

import EmptyImage from "../../assessts/empty_image.svg";

const Root = styled("div")`
    padding: ${({ theme }) => theme.spacing(3)};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(4)};
`;

const EmptyImageContainer = styled("img")`
    height: 220px;
    width: 220px;
`;

export interface EmptyRecordsProps {
    children?: ReactNode;
}

export const EmptyRecords: FunctionComponent<EmptyRecordsProps> = (
    props: EmptyRecordsProps,
): ReactElement => {
    const { children } = props;
    return (
        <Paper elevation={0} variant="outlined">
            <Root>
                <EmptyImageContainer src={EmptyImage} />
                {children ?? "No data found"}
            </Root>
        </Paper>
    );
};
