import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";

import {
    Button,
    CardContent,
    Chip,
    StepIcon,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";

import { CurrencyExchange, InfoOutlined } from "@mui/icons-material";

import { CheckCircle, PlayCircle, XCircle } from "@phosphor-icons/react";
import { format } from "date-fns";
import { useLocation, useParams } from "react-router-dom";

import { CircularLoader, Paper } from "../../components/common";
import {
    useGetOrder,
    useGetShopifyOrderId,
    useMarkOrderConverted,
    useMessage,
    useResendPaymentLink,
} from "../../sdk/hooks";
import { IOrderLineItem } from "../../sdk/types";
import { formatCurrency } from "../../sdk/utils/numbers";
import {
    globalDateTimeFormat,
    orderStatusColors,
    paymentSuccessfulTerminalStatuses,
    stateCodes,
} from "../../utils/constants";
import {
    ConfirmationSourceNames,
    OrderStatus,
    PaymentMethodType,
    orderStatusByEnum,
} from "../../utils/enums";

import { CancelOrderDialog } from "./CancelOrderDialog";
import { OrderLineItem } from "./OrderLineItem";
import { RefundDialog } from "./RefundDialog";
import { RefundSection } from "./RefundSection";

const Root = styled("main")`
    display: flex;
    flex-direction: column;
    justify-content: start;

    padding: ${({ theme }) => theme.spacing(2, 3)};
`;

const OrderContainer = styled("div")`
    max-width: 1000px;

    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};
    align-items: start;
    justify-items: start;

    ${({ theme }) => `
     ${theme.breakpoints.down("lg")} {
       flex-direction: column;
       gap: ${theme.spacing(3)};
     }
    
  `}
`;

const Container = styled("div")`
    display: flex;
    flex-direction: column;
    row-gap: ${({ theme }) => theme.spacing(1)};
`;

const TitleRow = styled("div")`
    max-width: 1000px;
    width: 100%;
    padding: ${({ theme }) => theme.spacing(1, 0)};

    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
    justify-content: space-between;

    ${({ theme }) => theme.breakpoints.down("md")} {
        flex-wrap: wrap;
    }
`;

const TitleContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
    justify-content: start;

    ${({ theme }) => theme.breakpoints.down("md")} {
        flex-wrap: wrap;
    }
`;

const ActionsContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
    justify-content: end;
`;

const Title = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const LeftContainer = styled("section")`
    max-width: 900px;
    flex: 2;

    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      max-width: unset;
      width:100%;
      flex:1;
    }
    
  `}
`;

const RightContainer = styled("section")`
    flex: 1;
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      width: 100%
    }`}
`;

const Subtitle = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
`;

const BodyText = styled(Typography)``;

const OrderDetail = styled("div")`
    display: flex;
    flex-direction: column;
`;

const OrderDetailLabel = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
    color: #222222;
`;

const OrderDetailValue = styled(Typography)`
    font-size: 14px;
    font-weight: 400;
    color: #7b7b7b;
`;

const TotalContainer = styled("span")`
    color: green;
`;

const Content = styled(CardContent)`
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    width: 100%;
`;

const CancelButton = styled(Button)`
    font-weight: 400;

    border-color: #222222;
    color: #222222;
`;

const ButtonWrapper = styled("div")`
    font-size: 14px;
    display: flex;

    place-self: end;
    justify-self: end;
`;

export interface IDiscountContainerProps {
    value: number;
}

const DiscountContainer = styled("span", {
    shouldForwardProp: (propName) => propName !== "value",
})<IDiscountContainerProps>`
    color: ${({ value }) => (value === 0 ? "#697386" : "#d32f2f")};
`;

export interface IStatusChipProps {
    value: OrderStatus;
}

const StatusChip = styled(Chip, {
    shouldForwardProp: (propName) => propName !== "value",
})<IStatusChipProps>`
    background-color: ${({ value }) => orderStatusColors[value]};
    color: white;
`;

const SubTotal = styled(Typography)`
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 40px;
    width: 100%;

    color: #1a1f36;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px solid #3c42571f;

    padding: ${({ theme }) => theme.spacing(1, 0)};
`;

const DiscountCode = styled("span")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const FinalTotal = styled(Typography)`
    display: flex;
    justify-content: end;

    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;

    padding: ${({ theme }) => theme.spacing(2, 0)};
`;

const StartIcon = styled(PlayCircle)`
    background-color: #7cb9e8;
    fill: white;
    border-radius: 50%;
`;

const CompleteIcon = styled(CheckCircle)`
    background-color: green;
    fill: white;
    border-radius: 50%;
`;

const FailIcon = styled(XCircle)`
    background-color: red;
    fill: white;
    border-radius: 50%;
`;

export const OrderScreen: FunctionComponent = (): ReactElement => {
    const params = useParams();
    const { search } = useLocation();
    const status = new URLSearchParams(search).get("status");

    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [openRefundDialog, setOpenRefundDialog] = useState(false);

    const orderQuery = useGetOrder(["getOrderById"], params.id ?? "");
    const shopifyOrderIdMutation = useGetShopifyOrderId();
    const markOrderConvertedMutation = useMarkOrderConverted();
    const resendPaymentLinkMutation = useResendPaymentLink(["getOrderById"]);
    const message = useMessage();

    const order = orderQuery.data;

    const refundIds = order?.refunds?.map((ref) => ref.id);

    const refunds = order?.refunds ?? [];

    const refundAmountById = useCallback(
        (refundId: string) => {
            return (
                refunds?.find((refund) => refund.id === refundId)?.amount ?? 0
            );
        },
        [refunds],
    );

    const refundHistoriesMap = useMemo(() => {
        return refundIds?.map((refundId) => {
            return order?.refundHistories
                ?.filter((refundHistory) => refundHistory.refundId === refundId)
                .sort(
                    (a, b) =>
                        new Date(a.createdAt).getTime() -
                        new Date(b.createdAt).getTime(),
                );
        });
    }, [order?.refundHistories, refundIds]);

    const returnIcon = useCallback((status: number) => {
        if (status === 0) {
            return <StepIcon icon={<StartIcon size={28} />} />;
        }
        if (status === 1) {
            return <StepIcon icon={<CompleteIcon size={28} />} />;
        }
        if (status !== 0 && status !== 1) {
            return <StepIcon icon={<FailIcon size={28} />} />;
        }
        return <></>;
    }, []);

    const handleOpenCancelDialog = useCallback(() => {
        setOpenCancelDialog(true);
    }, []);

    const handleCloseCancelDialog = useCallback(() => {
        setOpenCancelDialog(false);
    }, []);

    const handleOpenRefundDialog = useCallback(() => {
        setOpenRefundDialog(true);
    }, []);

    const handleCloseRefundDialog = useCallback(() => {
        setOpenRefundDialog(false);
    }, []);

    useEffect(() => {
        if (orderQuery.isSuccess && order && shopifyOrderIdMutation.isIdle) {
            shopifyOrderIdMutation.mutate({
                orderId: params.id ?? "",
            });
        }
    }, [order, orderQuery.isSuccess, params.id, shopifyOrderIdMutation]);

    useEffect(() => {
        if (
            order &&
            order.cartId &&
            status === "converted" &&
            markOrderConvertedMutation.isIdle
        ) {
            markOrderConvertedMutation.mutate({ cartId: order.cartId });
        }
    }, [markOrderConvertedMutation, message, order, status]);

    const loading =
        orderQuery.isLoading ||
        shopifyOrderIdMutation.isLoading ||
        markOrderConvertedMutation.isLoading;

    const handleResendPaymentLink = useCallback(() => {
        if (order) {
            resendPaymentLinkMutation.mutate(
                { orderId: order.id },
                {
                    onSuccess: () => {
                        message.showSuccess(
                            "New payment link sent successfully",
                        );
                    },
                    onError: () => {
                        message.showError("Could not send new payment link");
                    },
                },
            );
        }
    }, [message, order, resendPaymentLinkMutation]);

    if (loading) {
        return <CircularLoader />;
    }

    const payment = order?.payment;
    const shippingCustomerAddress = order?.shippingCustomerAddress;
    const appliedPriceRules = order?.appliedPriceRules;
    const shopifyOrderId = shopifyOrderIdMutation.data;

    return (
        <>
            {!loading && order && (
                <Root>
                    <TitleRow>
                        <TitleContainer>
                            {shopifyOrderId && <Title>{order.name}</Title>}
                            {!shopifyOrderId && <Title>{params.id}</Title>}
                            {order.status && (
                                <StatusChip
                                    value={order.status}
                                    label={orderStatusByEnum[order.status]}
                                />
                            )}
                            <Chip label={payment?.status} variant="outlined" />
                        </TitleContainer>
                        <ActionsContainer>
                            {order.payment.paymentMethodType ===
                                PaymentMethodType.PREPAID &&
                                paymentSuccessfulTerminalStatuses.includes(
                                    order.payment.status,
                                ) && (
                                    <ButtonWrapper>
                                        <Button
                                            variant="contained"
                                            onClick={handleOpenRefundDialog}
                                            sx={{
                                                gap: "8px",
                                            }}
                                            disabled={orderQuery.isLoading}
                                            size="small"
                                        >
                                            <CurrencyExchange fontSize="small" />
                                            Refund
                                        </Button>
                                    </ButtonWrapper>
                                )}
                            {(order.status === OrderStatus.SYNCED ||
                                order.status === OrderStatus.PREBOOKED) && (
                                <ButtonWrapper>
                                    <CancelButton
                                        onClick={handleOpenCancelDialog}
                                        variant="outlined"
                                        size="small"
                                        disabled={
                                            order.status !==
                                                OrderStatus.PREBOOKED &&
                                            !shopifyOrderId
                                        }
                                        id="orders--button--cancel-order"
                                    >
                                        CANCEL ORDER
                                    </CancelButton>
                                </ButtonWrapper>
                            )}
                            {(order.status === OrderStatus.CREATED ||
                                order.status ===
                                    OrderStatus.PREBOOKING_ORDER_CREATED) &&
                                order.payment.paymentMethodType !==
                                    PaymentMethodType.COD &&
                                order.payment.status !== "NEW" &&
                                order.sellerId &&
                                !paymentSuccessfulTerminalStatuses.includes(
                                    order.payment.status,
                                ) && (
                                    <ButtonWrapper>
                                        <Button
                                            variant="contained"
                                            onClick={handleResendPaymentLink}
                                            size="small"
                                            disabled={
                                                resendPaymentLinkMutation.isLoading
                                            }
                                        >
                                            Send New Payment Link
                                        </Button>
                                    </ButtonWrapper>
                                )}
                        </ActionsContainer>
                    </TitleRow>
                    <OrderContainer>
                        <LeftContainer>
                            <Paper>
                                <Container>
                                    <OrderDetail>
                                        <OrderDetailLabel>
                                            Order placed on:
                                        </OrderDetailLabel>
                                        <OrderDetailValue>
                                            {format(
                                                new Date(order.createdAt),
                                                globalDateTimeFormat,
                                            )}
                                        </OrderDetailValue>
                                    </OrderDetail>
                                    {order.seller && (
                                        <OrderDetail>
                                            <OrderDetailLabel>
                                                Order created by:
                                            </OrderDetailLabel>
                                            <OrderDetailValue>
                                                {`${order.seller.firstName} ${order.seller.lastName}`}
                                            </OrderDetailValue>
                                        </OrderDetail>
                                    )}
                                    <Subtitle>Product Details</Subtitle>
                                    {order.orderLineItems.map(
                                        (item: IOrderLineItem) => (
                                            <OrderLineItem
                                                key={item.id}
                                                orderLineItem={item}
                                            />
                                        ),
                                    )}
                                </Container>
                            </Paper>
                            <Paper>
                                <Container>
                                    <Subtitle>Payment Details</Subtitle>
                                    {order.name && (
                                        <OrderDetail>
                                            <OrderDetailLabel>
                                                Order ID
                                            </OrderDetailLabel>
                                            <OrderDetailValue>
                                                {order.name}
                                            </OrderDetailValue>
                                        </OrderDetail>
                                    )}
                                    {order.status && (
                                        <OrderDetail>
                                            <OrderDetailLabel>
                                                Order Status
                                            </OrderDetailLabel>
                                            <OrderDetailValue>
                                                {
                                                    orderStatusByEnum[
                                                        order.status
                                                    ]
                                                }
                                            </OrderDetailValue>
                                        </OrderDetail>
                                    )}
                                    {order.confirmationSource && (
                                        <OrderDetail>
                                            <OrderDetailLabel>
                                                Confirmation Source
                                            </OrderDetailLabel>
                                            <OrderDetailValue>
                                                {
                                                    ConfirmationSourceNames[
                                                        order.confirmationSource
                                                    ]
                                                }
                                            </OrderDetailValue>
                                        </OrderDetail>
                                    )}
                                    <OrderDetail>
                                        <OrderDetailLabel>
                                            Payment ID
                                        </OrderDetailLabel>
                                        <OrderDetailValue>
                                            {order.payment.id}
                                        </OrderDetailValue>
                                    </OrderDetail>

                                    <OrderDetail>
                                        <OrderDetailLabel>
                                            Payment status
                                        </OrderDetailLabel>
                                        <OrderDetailValue>
                                            {order.payment.status}
                                        </OrderDetailValue>
                                    </OrderDetail>
                                    <Content>
                                        <SubTotal>
                                            <span>SUBTOTAL</span>
                                            <TotalContainer>
                                                {formatCurrency(
                                                    order.subtotalAmount,
                                                )}
                                            </TotalContainer>
                                        </SubTotal>
                                        <SubTotal>
                                            <span>FAN DISCOUNT</span>
                                            <DiscountContainer
                                                value={
                                                    order.subtotalAmount -
                                                    order.actualAmount
                                                }
                                            >
                                                {order.subtotalAmount -
                                                    order.actualAmount !==
                                                    0 && "-"}{" "}
                                                {formatCurrency(
                                                    order.subtotalAmount -
                                                        order.actualAmount,
                                                )}
                                            </DiscountContainer>
                                        </SubTotal>
                                        {appliedPriceRules?.map((priceRule) => (
                                            <SubTotal key={priceRule.id}>
                                                <DiscountCode>
                                                    {priceRule.discountCode}
                                                    <Tooltip
                                                        enterTouchDelay={0}
                                                        title={
                                                            priceRule.description
                                                        }
                                                        placement="top"
                                                    >
                                                        <InfoOutlined fontSize="small" />
                                                    </Tooltip>
                                                </DiscountCode>
                                                <DiscountContainer
                                                    value={
                                                        priceRule.discountAmount
                                                    }
                                                >
                                                    {priceRule.discountAmount !==
                                                        0 && "-"}{" "}
                                                    {formatCurrency(
                                                        priceRule.discountAmount,
                                                    )}
                                                </DiscountContainer>
                                            </SubTotal>
                                        ))}
                                        <SubTotal>
                                            <span>DELIVERY</span>
                                            <DiscountContainer
                                                value={order.shippingAmount}
                                            >
                                                {formatCurrency(
                                                    order.shippingAmount,
                                                )}
                                            </DiscountContainer>
                                        </SubTotal>
                                        {[
                                            OrderStatus.PREBOOKED,
                                            OrderStatus.PREBOOKING_ORDER_CREATED,
                                        ].includes(order.status) && (
                                            <>
                                                <SubTotal>
                                                    <span>Amount Paid</span>
                                                    <TotalContainer>
                                                        {formatCurrency(
                                                            order.actualAmount -
                                                                order.discountAmount -
                                                                order.remainingAmount +
                                                                order.shippingAmount,
                                                        )}
                                                    </TotalContainer>
                                                </SubTotal>
                                                <SubTotal>
                                                    <span>
                                                        Amount Remaining
                                                    </span>
                                                    <DiscountContainer
                                                        value={
                                                            order.remainingAmount
                                                        }
                                                    >
                                                        {formatCurrency(
                                                            order.remainingAmount,
                                                        )}
                                                    </DiscountContainer>
                                                </SubTotal>
                                            </>
                                        )}
                                        <FinalTotal>
                                            TOTAL:{" "}
                                            {formatCurrency(
                                                order.actualAmount -
                                                    order.discountAmount +
                                                    order.shippingAmount,
                                            )}
                                        </FinalTotal>
                                    </Content>
                                </Container>
                            </Paper>
                            {refunds.length > 0 && (
                                <Paper>
                                    <Container>
                                        <RefundSection
                                            refundHistoriesMap={
                                                refundHistoriesMap
                                            }
                                            refundAmountById={refundAmountById}
                                            returnIcon={returnIcon}
                                            refunds={refunds}
                                        />
                                    </Container>
                                </Paper>
                            )}
                            {openCancelDialog && (
                                <CancelOrderDialog
                                    order={order}
                                    formOpen={openCancelDialog}
                                    onClose={handleCloseCancelDialog}
                                />
                            )}
                            {openRefundDialog && (
                                <RefundDialog
                                    orderId={params.id ?? ""}
                                    order={order}
                                    formOpen={openRefundDialog}
                                    onClose={handleCloseRefundDialog}
                                />
                            )}
                        </LeftContainer>
                        <RightContainer>
                            <Paper>
                                <Container>
                                    <Subtitle>Notes</Subtitle>
                                    <BodyText>
                                        {order.note ??
                                            "No notes from customer."}
                                    </BodyText>
                                </Container>
                            </Paper>
                            <Paper>
                                <Container>
                                    <OrderDetail>
                                        <OrderDetailLabel>
                                            Name
                                        </OrderDetailLabel>
                                        <OrderDetailValue>
                                            {shippingCustomerAddress?.firstName}
                                            &nbsp;
                                            {shippingCustomerAddress?.lastName}
                                        </OrderDetailValue>
                                    </OrderDetail>
                                    <OrderDetail>
                                        <OrderDetailLabel>
                                            Phone Number
                                        </OrderDetailLabel>
                                        <OrderDetailValue>
                                            {
                                                shippingCustomerAddress?.phoneNumber
                                            }
                                        </OrderDetailValue>
                                    </OrderDetail>
                                    <OrderDetail>
                                        <OrderDetailLabel>
                                            Email Address
                                        </OrderDetailLabel>
                                        <OrderDetailValue>
                                            {
                                                shippingCustomerAddress?.emailAddress
                                            }
                                        </OrderDetailValue>
                                    </OrderDetail>
                                    <OrderDetail>
                                        <OrderDetailLabel>
                                            Shipping Address
                                        </OrderDetailLabel>
                                        {shippingCustomerAddress && (
                                            <OrderDetailValue>
                                                {
                                                    shippingCustomerAddress.addressLine1
                                                }
                                                <br />
                                                {
                                                    shippingCustomerAddress.addressLine2
                                                }
                                                {shippingCustomerAddress.addressLine2 && (
                                                    <br />
                                                )}
                                                {shippingCustomerAddress.city}
                                                <br />
                                                {shippingCustomerAddress &&
                                                    stateCodes[
                                                        shippingCustomerAddress
                                                            .stateCode
                                                    ]}
                                                <br />
                                                {shippingCustomerAddress.zip}
                                            </OrderDetailValue>
                                        )}
                                    </OrderDetail>
                                    {shippingCustomerAddress?.alternatePhoneNumber && (
                                        <OrderDetail>
                                            <OrderDetailLabel>
                                                Alternate Phone Number
                                            </OrderDetailLabel>
                                            <OrderDetailValue>
                                                {
                                                    shippingCustomerAddress?.alternatePhoneNumber
                                                }
                                            </OrderDetailValue>
                                        </OrderDetail>
                                    )}
                                </Container>
                            </Paper>
                        </RightContainer>
                    </OrderContainer>
                </Root>
            )}
        </>
    );
};
