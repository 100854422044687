import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { ICustomerGroup } from "../../sdk/types";
import { api } from "../../sdk/utils";

export interface IDeleteCustomerGroupParameter {
    customerGroupId: string;
}

export const useDeleteCustomerGroup = (
    queryKey: string | string[],
): UseMutationResult<
    ICustomerGroup,
    unknown,
    IDeleteCustomerGroupParameter
> => {
    const auth = useAuth();
    return useMutation({
        mutationFn: async (parameters: IDeleteCustomerGroupParameter) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot delete customer group without authorization token.",
                );
            }

            return await api.CustomerGroup.delete({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
