import axios from "axios";

import {
    IAnnotatedPriceRule,
    IAppliedCartPriceRule,
    ICart,
    ICartLineItem,
    ICartPaymentRestriction,
    IDiscountCodeWithDescription,
    IExternalListPage,
} from "../../types";

export const transform = (data: any): ICart => {
    return {
        ...data,
        createdAt: new Date(data.createdAt),
        updatedAt: new Date(data.updatedAt),
        items: data.items.map((item: ICartLineItem) => ({
            ...item,
            createdAt: new Date(item.createdAt),
            updatedAt: new Date(item.updatedAt),
        })),
    };
};

export const transformWithDiscounts = (data: any): ICart => {
    const applicableDiscountCodes: IDiscountCodeWithDescription[] = [];

    /* Filter out the applicable discount codes from applicable annotated price rules. */
    for (const rule of data.publicAnnotatedPriceRules) {
        if (rule.applicable) {
            for (const discount of rule.discounts) {
                applicableDiscountCodes.push({
                    id: discount.id,
                    code: discount.code,
                    description: rule.description,
                });
            }
        }
    }

    return {
        ...data,
        createdAt: new Date(data.createdAt),
        updatedAt: new Date(data.updatedAt),
        items: data.items.map((item: ICartLineItem) => ({
            ...item,
            createdAt: new Date(item.createdAt),
            updatedAt: new Date(item.updatedAt),
        })),
        publicAnnotatedPriceRules: data.publicAnnotatedPriceRules.map(
            (rule: IAnnotatedPriceRule) => ({
                ...rule,
                createdAt: new Date(rule.createdAt),
                updatedAt: new Date(rule.updatedAt),
            }),
        ),
        annotation: {
            ...data.annotation,
            createdAt: new Date(data.annotation?.createdAt),
            updatedAt: new Date(data.annotation?.updatedAt),
        },
        appliedCartPriceRules: data.appliedCartPriceRules.map(
            (rule: IAppliedCartPriceRule) => ({
                ...rule,
                createdAt: new Date(rule.createdAt),
                updatedAt: new Date(rule.updatedAt),
            }),
        ),
        applicableDiscountCodes,
        paymentRestrictions: data.paymentRestrictions?.map(
            (restriction: ICartPaymentRestriction) => ({
                ...restriction,
                createdAt: new Date(restriction.createdAt),
                updatedAt: new Date(restriction.updatedAt),
            }),
        ),
    };
};

export interface IGetOrCreateCustomerCartParameters {
    token: string;
    customerId: string;
}

export const getOrCreateCustomerCart = async (
    parameters: IGetOrCreateCustomerCartParameters,
): Promise<ICart> => {
    const { token, customerId } = parameters;
    const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/carts/customer/${customerId}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );

    return transformWithDiscounts(data);
};

export interface IGetCartParameters {
    cartId: string;
    token: string;
}

export const getCart = async (
    parameters: IGetCartParameters,
): Promise<ICart> => {
    const { cartId, token } = parameters;
    const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/cart/${cartId}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );

    return transformWithDiscounts(data);
};

export interface IGetCartByCustomerIdParameters {
    token: string;
    customerId: string;
}

export const getCartByCustomerId = async (
    parameters: IGetCartByCustomerIdParameters,
): Promise<ICart> => {
    const { customerId, token } = parameters;
    const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/cart?customerId=${customerId}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );

    return transformWithDiscounts(data);
};

/**
 * Get all active carts of the current admin user.
 */
export const getActiveAdminCarts = async (
    token: string,
): Promise<IExternalListPage<ICart>> => {
    const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/carts/all`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );

    return {
        cursors: { ...data.cursors },
        records: data.records.map((record: ICart) => ({
            ...record,
            createdAt: new Date(record.createdAt),
            updatedAt: new Date(record.updatedAt),
        })),
    };
};

export interface IUpdateVariantToCartParameters {
    cartId: string;
    variantId: string;
    quantity: number;
    token: string;
}

export const updateVariantToCart = async (
    parameters: IUpdateVariantToCartParameters,
): Promise<ICart> => {
    const { cartId, variantId, quantity, token } = parameters;
    const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/carts/${cartId}/variants`,
        {
            variantId,
            quantity,
        },
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );

    return transformWithDiscounts(data);
};

export interface IMarkCartAsOrderConvertedParameters {
    cartId: string;
    token: string;
}

export const markCartAsOrderConverted = async (
    parameters: IMarkCartAsOrderConvertedParameters,
): Promise<void> => {
    const { cartId, token } = parameters;
    await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/carts/${cartId}/order-converted`,
        {},
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );
};

export interface IModifyDiscountCodeParameters {
    cartId: string;
    discountCode: string;
    token?: string;
}

export const applyDiscountCode = async (
    parameters: IModifyDiscountCodeParameters,
): Promise<ICart> => {
    const { cartId, discountCode, token } = parameters;
    const { data } = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/carts/${cartId}/discount-code`,
        {
            discountCode,
        },
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );

    return transformWithDiscounts(data);
};

export const removeDiscountCode = async (
    parameters: IModifyDiscountCodeParameters,
): Promise<ICart> => {
    const { cartId, discountCode, token } = parameters;
    const { data } = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/carts/${cartId}/discount-code`,
        {
            data: {
                discountCode,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );

    return transformWithDiscounts(data);
};

export interface IChangePaymentMethodParameters {
    paymentMethod: string;
    cartId: string;
    token: string;
}

export const changePaymentMethod = async (
    parameters: IChangePaymentMethodParameters,
): Promise<ICart> => {
    const { paymentMethod, cartId, token } = parameters;
    const { data } = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/carts/${cartId}/payment-method`,
        {
            paymentMethod,
        },
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Admin-Request": "true",
            },
        },
    );

    return transformWithDiscounts(data);
};
