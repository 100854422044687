import { FunctionComponent, ReactElement, useCallback } from "react";

import { FormControlLabel, Radio, RadioGroup, Tooltip } from "@mui/material";

import { Info } from "@mui/icons-material";

import { FormikErrors } from "formik";

import { Paper } from "../../../components/common";
import { CustomerSelection } from "../../../utils/enums";

import { CustomerGroups } from "./CustomerGroups";
import {
    DiscountSection,
    ErrorMessage,
    IPriceRuleForm,
    SectionTitle,
    SectionTitleContainer,
    StyledTooltip,
} from "./common";

export interface ICustomerEligibiltySectionProps {
    values: IPriceRuleForm;
    errors: FormikErrors<IPriceRuleForm>;
    onChange: (e: React.ChangeEvent<any>) => void;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
    ) => Promise<void> | Promise<FormikErrors<IPriceRuleForm>>;
}

export const CustomerEligibilitySection: FunctionComponent<
    ICustomerEligibiltySectionProps
> = (props: ICustomerEligibiltySectionProps): ReactElement => {
    const { values, errors, onChange, setFieldValue } = props;

    const handlePrereqCustomerGroupChange = useCallback(
        (newValue: any) => {
            setFieldValue("prereqCustomerGroupIds", newValue);
        },
        [setFieldValue],
    );

    return (
        <Paper>
            <DiscountSection>
                <SectionTitleContainer>
                    <SectionTitle>Customer eligibility</SectionTitle>
                    <StyledTooltip
                        enterTouchDelay={0}
                        placement="top"
                        title={
                            "Specifies if the discount can be used by all customers or selected customer groups"
                        }
                    >
                        <Info htmlColor="#626262" fontSize="small" />
                    </StyledTooltip>
                </SectionTitleContainer>
                <RadioGroup
                    defaultValue={CustomerSelection.ALL}
                    name="customerSelection"
                    value={values.customerSelection}
                    onChange={onChange}
                >
                    <FormControlLabel
                        value={CustomerSelection.ALL}
                        control={<Radio />}
                        label="All customers"
                    />
                    <FormControlLabel
                        value={CustomerSelection.PREREQ}
                        control={<Radio />}
                        label="Specific customer segments"
                    />
                    {Number(values.customerSelection) ===
                        CustomerSelection.PREREQ && (
                        <>
                            <CustomerGroups
                                priceRuleCustomerGroupIds={
                                    values.prereqCustomerGroupIds
                                }
                                onChange={handlePrereqCustomerGroupChange}
                            />
                            {errors.prereqCustomerGroupIds && (
                                <ErrorMessage>
                                    {errors.prereqCustomerGroupIds}
                                </ErrorMessage>
                            )}
                        </>
                    )}
                    <Tooltip title="This feature will soon be enabled">
                        <FormControlLabel
                            disabled={true}
                            value="specific-customers"
                            control={<Radio />}
                            label="Specific customers"
                        />
                    </Tooltip>
                </RadioGroup>
            </DiscountSection>
        </Paper>
    );
};
