import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { IInflatedEntitySchema, api } from "../../utils";
import { EntityAttributeType } from "../../utils/enums";

export interface ICreateEntitySchemaParameter {
    name: string;
    description: string;
    entityGroups: [
        {
            name: string;
            description: string;
            entityAttributes: [
                {
                    name: string;
                    type: EntityAttributeType;
                    required: boolean;
                    default?: JSON;
                    exclusive?: boolean;
                    min?: number;
                    max?: number;
                    pattern?: number;
                    entityAttributeOptions: [
                        {
                            name: string;
                            value: JSON;
                        },
                    ];
                },
            ];
        },
    ];
}

export const useCreateEntitySchema = (
    queryKey: string | string[],
): UseMutationResult<
    IInflatedEntitySchema,
    unknown,
    ICreateEntitySchemaParameter
> => {
    const auth = useAuth();
    return useMutation({
        mutationFn: async (parameters: ICreateEntitySchemaParameter) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot create entity schema without authorization token.",
                );
            }

            return await api.EntitySchema.create({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
