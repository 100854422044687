import { OrderStatus } from "./enums";

export const loaderMessages = [
    "Gathering the finest fragrances",
    "Blending the ingredients",
    "Filter the bottle...",
];

export const stateCodes: Record<string, string> = {
    AN: "Andaman and Nicobar Islands",
    AP: "Andhra Pradesh",
    AR: "Arunachal Pradesh",
    AS: "Assam",
    BR: "Bihar",
    CG: "Chandigarh",
    CH: "Chhattisgarh",
    DN: "Dadra and Nagar Haveli",
    DD: "Daman and Diu",
    DL: "Delhi",
    GA: "Goa",
    GJ: "Gujarat",
    HR: "Haryana",
    HP: "Himachal Pradesh",
    JK: "Jammu and Kashmir",
    JH: "Jharkhand",
    KA: "Karnataka",
    KL: "Kerala",
    LA: "Ladakh",
    LD: "Lakshadweep",
    MP: "Madhya Pradesh",
    MH: "Maharashtra",
    MN: "Manipur",
    ML: "Meghalaya",
    MZ: "Mizoram",
    NL: "Nagaland",
    OR: "Odisha",
    PY: "Puducherry",
    PB: "Punjab",
    RJ: "Rajasthan",
    SK: "Sikkim",
    TN: "Tamil Nadu",
    TS: "Telangana",
    TR: "Tripura",
    UP: "Uttar Pradesh",
    UK: "Uttarakhand",
    WB: "West Bengal",
};

export const paymentSuccessfulTerminalStatuses = [
    "CHARGED",
    "COD_INITIATED",
    "SUCCESS",
];

export const globalDateTimeFormat: string = "MMMM d, yyyy";

export const globalDateFormat: string = "yyyy-MM-dd";

export const orderStatusColors: Record<OrderStatus, string> = {
    [OrderStatus.CREATED]: "#2196F3", // Blue for CREATED
    [OrderStatus.SHIPPED]: "#4CAF50", // Green for SHIPPED
    [OrderStatus.RETURNED]: "#FFC107", // Amber for RETURNED
    [OrderStatus.CANCELLED]: "#F44336", // Red for CANCELLED
    [OrderStatus.FAILED]: "#FF5722", // Deep Orange for FAILED
    [OrderStatus.SYNCED]: "#607D8B", // Blue Grey for SYNCED
    [OrderStatus.DELIVERED]: "#009688", // Teal for DELIVERED
    [OrderStatus.REFUNDED]: "#FFEB3B", // Yellow for REFUNDED
    [OrderStatus.PREBOOKING_ORDER_CREATED]: "#673AB7", // Deep Purple for PREBOOKING_ORDER_CREATED
    [OrderStatus.PREBOOKED]: "#E91E63", // Pink for PREBOOKED
    [OrderStatus.CUSTOMER_CONFIRMATION_AWAITED]: "#FF9800", // Orange for CUSTOMER_CONFIRMATION_AWAITED
    [OrderStatus.CUSTOMER_CONFIRMATION_RECEIVED]: "#8BC34A", // Light Green for CUSTOMER_CONFIRMATION_RECEIVED
};

export const statusChipColors: Record<number, string> = {
    1: "#efa700", // Yellow for draft
    2: "#4CAF50", // Green for active/published
    3: "#74848c", // Grey for archived
};

export const statusChipBgColors: Record<number, string> = {
    1: "#fff9d3", // Yellow for draft
    2: "#e6ffe0", // Green for active/published
    3: "#f2f2f2", // Grey for archived
};

export const countryMapping = {
    Afghanistan: "AFG",
    Albania: "ALB",
    Algeria: "DZA",
    "American Samoa": "ASM",
    Andorra: "AND",
    Angola: "AGO",
    Anguilla: "AIA",
    Antarctica: "ATA",
    "Antigua and Barbuda": "ATG",
    Argentina: "ARG",
    Armenia: "ARM",
    Aruba: "ABW",
    Australia: "AUS",
    Austria: "AUT",
    Azerbaijan: "AZE",
    Bahamas: "BHS",
    Bahrain: "BHR",
    Bangladesh: "BGD",
    Barbados: "BRB",
    Belarus: "BLR",
    Belgium: "BEL",
    Belize: "BLZ",
    Benin: "BEN",
    Bermuda: "BMU",
    Bhutan: "BTN",
    "Bolivia (Plurinational State of)": "BOL",
    "Bonaire, Sint Eustatius and Saba": "BES",
    "Bosnia and Herzegovina": "BIH",
    Botswana: "BWA",
    "Bouvet Island": "BVT",
    Brazil: "BRA",
    "British Indian Ocean Territory": "IOT",
    "Brunei Darussalam": "BRN",
    Bulgaria: "BGR",
    "Burkina Faso": "BFA",
    Burundi: "BDI",
    "Cabo Verde": "CPV",
    Cambodia: "KHM",
    Cameroon: "CMR",
    Canada: "CAN",
    "Cayman Islands": "CYM",
    "Central African Republic": "CAF",
    Chad: "TCD",
    Chile: "CHL",
    China: "CHN",
    "Christmas Island": "CXR",
    "Cocos (Keeling) Islands": "CCK",
    Colombia: "COL",
    Comoros: "COM",
    "Congo (Democratic Republic of the)": "COD",
    Congo: "COG",
    "Cook Islands": "COK",
    "Costa Rica": "CRI",
    Croatia: "HRV",
    Cuba: "CUB",
    Curaçao: "CUW",
    Cyprus: "CYP",
    Czechia: "CZE",
    "Côte d'Ivoire": "CIV",
    Denmark: "DNK",
    Djibouti: "DJI",
    Dominica: "DMA",
    "Dominican Republic": "DOM",
    Ecuador: "ECU",
    Egypt: "EGY",
    "El Salvador": "SLV",
    "Equatorial Guinea": "GNQ",
    Eritrea: "ERI",
    Estonia: "EST",
    Eswatini: "SWZ",
    Ethiopia: "ETH",
    "Falkland Islands [Malvinas]": "FLK",
    "Faroe Islands": "FRO",
    Fiji: "FJI",
    Finland: "FIN",
    France: "FRA",
    "French Guiana": "GUF",
    "French Polynesia": "PYF",
    "French Southern Territories": "ATF",
    Gabon: "GAB",
    Gambia: "GMB",
    Georgia: "GEO",
    Germany: "DEU",
    Ghana: "GHA",
    Gibraltar: "GIB",
    Greece: "GRC",
    Greenland: "GRL",
    Grenada: "GRD",
    Guadeloupe: "GLP",
    Guam: "GUM",
    Guatemala: "GTM",
    Guernsey: "GGY",
    Guinea: "GIN",
    "Guinea-Bissau": "GNB",
    Guyana: "GUY",
    Haiti: "HTI",
    "Heard Island and McDonald Islands": "HMD",
    "Holy See": "VAT",
    Honduras: "HND",
    "Hong Kong": "HKG",
    Hungary: "HUN",
    Iceland: "ISL",
    India: "IND",
    Indonesia: "IDN",
    "Iran (Islamic Republic of)": "IRN",
    Iraq: "IRQ",
    Ireland: "IRL",
    "Isle of Man": "IMN",
    Israel: "ISR",
    Italy: "ITA",
    Jamaica: "JAM",
    Japan: "JPN",
    Jersey: "JEY",
    Jordan: "JOR",
    Kazakhstan: "KAZ",
    Kenya: "KEN",
    Kiribati: "KIR",
    "Korea (Democratic People's Republic of)": "PRK",
    "Korea (Republic of)": "KOR",
    Kuwait: "KWT",
    Kyrgyzstan: "KGZ",
    "Lao People's Democratic Republic": "LAO",
    Latvia: "LVA",
    Lebanon: "LBN",
    Lesotho: "LSO",
    Liberia: "LBR",
    Libya: "LBY",
    Liechtenstein: "LIE",
    Lithuania: "LTU",
    Luxembourg: "LUX",
    Macao: "MAC",
    Madagascar: "MDG",
    Malawi: "MWI",
    Malaysia: "MYS",
    Maldives: "MDV",
    Mali: "MLI",
    Malta: "MLT",
    "Marshall Islands": "MHL",
    Martinique: "MTQ",
    Mauritania: "MRT",
    Mauritius: "MUS",
    Mayotte: "MYT",
    Mexico: "MEX",
    "Micronesia (Federated States of)": "FSM",
    "Moldova (Republic of)": "MDA",
    Monaco: "MCO",
    Mongolia: "MNG",
    Montenegro: "MNE",
    Montserrat: "MSR",
    Morocco: "MAR",
    Mozambique: "MOZ",
    Myanmar: "MMR",
    Namibia: "NAM",
    Nauru: "NRU",
    Nepal: "NPL",
    Netherlands: "NLD",
    "New Caledonia": "NCL",
    "New Zealand": "NZL",
    Nicaragua: "NIC",
    Niger: "NER",
    Nigeria: "NGA",
    Niue: "NIU",
    "Norfolk Island": "NFK",
    "Northern Mariana Islands": "MNP",
    Norway: "NOR",
    Oman: "OMN",
    Pakistan: "PAK",
    Palau: "PLW",
    "Palestine, State of": "PSE",
    Panama: "PAN",
    "Papua New Guinea": "PNG",
    Paraguay: "PRY",
    Peru: "PER",
    Philippines: "PHL",
    Pitcairn: "PCN",
    Poland: "POL",
    Portugal: "PRT",
    "Puerto Rico": "PRI",
    Qatar: "QAT",
    "Republic of North Macedonia": "MKD",
    Romania: "ROU",
    "Russian Federation": "RUS",
    Rwanda: "RWA",
    Réunion: "REU",
    "Saint Barthélemy": "BLM",
    "Saint Helena, Ascension and Tristan da Cunha": "SHN",
    "Saint Kitts and Nevis": "KNA",
    "Saint Lucia": "LCA",
    "Saint Martin (French part)": "MAF",
    "Saint Pierre and Miquelon": "SPM",
    "Saint Vincent and the Grenadines": "VCT",
    Samoa: "WSM",
    "San Marino": "SMR",
    "Sao Tome and Principe": "STP",
    "Saudi Arabia": "SAU",
    Senegal: "SEN",
    Serbia: "SRB",
    Seychelles: "SYC",
    "Sierra Leone": "SLE",
    Singapore: "SGP",
    "Sint Maarten (Dutch part)": "SXM",
    Slovakia: "SVK",
    Slovenia: "SVN",
    "Solomon Islands": "SLB",
    Somalia: "SOM",
    "South Africa": "ZAF",
    "South Georgia and the South Sandwich Islands": "SGS",
    "South Sudan": "SSD",
    Spain: "ESP",
    "Sri Lanka": "LKA",
    Sudan: "SDN",
    Suriname: "SUR",
    Sweden: "SWE",
    Switzerland: "CHE",
    "Syrian Arab Republic": "SYR",
    "Taiwan, Province of China": "TWN",
    Tajikistan: "TJK",
    "Tanzania, United Republic of": "TZA",
    Thailand: "THA",
    "Timor-Leste": "TLS",
    Togo: "TGO",
    Tokelau: "TKL",
    Tonga: "TON",
    "Trinidad and Tobago": "TTO",
    Tunisia: "TUN",
    Turkey: "TUR",
    Turkmenistan: "TKM",
    "Turks and Caicos Islands": "TCA",
    Tuvalu: "TUV",
    Uganda: "UGA",
    Ukraine: "UKR",
    "United Arab Emirates": "ARE",
    "United Kingdom of Great Britain and Northern Ireland": "GBR",
    "United States of America": "USA",
    Uruguay: "URY",
    Uzbekistan: "UZB",
    Vanuatu: "VUT",
    "Venezuela (Bolivarian Republic of)": "VEN",
    "Viet Nam": "VNM",
    "Wallis and Futuna": "WLF",
    "Western Sahara": "ESH",
    Yemen: "YEM",
    Zambia: "ZMB",
    Zimbabwe: "ZWE",
};

export const reverseCountryMapping = Object.fromEntries(
    Object.entries(countryMapping).map(([country, code]) => [code, country]),
);
