import { FunctionComponent, ReactElement } from "react";

import { Button, Typography, styled } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import { InstantSearch } from "react-instantsearch";
import { SearchBox, SortBy } from "react-instantsearch";
import { Link } from "react-router-dom";

import { CollectionsGrid } from "./CollectionsGrid";
import { typesenseAdapter } from "./config/typesense.config.adapter";

const Root = styled("section")`
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const TitleContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Heading = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`;

const Container = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const SearchContainer = styled("div")`
    display: flex;
    align-items: center;

    height: 40px;

    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: ${({ theme }) => theme.shape.borderRadius};

    overflow: hidden;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
`;

const CreateButton = styled(Button)`
    height: 40px;
`;

const StyledSearchBox = styled(SearchBox)`
    width: 100%;
    display: flex;

    & input {
        flex-grow: 1;
        padding: ${({ theme }) => theme.spacing(1)};

        height: 100%;

        border: none;
        outline: none;

        font-size: ${({ theme }) => theme.typography.body1.fontSize};
        appearance: none;
        &::-webkit-search-cancel-button {
            display: none;
        }
    }
    & button {
        padding: ${({ theme }) => theme.spacing(1)};

        height: 100%;

        background-color: #fff;
        border: none;

        cursor: pointer;
        transition: background-color 0.3s ease;
    }
`;

const StyledSortBy = styled(SortBy)`
    margin-right: ${({ theme }) => theme.spacing(2)};

    min-width: 200px;

    border: 1px solid ${({ theme }) => theme.palette.divider};

    border-radius: ${({ theme }) => theme.shape.borderRadius};
    overflow: hidden;

    & select {
        padding: ${({ theme }) => theme.spacing(1.5)};

        border: none;
        outline: none;

        font-size: ${({ theme }) => theme.typography.body1.fontSize};

        background-color: transparent;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
`;

export const CollectionsScreen: FunctionComponent = (): ReactElement => {
    return (
        <InstantSearch
            searchClient={typesenseAdapter.searchClient}
            indexName={`${process.env.REACT_APP_TYPESENSE_PREFIX}product-collections`}
        >
            <Root>
                <TitleContainer>
                    <Heading>Product Collections</Heading>
                    <Container>
                        <SearchContainer>
                            <StyledSearchBox />
                        </SearchContainer>
                        <Link to="/product-collections/new">
                            <CreateButton
                                variant="contained"
                                startIcon={<AddIcon />}
                            >
                                Create Collection
                            </CreateButton>
                        </Link>
                    </Container>
                </TitleContainer>

                <TitleContainer>
                    <StyledSortBy
                        items={[
                            {
                                value: `${process.env.REACT_APP_TYPESENSE_PREFIX}product-collections/sort/createdAt:desc`,
                                label: "Created At (Newest First)",
                            },
                            {
                                value: `${process.env.REACT_APP_TYPESENSE_PREFIX}product-collections/sort/createdAt:asc`,
                                label: "Created At (Oldest First)",
                            },
                        ]}
                    />
                </TitleContainer>

                <CollectionsGrid />
            </Root>
        </InstantSearch>
    );
};
