import React, { useCallback, useState } from "react";

import { Button, IconButton, Modal, Typography, styled } from "@mui/material";

import { Close } from "@mui/icons-material";

import {
    DataGrid,
    GridColDef,
    GridRowSelectionModel,
    GridToolbar,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";

import { useGetAllCustomers } from "../../hooks";
import { useMessage } from "../../sdk/hooks";
import { IPage } from "../../types";
import { globalDateTimeFormat } from "../../utils/constants";

interface ICustomersTableModalProps {
    onClose: () => void;
    openModal: boolean;
    onSelectCustomerIds: (newSelection: GridRowSelectionModel) => void;
    selectedCustomerIds: string[];
    existingCustomerIds?: string[];
    action: string;
    snackbarAction: string;
}

const Container = styled("div")`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 80%;
    height: fit-content;

    background-color: #fff;
    outline: 0;

    border-radius: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(4, 6)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        padding: ${({ theme }) => theme.spacing(4, 2)};
    }
`;

const ButtonContainer = styled("div")`
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;

    margin: ${({ theme }) => theme.spacing(1, 0)};
`;

const HeadingContainer = styled("div")`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: ${({ theme }) => theme.spacing(1, 0)};
`;

const StyledButton = styled(Button)`
    margin-top: ${({ theme }) => theme.spacing(2)};
`;

const ModalTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const columns: GridColDef[] = [
    {
        field: "firstName",
        headerName: "First name",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "lastName",
        headerName: "Last name",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "phoneNumber",
        headerName: "Phone number",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "emailAddress",
        headerName: "Email address",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "createdAt",
        headerName: "Created on",
        flex: 1,
        minWidth: 300,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
    {
        field: "updatedAt",
        headerName: "Updated on",
        flex: 1,
        minWidth: 300,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
];

const CustomersTableModal: React.FC<ICustomersTableModalProps> = (
    props: ICustomersTableModalProps,
) => {
    const {
        onClose,
        onSelectCustomerIds,
        selectedCustomerIds,
        openModal,
        existingCustomerIds,
        action,
        snackbarAction,
    } = props;

    const [page, setPage] = useState<IPage>({
        limit: 20,
        cursor: "",
        direction: "after",
    });
    const customersQuery = useGetAllCustomers(
        ["customers", page.limit.toString(), page.cursor ?? "", page.direction],
        {
            limit: page.limit,
            cursor: page.cursor,
            direction: page.direction,
        },
    );
    const { showError, showSuccess } = useMessage();

    const handleCompareCustomerIds = useCallback(
        (selectedCustomerIds: string[], existingCustomerIds?: string[]) => {
            if (selectedCustomerIds.length !== existingCustomerIds?.length) {
                return false;
            }

            return selectedCustomerIds.every(
                (element, index) => element === existingCustomerIds[index],
            );
        },
        [],
    );

    const handleSave = useCallback(() => {
        onSelectCustomerIds(selectedCustomerIds);

        if (
            !handleCompareCustomerIds(selectedCustomerIds, existingCustomerIds)
        ) {
            showSuccess(`${snackbarAction} customers successfully!`);
            onClose();
        }
        if (selectedCustomerIds.length === 0) {
            showError("Please select atleast one customer!");
        }
        if (
            handleCompareCustomerIds(selectedCustomerIds, existingCustomerIds)
        ) {
            showError("The customers are unchanged");
        }
    }, [
        existingCustomerIds,
        handleCompareCustomerIds,
        onClose,
        selectedCustomerIds,
        onSelectCustomerIds,
        showError,
        showSuccess,
        snackbarAction,
    ]);
    return (
        <Modal onClose={onClose} open={openModal}>
            <Container>
                <HeadingContainer>
                    <ModalTitle>{action} customers</ModalTitle>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </HeadingContainer>
                {(customersQuery?.data?.records ?? []).length > 0 && (
                    <DataGrid
                        rows={customersQuery?.data?.records ?? []}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        rowSelectionModel={selectedCustomerIds}
                        onRowSelectionModelChange={onSelectCustomerIds}
                        pageSizeOptions={[5, 10]}
                        disableRowSelectionOnClick={true}
                        checkboxSelection={true}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                        disableColumnSelector={true}
                        disableDensitySelector={true}
                    />
                )}
                <ButtonContainer>
                    <StyledButton variant="contained" onClick={handleSave}>
                        Save
                    </StyledButton>
                </ButtonContainer>
            </Container>
        </Modal>
    );
};

export default CustomersTableModal;
