import React, { FunctionComponent, ReactElement } from "react";

import { Typography, styled } from "@mui/material";

import { ImageNotSupported } from "@mui/icons-material";

import { CircularLoader } from "../../components/common";
import { useGetProductVariantById } from "../../sdk/hooks";
import { IOrderLineItem } from "../../sdk/types";
import { numbers } from "../../sdk/utils";
import { ProductStatus } from "../../utils/enums";

export const Product = styled("div")`
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        flex-direction: column;
        justify-content: start;
        align-items: start;

        gap: ${({ theme }) => theme.spacing(0)};
    }
`;

const ProductImage = styled("img")`
    height: 150px;
    width: 150px;
    object-fit: cover;

    border-radius: ${({ theme }) => theme.spacing(1)};
`;

const BrokenImage = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #f4f4f4;
    border-radius: ${({ theme }) => theme.spacing(1)};

    aspect-ratio: 5/6;
    height: 100%;
    width: 100%;
    max-height: 150px;
    max-width: 150px;
`;

const ProductTitle = styled(Typography)`
    font-size: 16px;
    font-weight: 400;
    color: #1a1a1a;

    margin: ${({ theme }) => theme.spacing(1, 0)};

    ${({ theme }) => theme.breakpoints.down("md")} {
        font-size: 14px;
    }
`;

const ProductPrice = styled(Typography)`
    font-size: 16px;
    font-weight: 400;
    color: #1a1a1a;

    margin: ${({ theme }) => theme.spacing(1, 0)};

    ${({ theme }) => theme.breakpoints.down("md")} {
        font-size: 14px;
    }
`;

const ProductQuantity = styled(Typography)`
    font-size: 16px;
    font-weight: 400;
    color: #1a1a1a;

    margin: ${({ theme }) => theme.spacing(1, 0)};

    ${({ theme }) => theme.breakpoints.down("md")} {
        font-size: 14px;
    }
`;

const Container = styled("div")``;

export interface IOrderLineItemProps {
    orderLineItem: IOrderLineItem;
}

export const OrderLineItem: FunctionComponent<IOrderLineItemProps> = (
    props: IOrderLineItemProps,
): ReactElement => {
    const { orderLineItem } = props;
    const { variant, isLoading } = useGetProductVariantById({
        key: ["products", orderLineItem.variantId],
        variantId: orderLineItem.variantId,
        status: [
            ProductStatus.PUBLISHED.toString(),
            ProductStatus.ARCHIVED.toString(),
        ],
    });

    if (!variant) {
        return <></>;
    }

    if (isLoading) {
        return <CircularLoader />;
    }

    const freeVariant = orderLineItem.price === 0;
    /* Note: The below check will work only after we start fetching products from our DB.  */
    const merchProduct = variant.product?.meta?.tags?.includes("merch");

    return (
        <Product>
            {variant.images[0]?.objectUrl && (
                <ProductImage
                    src={variant.images[0].objectUrl}
                    alt={variant.product.title}
                />
            )}
            {!variant.images[0]?.objectUrl && (
                <BrokenImage>
                    <ImageNotSupported />
                </BrokenImage>
            )}
            <Container>
                <ProductTitle>
                    Name: {variant.product.title}{" "}
                    {!merchProduct && variant.title}
                </ProductTitle>
                <ProductQuantity>
                    Quantity: {orderLineItem.quantity}
                </ProductQuantity>
                <ProductPrice>
                    Price:{" "}
                    {freeVariant
                        ? numbers.formatCurrency(
                              orderLineItem.price * orderLineItem.quantity,
                          )
                        : numbers.formatCurrency(
                              orderLineItem.mrp * orderLineItem.quantity,
                          )}
                </ProductPrice>
            </Container>
        </Product>
    );
};
