import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";

import { Icon, Typography, styled } from "@mui/material";

import { LoadingButton } from "@mui/lab";

import Form from "@rjsf/core";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useNavigate, useParams } from "react-router-dom";

import {
    CircularLoader,
    Paper,
    WorkspaceToolbar,
} from "../../components/common";
import { getTemplates, getWidgets } from "../../components/rjsf";
import {
    useCreateEntityAttribute,
    useCreateEntityAttributeOption,
    useCreateEntityGroup,
    useCreateEntitySchema,
    useDeleteEntityAttribute,
    useDeleteEntityAttributeOption,
    useDeleteEntityGroup,
    useDeleteEntitySchema,
    useGetEntitySchemaById,
    useUpdateEntityAttribute,
    useUpdateEntityAttributeOption,
    useUpdateEntityGroup,
    useUpdateEntitySchema,
} from "../../hooks";
import { useMessage } from "../../sdk/hooks";
import {
    IEntityAttributeOption,
    IInflatedEntityAttribute,
    IInflatedEntityGroup,
    IInflatedEntitySchema,
} from "../../utils";
import { EntityAttributeType, entityAttributeNames } from "../../utils/enums";

const Container = styled("div")`
    display: flex;
    justify-content: start;
    align-items: center;

    max-width: 1000px;

    padding: ${({ theme }) => theme.spacing(2)};
    margin: ${({ theme }) => theme.spacing(1)};
`;

const Title = styled(Typography)`
    font-size: 18px;
    font-weight: 500;

    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const widgets = getWidgets();
const templates = getTemplates();

export const rjsfSchema: RJSFSchema = {
    type: "object",
    properties: {
        id: { type: "string", title: "ID", default: "" },
        name: {
            type: "string",
            minLength: 1,
            maxLength: 255,
            title: "Name",
            default: "",
        },
        description: {
            type: "string",
            minLength: 1,
            maxLength: 255,
            title: "Description",
            default: "",
        },
        groups: {
            type: "array",
            title: "Groups",
            items: {
                required: ["name"],
                type: "object",
                properties: {
                    id: { type: "string", title: "ID", default: "" },
                    name: {
                        type: "string",
                        minLength: 1,
                        maxLength: 255,
                        title: "Name",
                        default: "",
                    },
                    description: {
                        type: "string",
                        minLength: 1,
                        maxLength: 255,
                        title: "Description",
                        default: "",
                    },
                    schemaId: {
                        type: "string",
                        title: "Schema ID",
                        default: "",
                    },
                    attributes: {
                        type: "array",
                        title: "Attributes",
                        items: {
                            type: "object",
                            properties: {
                                id: {
                                    type: "string",
                                    title: "ID",
                                    default: "",
                                },
                                name: {
                                    type: "string",
                                    minLength: 1,
                                    maxLength: 255,
                                    title: "Name",
                                    default: "",
                                },
                                required: {
                                    type: "boolean",
                                    title: "Required",
                                    default: false,
                                },
                                default: {
                                    type: "string",
                                    title: "Default",
                                    default: "",
                                },
                                attributeType: {
                                    type: "number",
                                    title: "Attribute Type",
                                    default: "Integer",
                                    // enum: [
                                    //     EntityAttributeType.INTEGER,
                                    //     EntityAttributeType.NUMBER,
                                    //     EntityAttributeType.BOOLEAN,
                                    //     EntityAttributeType.SELECT,
                                    //     EntityAttributeType.CHECKLIST,
                                    //     EntityAttributeType.SINGLE_LINE,
                                    //     EntityAttributeType.MULTI_LINE,
                                    //     EntityAttributeType.MARKDOWN,
                                    //     EntityAttributeType.DATE,
                                    //     EntityAttributeType.DATE_TIME,
                                    //     EntityAttributeType.TIME,
                                    // ],
                                    enum: [
                                        "Integer",
                                        "Number",
                                        "Boolean",
                                        "Select",
                                        "Checklist",
                                        "Single Line",
                                        "Multiline",
                                        "Markdown",
                                        "Date",
                                        "Date Time",
                                        "Time",
                                    ],
                                },
                                min: {
                                    type: "number",
                                    title: "Min",
                                    default: 0,
                                },
                                max: {
                                    type: "number",
                                    title: "Max",
                                    default: 0,
                                },
                                pattern: {
                                    type: "string",
                                    minLength: 1,
                                    maxLength: 255,
                                    title: "Pattern",
                                    default: "",
                                },
                                exclusive: {
                                    type: "boolean",
                                    title: "Exclusive",
                                    default: false,
                                },
                                groupId: {
                                    type: "string",
                                    title: "Group ID",
                                    default: "",
                                },
                                options: {
                                    type: "array",
                                    title: "Options",
                                    items: {
                                        type: "object",
                                        properties: {
                                            id: {
                                                type: "string",
                                                title: "ID",
                                                default: "",
                                            },
                                            name: {
                                                type: "string",
                                                minLength: 1,
                                                maxLength: 255,
                                                title: "Name",
                                                default: "",
                                            },
                                            value: {
                                                type: "string",
                                                title: "Value",
                                                default: "",
                                            },
                                            attributeId: {
                                                type: "string",
                                                title: "Attribute ID",
                                                default: "",
                                            },
                                        },
                                        required: ["name", "value"],
                                    },
                                },
                            },
                            dependencies: {
                                attributeType: {
                                    oneOf: [
                                        {
                                            properties: {
                                                attributeType: {
                                                    enum: ["Integer", "Number"],
                                                },
                                                min: {
                                                    type: "number",
                                                    title: "Min",
                                                },
                                                max: {
                                                    type: "number",
                                                    title: "Max",
                                                },
                                            },
                                            required: ["min", "max"],
                                        },
                                        {
                                            properties: {
                                                attributeType: {
                                                    enum: [
                                                        "Boolean",
                                                        "Select",
                                                        "Checklist",
                                                        "Single Line",
                                                        "Multi Line",
                                                        "Markdown",
                                                        "Date",
                                                        "Date Time",
                                                        "Time",
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                            required: ["name", "required", "attributeType"],
                        },
                    },
                },
            },
        },
    },
    required: ["name"],
};

export const uiSchema: UiSchema = {
    id: { "ui:widget": "hidden" },
    name: {
        "ui:widget": "text",
        "ui:options": {
            style: { width: "850px" },
        },
    },
    description: {
        "ui:widget": "textarea",
        "ui:options": {
            style: { width: "850px", heigth: "150px" },
        },
    },
    groups: {
        items: {
            id: { "ui:widget": "hidden" },
            name: {
                "ui:widget": "text",
                "ui:options": {
                    style: { width: "850px", margin: "10px 0px 0px 0px" },
                },
            },
            description: {
                "ui:widget": "textarea",
                "ui:options": {
                    style: { width: "850px", heigth: "50px" },
                },
            },
            schemaId: {
                "ui:widget": "hidden",
            },
            attributes: {
                items: {
                    id: { "ui:widget": "hidden" },
                    name: {
                        "ui:widget": "text",
                        "ui:options": {
                            style: {
                                width: "850px",
                                margin: "10px 0px 0px 0px",
                            },
                        },
                    },
                    required: {
                        "ui:widget": "checkbox",
                    },
                    default: {
                        "ui:widget": "textarea",
                        "ui:options": {
                            style: { width: "850px" },
                        },
                    },
                    attributeType: {
                        "ui:widget": "select",
                        "ui:options": {
                            style: { width: "300px" },
                        },
                    },
                    min: {
                        "ui:widget": "text",
                        "ui:options": {
                            style: { width: "300px" },
                        },
                    },
                    max: {
                        "ui:widget": "text",
                        "ui:options": {
                            style: { width: "300px" },
                        },
                    },
                    pattern: {
                        "ui:widget": "text",
                        "ui:options": {
                            style: { width: "850px" },
                        },
                    },
                    exclusive: {
                        "ui:widget": "checkbox",
                    },
                    groupId: {
                        "ui:widget": "hidden",
                    },
                    options: {
                        items: {
                            id: { "ui:widget": "hidden" },
                            name: {
                                "ui:widget": "text",
                                "ui:options": {
                                    style: {
                                        width: "850px",
                                        margin: "10px 0px 0px 0px",
                                    },
                                },
                            },
                            value: {
                                "ui:widget": "textarea",
                                "ui:options": {
                                    style: {
                                        width: "850px",
                                        heigth: "50px",
                                    },
                                },
                            },
                            attributeId: {
                                "ui:widget": "hidden",
                            },
                        },
                    },
                },
            },
        },
    },
};

const generateDiff = (
    original: Record<string, any>,
    updated: Record<string, any>,
): Record<string, any> => {
    let diff: Record<string, any> = {};

    for (let key in original) {
        if (updated.hasOwnProperty(key)) {
            if (Array.isArray(original[key])) {
                diff[key] = original[key]
                    .map((item: any, index: number) => {
                        if (updated[key][index]) {
                            return generateDiff(item, updated[key][index]);
                        }
                        return null;
                    })
                    .filter((item: any) => item !== null)
                    .concat(
                        updated[key]
                            .slice(original[key].length)
                            .map((item: any) => ({ id: null, value: item })),
                    );
            } else if (
                typeof original[key] === "object" &&
                original[key] !== null &&
                updated[key]
            ) {
                let subDiff = generateDiff(original[key], updated[key]);
                if (Object.keys(subDiff).length > 0) {
                    /* Only include the sub-diff if it has any keys */
                    diff[key] = subDiff;
                }
            } else if (original[key] !== updated[key]) {
                diff[key] = { id: original.id, value: updated[key] };
            }
        }
    }

    return diff;
};

const mapEntityAttributeType = (formData: any) => {
    const updatedFormData = { ...formData };

    updatedFormData.groups.forEach((group: any) => {
        group.attributes.forEach((attribute: any) => {
            const attributeTypeKey = Object.keys(EntityAttributeType).find(
                (key) =>
                    entityAttributeNames[
                        EntityAttributeType[
                            key as keyof typeof EntityAttributeType
                        ]
                    ] === attribute.attributeType,
            );

            if (attributeTypeKey) {
                attribute.attributeType =
                    EntityAttributeType[
                        attributeTypeKey as keyof typeof EntityAttributeType
                    ];
            }
        });
    });

    return updatedFormData;
};

export const EditEntitySchemaScreen: FunctionComponent = (): ReactElement => {
    const params = useParams();
    const createNewEntitySchema = params.id === "new";
    const navigate = useNavigate();

    const [updatedEntitySchema, setUpdatedEntitySchema] = useState<any>();
    const entitySchemaQuery = useGetEntitySchemaById(
        ["entitySchemas", params.id ?? ""],
        params.id ?? "",
    );

    const createEntitySchemaMutation = useCreateEntitySchema("entitySchemas");
    const updateEntitySchemaMutation = useUpdateEntitySchema("entitySchemas");
    const deleteEntitySchemaMutation = useDeleteEntitySchema("entitySchemas");

    const createEntityGroupMutation = useCreateEntityGroup("entityGroups");
    const updateEntityGroupMutation = useUpdateEntityGroup("entityGroups");
    const deleteEntityGroupMutation = useDeleteEntityGroup("entityGroups");

    const createEntityAttributeMutation = useCreateEntityAttribute();
    const updateEntityAttributeMutation =
        useUpdateEntityAttribute("entityAttributes");
    const deleteEntityAttributeMutation =
        useDeleteEntityAttribute("entityAttributes");

    const createEntityAttributeOptionMutation = useCreateEntityAttributeOption(
        "entityAttributeOptions",
    );
    const updateEntityAttributeOptionMutation = useUpdateEntityAttributeOption(
        "entityAttributeOptions",
    );
    const deleteEntityAttributeOptionMutation = useDeleteEntityAttributeOption(
        "entityAttributeOptions",
    );

    const [pageTitle, setPageTitle] = useState("");
    const [action, setAction] = useState("");
    const [snackbarAction, setSnackbarAction] = useState("");

    const { showError, showSuccess } = useMessage();
    const [isLoading, setIsLoading] = useState(true);
    const [transformedResponse, setTransformedResponse] = useState<any>();

    useEffect(() => {
        if (entitySchemaQuery?.data) {
            const response = transformResponse(entitySchemaQuery.data);
            setTransformedResponse(response);
            setIsLoading(false);
            if (params.id === "new") {
                setIsLoading(false);
            }
        }
    }, [entitySchemaQuery, entitySchemaQuery.data, params.id]);

    useEffect(() => {
        if (createNewEntitySchema) {
            setPageTitle("Create");
            setAction("Select");
            setSnackbarAction("Added");
        } else {
            setPageTitle("Edit");
            setAction("Update");
            setSnackbarAction("Updated");
        }
    }, [createNewEntitySchema]);

    const transformResponse = (response: IInflatedEntitySchema) => {
        const { id, name, description, entityGroups } = response;

        const groups = entityGroups.map((group: IInflatedEntityGroup) => {
            const { id, name, description, entityAttributes, entitySchemaId } =
                group;

            const attributes = entityAttributes.map(
                (attribute: IInflatedEntityAttribute) => {
                    const {
                        id,
                        name,
                        type,
                        required,
                        min,
                        max,
                        exclusive,
                        pattern,
                        default: defaultValue,
                        entityAttributeOptions,
                        entityGroupId,
                    } = attribute;

                    const options = entityAttributeOptions.map(
                        (option: IEntityAttributeOption) => {
                            const { id, name, value, entityAttributeId } =
                                option;
                            return {
                                id,
                                name,
                                value: JSON.stringify(value),
                                entityAttributeId,
                            };
                        },
                    );

                    return {
                        id,
                        name,
                        required: Boolean(required),
                        min,
                        max,
                        exclusive,
                        pattern,
                        default: JSON.stringify(defaultValue),
                        attributeType: entityAttributeNames[type],
                        entityGroupId,
                        options: options.reverse(),
                    };
                },
            );

            return {
                id,
                name,
                description,
                entitySchemaId,
                attributes: attributes.reverse(),
            };
        });

        return {
            id,
            name,
            description,
            groups,
        };
    };

    async function handleSave() {
        const formData = mapEntityAttributeType(updatedEntitySchema);
        if (createNewEntitySchema) {
            createEntitySchemaMutation.mutate(
                {
                    name: formData.name,
                    description: formData.description,
                    entityGroups: formData.groups.map((group: any) => ({
                        name: group.name,
                        description: group.description,
                        entityAttributes: group.attributes.map(
                            (attribute: any) => {
                                return {
                                    name: attribute.name,
                                    type: attribute.attributeType,
                                    required: attribute.required,
                                    default:
                                        attribute.default.length === 0
                                            ? {}
                                            : JSON.parse(attribute.default),
                                    exclusive: attribute.exclusive,
                                    min: attribute.min,
                                    max: attribute.max,
                                    pattern: attribute.pattern,
                                    entityAttributeOptions:
                                        attribute.options.map(
                                            (option: any) => ({
                                                name: option.name,
                                                value: JSON.parse(option.value),
                                            }),
                                        ),
                                };
                            },
                        ),
                    })),
                },
                {
                    onSuccess: (newSchema) => {
                        showSuccess("Entity schema created successfully");
                        navigate(`/entity-schemas/${newSchema.id}`);
                    },
                    onError: () => {
                        showError("Failed to create entity schema");
                    },
                },
            );
        } else {
            const diff = generateDiff(transformedResponse, formData);
            if (diff) {
                if (
                    diff.name !== transformedResponse.name ||
                    diff.description !== transformedResponse.description
                ) {
                    updateEntitySchemaMutation.mutate(
                        {
                            name: updatedEntitySchema.name,
                            description: updatedEntitySchema.description,
                            schemaId: updatedEntitySchema.id,
                        },
                        {
                            onSuccess: () => {
                                showSuccess(
                                    "Entity schema updated successfully",
                                );
                            },
                            onError: () => {
                                showError("Failed to update entity schema");
                            },
                        },
                    );
                }

                for (let group of transformedResponse.groups) {
                    const updatedGroup = formData.groups.find(
                        (g: any) => g.id === group.id,
                    );

                    if (!updatedGroup) {
                        deleteEntityGroupMutation.mutate(
                            {
                                groupId: group.id,
                            },
                            {
                                onSuccess: () => {
                                    showError(
                                        "Entity group deleted successfully",
                                    );
                                },
                                onError: () => {
                                    showError("Failed to delete group");
                                },
                            },
                        );
                    } else {
                        if (
                            JSON.stringify(group) !==
                            JSON.stringify(updatedGroup)
                        ) {
                            updateEntityGroupMutation.mutate(
                                {
                                    name: updatedGroup.name,
                                    description: updatedGroup.description,
                                    groupId: updatedGroup.id,
                                },
                                {
                                    onSuccess: () => {
                                        showSuccess(
                                            "Entity group updated successfully",
                                        );
                                    },
                                    onError: () => {
                                        showError("Failed to update group");
                                    },
                                },
                            );
                        }

                        for (let attribute of group.attributes) {
                            const updatedAttribute =
                                updatedGroup.attributes.find(
                                    (a: any) => a.id === attribute.id,
                                );
                            if (!updatedAttribute && !attribute.id) {
                                createEntityAttributeMutation.mutate(
                                    {
                                        name: attribute.name,
                                        type: attribute.attributeType,
                                        required: attribute.required,
                                        default: JSON.parse(attribute.default),
                                        exclusive: attribute.exclusive,
                                        min: attribute.min,
                                        max: attribute.max,
                                        pattern: attribute.pattern,
                                        groupId: group.id,
                                    },
                                    {
                                        onSuccess: (newAttribute) => {
                                            attribute.id = newAttribute.id;
                                            attribute.groupId =
                                                newAttribute.entityGroupId;
                                            showSuccess(
                                                "Attribute created successfully",
                                            );
                                        },
                                        onError: () => {
                                            showError(
                                                "Failed to create attribute",
                                            );
                                        },
                                    },
                                );
                            } else if (!updatedAttribute) {
                                deleteEntityAttributeMutation.mutate(
                                    {
                                        attributeId: attribute.id,
                                    },
                                    {
                                        onSuccess: () => {
                                            showError(
                                                "Entity attribute deleted successfully",
                                            );
                                        },
                                        onError: () => {
                                            showError(
                                                "Failed to delete attribute",
                                            );
                                        },
                                    },
                                );
                            } else {
                                if (
                                    JSON.stringify(attribute) !==
                                    JSON.stringify(updatedAttribute)
                                ) {
                                    updateEntityAttributeMutation.mutate(
                                        {
                                            name: updatedAttribute.name,
                                            type: updatedAttribute.attributeType,
                                            required: updatedAttribute.required,
                                            default: JSON.parse(
                                                updatedAttribute.default,
                                            ),
                                            exclusive:
                                                updatedAttribute.exclusive,
                                            min: updatedAttribute.min,
                                            max: updatedAttribute.max,
                                            pattern: updatedAttribute.pattern,
                                            groupId: updatedAttribute.groupId,
                                            attributeId: updatedAttribute.id,
                                        },
                                        {
                                            onSuccess: () => {
                                                showSuccess(
                                                    "Entity attribute updated successfully",
                                                );
                                            },
                                            onError: () => {
                                                showError(
                                                    "Failed to update attribute",
                                                );
                                            },
                                        },
                                    );
                                }

                                for (let option of attribute.options) {
                                    const updatedOption =
                                        updatedAttribute.options.find(
                                            (o: any) => o.id === option.id,
                                        );

                                    if (!updatedOption) {
                                        deleteEntityAttributeOptionMutation.mutate(
                                            {
                                                optionId: option.id,
                                            },
                                            {
                                                onSuccess: () => {
                                                    showError(
                                                        "Entity attribute option deleted successfully",
                                                    );
                                                },
                                                onError: () => {
                                                    showError(
                                                        "Failed to delete attribute option",
                                                    );
                                                },
                                            },
                                        );
                                    } else if (
                                        JSON.stringify(option) !==
                                        JSON.stringify(updatedOption)
                                    ) {
                                        updateEntityAttributeOptionMutation.mutate(
                                            {
                                                name: updatedOption.name,
                                                value: JSON.parse(
                                                    updatedOption.value,
                                                ),
                                                attributeId:
                                                    updatedOption.attributeId,
                                                optionId: updatedOption.id,
                                            },
                                            {
                                                onSuccess: () => {
                                                    showSuccess(
                                                        "Entity attribute option updated successfully",
                                                    );
                                                },
                                                onError: () => {
                                                    showError(
                                                        "Failed to update attribute option",
                                                    );
                                                },
                                            },
                                        );
                                    }
                                }
                            }
                        }
                    }
                }

                /* check for new groups */
                for (let group of formData.groups) {
                    if (
                        !transformedResponse.groups.find(
                            (g: any) => g.id === group.id,
                        )
                    ) {
                        /* create new group */
                        createEntityGroupMutation.mutate(
                            {
                                ...group,
                                entitySchemaId: formData.id,
                            },
                            {
                                onSuccess: (newGroup) => {
                                    group.id = newGroup.id;
                                    group.entitySchemaId =
                                        newGroup.entitySchemaId;
                                    showSuccess("Group created successfully");
                                },
                                onError: () => {
                                    showError("Failed to create group");
                                },
                            },
                        );

                        /* check for new attributes */
                        for (let attribute of group.attributes) {
                            if (
                                !group.attributes.find(
                                    (a: any) => a.id === attribute.id,
                                )
                            ) {
                                /* create attribute */
                                createEntityAttributeMutation.mutate(
                                    {
                                        name: attribute.name,
                                        type: attribute.attributeType,
                                        required: attribute.required,
                                        default: JSON.parse(attribute.default),
                                        exclusive: attribute.exclusive,
                                        min: attribute.min,
                                        max: attribute.max,
                                        pattern: attribute.pattern,
                                        groupId: group.id,
                                    },
                                    {
                                        onSuccess: (newAttribute) => {
                                            attribute.id = newAttribute.id;
                                            attribute.groupId =
                                                newAttribute.entityGroupId;
                                            showSuccess(
                                                "Attribute created successfully",
                                            );
                                        },
                                        onError: () => {
                                            showError(
                                                "Failed to create attribute",
                                            );
                                        },
                                    },
                                );

                                /* check for new options */
                                for (let option of attribute.attributeOptions) {
                                    if (
                                        !attribute.attributeOptions.find(
                                            (o: any) => o.id === option.id,
                                        )
                                    ) {
                                        /* create new option */
                                        createEntityAttributeOptionMutation.mutate(
                                            {
                                                name: option.name,
                                                value: JSON.parse(option.value),
                                                attributeId: attribute.id,
                                            },
                                            {
                                                onSuccess: (newOption) => {
                                                    option.id = newOption.id;
                                                    option.attributeId =
                                                        newOption.entityAttributeId;
                                                    showSuccess(
                                                        "Option created successfully",
                                                    );
                                                },
                                                onError: () => {
                                                    showError(
                                                        "Failed to create option",
                                                    );
                                                },
                                            },
                                        );
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    const handleChange = useCallback((e: any) => {
        setUpdatedEntitySchema(e.formData);
    }, []);

    const handleDiscard = useCallback(() => {
        navigate("/entity-schemas");
    }, [navigate]);

    const handleDelete = useCallback(() => {
        deleteEntitySchemaMutation.mutate(
            {
                schemaId: params.id ?? "",
            },
            {
                onSuccess: () => {
                    showError("Entity schema deleted successfully");
                    navigate("/entity-schemas");
                },
                onError: () => {
                    showError("Failed to delete entity schema");
                },
            },
        );
    }, [deleteEntitySchemaMutation, navigate, params.id, showError]);

    if (isLoading && !createNewEntitySchema) {
        return <CircularLoader />;
    }

    return (
        <>
            <WorkspaceToolbar
                title={`${pageTitle} Entity Schema`}
                enableSearch={false}
                actions={
                    <>
                        {createNewEntitySchema && (
                            <LoadingButton
                                variant="contained"
                                color="error"
                                size="small"
                                endIcon={<Icon>delete</Icon>}
                                onClick={handleDiscard}
                                disabled={updatedEntitySchema}
                            >
                                Discard
                            </LoadingButton>
                        )}
                        {!createNewEntitySchema && (
                            <LoadingButton
                                variant="contained"
                                color="error"
                                size="small"
                                endIcon={<Icon>delete</Icon>}
                                onClick={handleDelete}
                                disabled={createNewEntitySchema}
                                loading={deleteEntitySchemaMutation.isLoading}
                            >
                                Delete
                            </LoadingButton>
                        )}
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="small"
                            endIcon={<Icon>check_circle</Icon>}
                            onClick={handleSave}
                            disabled={
                                !updatedEntitySchema || !updatedEntitySchema
                            }
                            loading={
                                createEntityAttributeMutation.isLoading ||
                                createEntityAttributeOptionMutation.isLoading ||
                                createEntityGroupMutation.isLoading ||
                                createEntitySchemaMutation.isLoading ||
                                deleteEntityAttributeMutation.isLoading ||
                                deleteEntityAttributeOptionMutation.isLoading ||
                                deleteEntityGroupMutation.isLoading ||
                                deleteEntitySchemaMutation.isLoading ||
                                updateEntityAttributeMutation.isLoading ||
                                updateEntityAttributeOptionMutation.isLoading ||
                                updateEntityGroupMutation.isLoading ||
                                updateEntitySchemaMutation.isLoading
                            }
                        >
                            Save
                        </LoadingButton>
                    </>
                }
            />
            <Container>
                <Paper>
                    <Form
                        schema={rjsfSchema}
                        uiSchema={uiSchema}
                        validator={validator}
                        templates={templates}
                        widgets={widgets}
                        formData={
                            createNewEntitySchema ? {} : transformedResponse
                        }
                        children={true}
                        onChange={handleChange}
                        onSubmit={handleSave}
                    />
                </Paper>
            </Container>
        </>
    );
};
