import axios from "axios";

import * as api from "../../types/api";
import { IAuthHistory } from "../../../types";

export interface IGetCustomerParameter {
    customerId: string;
    token: string;
}

export interface IGetAllCustomersParameter {
    token: string;
    limit: number;
    direction: string;
    cursor: string | null;
}

export interface ICreateCustomerParameter {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    token: string;
}

export interface IUpdateCustomerParameter {
    customerId: string;
    emailAddress: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    token: string;
}

export class Customer {
    public static transform = (data: api.ICustomer): api.ICustomer => {
        return {
            ...data,
            createdAt: new Date(data.createdAt),
            updatedAt: new Date(data.updatedAt),
        };
    };

    static async create(
        parameters: ICreateCustomerParameter,
    ): Promise<api.ICustomer> {
        const { token, ...body } = parameters;
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/storefronts/v1.1/customers`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return this.transform(data);
    }

    static async getById(
        parameters: IGetCustomerParameter,
    ): Promise<api.ICustomer> {
        const { token, customerId } = parameters;
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/storefronts/v1.1/customers/${customerId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return this.transform(data);
    }

    static async getAll(
        parameters: IGetAllCustomersParameter,
    ): Promise<api.ICustomer[]> {
        const { token, limit, direction, cursor } = parameters;
        const params = new URLSearchParams([
            ["limit", limit.toString()],
            ["direction", direction],
        ]);

        if (cursor) {
            params.append("cursor", cursor);
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/storefronts/v1.1/customers?${params}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return data;
    }

    static async update(
        parameters: IUpdateCustomerParameter,
    ): Promise<api.ICustomer> {
        const { token, customerId, ...body } = parameters;
        const { data } = await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/api/storefronts/v1.1/customers/${customerId}`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return this.transform(data);
    }

    static async getAuthHistory(
        phoneNumber: string,
        parameters: IGetAllCustomersParameter,
    ): Promise<api.IExternalListPage<IAuthHistory>> {
        const { token, limit, direction, cursor } = parameters;
        const params = new URLSearchParams([
            ["limit", limit.toString()],
            ["direction", direction],
        ]);

        if (cursor) {
            params.append("cursor", cursor);
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/storefronts/v1.1/customers/${phoneNumber}/auth-history/?${params}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Admin-Request": "true",
                },
            },
        );

        return data;
    }
}
