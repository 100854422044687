import { FunctionComponent, ReactElement, useCallback } from "react";

import {
    Divider,
    Modal,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import { useMessage, useRefundOrder } from "../../sdk/hooks";
import { IGetOrderResponse, IRefundFormValues } from "../../sdk/types";

import { RefundForm } from "./RefundForm";
import { RefundList } from "./RefundList";

const DialogContainer = styled("div")`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};
    align-items: start;
    justify-items: start;

    width: fit-content;
    height: fit-content;

    background-color: #fff;
    outline: 0;

    border-radius: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(4, 6)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        flex-direction: column;
        gap: ${({ theme }) => theme.spacing(1)};
        padding: ${({ theme }) => theme.spacing(2)};
    }
`;

const Container = styled("div")`
    display: flex;
    flex-direction: column;
`;

const LeftContainer = styled("section")`
    display: flex;
    flex-direction: column;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      max-width: unset;
      width:100%;
      flex:1;
    }
    
  `}
`;

const RightContainer = styled("section")`
    flex: 1;
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing(3)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
        width: 100%;
        gap: ${theme.spacing(1)};

    }`}
`;

const Title = styled(Typography)`
    font-size: 16px;
    font-weight: 500;

    margin-bottom: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => theme.breakpoints.down("sm")} {
        margin-bottom: 0;
    }
`;

const Subtitle = styled(Typography)`
    font-size: 16px;
    font-weight: 500;

    color: #222222;
`;

export interface IRefundDialogProps {
    orderId: string;
    order: IGetOrderResponse;
    formOpen: boolean;
    onClose: () => void;
}

export const RefundDialog: FunctionComponent<IRefundDialogProps> = (
    props: IRefundDialogProps,
): ReactElement => {
    const { orderId, formOpen, onClose, order } = props;
    const refunds = order?.refunds ?? [];

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const refundOrderMutation = useRefundOrder(["getOrderById", orderId]);
    const message = useMessage();

    const handleFormSubmit = useCallback(
        (values: IRefundFormValues) => {
            refundOrderMutation.mutate(
                {
                    orderId: order.id,
                    reason: values.reason,
                    note: values.note,
                    amount: values.amount,
                },
                {
                    onSuccess: (data) => {
                        message.showSuccess(data.message);
                        onClose();
                    },
                    onError: (error: any) => {
                        message.showError(error);
                    },
                },
            );
        },
        [message, onClose, order.id, refundOrderMutation],
    );

    return (
        <Modal open={formOpen} onClose={onClose}>
            <DialogContainer>
                <LeftContainer>
                    <Container>
                        <Title>Past refunds</Title>
                        <RefundList refunds={refunds} />
                    </Container>
                </LeftContainer>
                {!smallScreen && (
                    <Divider orientation="vertical" variant="middle" flexItem />
                )}
                <RightContainer>
                    <Subtitle>Refund</Subtitle>
                    <RefundForm
                        onCancel={onClose}
                        onSubmit={handleFormSubmit}
                        order={order}
                        debounce={refundOrderMutation.isLoading}
                        cancelButtonText="Cancel"
                    />
                </RightContainer>
            </DialogContainer>
        </Modal>
    );
};
