import Client from "shopify-buy";

declare const process: any;

export const shopify = Client.buildClient({
    apiVersion: "2023-01",
    storefrontAccessToken:
        process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    domain: process.env.REACT_APP_SHOPIFY_STORE_DOMAIN,
});

export const parseShopifyResponse = (response: any) =>
    JSON.parse(JSON.stringify(response));

export const fetchAllProducts = async () => {
    const products = await shopify.product.fetchAll();
    const productResponse = await parseShopifyResponse(products);
    return productResponse.map((product: any) => ({
        title: product.title,
        description: product.description,
        id: product.id,
        productType: product.productType,
        variants: product.variants.map((variant: any) => ({
            id: variant.id,
            imageUrl: variant.image?.src || "",
            title: variant.title,
            comparePrice: variant.compareAtPrice,
            price: {
                amount: variant.price.amount,
                currencyCode: variant.price.currencyCode,
            },
        })),
    }));
};

export const createCheckout = async () => {
    const checkout = await shopify.checkout.create();
    const checkoutResponse = await parseShopifyResponse(checkout);
    return {
        id: checkoutResponse.id,
        currencyCode: checkoutResponse.currencyCode,
        items: checkoutResponse.lineItems,
        subTotal: {
            amount: checkoutResponse.subtotalPrice.amount,
            currencyCode: checkoutResponse.subtotalPrice.currencyCode,
        },
        total: {
            amount: checkoutResponse.totalPrice.amount,
            currencyCode: checkoutResponse.totalPrice.currencyCode,
        },
        paymentDue: {
            amount: checkoutResponse.paymentDue.amount,
            currencyCode: checkoutResponse.paymentDue.currencyCode,
        },
    };
};

export const fetchCheckout = async (checkoutId: string) => {
    const response = await shopify.checkout.fetch(checkoutId);
    const checkoutResponse = await parseShopifyResponse(response);
    return {
        id: checkoutResponse.id,
        currencyCode: checkoutResponse.currencyCode,
        items: checkoutResponse.lineItems.map((item: any) => ({
            id: item.id,
            variantId: item.variant.id,
            imageUrl: item.variant.image.src,
            title: item.title,
            quantity: item.quantity,
            price: {
                amount: item.variant.price.amount,
                currencyCode: item.variant.price.currencyCode,
            },
        })),
        subTotal: {
            amount: checkoutResponse.subtotalPrice.amount,
            currencyCode: checkoutResponse.subtotalPrice.currencyCode,
        },
        total: {
            amount: checkoutResponse.totalPrice.amount,
            currencyCode: checkoutResponse.totalPrice.currencyCode,
        },
        paymentDue: {
            amount: checkoutResponse.paymentDue.amount,
            currencyCode: checkoutResponse.paymentDue.currencyCode,
        },
    };
};

export const addProduct = async (
    checkoutId: string,
    productVariantId: string,
) => {
    const lineItemsToAdd = [
        {
            variantId: productVariantId,
            quantity: 1,
        },
    ];
    const response = await shopify.checkout.addLineItems(
        checkoutId,
        lineItemsToAdd,
    );
    return parseShopifyResponse(response);
};

export const updateProduct = async (
    checkoutId: string,
    lineItemId: string,
    quantity: number,
) => {
    const lineItemsToUpdate = [{ id: lineItemId, quantity }];
    const response = await shopify.checkout.updateLineItems(
        checkoutId,
        lineItemsToUpdate as any, // Add variant ID to line item
    );
    return parseShopifyResponse(response);
};

export const removeProducts = async (
    checkoutId: string,
    lineItemIds: string[],
) => {
    const response = await shopify.checkout.removeLineItems(
        checkoutId,
        lineItemIds,
    );
    return parseShopifyResponse(response);
};
