import { useQuery } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { api } from "../../sdk/utils";

export interface IGetAllPriceRulesParams {
    limit: number;
    cursor: string | null;
    direction: string;
}

export const useGetAllPriceRule = (
    queryKey: string | string[],
    parameters: IGetAllPriceRulesParams,
) => {
    const auth = useAuth();
    return useQuery(queryKey, async () => {
        if (!auth.token) {
            throw new Error(
                "Cannot fetch price rules without authorization token.",
            );
        }

        return await api.priceRule.fetchAllPriceRule({
            ...parameters,
            token: auth.token,
        });
    });
};
