/* eslint-disable camelcase */
import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useMemo,
    useState,
} from "react";

import { Typography, styled } from "@mui/material";

import { CircularLoader } from "../../../../components/common";
import {
    useApplyDiscount,
    useChangeCartPaymentMethod,
    useGetPriceRulesForCustomer,
    useMessage,
    useRemoveDiscount,
} from "../../../../sdk/hooks";
import { PriceRuleService } from "../../../../sdk/services";
import { ICart, IDiscountCodeWithDescription } from "../../../../sdk/types";
import { PriceRuleValidity } from "../../../../sdk/utils";
import {
    DiscountType,
    PaymentMethodType,
    PaymentPlatform,
    PriceRuleCategoryType,
} from "../../../../utils/enums";

import { Discounts } from "./Discounts";
import { PaymentMethods } from "./PaymentMethods";
import { Total } from "./Total";

const Subtitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const LeftContainer = styled("div")`
    flex: 3;
    display: flex;
    flex-direction: column;

    width: 100%;

    gap: ${({ theme }) => theme.spacing(2)};
`;

const paymentMethodByString: Record<string, PaymentMethodType> = {
    cod: PaymentMethodType.COD,
    prepaid: PaymentMethodType.PREPAID,
};

export interface IPaymentOptionsProps {
    loading: boolean;
    paymentPlatform: PaymentPlatform;
    setPaymentPlatform: React.Dispatch<React.SetStateAction<PaymentPlatform>>;
    paymentMethod: string;
    setPaymentMethod: React.Dispatch<React.SetStateAction<string>>;
    alreadyAppliedDiscountCode: IDiscountCodeWithDescription | null;
    cart: ICart;
    description: string;
}

export const PaymentOptions: FunctionComponent<IPaymentOptionsProps> = (
    props: IPaymentOptionsProps,
): ReactElement => {
    const {
        loading,
        paymentMethod,
        setPaymentMethod,
        paymentPlatform,
        setPaymentPlatform,
        alreadyAppliedDiscountCode,
        cart,
        description,
    } = props;

    const { customerId, totalMrp, totalSellingPrice, items } = cart;

    const [errorMessage, setErrorMessage] = useState("");
    const [appliedDiscountCode, setAppliedDiscountCode] = useState(
        alreadyAppliedDiscountCode?.code ?? "",
    );
    const [discountCodeToMutate, setDiscountCodeToMutate] = useState(
        () => alreadyAppliedDiscountCode?.code ?? "",
    );

    const getPriceRulesPaymentMethodQuery = useGetPriceRulesForCustomer(
        "getPriceRules",
        PriceRuleCategoryType.PAYMENT_METHODS,
        customerId,
    );

    const applyDiscountCode = useApplyDiscount();
    const removeDiscountCode = useRemoveDiscount();
    const changePaymentMethodMutation = useChangeCartPaymentMethod();
    const message = useMessage();

    const annotatedPaymentMethodPriceRules = useMemo(
        () =>
            PriceRuleService.annotatePriceRules({
                priceRules: getPriceRulesPaymentMethodQuery.data?.records ?? [],
                paymentMethod: paymentMethodByString[paymentMethod],
                subtotal: totalSellingPrice,
                variantIds: items.map((item) => item.variantId),
            }),
        [
            getPriceRulesPaymentMethodQuery.data?.records,
            items,
            paymentMethod,
            totalSellingPrice,
        ],
    );

    const handlePaymentSelect = useCallback(
        (id: string, platform: number) => {
            if (paymentMethod !== id) {
                changePaymentMethodMutation.mutate(
                    {
                        cartId: cart.id,
                        paymentMethod: id,
                    },
                    {
                        onSuccess: () => {
                            setPaymentMethod(id);
                            setPaymentPlatform(platform);
                        },
                    },
                );
            } else {
                setPaymentPlatform(platform);
            }
        },
        [
            cart.id,
            changePaymentMethodMutation,
            paymentMethod,
            setPaymentMethod,
            setPaymentPlatform,
        ],
    );

    const handleApplyDiscount = useCallback(
        (code?: string) => {
            if (!discountCodeToMutate && !code) {
                setErrorMessage("No discount code found");
                return;
            }

            const discountCode0 = code ?? discountCodeToMutate;

            applyDiscountCode.mutate(
                { discountCode: discountCode0.trim(), cartId: cart.id },
                {
                    onSuccess: (cart) => {
                        if (cart.appliedCartPriceRules.length > 0) {
                            /* Discount code was found and was applied successfully */
                            const appliedPriceRules =
                                cart.appliedCartPriceRules;
                            setErrorMessage("");
                            setAppliedDiscountCode(
                                appliedPriceRules[0].discountCode,
                            );
                            message.showSuccess(
                                "Discount code applied successfully",
                            );
                        } else if (cart.annotation) {
                            /* Discount code was found but was not applicable  */
                            const reason = PriceRuleValidity.getReason(
                                cart.annotation,
                                cart,
                            );
                            if (reason) {
                                setErrorMessage(reason);
                                message.showError(reason);
                            } else {
                                throw new Error(
                                    `Control should not reach here. Unknown discount inapplicability reason.`,
                                );
                            }
                        }
                    },
                    onError: () => {
                        /* Discount code not found or other error */
                        setErrorMessage("Invalid discount code");
                        message.showError("Could not apply discount code");
                    },
                },
            );
        },
        [applyDiscountCode, cart.id, discountCodeToMutate, message],
    );

    const handleRemoveDiscount = useCallback(() => {
        removeDiscountCode.mutate(
            {
                discountCode: appliedDiscountCode,
                cartId: cart.id,
            },
            {
                onSuccess: () => {
                    setDiscountCodeToMutate("");
                    setAppliedDiscountCode("");
                },
            },
        );
    }, [appliedDiscountCode, cart.id, removeDiscountCode]);

    const prepaidDiscount = Object.entries(
        annotatedPaymentMethodPriceRules,
    ).find(
        ([, priceRule]) =>
            priceRule.prereqPaymentMethod === PaymentMethodType.PREPAID,
    );

    let discountValue = 0;
    if (paymentMethod === "prepaid") {
        if (prepaidDiscount) {
            switch (prepaidDiscount[1].discountType) {
                case DiscountType.FIXED: {
                    discountValue = prepaidDiscount[1].discountValue;
                    break;
                }
                case DiscountType.PERCENTAGE: {
                    discountValue =
                        totalSellingPrice *
                        (Math.min(prepaidDiscount[1].discountValue, 100) / 100);
                    break;
                }
                default: {
                    throw new Error(
                        `Unknown discount type "${prepaidDiscount[1].discountType}"`,
                    );
                }
            }
        }
    }

    const restrictedPaymentMethods: PaymentMethodType[] = [];
    const paymentRestrictions = cart.paymentRestrictions ?? [];

    if (cart && paymentRestrictions.length > 0) {
        for (const restriction of paymentRestrictions) {
            const minAmountCondition =
                totalSellingPrice >= restriction.minAmount;

            const maxAmountCondition =
                restriction.maxAmount !== null &&
                totalSellingPrice <= restriction.maxAmount;

            if (
                minAmountCondition &&
                (maxAmountCondition || restriction.maxAmount === null)
            ) {
                restrictedPaymentMethods.push(restriction.paymentMethod);
            }
        }
    }

    return (
        <LeftContainer>
            {!loading && (
                <>
                    <Subtitle>Step 3: Select Payment Method</Subtitle>
                    <PaymentMethods
                        changingPaymentMethod={
                            changePaymentMethodMutation.isLoading
                        }
                        selectedMethod={paymentMethod}
                        selectedPlatform={paymentPlatform}
                        onSelect={handlePaymentSelect}
                        annotatedPriceRules={annotatedPaymentMethodPriceRules}
                        restrictedPaymentMethods={restrictedPaymentMethods}
                    />
                    <Discounts
                        cart={cart}
                        onChange={setDiscountCodeToMutate}
                        onApply={handleApplyDiscount}
                        error={errorMessage}
                        applied={!!appliedDiscountCode}
                        onRemove={handleRemoveDiscount}
                        appliedDiscountCode={appliedDiscountCode}
                        description={description}
                        verifying={applyDiscountCode.isLoading}
                        couponDiscount={cart.totalDiscountAmount}
                        discountCode={discountCodeToMutate}
                    />
                    <Total
                        total={totalSellingPrice}
                        subtotal={totalMrp}
                        savedAmount={totalMrp - totalSellingPrice}
                        discountValue={discountValue}
                        couponDiscount={cart.totalDiscountAmount}
                    />
                </>
            )}
            {loading && <CircularLoader />}
        </LeftContainer>
    );
};
