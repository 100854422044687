import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { ICart } from "../types";
import { carts } from "../utils/api";

import { useAuth } from "./useAuth";
import { useMessage } from "./useMessage";

export interface IUseUpdateCartLineItemParameters {
    /** ID of the cart */
    cartId: string;
    /** ID of the line item, aka the product variant ID */
    lineItemId: string;
    /** The resultant quantity of `lineItemId` in the cart */
    quantity: number;
}

export const useUpdateCartLineItem = (
    invalidQueryKeys: string[] = [],
): UseMutationResult<
    ICart | undefined,
    unknown,
    IUseUpdateCartLineItemParameters
> => {
    const queryClient = useQueryClient();
    const { showError } = useMessage();
    const auth = useAuth();

    return useMutation<
        ICart | undefined,
        unknown,
        IUseUpdateCartLineItemParameters
    >({
        mutationFn: async (
            parameters: IUseUpdateCartLineItemParameters,
        ): Promise<ICart | undefined> => {
            if (!auth.token) {
                throw new Error("Cannot update cart without valid token.");
            }

            const { cartId, lineItemId, quantity } = parameters;

            return await carts.updateVariantToCart({
                cartId,
                variantId: lineItemId,
                quantity,
                token: auth.token,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(invalidQueryKeys);
        },
        onError: (error) => {
            showError("Could not update cart items");
        },
    });
};
