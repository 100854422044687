import React, { FunctionComponent, ReactElement, useCallback } from "react";

import { Typography, styled } from "@mui/material";

import { GridRowParams } from "@mui/x-data-grid";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { Pagination, useHits } from "react-instantsearch";
import { useNavigate } from "react-router-dom";

import { IProductCollectionEntry } from "../../sdk/types";
import { globalDateTimeFormat } from "../../utils/constants";

interface CollectionsHits {
    hits: IProductCollectionEntry[];
}

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }
`;

const StyledPaginationContainer = styled("div")`
    .ais-Pagination-list {
        display: flex;
        justify-content: center;
        padding: 16px;
        list-style: none;
    }

    .ais-Pagination-item {
        margin: 0 4px;
    }

    .ais-Pagination-link {
        padding: 8px 16px;

        border: 1px solid ${({ theme }) => theme.palette.divider};
        border-radius: 4px;

        color: ${({ theme }) => theme.palette.text.primary};
        text-decoration: none;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: ${({ theme }) => theme.palette.action.hover};
        }
    }

    .ais-Pagination-item--disabled .ais-Pagination-link {
        color: ${({ theme }) => theme.palette.action.disabled};
        cursor: not-allowed;
    }

    .ais-Pagination-item--selected .ais-Pagination-link {
        background-color: #042442;
        color: white;

        cursor: default;
    }
`;

const CollectionsTitle = styled(Typography)`
    font-size: 16px;
    color: ${({ theme }) => theme.palette.text.primary};
`;

const Description = styled(Typography)`
    font-size: 13px;
    color: grey;
`;

const StyledCell = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: start;
`;

const columns: GridColDef[] = [
    {
        field: "title",
        headerName: "Title",
        flex: 5,
        minWidth: 300,
        renderCell: (params: GridRenderCellParams) => (
            <StyledCell>
                <CollectionsTitle>{params.row.title}</CollectionsTitle>
                <Description>{params.row.description}</Description>
            </StyledCell>
        ),
    },
    {
        field: "slug",
        headerName: "Slug",
        flex: 1,
        minWidth: 300,
    },
    {
        field: "createdAt",
        headerName: "Created on",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
            /**
             * The `createdAt` field is retrieved as an `int64` from the Typesense collection.
             * This may require adding a filter or sort functionality based on the `createdAt` field
             * to ensure proper data handling and ordering.
             */
            return `${format(
                new Date(params.row.createdAt * 1000),
                globalDateTimeFormat,
            )}`;
        },
    },
    {
        field: "updatedAt",
        headerName: "Updated on",
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.updatedAt), globalDateTimeFormat)}`,
    },
];

export const CollectionsGrid: FunctionComponent = (): ReactElement => {
    const navigate = useNavigate();
    const { hits }: CollectionsHits = useHits();

    const handleRowClick = useCallback(
        (params: GridRowParams) => {
            navigate(`/product-collections/${params.row.id}`);
        },
        [navigate],
    );
    return (
        <>
            <StyledDataGrid
                autoHeight={true}
                rows={hits}
                columns={columns}
                onRowClick={handleRowClick}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        csvOptions: {
                            disableToolbarButton: false,
                        },
                        printOptions: {
                            disableToolbarButton: true,
                        },
                    },
                }}
            />
            <StyledPaginationContainer>
                <Pagination />
            </StyledPaginationContainer>
        </>
    );
};
