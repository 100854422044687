import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";

import { Typography, styled } from "@mui/material";

import {
    DataGrid,
    GridColDef,
    GridRowId,
    GridRowSelectionModel,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";

import { EmptyRecords } from "../../../components/common";
import { DataGridFooter } from "../../../components/common";
import { useGetAllCustomerGroups } from "../../../sdk/hooks";
import { IPage } from "../../../types";
import { globalDateTimeFormat } from "../../../utils/constants";

const Root = styled("section")`
    padding: ${({ theme }) => theme.spacing(1)};
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const Heading = styled(Typography)`
    font-size: ${({ theme }) => theme.spacing(2)};
    font-weight: 500;
`;

const EmptyRecordsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
`;

const Title = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`;

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }

    .MuiDataGrid-row:hover {
        cursor: pointer;
    }
`;

const columns: GridColDef[] = [
    {
        field: "name",
        headerName: "Name",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) => params.row.name,
    },
    {
        field: "createdAt",
        headerName: "Created at",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
];

export interface ICustomerGroupsProps {
    priceRuleCustomerGroupIds: string[];
    onChange: any;
}

export const CustomerGroups: FunctionComponent<ICustomerGroupsProps> = (
    props: ICustomerGroupsProps,
): ReactElement => {
    const { priceRuleCustomerGroupIds: priceRuleCustomerGroups, onChange } =
        props;
    const [page, setPage] = useState<IPage>({
        limit: 20,
        cursor: null,
        direction: "after",
    });

    const fetchAllCustomerGroupsQuery = useGetAllCustomerGroups(
        [
            "customerGroups",
            page.limit.toString(),
            page.cursor ?? "",
            page.direction,
        ],
        {
            limit: page.limit,
            cursor: page.cursor,
            direction: page.direction,
        },
    );

    const handleNavigateBack = useCallback(() => {
        setPage({
            ...page,
            cursor: fetchAllCustomerGroupsQuery.data?.cursors?.previous ?? "",
            direction: "before",
        });
    }, [fetchAllCustomerGroupsQuery.data?.cursors?.previous, page]);

    const handleNavigateForward = useCallback(() => {
        setPage({
            ...page,
            cursor: fetchAllCustomerGroupsQuery.data?.cursors?.next ?? "",
            direction: "after",
        });
    }, [fetchAllCustomerGroupsQuery.data?.cursors?.next, page]);

    const [selectedCustomerGroupIds, setSelectedCustomerGroupIds] =
        useState<GridRowId[]>();

    useEffect(() => {
        setSelectedCustomerGroupIds(priceRuleCustomerGroups);
    }, [priceRuleCustomerGroups]);

    return (
        <Root>
            <Heading>Select Customer Group</Heading>
            {fetchAllCustomerGroupsQuery.data?.records?.length === 0 && (
                <EmptyRecords>
                    <EmptyRecordsContainer>
                        <Title>No customer groups available</Title>
                        <div>Create a customer group use this feature</div>
                    </EmptyRecordsContainer>
                </EmptyRecords>
            )}
            {fetchAllCustomerGroupsQuery.isSuccess &&
                fetchAllCustomerGroupsQuery.data?.records?.length > 0 && (
                    <StyledDataGrid
                        autoHeight={true}
                        rows={fetchAllCustomerGroupsQuery.data?.records ?? []}
                        columns={columns}
                        rowSelection={true}
                        rowSelectionModel={selectedCustomerGroupIds}
                        checkboxSelection={true}
                        onRowSelectionModelChange={(
                            newSelection: GridRowSelectionModel,
                        ) => {
                            setSelectedCustomerGroupIds(
                                newSelection as string[],
                            );
                            onChange(newSelection as string[]);
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 20,
                                },
                            },
                        }}
                        pageSizeOptions={[20]}
                        components={{
                            Footer: () => (
                                <DataGridFooter
                                    hasNextRecords={Boolean(
                                        fetchAllCustomerGroupsQuery.data.cursors
                                            .next,
                                    )}
                                    hasPreviousRecords={Boolean(
                                        fetchAllCustomerGroupsQuery.data.cursors
                                            .previous,
                                    )}
                                    onNavigateBack={handleNavigateBack}
                                    onNavigateForward={handleNavigateForward}
                                />
                            ),
                        }}
                        loading={fetchAllCustomerGroupsQuery.isLoading}
                    />
                )}
        </Root>
    );
};
