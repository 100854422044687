import { AxiosError, AxiosResponse } from "axios";
import {
    UseMutationOptions,
    UseMutationResult,
    useMutation,
    useQueryClient,
} from "react-query";

import { ILink } from "../../types";
import { api } from "../../utils";
import { useAuth } from "../useAuth";

export interface ICreateFromFileMutationParameters {
    file: File;
}

/**
 * @author Nikhil babar <nikhil.babar@houseofx.in>
 */
export const useCreateFromFile = (
    invalidQueryKeys?: string | string[],
    options?: UseMutationOptions<
        ILink[],
        AxiosError,
        ICreateFromFileMutationParameters,
        unknown
    >,
): UseMutationResult<
    ILink[],
    AxiosError,
    ICreateFromFileMutationParameters
> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation<ILink[], AxiosError, ICreateFromFileMutationParameters>({
        ...options,
        mutationFn: (
            parameters: ICreateFromFileMutationParameters,
        ): Promise<ILink[]> => {
            if (!auth.token) {
                throw new Error(
                    "Cannot import links without authorization token.",
                );
            }

            return api.Link.createFromFile({
                token: auth.token,
                file: parameters.file,
            });
        },
        onError: options?.onError,
        onSuccess: (
            links: ILink[],
            variables: ICreateFromFileMutationParameters,
            context: unknown,
        ) => {
            if (invalidQueryKeys !== undefined) {
                queryClient.invalidateQueries(invalidQueryKeys);
            }

            options?.onSuccess?.(links, variables, context);
        },
    });
};
