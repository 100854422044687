import React, { useCallback, useState } from "react";

import {
    Button,
    ButtonBase,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputAdornment,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { Close } from "@mui/icons-material";

import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers";
import { format, parseISO } from "date-fns";
import { useFormik } from "formik";

import {
    FilterChip,
    FilterTab,
    ToggleAll,
} from "../../components/common/filters";
import { useMessage } from "../../sdk/hooks";
import { globalDateFormat } from "../../utils/constants";
import {
    NativePaymentStatus,
    OrderSource,
    OrderStatus,
    PaymentMethodNames,
    PaymentMethodType,
    orderStatusByEnum,
} from "../../utils/enums";

const Container = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};

    width: 100%;
`;

const FilterCategory = styled("div")`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};

    padding: ${({ theme }) => theme.spacing(1)};
    border-right: 1px solid gray;
`;

const OptionsContainer = styled("div")`
    flex: 2;
    display: flex;
    flex-direction: column;

    height: 500px;
    padding: ${({ theme }) => theme.spacing(1)};
    overflow-y: scroll;
`;

const DateRangeContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(2, 0)};

    ${({ theme }) => theme.breakpoints.down("md")} {
        flex-direction: column;
    }
`;

const StyledDialog = styled(Dialog)`
    .MuiDialogContent-root {
        display: flex;
        gap: ${({ theme }) => theme.spacing(2)};
        padding-left: ${({ theme }) => theme.spacing(1)};
    }
`;

const Header = styled(DialogTitle)`
    width: 100%;
    background-color: #f0f0f0;

    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const FormCloseHandler = styled("div")`
    display: flex;
    justify-content: center;
`;

const FormDrawerCloseButton = styled(ButtonBase)`
    width: 32px;
    height: 32px;

    border-radius: 50%;
`;

const ChipContainer = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(0.5)};
    flex-wrap: wrap;

    padding: ${({ theme }) => theme.spacing(1, 0)};
`;

const UserFilterContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};

    padding: ${({ theme }) => theme.spacing(1, 0)};
`;

const Subtitle = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
    color: #626262;
`;

interface IFiltersDialogProps {
    open: boolean;
    initialValues: Record<string, any>;
    onClose: () => void;
    onApply: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

interface IFilter {
    title: string;
    options: string[];
    type: "standard" | "custom";
    names: Record<number | string, string>;
}

const filters: Record<string, IFilter> = {
    orderStatuses: {
        title: "Order status",
        type: "standard",
        options: Object.keys(orderStatusByEnum),
        names: orderStatusByEnum,
    },
    paymentMethods: {
        title: "Payment method",
        type: "standard",
        options: Object.values(PaymentMethodType),
        names: PaymentMethodNames,
    },
    paymentStatuses: {
        title: "Payment status",
        type: "standard",
        options: Object.keys(NativePaymentStatus),
        names: NativePaymentStatus,
    },
    dateRange: {
        title: "Date range",
        type: "custom",
        options: [],
        names: {},
    },
    orderSources: {
        title: "Order source",
        type: "standard",
        options: Object.keys(OrderSource),
        names: OrderSource,
    },
    other: {
        title: "Other filters",
        type: "custom",
        options: [],
        names: {},
    },
};

const validationSchema = Yup.object({
    orderStatuses: Yup.array().of(
        Yup.number().oneOf([
            OrderStatus.CREATED,
            OrderStatus.CANCELLED,
            OrderStatus.CUSTOMER_CONFIRMATION_AWAITED,
            OrderStatus.CUSTOMER_CONFIRMATION_RECEIVED,
            OrderStatus.DELIVERED,
            OrderStatus.FAILED,
            OrderStatus.PREBOOKED,
            OrderStatus.PREBOOKING_ORDER_CREATED,
            OrderStatus.REFUNDED,
            OrderStatus.RETURNED,
            OrderStatus.SHIPPED,
            OrderStatus.SYNCED,
        ]),
    ),
    paymentStatuses: Yup.array().of(
        Yup.string().oneOf(Object.keys(NativePaymentStatus)),
    ),
    paymentMethods: Yup.array().of(
        Yup.string().oneOf(Object.keys(PaymentMethodNames)),
    ),
    orderSources: Yup.array().of(Yup.string().oneOf(Object.keys(OrderSource))),
    startDate: Yup.date(),
    endDate: Yup.date(),
    limit: Yup.number(),
    cursor: Yup.string().nullable(),
    direction: Yup.mixed().oneOf(["after", "before"]),
    phoneNumbers: Yup.array().of(Yup.string().length(13)),
    names: Yup.array().of(Yup.string().min(7)),
    sellers: Yup.array().of(Yup.string().email().min(1).required()),
});

export const FiltersDialog = (props: IFiltersDialogProps) => {
    const { open, initialValues, onApply, onClose } = props;
    const { showError } = useMessage();
    const [selectedCategory, setSelectedCategory] = useState("orderStatuses");
    const { values, errors, setFieldValue, handleChange, handleSubmit } =
        useFormik<Record<string, any>>({
            initialValues,
            validationSchema,
            onSubmit: (values) => {
                onApply(values);
                onClose();
            },
        });

    const handleCategoryChange = useCallback((category: string) => {
        setSelectedCategory(category);
    }, []);

    const handleStartDateChange = useCallback(
        (newValue: Date | null) => {
            if (newValue) {
                const startDate = format(newValue, globalDateFormat);
                setFieldValue("startDate", startDate);
            }
        },
        [setFieldValue],
    );

    const handleEndDateChange = useCallback(
        (newValue: Date | null) => {
            if (newValue) {
                const endDate = format(newValue, globalDateFormat);
                setFieldValue("endDate", endDate);
            }
        },
        [setFieldValue],
    );

    const handleOrderNamesChange = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
                const names = values.names;
                setFieldValue("names", [
                    ...names,
                    `#${(event.target as HTMLInputElement).value}`,
                ]);
                (event.target as HTMLInputElement).value = "";
            }
        },
        [setFieldValue, values.names],
    );

    const handlePhoneNumbersChange = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
                if ((event.target as HTMLInputElement).value.length === 10) {
                    const phoneNumbers = values.phoneNumbers;
                    setFieldValue("phoneNumbers", [
                        ...phoneNumbers,
                        `+91${(event.target as HTMLInputElement).value}`,
                    ]);
                    (event.target as HTMLInputElement).value = "";
                } else {
                    showError("Enter a 10 digit phone number");
                }
            }
        },
        [setFieldValue, showError, values.phoneNumbers],
    );

    const handleSellersChange = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter") {
                const sellers = [
                    ...values.sellers,
                    (event.target as HTMLInputElement).value,
                ];
                validationSchema
                    .validate({ sellers })
                    .then(() => {
                        setFieldValue("sellers", sellers);
                        (event.target as HTMLInputElement).value = "";
                    })
                    .catch((err) => showError(err));
            }
        },
        [setFieldValue, showError, values.sellers],
    );

    const handleApply = useCallback(() => handleSubmit(), [handleSubmit]);

    return (
        <StyledDialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth="md"
        >
            <Header>
                Filter Orders
                <FormCloseHandler>
                    <FormDrawerCloseButton onClick={onClose}>
                        <Close />
                    </FormDrawerCloseButton>
                </FormCloseHandler>
            </Header>
            <DialogContent>
                <Container>
                    <FilterCategory>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                Filter Options
                            </FormLabel>
                            <FormGroup>
                                {Object.keys(filters).map((filter, index) => (
                                    <FilterTab
                                        key={index}
                                        value={filter}
                                        selected={selectedCategory === filter}
                                        label={filters[filter].title}
                                        onSelect={handleCategoryChange}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </FilterCategory>
                    <OptionsContainer>
                        <FormControl>
                            <FormLabel>
                                {`Select ${filters[
                                    selectedCategory
                                ].title.toLowerCase()}`}
                            </FormLabel>
                            <FormGroup>
                                {filters[selectedCategory].type ===
                                    "standard" && (
                                    <>
                                        <ToggleAll
                                            key={0}
                                            options={
                                                filters[selectedCategory]
                                                    .options
                                            }
                                            field={selectedCategory}
                                            values={values[selectedCategory]}
                                            onToggle={setFieldValue}
                                        />
                                        {filters[selectedCategory].options.map(
                                            (option, index) => (
                                                <FormControlLabel
                                                    key={index + 1}
                                                    control={
                                                        <Checkbox
                                                            checked={[
                                                                ...values[
                                                                    selectedCategory
                                                                ],
                                                            ].includes(option)}
                                                        />
                                                    }
                                                    name={selectedCategory}
                                                    value={option}
                                                    onChange={handleChange}
                                                    label={
                                                        filters[
                                                            selectedCategory
                                                        ].names[option]
                                                    }
                                                />
                                            ),
                                        )}
                                    </>
                                )}
                            </FormGroup>
                        </FormControl>
                        {selectedCategory === "dateRange" && (
                            <DateRangeContainer>
                                <DatePicker
                                    label="Start Date"
                                    value={parseISO(values.startDate)}
                                    maxDate={new Date()}
                                    onChange={handleStartDateChange}
                                />
                                <DatePicker
                                    label="End Date"
                                    value={parseISO(values.endDate)}
                                    minDate={parseISO(values.startDate)}
                                    onChange={handleEndDateChange}
                                />
                            </DateRangeContainer>
                        )}
                        {selectedCategory === "other" && (
                            <>
                                <UserFilterContainer>
                                    <Subtitle>Filter by order number</Subtitle>
                                    <TextField
                                        fullWidth={true}
                                        name="names"
                                        label="Order number"
                                        onKeyDown={handleOrderNamesChange}
                                        size="small"
                                        variant="outlined"
                                        error={Boolean(errors.names)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    #
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <ChipContainer>
                                        {values.names &&
                                            values.names.map(
                                                (
                                                    name: string,
                                                    index: number,
                                                ) => (
                                                    <FilterChip
                                                        key={index}
                                                        index={index}
                                                        valueKey="names"
                                                        label={name}
                                                        onDelete={setFieldValue}
                                                        values={values.names}
                                                    />
                                                ),
                                            )}
                                    </ChipContainer>
                                </UserFilterContainer>
                                <UserFilterContainer>
                                    <Subtitle>Filter by customers</Subtitle>
                                    <TextField
                                        fullWidth={true}
                                        name="phoneNumbers"
                                        type="number"
                                        label="Customer phone number"
                                        onKeyDown={handlePhoneNumbersChange}
                                        size="small"
                                        variant="outlined"
                                        error={Boolean(errors.phoneNumbers)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    +91
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <ChipContainer>
                                        {values.phoneNumbers &&
                                            values.phoneNumbers.map(
                                                (
                                                    phoneNumber: string,
                                                    index: number,
                                                ) => (
                                                    <FilterChip
                                                        key={index}
                                                        index={index}
                                                        valueKey="phoneNumbers"
                                                        label={phoneNumber}
                                                        onDelete={setFieldValue}
                                                        values={
                                                            values.phoneNumbers
                                                        }
                                                    />
                                                ),
                                            )}
                                    </ChipContainer>
                                </UserFilterContainer>
                                <UserFilterContainer>
                                    <Subtitle>Filter by agents/seller</Subtitle>
                                    <TextField
                                        fullWidth={true}
                                        name="sellers"
                                        type="email"
                                        label="Seller email address"
                                        onKeyDown={handleSellersChange}
                                        size="small"
                                        variant="outlined"
                                        error={Boolean(errors.sellers)}
                                        helperText={errors.sellers?.toString()}
                                    />
                                    <ChipContainer>
                                        {values.sellers &&
                                            values.sellers.map(
                                                (
                                                    seller: string,
                                                    index: number,
                                                ) => (
                                                    <FilterChip
                                                        key={index}
                                                        index={index}
                                                        valueKey="sellers"
                                                        label={seller}
                                                        onDelete={setFieldValue}
                                                        values={values.sellers}
                                                    />
                                                ),
                                            )}
                                    </ChipContainer>
                                </UserFilterContainer>
                            </>
                        )}
                    </OptionsContainer>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    onClick={handleApply}
                    variant="contained"
                    color="primary"
                >
                    Apply
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};
