import React, { FunctionComponent, useCallback } from "react";

import { Chip } from "@mui/material";

export interface IFilterChipProps {
    index: number;
    onDelete: (key: string, values: string[]) => void;
    values: string[];
    label: string;
    valueKey: string;
}

export const FilterChip: FunctionComponent<IFilterChipProps> = (
    props: IFilterChipProps,
) => {
    const { index, onDelete, values, label, valueKey } = props;

    const handleDelete = useCallback(() => {
        const updatedValue = [...values];
        updatedValue.splice(index, 1);
        onDelete(valueKey, updatedValue);
    }, [index, onDelete, valueKey, values]);

    return <Chip key={index} label={label} onDelete={handleDelete} />;
};
