import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { IEntityAttributeOption, api } from "../../utils";

export interface ICreateEntityAttributeOptionParameter {
    name: string;
    value: JSON;
    attributeId: string;
}

export const useCreateEntityAttributeOption = (
    queryKey: string | string[],
): UseMutationResult<
    IEntityAttributeOption,
    unknown,
    ICreateEntityAttributeOptionParameter
> => {
    const auth = useAuth();
    return useMutation({
        mutationFn: async (
            parameters: ICreateEntityAttributeOptionParameter,
        ) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot create entity attribute option without authorization token.",
                );
            }

            return await api.EntityAttributeOption.create({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
