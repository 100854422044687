import { UseQueryResult, useQuery } from "react-query";

import { useAuth } from "../../sdk/hooks/useAuth";
import { IAdminGroup } from "../../sdk/types";
import { adminGroups } from "../../sdk/utils/api";

export const useGetAdminGroupById = (
    adminGroupId: string,
): UseQueryResult<IAdminGroup> => {
    const auth = useAuth();

    return useQuery<IAdminGroup>(
        "getAdminGroup",
        async () => {
            if (!auth.token) {
                throw new Error(
                    `Cannot fetch admin group with ${adminGroupId} without authorization token.`,
                );
            }

            return await adminGroups.AdminGroup.getById({
                id: adminGroupId,
                token: auth.token,
            });
        },
        { enabled: adminGroupId !== "new" && adminGroupId !== "" },
    );
};
