import { FunctionComponent, ReactElement } from "react";

import { Button, styled } from "@mui/material";

const Root = styled("div")`
    display: flex;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing(3)};
`;

const LeftNavigationButton = styled(Button)`
    border-radius: ${({ theme }) => theme.spacing(1, 0, 0, 1)};
`;

const RightNavigationButton = styled(Button)`
    border-radius: ${({ theme }) => theme.spacing(0, 1, 1, 0)};
`;

export interface IDataGridFooterProps {
    onNavigateBack: () => void;
    onNavigateForward: () => void;
    hasPreviousRecords: boolean;
    hasNextRecords: boolean;
}

export const DataGridFooter: FunctionComponent<IDataGridFooterProps> = (
    props: IDataGridFooterProps,
): ReactElement => {
    const {
        onNavigateBack,
        onNavigateForward,
        hasPreviousRecords,
        hasNextRecords,
    } = props;

    return (
        <Root>
            <LeftNavigationButton
                disabled={!hasPreviousRecords}
                onClick={onNavigateBack}
                variant="outlined">
                Newer
            </LeftNavigationButton>
            <RightNavigationButton
                onClick={onNavigateForward}
                disabled={!hasNextRecords}
                variant="outlined">
                Older
            </RightNavigationButton>
        </Root>
    );
};
