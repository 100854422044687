import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useState,
} from "react";

import { Button, Chip, Typography, styled } from "@mui/material";

import { Add } from "@mui/icons-material";

import * as Yup from "yup";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { useFormik } from "formik";

import { EmptyRecords } from "../../components/common";
import { DataGridFooter } from "../../components/common";
import { useGetAdmins } from "../../sdk/hooks";
import { globalDateTimeFormat } from "../../utils/constants";
import {
    userDepartmentNames,
    userRoleNames,
    userStatusNames,
} from "../../utils/enums";

import { InviteUser } from "./InviteUser";

const Root = styled("section")`
    padding: ${({ theme }) => theme.spacing(3)};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const Heading = styled(Typography)`
    font-size: ${({ theme }) => theme.spacing(3)};
    font-weight: 500;
`;

const EmptyRecordsContainer = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
`;

const Title = styled(Typography)`
    font-size: 24px;
    font-weight: 500;
`;

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }
`;

const TitleContainer = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const columns: GridColDef[] = [
    {
        field: "user",
        headerName: "User",
        flex: 0.4,
        valueGetter: (params: GridValueGetterParams) =>
            params.row.firstName && params.row.lastName
                ? `${params.row.firstName} ${params.row.lastName}`
                : "Invited User",
    },
    {
        field: "department",
        headerName: "Department",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) =>
            userDepartmentNames[params.row.department],
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Chip label={params.value} variant="outlined" size="small" />
        ),
    },
    {
        field: "emailAddress",
        headerName: "Email",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) => params.row.emailAddress,
    },
    {
        field: "status",
        headerName: "Status",
        flex: 0.4,
        valueGetter: (params: GridValueGetterParams) =>
            `${userStatusNames[params.row.status]}`,
        renderCell: (params: GridRenderCellParams<Date>) => (
            <Chip label={params.value} variant="outlined" size="small" />
        ),
    },
    {
        field: "role",
        headerName: "Role",
        flex: 0.3,
        valueGetter: (params: GridValueGetterParams) =>
            `${userRoleNames[params.row.role]}`,
    },
    {
        field: "createdAt",
        headerName: "Created at",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
];

const filterSchema = Yup.object({
    limit: Yup.number(),
    cursor: Yup.string().nullable(),
    pageNumber: Yup.number(),
    direction: Yup.mixed().oneOf(["after", "before"]),
});

export const ViewUsersScreen: FunctionComponent = (): ReactElement => {
    const [open, setOpen] = useState(false);

    const handleToggleDialog = useCallback(() => {
        setOpen((oldOpen) => !oldOpen);
    }, []);
    /**
     *  `onSubmit` simply returns void because onSubmit is required in useFormik schema
     *  if we do not add an `onSubmit` then TS throws an error
     */
    const { values, setFieldValue } = useFormik({
        initialValues: {
            limit: 20,
            cursor: null,
            pageNumber: 0,
            direction: "after",
        },
        validationSchema: filterSchema,
        onSubmit: (values) => {
            return;
        },
    });

    const getUsersQuery = useGetAdmins(
        [
            "users",
            values.limit.toString(),
            values.cursor ?? "",
            values.direction,
        ],
        {
            limit: values.limit,
            cursor: values.cursor,
            direction: values.direction,
        },
    );

    const handleNavigateBack = useCallback(() => {
        setFieldValue("cursor", getUsersQuery?.data?.cursors?.previous ?? "");
        setFieldValue("direction", "before");
    }, [setFieldValue, getUsersQuery?.data?.cursors?.previous]);

    const handleNavigateForward = useCallback(() => {
        setFieldValue("cursor", getUsersQuery?.data?.cursors?.next);
        setFieldValue("direction", "after");
    }, [setFieldValue, getUsersQuery?.data?.cursors?.next]);

    return (
        <Root>
            <TitleContainer>
                <Heading>Manage users</Heading>
                <Button
                    variant="contained"
                    size="small"
                    onClick={handleToggleDialog}
                    startIcon={<Add fontSize="small" />}
                >
                    Invite User
                </Button>
                <InviteUser open={open} onChange={handleToggleDialog} />
            </TitleContainer>
            {getUsersQuery?.data?.records.length === 0 && (
                <EmptyRecords>
                    <EmptyRecordsContainer>
                        <Title>No Users were found</Title>
                        <div>Start by inviting a user</div>
                    </EmptyRecordsContainer>
                </EmptyRecords>
            )}
            {getUsersQuery.isSuccess &&
                getUsersQuery?.data?.records?.length > 0 && (
                    <StyledDataGrid
                        autoHeight={true}
                        rows={getUsersQuery?.data?.records ?? []}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 20,
                                },
                            },
                        }}
                        pageSizeOptions={[20]}
                        slots={{
                            footer: () => (
                                <DataGridFooter
                                    hasNextRecords={Boolean(
                                        getUsersQuery.data.cursors.next,
                                    )}
                                    hasPreviousRecords={Boolean(
                                        getUsersQuery.data.cursors.previous,
                                    )}
                                    onNavigateBack={handleNavigateBack}
                                    onNavigateForward={handleNavigateForward}
                                />
                            ),
                        }}
                        loading={getUsersQuery.isLoading}
                    />
                )}
        </Root>
    );
};
