import { FunctionComponent, ReactElement, useEffect, useState } from "react";

import { Container, Typography, keyframes, styled } from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { Google } from "@mui/icons-material";

import { useLocation, useNavigate } from "react-router-dom";

import { useSignIn } from "../../hooks";
import { useAuth, useMessage } from "../../sdk/hooks";
import { google } from "../../utils/third-party";

const Main = styled("main")`
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          flex-direction: column;
          justify-content: start;
    }
  `}
`;

const LeftContainerAnimation = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

const LeftContainer = styled("div")`
    flex: 4;
    height: 100vh;

    background-image: radial-gradient(
        circle at top right,
        #bbe1fa 0%,
        #bbe1fa 12.5%,
        #a9cee8 12.5%,
        #a9cee8 25%,
        #95bad4 25%,
        #95bad4 37.5%,
        #6f92ad 37.5%,
        #6f92ad 50%,
        #4c6f8b 50%,
        #4c6f8b 62.5%,
        #294a67 62.5%,
        #294a67 75%,
        #042442 75%,
        #042442 87.5%,
        #0a1c2f 87.5%,
        #0a1c2f 100%
    );
    background-size: 110% 110%;
    animation: ${LeftContainerAnimation} 15s ease infinite;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          flex: unset;
          width: 100%;
          height: 100px;
    }
  `}
`;

const RightContainer = styled("div")`
    flex: 3;
    height: 100%;
    padding: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          flex: unset;
          width: 100%;
          padding: ${theme.spacing(10, 1, 2)};
    }
  `}
`;

const Title = styled(Typography)`
    font-family: "Poppins";
    font-size: 28px;
    font-weight: 600;
    text-align: center;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
        font-size: 26px;
    }
  `}
`;

const Subtitle = styled(Title)`
    font-size: 22px;
    font-weight: 500;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
        font-size: 20px;
    }
  `}
`;

const OAuthContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: ${({ theme }) => theme.spacing(5)};
`;

const GoogleLoginButton = styled(LoadingButton)`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    width: 100%;

    font-weight: 500;
    color: white;

    background-color: #000000;
    border-radius: 1;
    text-decoration: none;
    cursor: pointer;

    padding: ${({ theme }) => theme.spacing(2, 3)};

    &:hover {
        background-color: #6c6c6c;
    }
`;

const LogoContainer = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1.5)};
    font-size: 24px;
    font-family: "Montserrat";
    font-weight: 500;
`;

const TextContainer = styled("div")`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: ${({ theme }) => theme.spacing(2)};
`;

export const Login: FunctionComponent = (): ReactElement => {
    const { state, search } = useLocation();
    const [isRequestSent, setRequestSent] = useState(false);
    const useSignInMutation = useSignIn();
    const navigate = useNavigate();
    let from = state?.from?.pathname ?? "/";
    const code = new URLSearchParams(search).get("code");
    const auth = useAuth();
    const { showError, showSuccess } = useMessage();

    useEffect(() => {
        const signIn = async () => {
            try {
                if (code && !isRequestSent) {
                    setRequestSent(true);
                    useSignInMutation.mutate(
                        {
                            code,
                            redirectUrl:
                                process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT ||
                                "",
                        },
                        {
                            onError: () => {
                                showError("Failed to login");
                            },
                        },
                    );
                }
                if (auth.user) {
                    showSuccess("Logged in successfully!");
                    navigate("/");
                }
            } catch (error: any) {
                showError("Something went wrong!");
                throw new Error(error);
            }
        };
        signIn();
    }, [
        auth.user,
        code,
        isRequestSent,
        navigate,
        showError,
        showSuccess,
        useSignInMutation,
    ]);

    return (
        <Main>
            <LeftContainer />
            <RightContainer>
                <OAuthContainer>
                    <LogoContainer>
                        HOUSE OF
                        <img
                            height={"24px"}
                            src={`${process.env.REACT_APP_CLOUDFRONT_URL}/hox-website/hox-logo-default.webp`}
                            alt="House of X"
                        />
                    </LogoContainer>
                    <TextContainer>
                        <Title>Welcome to Powerhouse</Title>
                        <Subtitle>Sign in to continue</Subtitle>
                    </TextContainer>
                    <div>
                        <GoogleLoginButton href={google.getGoogleUrl(from)}>
                            <Google />
                            Continue with Google
                        </GoogleLoginButton>
                    </div>
                </OAuthContainer>
            </RightContainer>
        </Main>
    );
};
