import { useMutation, useQueryClient } from "react-query";

import { api } from "../utils";

import { useAuth } from "./useAuth";

export interface ICreateCustomerAddressParameters {
    addressLine1: string;
    addressLine2: string;
    city: string;
    countryCode: string;
    company: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    stateCode: string;
    zip: string;
    name: string;
    defaultAddress: boolean;
    customerId: string;
}

export const useCreateCustomerAddress = (invalidQueryKeys: string[] = []) => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    if (!auth.token) {
        throw new Error(
            "Cannot create customer address without a valid token.",
        );
    }

    return useMutation({
        mutationFn: async (values: ICreateCustomerAddressParameters) =>
            await api.addresses.createCustomerAddress({
                ...values,
                token: auth.token!,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries(invalidQueryKeys);
        },
    });
};
