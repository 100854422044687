import { UseMutationResult, useMutation } from "react-query";

import { ICart } from "../types";
import { carts } from "../utils/api";

import { useAuth } from "./useAuth";

export interface IGetOrCreateCartMutationParameters {
    customerId: string;
}

/**
 * Get or create a new admin cart for the specified `customerId`.
 */
export const useInitializeCart = (): UseMutationResult<
    ICart,
    unknown,
    IGetOrCreateCartMutationParameters
> => {
    const auth = useAuth();

    return useMutation<ICart, unknown, IGetOrCreateCartMutationParameters>({
        mutationFn: (
            parameters: IGetOrCreateCartMutationParameters,
        ): Promise<ICart> => {
            if (!auth.token) {
                throw new Error(
                    "Cannot get or create cart without authorization token.",
                );
            }

            return carts.getOrCreateCustomerCart({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
