import React, { FunctionComponent, useCallback } from "react";

import { styled } from "@mui/material";

import { GridRowParams } from "@mui/x-data-grid";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import { Pagination, useHits } from "react-instantsearch";
import { useNavigate } from "react-router-dom";

import { globalDateTimeFormat } from "../../utils/constants";

interface ICustomerData {
    id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    createdAt: string;
    updatedAt: string;
}

const StyledDataGrid = styled(DataGrid)`
    .MuiDataGrid-cell:focus {
        outline: none;
    }
`;

const StyledPaginationContainer = styled("div")`
    .ais-Pagination-list {
        display: flex;
        justify-content: center;
        padding: 16px;
        list-style: none;
    }

    .ais-Pagination-item {
        margin: 0 4px;
    }

    .ais-Pagination-link {
        padding: 8px 16px;

        border: 1px solid ${({ theme }) => theme.palette.divider};
        border-radius: 4px;

        color: ${({ theme }) => theme.palette.text.primary};

        text-decoration: none;

        cursor: pointer;

        transition: background-color 0.2s ease;

        &:hover {
            background-color: ${({ theme }) => theme.palette.action.hover};
        }
    }

    .ais-Pagination-item--disabled .ais-Pagination-link {
        color: ${({ theme }) => theme.palette.action.disabled};
        cursor: not-allowed;
    }

    .ais-Pagination-item--selected .ais-Pagination-link {
        background-color: #042442;

        color: white;

        cursor: default;
    }
`;

const columns: GridColDef[] = [
    {
        field: "name",
        headerName: "Name",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) =>
            params.row.firstName && params.row.lastName
                ? `${params.row.firstName} ${params.row.lastName}`
                : "",
    },

    {
        field: "emailAddress",
        headerName: "Email",
        flex: 0.8,
        valueGetter: (params: GridValueGetterParams) => params.row.emailAddress,
    },
    {
        field: "phoneNumber",
        headerName: "Number",
        flex: 0.4,
        valueGetter: (params: GridValueGetterParams) => params.row.phoneNumber,
    },

    {
        field: "createdAt",
        headerName: "Created at",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.createdAt), globalDateTimeFormat)}`,
    },
    {
        field: "updatedAt",
        headerName: "Updated at",
        flex: 0.5,
        valueGetter: (params: GridValueGetterParams) =>
            `${format(new Date(params.row.updatedAt), globalDateTimeFormat)}`,
    },
];

export const CustomersGrid: FunctionComponent = () => {
    const navigate = useNavigate();
    const { hits }: { hits: ICustomerData[] } = useHits();

    const handleRowClick = useCallback(
        (params: GridRowParams) => {
            navigate(`/customers/${params.row.id}`);
        },
        [navigate],
    );

    return (
        <>
            <StyledDataGrid
                autoHeight={true}
                rows={hits}
                columns={columns}
                onRowClick={handleRowClick}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        csvOptions: {
                            disableToolbarButton: false,
                        },
                        printOptions: {
                            disableToolbarButton: true,
                        },
                    },
                }}
            />
            <StyledPaginationContainer>
                <Pagination />
            </StyledPaginationContainer>
        </>
    );
};
