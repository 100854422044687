import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { Authentication } from "../../sdk/utils/api/auth";
import { AdminRole, Department } from "../../utils/enums";

export interface ICreateInviteParameter {
    emailAddress: string;
    role: AdminRole;
    department: Department;
}

export interface ICreateInviteResult {
    invitationUrl: string;
    inviteeId: string;
    inviterId: string;
}

export const useCreateInvite = (): UseMutationResult<
    ICreateInviteResult,
    unknown,
    ICreateInviteParameter
> => {
    const auth = useAuth();
    return useMutation({
        mutationFn: async (parameters: ICreateInviteParameter) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot create invitation link without authorization token.",
                );
            }

            return await Authentication.invite({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
