import {
    FunctionComponent,
    ReactElement,
    useCallback,
    useRef,
    useState,
} from "react";

import {
    Button,
    ButtonBase,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import { Close, CopyAll } from "@mui/icons-material";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useCreateInvite } from "../../hooks";
import { useMessage } from "../../sdk/hooks";
import { IInvitationformValues } from "../../types";
import {
    AdminRole,
    Department,
    userDepartmentNames,
    userRoleNames,
} from "../../utils/enums";

const Root = styled("main")`
    display: flex;
    flex-direction: column;
`;

const StyledDialog = styled(Dialog)`
    .MuiDialog-paper {
        box-shadow: none;
    }
`;

const FormContainer = styled("form")`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};
    padding-top: ${({ theme }) => theme.spacing(3)};
    height: fit-content;

    align-items: center;

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        gap: ${theme.spacing(1.5)};
    }
    `}
`;

const FormHeading = styled(Typography)`
    font-size: 20px;
    font-weight: 500;
`;

const InputHolder = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    width: 100%;
`;

const InputField = styled(TextField)`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    width: 100%;

    & label.Mui-focused {
        color: #a3a3a3;
    }
    & .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
            border-color: #a3a3a3;
        }
    }

    ${({ theme }) => `
    ${theme.breakpoints.down("sm")}{
        font-size: 12px;
        line-height: 16px;
    }
    `}
`;

const SelectField = styled(Select)`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    & .MuiSelect-select.label {
        color: #a3a3a3;
    }
    & .MuiSelect-select {
        &.Mui-focused fieldset {
            border-color: #a3a3a3;
        }
    }

    ${({ theme }) => `
        ${theme.breakpoints.down("sm")}{
            font-size: 12px;
            line-height: 16px;

            width: 100%;
        }
    `}
`;

const SelectInputLabel = styled(InputLabel)``;

const SelectMenuItem = styled(MenuItem)`
    .MuiMenuItem-root {
        font-weight: 400;
        font-size: 16px;
    }
`;

const SubmitButton = styled(Button)`
    max-width: 400px;
`;

const LinkContainer = styled("div")`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};

    margin-top: ${({ theme }) => theme.spacing(1)};
`;

const Header = styled(DialogTitle)`
    width: 100%;
    background-color: #f0f0f0;

    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const FormCloseHandler = styled("div")`
    display: flex;
    justify-content: center;
`;

const FormDrawerCloseButton = styled(ButtonBase)`
    width: 32px;
    height: 32px;
    border-radius: 50%;
`;

const ButtonContainer = styled("div")`
    width: 100%;
    display: flex;
    justify-content: end;
`;

const InfoText = styled(Typography)`
    font-size: 12px;
    color: gray;
`;

export interface IInviteForm {
    emailAddress: string;
    role: AdminRole;
    department: Department;
}

const emailAddressPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

const inviteFormSchema = Yup.object({
    emailAddress: Yup.string()
        .min(2)
        .max(100)
        .required("Invitee email address is required")
        .email()
        .matches(emailAddressPattern, "Enter a valid email address"),
    role: Yup.number().min(1).required("Select the role of the new user"),
    department: Yup.number()
        .min(1)
        .required("Select the department of the new user"),
});

export interface IInviteUserProps {
    open: boolean;
    onChange: () => void;
}

export const InviteUser: FunctionComponent<IInviteUserProps> = (
    props: IInviteUserProps,
): ReactElement => {
    const { open, onChange } = props;
    const [inviteLink, setInviteLink] = useState<string>("");
    const [copyMessage, setCopyMessage] = useState<string>("Copy Link");
    const createInviteMutation = useCreateInvite();
    const inviteLinkRef = useRef<HTMLInputElement | null>(null);
    const { showError, showSuccess } = useMessage();

    const {
        values,
        errors,
        touched,
        resetForm,
        handleSubmit,
        handleChange,
        handleBlur,
    } = useFormik({
        initialValues: {
            emailAddress: "",
            role: AdminRole.GUEST,
            department: Department.DEFAULT,
        },
        onSubmit: (values: IInvitationformValues) => {
            const { emailAddress, role, department } = values;

            createInviteMutation.mutate(
                {
                    emailAddress,
                    role,
                    department,
                },
                {
                    onSuccess: (response) => {
                        setInviteLink(response.invitationUrl);
                        showSuccess("Invitation link generated");
                    },
                    onError: (error: any) => showError(error),
                },
            );
        },
        validationSchema: inviteFormSchema,
    });

    const handleCopyClick = useCallback(async () => {
        try {
            if (inviteLinkRef.current) {
                inviteLinkRef.current.select();
                await navigator.clipboard.writeText(
                    inviteLinkRef.current.value,
                );
            }
            setCopyMessage("Copied!");
        } catch (error) {
            throw new Error(`Could not copy the URL: ${error}`);
        }
    }, [inviteLinkRef]);

    const handleFormSubmit = useCallback(() => {
        handleSubmit();
    }, [handleSubmit]);

    const handleCloseForm = useCallback(() => {
        onChange();
        resetForm();
        setInviteLink("");
        setCopyMessage("Copy Link");
    }, [onChange, resetForm]);

    return (
        <StyledDialog open={open} maxWidth="xs" fullWidth={true}>
            <Root>
                <Header>
                    <FormHeading>Invite user</FormHeading>
                    <FormCloseHandler>
                        <FormDrawerCloseButton onClick={handleCloseForm}>
                            <Close />
                        </FormDrawerCloseButton>
                    </FormCloseHandler>
                </Header>
                <DialogContent>
                    <FormContainer>
                        <InputHolder>
                            <InputField
                                label="Email address"
                                variant="outlined"
                                name="emailAddress"
                                size="small"
                                value={values.emailAddress}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                    touched.emailAddress &&
                                    Boolean(errors.emailAddress)
                                }
                                fullWidth={true}
                                helperText={
                                    touched.emailAddress && errors.emailAddress
                                }
                                disabled={Boolean(inviteLink)}
                            />
                        </InputHolder>

                        <FormControl variant="outlined" fullWidth={true}>
                            <SelectInputLabel>Role</SelectInputLabel>
                            <SelectField
                                name="role"
                                value={values.role}
                                onChange={handleChange}
                                label="Role"
                                size="small"
                                disabled={Boolean(inviteLink)}
                                fullWidth={true}
                            >
                                {Object.entries(AdminRole)
                                    .filter(
                                        ([key, value]) =>
                                            typeof value === "string",
                                    )
                                    .map(([key, value]) => (
                                        <SelectMenuItem key={key} value={key}>
                                            {userRoleNames[Number(key)]}
                                        </SelectMenuItem>
                                    ))}
                            </SelectField>
                        </FormControl>

                        <FormControl variant="outlined" fullWidth={true}>
                            <SelectInputLabel>Department</SelectInputLabel>
                            <SelectField
                                name="department"
                                value={values.department}
                                onChange={handleChange}
                                disabled={Boolean(inviteLink)}
                                label="Department"
                                size="small"
                                fullWidth={true}
                            >
                                {Object.entries(Department)
                                    .filter(
                                        ([key, value]) =>
                                            typeof value === "string",
                                    )
                                    .map(([key, value]) => (
                                        <SelectMenuItem key={key} value={key}>
                                            {userDepartmentNames[Number(key)]}
                                        </SelectMenuItem>
                                    ))}
                            </SelectField>
                            {inviteLink && (
                                <LinkContainer>
                                    <TextField
                                        inputRef={inviteLinkRef}
                                        fullWidth={true}
                                        label="Invitation link"
                                        size="small"
                                        value={inviteLink}
                                        variant="outlined"
                                        margin="normal"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <InfoText>
                                        Note: The above invitation link is
                                        visible only once and remains valid for
                                        24 hours
                                    </InfoText>
                                </LinkContainer>
                            )}
                        </FormControl>

                        {!inviteLink && (
                            <ButtonContainer>
                                <SubmitButton
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled={inviteLink !== ""}
                                    onClick={handleFormSubmit}
                                >
                                    Generate Link
                                </SubmitButton>
                            </ButtonContainer>
                        )}
                        {inviteLink && (
                            <ButtonContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={handleCopyClick}
                                    startIcon={<CopyAll />}
                                >
                                    {copyMessage}
                                </Button>
                            </ButtonContainer>
                        )}
                    </FormContainer>
                </DialogContent>
            </Root>
        </StyledDialog>
    );
};
