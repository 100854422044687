import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { ICollection } from "../types";
import { collections } from "../utils/api";

import { useAuth } from "./useAuth";

interface IWeightedVariant {
    id: string;
    manualScore: number;
}

export interface IUseUpdateCollectionMutationParameters {
    title: string;
    description: string;
    slug: string;
    sortOrder: number;
    id: string;
    variants: IWeightedVariant[];
}

export const useUpdateCollection = (
    invalidQueryKeys: string | string[],
): UseMutationResult<
    unknown,
    ICollection,
    IUseUpdateCollectionMutationParameters
> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (
            parameters: IUseUpdateCollectionMutationParameters,
        ) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot update product collection without authorization token.",
                );
            }
            return await collections.ProductCollection.updateCollection({
                token: auth.token,
                ...parameters,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(invalidQueryKeys);
        },
    });
};
