import { FunctionComponent, ReactElement, useCallback } from "react";

import { Button, Card, Chip, Paper, Typography, styled } from "@mui/material";

import { ImageNotSupported, WarningAmberOutlined } from "@mui/icons-material";

import { Counter } from "../../../../components/common";
import { useUpdateCartLineItem } from "../../../../sdk/hooks";
import { ICart, IProductVariant } from "../../../../sdk/types";
import { formatCurrency } from "../../../../sdk/utils/numbers";
import { ProductStatus, productStatusMap } from "../../../../utils/enums";

const Root = styled(Card)`
    padding: ${({ theme }) => theme.spacing(1)};

    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const TitleContainer = styled("div")`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing(0, 1)};
`;

const Title = styled(Typography)`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
`;

const Price = styled(Typography)`
    font-size: 16px;
`;

const PrimaryImage = styled("img")`
    object-fit: contain;
    width: 100%;
`;

const CounterWrapper = styled(Paper)`
    height: 32px;

    place-items: center;
`;

const BrokenImage = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 5/6;

    background-color: #f4f4f4;
`;

const CtaWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    justify-content: end;
`;

const Oos = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;

    gap: ${({ theme }) => theme.spacing(1)};
`;

const Freebie = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;

    gap: ${({ theme }) => theme.spacing(1)};
`;

const FreebieTypography = styled(Typography)`
    font-size: 12px;
    color: #ed6c02;
`;

export interface IStatusProps {
    status: ProductStatus;
}

const statusColorMap: Record<ProductStatus, string> = {
    [ProductStatus.DRAFT]: "gray",
    [ProductStatus.PUBLISHED]: "green",
    [ProductStatus.ARCHIVED]: "red",
};

const StyledChip = styled(Chip)<IStatusProps>`
    height: 24px;

    color: ${({ status }) => statusColorMap[status]};
    border-color: ${({ status }) => statusColorMap[status]};
`;

export interface IProductVariantProps {
    variant: IProductVariant;
    productTitle?: string;
    quantityInCart: number;
    setCart: React.Dispatch<React.SetStateAction<ICart | undefined>>;
    cartId: string | undefined;
    oos: boolean;
}

export const ProductVariant: FunctionComponent<IProductVariantProps> = (
    props: IProductVariantProps,
): ReactElement => {
    const { variant, productTitle, quantityInCart, setCart, cartId, oos } =
        props;
    const updateCartLineItemMutation = useUpdateCartLineItem();

    const freebieVariant = variant.price === 0;

    const disabled =
        !cartId ||
        updateCartLineItemMutation.isLoading ||
        !variant.shopifyVariantId;

    const handleUpdateCart = useCallback(
        (quantity: number) => {
            if (!cartId) {
                throw new Error("Buy Now should not be enabled without cart.");
            }

            updateCartLineItemMutation.mutate(
                {
                    cartId,
                    lineItemId: variant.shopifyVariantId,
                    quantity,
                },
                {
                    onSuccess: (cart) => {
                        setCart(cart);
                    },
                },
            );
        },
        [cartId, setCart, updateCartLineItemMutation, variant.shopifyVariantId],
    );

    const handleAddToCart = useCallback(() => {
        handleUpdateCart(quantityInCart + 1);
    }, [handleUpdateCart, quantityInCart]);

    return (
        <Root>
            {variant.images[0]?.objectUrl && (
                <PrimaryImage
                    src={variant.images[0]?.objectUrl}
                    alt={`${variant.sku}-${variant.title}`}
                />
            )}
            {!variant.images[0]?.objectUrl && (
                <BrokenImage>
                    <ImageNotSupported fontSize="large" />
                </BrokenImage>
            )}
            <TitleContainer>
                {!productTitle && <Title>{variant.title}</Title>}
                {productTitle && (
                    <Title>{`${productTitle} - ${variant.title}`}</Title>
                )}
                <Price>{formatCurrency(variant.price)}</Price>
            </TitleContainer>
            <StyledChip
                variant="outlined"
                status={variant.status}
                label={productStatusMap[variant.status]}
            />
            {oos && (
                <Oos>
                    <WarningAmberOutlined color="error" fontSize="small" />
                    <Typography color="error">Out of stock</Typography>
                </Oos>
            )}
            {freebieVariant && (
                <Freebie>
                    <WarningAmberOutlined color="warning" fontSize="small" />
                    <FreebieTypography color="warning">
                        Freebie product
                    </FreebieTypography>
                </Freebie>
            )}
            <CtaWrapper>
                {quantityInCart === 0 && (
                    <Button
                        variant="contained"
                        onClick={handleAddToCart}
                        disabled={disabled}
                    >
                        Add to Cart
                    </Button>
                )}
                {quantityInCart > 0 && (
                    <CounterWrapper elevation={0} variant="outlined">
                        <Counter
                            value={quantityInCart}
                            loading={
                                updateCartLineItemMutation.isLoading || disabled
                            }
                            onChange={handleUpdateCart}
                        />
                    </CounterWrapper>
                )}
            </CtaWrapper>
        </Root>
    );
};
