import React, { FunctionComponent, ReactElement, ReactNode } from "react";

import { styled } from "@mui/material";

const Root = styled("div")`
    border: 1px solid #d9d9d9;
    border-radius: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(3)};
`;

export interface PaperProps {
    children: ReactNode;
}

export const Paper: FunctionComponent<PaperProps> = (
    props: PaperProps,
): ReactElement => {
    const { children } = props;
    return <Root>{children}</Root>;
};
