import { useMutation, useQueryClient } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { api } from "../../sdk/utils";
import { DiscountCodeStatus } from "../../utils/enums";

export interface ICreateDiscountParameter {
    priceRuleId: string;
    codes: {
        code: string;
        status: DiscountCodeStatus;
    }[];
}

export const useCreateDiscount = (queryKey: string | string[]) => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (parameters: ICreateDiscountParameter) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot create discount code without authorization token.",
                );
            }

            return await api.discounts.createDiscount({
                ...parameters,
                token: auth.token,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });
};
