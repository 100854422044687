import { FunctionComponent, ReactElement, useCallback } from "react";

import { Checkbox, FormControlLabel, styled } from "@mui/material";

import { Info } from "@mui/icons-material";

import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { FormikErrors } from "formik";

import { Paper } from "../../../components/common";

import {
    DiscountSection,
    IPriceRuleForm,
    SectionTitle,
    SectionTitleContainer,
    StyledTooltip,
} from "./common";

const DateContainer = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          flex-direction: column;
    align-items: start;
    }
  `}
`;

export interface IActivationSectionProps {
    values: IPriceRuleForm;
    onChange: (e: React.ChangeEvent<any>) => void;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
    ) => Promise<void> | Promise<FormikErrors<IPriceRuleForm>>;
}

export const ActivationSection: FunctionComponent<IActivationSectionProps> = (
    props: IActivationSectionProps,
): ReactElement => {
    const { values, onChange, setFieldValue } = props;

    const handleStartDateChange = useCallback(
        (newValue: Date | null) => {
            setFieldValue("startDate", newValue);
        },
        [setFieldValue],
    );

    const handleStartTimeChange = useCallback(
        (newValue: Date | null) => {
            setFieldValue("startTime", newValue);
        },
        [setFieldValue],
    );

    const handleEndDateChange = useCallback(
        (newValue: Date | null) => {
            setFieldValue("endDate", newValue);
        },
        [setFieldValue],
    );

    const handleEndTimeChange = useCallback(
        (newValue: Date | null) => {
            setFieldValue("endTime", newValue);
        },
        [setFieldValue],
    );

    return (
        <Paper>
            <DiscountSection>
                <SectionTitleContainer>
                    <SectionTitle>Activation</SectionTitle>
                    <StyledTooltip
                        enterTouchDelay={0}
                        placement="top"
                        title={
                            "Specifies the duration within which the discount is going to remain active."
                        }
                    >
                        <Info htmlColor="#626262" fontSize="small" />
                    </StyledTooltip>
                </SectionTitleContainer>
                <DateContainer>
                    <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                            label="Start date"
                            value={values.startDate}
                            onChange={handleStartDateChange}
                        />
                    </DemoContainer>
                    <DemoContainer components={["DatePicker"]}>
                        <TimePicker
                            label="Start time"
                            value={values.startTime}
                            onChange={handleStartTimeChange}
                        />
                    </DemoContainer>
                </DateContainer>
                <FormControlLabel
                    name="enableEndTime"
                    label="Set end time"
                    control={
                        <Checkbox
                            checked={values.enableEndTime}
                            onChange={onChange}
                        />
                    }
                />
                {values.enableEndTime && (
                    <DateContainer>
                        <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                                label="End date"
                                value={values.endDate}
                                onChange={handleEndDateChange}
                            />
                        </DemoContainer>
                        <DemoContainer components={["DatePicker"]}>
                            <TimePicker
                                label="End time"
                                value={values.endTime}
                                onChange={handleEndTimeChange}
                            />
                        </DemoContainer>
                    </DateContainer>
                )}
            </DiscountSection>
        </Paper>
    );
};
