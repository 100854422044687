import { useMemo } from "react";

import { useGetAllProducts } from "./useGetAllProducts";

interface IGetProductVariantParams {
    key: string | string[];
    variantId: string;
    status: string[];
}
export const useGetProductVariantById = (
    parameters: IGetProductVariantParams,
) => {
    const { key, variantId, status } = parameters;
    const products = useGetAllProducts(key, { status });

    const productVariant = useMemo(() => {
        if (products.isSuccess) {
            const variants: Record<string, any> = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const product of Object.values(products.data as any)) {
                // eslint-disable-next-line no-restricted-syntax
                for (const variant of (product as any).variants) {
                    variants[variant.shopifyVariantId] = {
                        ...variant,
                        product,
                    };
                }
            }
            return variants[variantId];
        }
        return undefined;
    }, [products.data, products.isSuccess, variantId]);

    return { variant: productVariant, isLoading: products.isLoading };
};
