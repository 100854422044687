import React, { FunctionComponent, ReactElement } from "react";

import { Typography, styled } from "@mui/material";

import { format } from "date-fns";
import { useParams } from "react-router-dom";

import { Paper } from "../../components/common";
import {
    useGetBulkOrderById,
    useGetProductVariantByIds,
} from "../../sdk/hooks";
import { reverseCountryMapping } from "../../utils/constants";

const Root = styled("main")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(3)};
    margin: ${({ theme }) => theme.spacing(0, "auto")};

    ${({ theme }) => `
        ${theme.breakpoints.down("md")} {
            gap: ${theme.spacing(3)};
    }
  `}
`;

const Title = styled(Typography)`
    padding: ${({ theme }) => theme.spacing(0, 3, 3, 3)};

    font-size: ${({ theme }) => theme.spacing(3)};
    font-weight: 600;
    text-transform: capitalize;
`;

const SectionTitle = styled(Typography)`
    font-size: ${({ theme }) => theme.spacing(3)};
    font-weight: 500;
    color: black;

    ${({ theme }) => `
        ${theme.breakpoints.down("lg")} {
            font-size: ${theme.spacing(2)};
    }
  `}

    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const ItemsContainer = styled("div")`
    display: flex;
    justify-content: space-between;

    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const ItemDateContainer = styled("div")`
    display: flex;
    flex-direction: column;
`;

const DateHeader = styled("div")`
    font-size: ${({ theme }) => theme.spacing(2)};
    font-weight: 500;
    color: #6a6a6a;

    display: flex;
    flex-direction: row-reverse;
`;

const DateContent = styled("div")`
    font-size: ${({ theme }) => theme.spacing(2)};
    font-weight: 600;
    color: #202020;

    display: flex;
    flex-direction: row-reverse;
`;

const ItemContainer = styled("div")`
    display: flex;
    flex-direction: column;

    margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const ItemDataContainer = styled("div")`
    font-size: ${({ theme }) => theme.spacing(2)};
    font-weight: 500;
    color: black;
`;

const ItemDataReasonContainer = styled("div")`
    width: 100%;
    min-height: 120px;

    border-radius: 4px;

    font-size: ${({ theme }) => theme.spacing(2)};
    font-weight: 500;

    color: #8e8e8e;
    background-color: #efefef;

    padding: ${({ theme }) => theme.spacing(1)};
`;

const ItemDataHeader = styled("div")`
    font-size: ${({ theme }) => theme.spacing(2)};
    font-weight: 400;
    color: #6a6a6a;
`;

const LeftContainerr = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};
    flex: 1;

    width: 60%;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      max-width: unset;
    }
    
  `}
`;

const RightContainer = styled("section")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};

    width: 40%;

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
          padding-top: 0px;
    }
    
  `}
`;

const TopContainer = styled("div")`
    padding: ${({ theme }) => theme.spacing(0, 3, 3, 3)};

    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => `
    ${theme.breakpoints.down("md")} {
      flex-direction:column;
      gap: ${theme.spacing(3)};
    }
  `}
`;

const Product = styled("div")`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};

    width: 100%;
    height: 100%;
`;

const ProductContainer = styled("div")`
      display:flex;
      flex-direction:row;
      justify-content:space-between;
      
      background-color:#EFEFEF;

      width:100%;

      border-radius:4px;
      padding:${({ theme }) => theme.spacing(3)}
      margin-top:${({ theme }) => theme.spacing(2)}

     
`;

const ProductDetails = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};
    justify-content: space-between;

    padding: ${({ theme }) => theme.spacing(0, 2)};

    width: 100%;
    height: 100%;
`;

const ProductContentDetails = styled("div")`
    display: flex;
    gap: ${({ theme }) => theme.spacing(2)};

    padding: ${({ theme }) => theme.spacing(2)};

    height: 100%;
`;

const ItemQuantityDetails = styled("div")`
    display: flex;
    flex-direction: row-reverse;
`;

const ItemQuantityColumn = styled("div")`
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
`;

const Spacer = styled("div")`
    flex: 0.8;
`;

const ItemQuantityDataHeader = styled("div")`
    font-size: ${({ theme }) => theme.spacing(2)};
    font-weight: 400;
    color: #6a6a6a;

    padding-right: ${({ theme }) => theme.spacing(1)};
`;

const ItemQuantityContainer = styled("div")`
    display: flex;
    justify-content: space-between;
`;

const ItemQuantityData = styled("div")`
    font-size: ${({ theme }) => theme.spacing(2)};
    font-weight: 600;
    color: #202020;

    display: flex;
`;

const ItemQuantity = styled("div")`
    font-size: ${({ theme }) => theme.spacing(2)};
    font-weight: 400;
    color: #6a6a6a;

    display: flex;

    padding-right: ${({ theme }) => theme.spacing(1)};
`;

const ProductImage = styled("img")`
    height: 140px;
    width: 140px;

    object-fit: cover;
    border-radius: ${({ theme }) => theme.spacing(1)};
`;

const ProductTitle = styled("div")`
    font-size: ${({ theme }) => theme.spacing(3)};
    font-weight: 500;
    color: #202020;
    padding-left: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => `
    ${theme.breakpoints.down("lg")} {
      flex-direction:column;
      gap: ${theme.spacing(1)};
    }
  `}
`;

const formatPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/^(\+\d{2})(\d+)/, "$1 $2");
};

export const BulkOrderScreen: FunctionComponent = (): ReactElement => {
    const params = useParams();

    const bulkOrderQuery = useGetBulkOrderById(
        "bulk-detail",
        params.bulkOrderId ?? "",
    );

    const productVariants = useGetProductVariantByIds({
        variantIds:
            bulkOrderQuery?.data?.bulkOrderLineItems.map(
                (item) => item.variantId,
            ) ?? [],
        key: "variants",
    });

    const bulkOrderData = [
        {
            header: "Name",
            data: `${bulkOrderQuery.data?.bulkOrder.firstName} ${bulkOrderQuery.data?.bulkOrder.lastName}`,
        },
        {
            header: "Phone Number",
            data:
                bulkOrderQuery?.data?.bulkOrder.phoneNumber &&
                formatPhoneNumber(bulkOrderQuery?.data?.bulkOrder.phoneNumber),
        },
        {
            header: "Email",
            data: bulkOrderQuery.data?.bulkOrder.emailAddress ?? "NA",
        },
        {
            header: "Country",
            data: bulkOrderQuery.data?.bulkOrder.country
                ? reverseCountryMapping[bulkOrderQuery.data?.bulkOrder.country]
                : "NA",
        },
        {
            header: "Total Asking Price",
            data: `₹${bulkOrderQuery.data?.bulkOrder.totalAskingPrice}`,
        },
        {
            header: "Total Selling Price",
            data: `₹${bulkOrderQuery.data?.bulkOrder.totalSellingPrice}`,
        },
        {
            header: "Scheduled Call Date",
            data:
                bulkOrderQuery.data?.bulkOrder.scheduledCallDate &&
                format(
                    new Date(
                        bulkOrderQuery.data?.bulkOrder.scheduledCallDate ?? "",
                    ),
                    "do MMMM yyyy",
                ),
        },
        {
            header: "Scheduled Call Start Time",
            data: bulkOrderQuery.data?.bulkOrder.scheduledCallStartTime,
        },
        {
            header: "Scheduled Call End Time",
            data: bulkOrderQuery.data?.bulkOrder.scheduledCallEndTime,
        },
    ];

    return (
        <Root>
            <Title>Bulk Order Request</Title>
            <TopContainer>
                <LeftContainerr>
                    <Paper>
                        <ItemsContainer>
                            <SectionTitle>Products Requested</SectionTitle>
                            <ItemDateContainer>
                                <DateHeader>Request received on:</DateHeader>
                                <DateContent>
                                    {bulkOrderQuery &&
                                        bulkOrderQuery.data?.bulkOrder
                                            .createdAt &&
                                        format(
                                            new Date(
                                                bulkOrderQuery.data?.bulkOrder
                                                    .createdAt ?? "",
                                            ),
                                            "do MMMM yyyy",
                                        )}
                                </DateContent>
                            </ItemDateContainer>
                        </ItemsContainer>
                        <Product>
                            {productVariants.variants.length > 0 &&
                                bulkOrderQuery.data &&
                                bulkOrderQuery.data?.bulkOrderLineItems.length >
                                    0 &&
                                productVariants.variants.map((item, index) => {
                                    return (
                                        <ProductContainer>
                                            <ProductDetails>
                                                <ProductContentDetails>
                                                    {item.images.length > 0 &&
                                                        item.images[0]
                                                            .objectUrl && (
                                                            <ProductImage
                                                                src={
                                                                    item
                                                                        .images[0]
                                                                        ?.objectUrl
                                                                }
                                                                alt={
                                                                    item.product
                                                                        .title
                                                                }
                                                            />
                                                        )}
                                                    <ProductTitle>
                                                        {item.product.title}
                                                    </ProductTitle>
                                                </ProductContentDetails>

                                                <ItemQuantityDetails>
                                                    <ItemQuantityColumn>
                                                        <Spacer />
                                                        <ItemQuantityContainer>
                                                            <Spacer />
                                                            <ItemQuantityData>
                                                                <ItemQuantity>
                                                                    Quantity:
                                                                </ItemQuantity>
                                                                {bulkOrderQuery
                                                                    .data
                                                                    ?.bulkOrderLineItems[
                                                                    index
                                                                ]?.quantity ??
                                                                    "NA"}
                                                            </ItemQuantityData>
                                                        </ItemQuantityContainer>
                                                        <ItemQuantityContainer>
                                                            <ItemQuantityDataHeader>
                                                                Asking Price:
                                                            </ItemQuantityDataHeader>
                                                            <ItemQuantityData>
                                                                ₹
                                                                {bulkOrderQuery
                                                                    .data
                                                                    ?.bulkOrderLineItems[
                                                                    index
                                                                ]
                                                                    ?.askingPrice ??
                                                                    "NA"}
                                                            </ItemQuantityData>
                                                        </ItemQuantityContainer>
                                                    </ItemQuantityColumn>
                                                </ItemQuantityDetails>
                                            </ProductDetails>
                                        </ProductContainer>
                                    );
                                })}
                        </Product>
                    </Paper>
                </LeftContainerr>
                {bulkOrderQuery.data?.bulkOrderLineItems &&
                    bulkOrderQuery.data?.bulkOrderLineItems?.length > 0 && (
                        <RightContainer>
                            <Paper>
                                <SectionTitle>Requester Details</SectionTitle>
                                {bulkOrderData.length > 0 &&
                                    bulkOrderData.map((detail) => {
                                        return (
                                            <ItemContainer>
                                                <ItemDataHeader>
                                                    {detail.header}
                                                </ItemDataHeader>
                                                <ItemDataContainer>
                                                    {detail.data}
                                                </ItemDataContainer>
                                            </ItemContainer>
                                        );
                                    })}
                                <ItemDataHeader>
                                    <ItemDataHeader>Reason</ItemDataHeader>
                                    <ItemDataReasonContainer>
                                        {bulkOrderQuery?.data?.bulkOrder
                                            .reason ?? ""}
                                    </ItemDataReasonContainer>
                                </ItemDataHeader>
                            </Paper>
                        </RightContainer>
                    )}
            </TopContainer>
        </Root>
    );
};
