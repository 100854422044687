import axios from "axios";

import * as api from "../types";
import { EntityAttributeType } from "../enums";

export interface IGetEntitySchemaByIdResponse {
    entitySchema: api.IInflatedEntitySchema;
}

export interface ICreateEntitySchemaParams {
    token: string;
    name: string;
    description: string;
    entityGroups: [
        {
            name: string;
            description: string;
            entityAttributes: [
                {
                    name: string;
                    type: EntityAttributeType;
                    required: boolean;
                    default?: JSON;
                    exclusive?: boolean;
                    min?: number;
                    max?: number;
                    pattern?: number;
                    entityAttributeOptions: [
                        {
                            name: string;
                            value: JSON;
                        },
                    ];
                },
            ];
        },
    ];
}

export interface IUpdateEntitySchemaByIdParams {
    token: string;
    schemaId: string;
    name: string;
    description: string;
}

export interface IGetAllEntitySchemaParameter {
    limit: number;
    token: string;
    cursor: string | null;
    direction: string;
}

export interface IDeleteEntitySchemaParameter {
    token: string;
    schemaId: string;
}

export class EntitySchema {
    private static transform = (
        data: api.IInflatedEntitySchema,
    ): api.IInflatedEntitySchema => {
        return {
            ...data,
            createdAt: new Date(data.createdAt),
            updatedAt: new Date(data.updatedAt),
            entityGroups: data.entityGroups?.map((group) => ({
                ...group,
                createdAt: new Date(group.createdAt),
                updatedAt: new Date(group.updatedAt),
                entityAttributes: group.entityAttributes.map((attribute) => ({
                    ...attribute,
                    createdAt: new Date(attribute.createdAt),
                    updatedAt: new Date(attribute.updatedAt),
                    entityAttributeOptions:
                        attribute.entityAttributeOptions.map((option) => ({
                            ...option,
                            createdAt: new Date(option.createdAt),
                            updatedAt: new Date(option.updatedAt),
                        })),
                })),
            })),
        };
    };

    public static async create(
        parameters: ICreateEntitySchemaParams,
    ): Promise<api.IInflatedEntitySchema> {
        const { token, ...body } = parameters;
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return this.transform(data);
    }

    public static async getById(
        schemaId: string,
        token: string,
    ): Promise<api.IInflatedEntitySchema> {
        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/${schemaId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        return this.transform(data);
    }

    static async getAll(
        parameters: IGetAllEntitySchemaParameter,
    ): Promise<api.IEntitySchema> {
        const { token, ...body } = parameters;
        const params = new URLSearchParams([
            ["limit", body.limit.toString()],
            ["direction", body.direction],
        ]);

        if (body.cursor) {
            params.append("cursor", body.cursor);
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas?${params}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        return {
            ...data,
            records: data.records.map(EntitySchema.transform),
        };
    }

    public static async delete(
        parameters: IDeleteEntitySchemaParameter,
    ): Promise<void> {
        const { token, schemaId } = parameters;
        await axios.delete(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/${schemaId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
    }

    public static async update(
        parameters: IUpdateEntitySchemaByIdParams,
    ): Promise<api.IEntitySchema> {
        const { token, schemaId, ...body } = parameters;
        const { data } = await axios.patch(
            `${process.env.REACT_APP_API_BASE_URL}/api/admins/v1/eavs/schemas/${schemaId}`,
            body,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return this.transform(data);
    }
}
