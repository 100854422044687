import { format } from "date-fns";

/* Create number formatter */
const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
});

export const formatCurrency = (value: number) => formatter.format(value);

export const formatDate = (value: Date) => format(value, "d MMM, yyyy");
