import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { api } from "../../utils";

export interface IDeleteEntityGroupParameters {
    groupId: string;
}

export const useDeleteEntityGroup = (
    queryKey: string | string[],
): UseMutationResult<void, unknown, IDeleteEntityGroupParameters> => {
    const auth = useAuth();
    return useMutation({
        mutationFn: async (parameters: IDeleteEntityGroupParameters) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot delete entity group without authorization token.",
                );
            }

            return await api.EntityGroup.delete({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
