import { UseMutationResult, useMutation, useQueryClient } from "react-query";

import { ICustomerAddress } from "../types";
import { api } from "../utils";

import { useAuth } from "./useAuth";

export interface IUseUpdateCustomerAddressMutationParameters {
    id: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    countryCode: string;
    company: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    stateCode: string;
    zip: string;
    name: string;
    defaultAddress: boolean;
    customerId: string;
}

export const useUpdateCustomerAddress = (
    invalidQueryKeys: string[] = [],
): UseMutationResult<
    ICustomerAddress,
    unknown,
    IUseUpdateCustomerAddressMutationParameters
> => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    return useMutation<
        ICustomerAddress,
        unknown,
        IUseUpdateCustomerAddressMutationParameters
    >({
        mutationFn: async (
            values: IUseUpdateCustomerAddressMutationParameters,
        ): Promise<ICustomerAddress> => {
            if (!auth.token) {
                throw new Error(
                    "Cannot update customer address without valid token.",
                );
            }
            const response = await api.addresses.updateCustomerAddress({
                ...values,
                token: auth.token,
            });
            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries(invalidQueryKeys);
        },
    });
};
