import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { IJwtSession } from "../../sdk/types";
import { Authentication } from "../../sdk/utils/api/auth";

export interface ISignInParameters {
    code: string;
    redirectUrl: string;
}

/**
 * Returns a mutation that calls the REST API for completing Google authentication.
 * Once the email address is verified, "user" and "token" values are automatically
 * updated in the authentication context and local storage.
 */
export const useSignIn = (): UseMutationResult<
    IJwtSession,
    unknown,
    ISignInParameters
> => {
    const { setUser, setToken } = useAuth();

    return useMutation<IJwtSession, unknown, ISignInParameters>({
        mutationFn: async (
            parameters: ISignInParameters,
        ): Promise<IJwtSession> =>
            await Authentication.signIn({
                code: parameters.code,
                redirectUrl: parameters.redirectUrl,
            }),
        onSuccess: async (session) => {
            const { admin, jwtToken } = session;

            /*
             * Save the user and token to local storage. Also, update the
             * authentication context to propogate these values to other
             * children.
             */
            setUser(admin);
            setToken(jwtToken);
        },
    });
};
