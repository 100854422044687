import {
    FunctionComponent,
    ReactElement,
    SyntheticEvent,
    useCallback,
    useMemo,
    useState,
} from "react";

import {
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    Typography,
    styled,
} from "@mui/material";

import { ChevronRight } from "@mui/icons-material";

import Fuse from "fuse.js";

import { SearchBar } from "../../../../components/common";
import { CircularLoader } from "../../../../components/common/CircularLoader";
import { useGetAllProducts } from "../../../../sdk/hooks";
import { ICart } from "../../../../sdk/types";
import { IProduct } from "../../../../types";
import { ProductStatus } from "../../../../utils/enums";

import { ProductVariant } from "./ProductVariant";

const ProductsContainer = styled("div")`
    flex: 3;
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.spacing(2)};
    max-width: 60%;

    ${({ theme }) => theme.breakpoints.down("md")} {
        max-width: 100%;
    }
`;

const SubTitle = styled(Typography)`
    font-size: 18px;
    font-weight: 500;
`;

const TitleRow = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const MoreOptions = styled("div")`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};
`;

const ProductAccordion = styled(MuiAccordion)`
    width: 100%;

    &:before {
        display: none;
    }
    font-family: "Poppins";

    border: 1px solid rgba(0, 0, 0, 0.12);
`;

const AccordionSummary = styled(MuiAccordionSummary)`
    flex-direction: row;

    & .MuiAccordionSummary-expandIconWrapper {
        transform: rotate(90deg);
    }

    & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        transform: rotate(-90deg);
    }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
    background-color: rgba(240, 240, 240, 1);

    display: grid;
    gap: ${({ theme }) => theme.spacing(2)};
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
`;

const SearchResults = styled("div")`
    background-color: rgba(240, 240, 240, 1);

    display: grid;
    gap: ${({ theme }) => theme.spacing(2)};
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

    padding: ${({ theme }) => theme.spacing(1)};
`;

export interface ISelectProductsProps {
    cart: ICart;
    setCart: React.Dispatch<React.SetStateAction<ICart | undefined>>;
}

const getProductVariants = (products: IProduct[]) =>
    products.flatMap((product) =>
        product.variants
            .filter((variant) => variant.price > 0)
            .map((variant) => {
                return {
                    productTitle: product.title,
                    variantTitle: variant.title,
                    fullTitle: `${product.slug} ${variant.title}`,
                    slug: product.slug,
                    tagLine: product.tagLine.trim() ?? "",
                    meta: product.meta,
                    tags: product.meta?.tags ?? [],
                    occasions: product.meta.occasions,
                    ...variant,
                };
            }),
    );

export const SelectProducts: FunctionComponent<ISelectProductsProps> = (
    props: ISelectProductsProps,
): ReactElement => {
    const { cart, setCart } = props;
    const getAllProductsQuery = useGetAllProducts(
        ["products", ProductStatus.PUBLISHED.toString()],
        {
            status: [ProductStatus.PUBLISHED.toString()],
        },
    );

    const fuse = useMemo(
        () =>
            new Fuse(getProductVariants(getAllProductsQuery.data ?? []), {
                keys: [
                    "productTitle",
                    "tagLine",
                    "fullTitle",
                    "variantTitle",
                    "tags",
                    "occasions",
                ],
                threshold: 0.3,
            }),
        [getAllProductsQuery.data],
    );

    const [expanded, setExpanded] = useState<string | false>(false);
    const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
    const [searchString, setSearchString] = useState("");
    const products = getAllProductsQuery.data ?? [];

    /* Sort the products in alphabetical order */
    products.sort((productA, productB) =>
        productA.title.localeCompare(productB.title),
    );

    const handleAccordionChange = useCallback(
        (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        },
        [setExpanded],
    );

    const handleSearchStringChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchString(e.target.value);
        },
        [],
    );

    const handleClearSearch = useCallback(() => {
        setSearchString("");
        setFilteredProducts([]);
    }, []);

    const handleSearch = useCallback(() => {
        const results = fuse.search(searchString);
        setFilteredProducts(results.map((searchResult) => searchResult.item));
    }, [fuse, searchString]);

    const getQuantityInCart = (variantId: string) => {
        const variant = cart?.items.find(
            (item) => item.variantId === variantId,
        );
        return variant?.quantity;
    };

    if (getAllProductsQuery.isLoading) {
        return <CircularLoader />;
    }

    return (
        <ProductsContainer>
            <TitleRow>
                <SubTitle>
                    {filteredProducts.length > 0
                        ? `${filteredProducts.length} products found`
                        : "All products"}
                </SubTitle>
                <MoreOptions>
                    <SearchBar
                        value={searchString}
                        onClear={handleClearSearch}
                        onChange={handleSearchStringChange}
                        onSubmit={handleSearch}
                    />
                </MoreOptions>
            </TitleRow>
            {filteredProducts.length > 0 && (
                <>
                    <SearchResults>
                        {filteredProducts.map((item) => (
                            <ProductVariant
                                cartId={cart?.id}
                                variant={item}
                                productTitle={item.productTitle}
                                quantityInCart={
                                    getQuantityInCart(item.shopifyVariantId) ??
                                    0
                                }
                                setCart={setCart}
                                oos={item.meta?.tags?.includes("oos") ?? false}
                            />
                        ))}
                    </SearchResults>
                    <SubTitle>All products</SubTitle>
                </>
            )}
            {products.map((product, index) => (
                <ProductAccordion
                    disableGutters={true}
                    elevation={0}
                    square={true}
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleAccordionChange(`panel${index}`)}
                >
                    <AccordionSummary
                        id={`notes--accordion--note${index}`}
                        expandIcon={<ChevronRight />}
                    >
                        {product.title}
                    </AccordionSummary>
                    <AccordionDetails>
                        {product.variants.map((variant) => (
                            <ProductVariant
                                cartId={cart?.id}
                                variant={variant}
                                quantityInCart={
                                    getQuantityInCart(
                                        variant.shopifyVariantId,
                                    ) ?? 0
                                }
                                setCart={setCart}
                                oos={
                                    product.meta?.tags?.includes("oos") ?? false
                                }
                            />
                        ))}
                    </AccordionDetails>
                </ProductAccordion>
            ))}
        </ProductsContainer>
    );
};
