import { FunctionComponent, ReactElement, useEffect, useState } from "react";

import { styled } from "@mui/material";

import Form from "@rjsf/core";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";

import { CircularLoader } from "../../components/common";
import { getTemplates, getWidgets } from "../../components/rjsf";
import { useGetEntitySchemaById } from "../../hooks";
import {
    IEntityAttributeOption,
    IInflatedEntityAttribute,
    IInflatedEntityGroup,
    IInflatedEntitySchema,
} from "../../utils";
import { entityAttributeNames } from "../../utils/enums";

const Root = styled("main")`
    margin: ${({ theme }) => theme.spacing(2, 0)};
`;

const widgets = getWidgets();
const templates = getTemplates();

const rjsfSchema: RJSFSchema = {
    type: "object",
    properties: {
        id: { type: "string", title: "ID", default: "" },
        name: {
            type: "string",
            minLength: 1,
            maxLength: 255,
            title: "Name",
            default: "",
        },
        description: {
            type: "string",
            minLength: 1,
            maxLength: 255,
            title: "Description",
            default: "",
        },
        groups: {
            type: "array",
            title: "Groups",
            items: {
                required: ["name"],
                type: "object",
                properties: {
                    id: { type: "string", title: "ID", default: "" },
                    name: {
                        type: "string",
                        minLength: 1,
                        maxLength: 255,
                        title: "Name",
                        default: "",
                    },
                    description: {
                        type: "string",
                        minLength: 1,
                        maxLength: 255,
                        title: "Description",
                        default: "",
                    },
                    schemaId: {
                        type: "string",
                        title: "Schema ID",
                        default: "",
                    },
                    attributes: {
                        type: "array",
                        title: "Attributes",
                        items: {
                            type: "object",
                            properties: {
                                id: {
                                    type: "string",
                                    title: "ID",
                                    default: "",
                                },
                                name: {
                                    type: "string",
                                    minLength: 1,
                                    maxLength: 255,
                                    title: "Name",
                                    default: "",
                                },
                                required: {
                                    type: "boolean",
                                    title: "Required",
                                    default: false,
                                },
                                default: {
                                    type: "string",
                                    title: "Default",
                                    default: "",
                                },
                                attributeType: {
                                    type: "number",
                                    title: "Attribute Type",
                                    default: "Integer",
                                    // enum: [
                                    //     EntityAttributeType.INTEGER,
                                    //     EntityAttributeType.NUMBER,
                                    //     EntityAttributeType.BOOLEAN,
                                    //     EntityAttributeType.SELECT,
                                    //     EntityAttributeType.CHECKLIST,
                                    //     EntityAttributeType.SINGLE_LINE,
                                    //     EntityAttributeType.MULTI_LINE,
                                    //     EntityAttributeType.MARKDOWN,
                                    //     EntityAttributeType.DATE,
                                    //     EntityAttributeType.DATE_TIME,
                                    //     EntityAttributeType.TIME,
                                    // ],
                                    enum: [
                                        "Integer",
                                        "Number",
                                        "Boolean",
                                        "Select",
                                        "Checklist",
                                        "Single Line",
                                        "Multiline",
                                        "Markdown",
                                        "Date",
                                        "Date Time",
                                        "Time",
                                    ],
                                },
                                min: {
                                    type: "number",
                                    title: "Min",
                                    default: 0,
                                },
                                max: {
                                    type: "number",
                                    title: "Max",
                                    default: 0,
                                },
                                pattern: {
                                    type: "string",
                                    minLength: 1,
                                    maxLength: 255,
                                    title: "Pattern",
                                    default: "",
                                },
                                exclusive: {
                                    type: "boolean",
                                    title: "Exclusive",
                                    default: false,
                                },
                                groupId: {
                                    type: "string",
                                    title: "Group ID",
                                    default: "",
                                },
                                options: {
                                    type: "array",
                                    title: "Options",
                                    items: {
                                        type: "object",
                                        properties: {
                                            id: {
                                                type: "string",
                                                title: "ID",
                                                default: "",
                                            },
                                            name: {
                                                type: "string",
                                                minLength: 1,
                                                maxLength: 255,
                                                title: "Name",
                                                default: "",
                                            },
                                            value: {
                                                type: "string",
                                                title: "Value",
                                                default: "",
                                            },
                                            attributeId: {
                                                type: "string",
                                                title: "Attribute ID",
                                                default: "",
                                            },
                                        },
                                        required: ["name", "value"],
                                    },
                                },
                            },
                            dependencies: {
                                attributeType: {
                                    oneOf: [
                                        {
                                            properties: {
                                                attributeType: {
                                                    enum: ["Integer", "Number"],
                                                },
                                                min: {
                                                    type: "number",
                                                    title: "Min",
                                                },
                                                max: {
                                                    type: "number",
                                                    title: "Max",
                                                },
                                            },
                                            required: ["min", "max"],
                                        },
                                        {
                                            properties: {
                                                attributeType: {
                                                    enum: [
                                                        "Boolean",
                                                        "Select",
                                                        "Checklist",
                                                        "Single Line",
                                                        "Multi Line",
                                                        "Markdown",
                                                        "Date",
                                                        "Date Time",
                                                        "Time",
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                            required: ["name", "required", "attributeType"],
                        },
                    },
                },
            },
        },
    },
    required: ["name"],
};

const uiSchema: UiSchema = {
    id: { "ui:widget": "hidden" },
    name: {
        "ui:widget": "text",
        "ui:options": {
            style: { width: "400px" },
        },
    },
    description: {
        "ui:widget": "textarea",
        "ui:options": {
            style: { width: "400px", heigth: "150px" },
        },
    },
    groups: {
        items: {
            id: { "ui:widget": "hidden" },
            name: {
                "ui:widget": "text",
                "ui:options": {
                    style: { width: "400px", margin: "10px 0px 0px 0px" },
                },
            },
            description: {
                "ui:widget": "textarea",
                "ui:options": {
                    style: { width: "400px", heigth: "50px" },
                },
            },
            schemaId: {
                "ui:widget": "hidden",
            },
            attributes: {
                items: {
                    id: { "ui:widget": "hidden" },
                    name: {
                        "ui:widget": "text",
                        "ui:options": {
                            style: {
                                width: "400px",
                                margin: "10px 0px 0px 0px",
                            },
                        },
                    },
                    required: {
                        "ui:widget": "checkbox",
                    },
                    default: {
                        "ui:widget": "textarea",
                        "ui:options": {
                            style: { width: "400px" },
                        },
                    },
                    attributeType: {
                        "ui:widget": "select",
                        "ui:options": {
                            style: { width: "150px" },
                        },
                    },
                    min: {
                        "ui:widget": "text",
                        "ui:options": {
                            style: { width: "150px" },
                        },
                    },
                    max: {
                        "ui:widget": "text",
                        "ui:options": {
                            style: { width: "150px" },
                        },
                    },
                    pattern: {
                        "ui:widget": "text",
                        "ui:options": {
                            style: { width: "400px" },
                        },
                    },
                    exclusive: {
                        "ui:widget": "checkbox",
                    },
                    groupId: {
                        "ui:widget": "hidden",
                    },
                    options: {
                        items: {
                            id: { "ui:widget": "hidden" },
                            name: {
                                "ui:widget": "text",
                                "ui:options": {
                                    style: {
                                        width: "400px",
                                        margin: "10px 0px 0px 0px",
                                    },
                                },
                            },
                            value: {
                                "ui:widget": "textarea",
                                "ui:options": {
                                    style: {
                                        width: "400px",
                                        heigth: "50px",
                                    },
                                },
                            },
                            attributeId: {
                                "ui:widget": "hidden",
                            },
                        },
                    },
                },
            },
        },
    },
};

export interface IPreviewRjsfProps {
    schemaId: string;
}

export const PreviewEntitySchema: FunctionComponent<IPreviewRjsfProps> = (
    props: IPreviewRjsfProps,
): ReactElement => {
    const { schemaId } = props;

    const entitySchemaQuery = useGetEntitySchemaById(
        ["entitySchemas", schemaId ?? ""],
        schemaId,
    );
    const [transformedSchema, setTransformedSchema] = useState<any>();
    const transformResponse = (response: IInflatedEntitySchema) => {
        const { id, name, description, entityGroups } = response;

        const groups = entityGroups.map((group: IInflatedEntityGroup) => {
            const { id, name, description, entityAttributes, entitySchemaId } =
                group;

            const attributes = entityAttributes.map(
                (attribute: IInflatedEntityAttribute) => {
                    const {
                        id,
                        name,
                        type,
                        required,
                        min,
                        max,
                        exclusive,
                        pattern,
                        default: defaultValue,
                        entityAttributeOptions,
                        entityGroupId,
                    } = attribute;

                    const options = entityAttributeOptions.map(
                        (option: IEntityAttributeOption) => {
                            const { id, name, value, entityAttributeId } =
                                option;
                            return {
                                id,
                                name,
                                value: JSON.stringify(value),
                                entityAttributeId,
                            };
                        },
                    );

                    return {
                        id,
                        name,
                        required: Boolean(required),
                        min,
                        max,
                        exclusive,
                        pattern,
                        default: JSON.stringify(defaultValue),
                        attributeType: entityAttributeNames[type],
                        entityGroupId,
                        options: options.reverse(),
                    };
                },
            );

            return {
                id,
                name,
                description,
                entitySchemaId,
                attributes: attributes.reverse(),
            };
        });

        return {
            id,
            name,
            description,
            groups,
        };
    };

    useEffect(() => {
        if (entitySchemaQuery.data) {
            setTransformedSchema(transformResponse(entitySchemaQuery.data));
        }
    }, [entitySchemaQuery.data]);

    if (entitySchemaQuery.isLoading) {
        return <CircularLoader />;
    }

    return (
        <Root>
            <Form
                schema={rjsfSchema}
                uiSchema={uiSchema}
                validator={validator}
                templates={templates}
                widgets={widgets}
                formData={transformedSchema}
                children={true}
                readonly={true}
            />
        </Root>
    );
};
