import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { api } from "../../utils";

export interface IDeleteEntityAttributeParameters {
    attributeId: string;
}

export const useDeleteEntityAttribute = (
    queryKey: string | string[],
): UseMutationResult<void, unknown, IDeleteEntityAttributeParameters> => {
    const auth = useAuth();
    return useMutation({
        mutationFn: async (parameters: IDeleteEntityAttributeParameters) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot delete entity attribute without authorization token.",
                );
            }

            return await api.EntityAttribute.delete({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
