import { UseMutationResult, useMutation } from "react-query";

import { useAuth } from "../../sdk/hooks";
import { PriceRuleAllocationMethod } from "../../sdk/types";
import { IPriceRule } from "../../sdk/types";
import { api } from "../../sdk/utils";
import {
    CustomerSelection,
    DiscountType,
    PriceRuleCategoryType,
    PriceRuleStatus,
} from "../../utils/enums";

export interface ICreatePriceRuleParameters {
    allocationMethod: PriceRuleAllocationMethod;
    customerSelection: CustomerSelection;
    prereqCustomerGroupIds: string[];
    discountType: DiscountType;
    startsAt: Date;
    endsAt: Date | null;
    usageLimitByCustomer: number | null;
    usageLimitByTotal: number | null;
    discountValue: number;
    prereqQuantityMin: number | null;
    prereqSubtotalMin: number | null;
    name: string;
    description: string;
    category: PriceRuleCategoryType;
    prereqPaymentMethod: string | null;
    prereqVariantsRequired: number;
    variantIds: string[];
    status: PriceRuleStatus;
    maxDiscountAmount: number | null;
}

export const useCreatePriceRule = (
    queryKey: string | string[],
): UseMutationResult<IPriceRule, unknown, ICreatePriceRuleParameters> => {
    const auth = useAuth();
    return useMutation<IPriceRule, unknown, ICreatePriceRuleParameters>({
        mutationFn: async (parameters: ICreatePriceRuleParameters) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot create price rule without authorization token.",
                );
            }

            return await api.priceRule.createPriceRule({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
