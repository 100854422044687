import { UseMutationResult, useMutation } from "react-query";

import { ICollection } from "../types";
import { api } from "../utils";
import { collections } from "../utils/api";

import { useAuth } from "./useAuth";

export interface IWeightedVariant {
    id: string;
    manualScore: number;
}

export interface IUseCreateCollectionMutationParameters {
    title: string;
    description: string;
    slug: string;
    sortOrder: number;
    variants: IWeightedVariant[];
}

export const useCreateCollection = (): UseMutationResult<
    ICollection,
    unknown,
    IUseCreateCollectionMutationParameters
> => {
    const auth = useAuth();

    return useMutation({
        mutationFn: (parameters: IUseCreateCollectionMutationParameters) => {
            if (!auth.token) {
                throw new Error(
                    "Cannot create collection without authorization token.",
                );
            }

            return collections.ProductCollection.createCollection({
                ...parameters,
                token: auth.token,
            });
        },
    });
};
